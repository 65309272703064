const styles = (theme) => ({
  addressConfirmBox: {
    margin: '25px 0',
    borderRadius: '4px',
    borderStyle: 'dashed',
    borderWidth: '2px',
    padding: '20px 10px 10px 20px',
    color: '#515357',
    fontWeight: 300
  },
  addressConfirmBoxSelected: {
    borderStyle: 'solid',
    borderColor: '#0071CE',
    fontWeight: 500
  },
  icon: {
    justifyContent: 'start'
  },
  suggestionTitle: {
    marginBottom: '20px',
    fontSize: '14px'
  },
  suggestionTitleSelected: {
    color: '#0071CE',
    fontWeight: 'bold'
  },
  addressContent: {
    fontSize: '15px'
  },
  dialogActions: {
    padding: '8px 24px',
    marginBottom: '30px',
    [theme.breakpoints.down(600)]: {
      '&.MuiDialogActions-spacing > :not(:first-child)': {
        marginLeft: 32
      }
    }
  },
  description: {
    width: '60%',
    fontSize: '16px'
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0
  },
  boxTitle: {
    padding: '2px 0',
    [theme.breakpoints.down(400)]: {
      padding: '2px 5px'
    }
  },
  googleTitle: {
    justifyContent: 'end',
    marginTop: '10px',
    marginBottom: '15px'
  },
  modalPaper: {
    minHeight: '500px'
  },
  textFieldErrorMsg: {
    color: 'rgba(213, 34, 28, 1)',
    fontSize: 12,
    fontWeight: 400,
    textAlign: 'left'
  },
  errorIcon: {
    color: 'rgba(213, 34, 28, 1)',
    height: 20,
    width: 20,
    marginRight: 5
  },
  formControl: {
    width: 342.44,
    margin: '8px 8px 8px 0',
    fontSize: 14,
    borderRadius: 4,
    backgroundColor: '#ffffff',
    [theme.breakpoints.up('sm')]: {
      height: 54,
      fontSize: 14
    },
    [theme.breakpoints.up('md')]: {
      height: 54
    },
    [theme.breakpoints.down(600)]: {
      width: '100%'
    }
  }
});

export default styles;
