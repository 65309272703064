import React, { useEffect } from 'react';
import Iframe from '../../components/Iframe';
import { PAGES } from '../../constants/analytics';
import { FOOTER_LINKS, FOOTER_LINK_NAMES } from '../../constants/common';
import analytics from '../../utils/analytics';

const TermsOfUse = () => {
  useEffect(() => {
    analytics.trackPageView(PAGES.TERMS_OF_USE);
  }, []);

  return (
    <Iframe
      url={
        FOOTER_LINKS.find(
        footerLinks => footerLinks.name === FOOTER_LINK_NAMES.TERMS_OF_USE
        )?.url
        }
      title="Terms of Use"
      type="termsUseEvent"
    />
  );
};

export default TermsOfUse;
