import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useHistory, useLocation } from 'react-router';
import { MICRO_SURVEYS } from '../../../constants/keywords';
import { Heading, IconButton } from '@walmart-web/livingdesign-components';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    display: 'flex',
    wrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '8px 16px',
    columnGap: 16,
    backgroundColor: 'white',
    overflow: 'hidden'
  }
}));

const Header = () => {
  const classes = useStyles();
  const history = useHistory();
  const { state: locationState } = useLocation();
  const isFeedbackSurvey = locationState === 'feedbackSurvey';

  return (
    <header style={{ zIndex: 10 }}>
      <Grid className={classes.container}>
        <IconButton
          a11yLabel="go back"
          size="large"
          onClick={() => {
            const isLoggedIn = !!localStorage.getItem('token');
            if (isLoggedIn) {
              history.replace(
                isFeedbackSurvey ? '/activities' : '/activities/weekly-quests',
                'previousScreenWasWeeklyQuest'
              );
            } else {
              history.replace('/');
            }
          }}
        >
          <ChevronLeftIcon fontSize="large" />
        </IconButton>
        <Heading weight="400">
          {isFeedbackSurvey
            ? MICRO_SURVEYS.FEEDBACK_HEADER_TEXT
            : MICRO_SURVEYS.HEADER_TEXT}
        </Heading>
      </Grid>
    </header>
  );
};

export default Header;
