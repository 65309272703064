import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  withStyles
} from '@material-ui/core';
import moment from 'moment';
import { DATE_DISPLAY_SLASH_FORMAT } from '../../constants/common';
import styles from './styles';

const DataTable = ({
  classes,
  fieldMapping,
  hasNextPage,
  handleSort,
  handlePageUp,
  listData,
  sort,
  onCellClick
}) => {
  const getCellStyles = (item, isHeader) => {
    switch (item.type) {
      case 'name':
        return classes.name;
      case 'shortId':
        return classes.shortId;
      case 'points':
        return classes.points;
      case 'status':
        return classes.status;
      case 'date':
        return fieldMapping?.length <= 4 ? classes.date : classes.dateBig;
      case 'clickable':
        return isHeader ? classes.clickableHeader : classes.clickable;
      default:
        return '';
    }
  };

  const getTableData = (data, item) => {
    switch (item.type) {
      case 'name':
      case 'shortId':
        return data[item.field];
      case 'status':
        return data[item.field];
      case 'clickable':
        return data[item?.enable] && data[item.field] ? (
          <Button
            variant="primary"
            classes={{
              root: classes.linkButton
            }}
          >
            {data[item?.enable] && data[item.field]}
          </Button>
        ) : null;
      case 'points':
        return data[item.field] + ` pts.`;
      case 'date':
        return moment(data[item.field]).format(DATE_DISPLAY_SLASH_FORMAT);
      default:
        return '';
    }
  };

  return (
    <TableContainer className={classes.container}>
      <Table
        className={classes.tableWrapper}
        stickyHeader
        aria-label="history table"
      >
        <caption hidden>Rewards history table</caption>
        <TableHead>
          <TableRow>
            {fieldMapping.map((item, index) => (
              <TableCell
                key={index}
                className={getCellStyles(item, true)}
                classes={{ head: classes.tableCellHead }}
                align="left"
                onClick={() => {
                  item.sort && handleSort(item.field);
                }}
                onKeyPress={() => {
                  item.sort && handleSort(item.field);
                }}
                aria-sort={
                  item.sort
                    ? sort.order === 'desc'
                      ? 'descending'
                      : 'ascending'
                    : ''
                }
                tabIndex={item.sort ? 0 : -1}
                aria-label={
                  item.sort
                    ? `Sortable ${item.display} column. Currently sorted in ${
                        sort.order === 'desc' ? 'descending' : 'ascending'
                      } order`
                    : ''
                }
              >
                {item.display}
                {item.sort && (
                  <i
                    className={`icons ${classes.sortIcon}`}
                    aria-sort={
                      sort.order === 'desc' ? 'descending' : 'ascending'
                    }
                  />
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {listData.map((data, index) => (
            <TableRow key={index}>
              {fieldMapping.map((item, index) => (
                <TableCell
                  key={index}
                  className={data[item?.enable] && getCellStyles(item)}
                  classes={{ body: classes.tableCellBody }}
                  align="left"
                  onClick={() =>
                    item?.type === 'clickable' &&
                    data[item?.enable] &&
                    onCellClick?.(data[item.clickableField])
                  }
                >
                  {getTableData(data, item)}
                </TableCell>
              ))}
            </TableRow>
          ))}
          {hasNextPage && (
            <TableRow>
              <TableCell
                colSpan={fieldMapping?.length}
                align="center"
                classes={{ body: classes.tableCellShowMore }}
              >
                <Button
                  id="show-more"
                  variant="outlined"
                  color="primary"
                  onClick={handlePageUp}
                  style={{ fontSize: '14px' }}
                >
                  Show more
                </Button>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

DataTable.defaultProps = {
  classes: {},
  sort: {}
};

DataTable.propTypes = {
  classes: PropTypes.object,
  fieldMapping: PropTypes.arrayOf(PropTypes.object).isRequired,
  hasNextPage: PropTypes.bool.isRequired,
  handleSort: PropTypes.func.isRequired,
  handlePageUp: PropTypes.func.isRequired,
  listData: PropTypes.array.isRequired,
  sort: PropTypes.object
};

export default withStyles(styles)(DataTable);
