/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';
import styles from './styles';
import { objectToString, IMAGE_PATH } from '../../utilities/utils';
import Transition from '../../components/Transition';
import { FormattedMessage } from 'react-intl';

const InfoScreen = ({ classes, displayContent }) => {
  const {
    container,
    contentContainer,
    flexCenter,
    headerContent,
    image,
    subContent,
    infoScreenWrap
  } = classes;

  let str = objectToString(displayContent);
  let titleID = `${str}_TITLE`;
  let descriptionID = `${str}_DESCRIPTION`;

  return (
    <div className={infoScreenWrap}>
      <div className={container}>
        <div className={flexCenter}>
          <Transition in>
            <img
              alt="customer-perception"
              className={image}
              src={`${IMAGE_PATH}/spark.svg`}
            />
          </Transition>
        </div>
        <div className={`${flexCenter} ${contentContainer}`}>
          <Transition in={!!displayContent?.title} timeout={300}>
            <Typography variant="h2" className={headerContent}>
              <FormattedMessage
                id={titleID}
                defaultMessage={displayContent?.title}
              />
            </Typography>
          </Transition>
          <Transition in={!!displayContent?.description} timeout={300}>
            {descriptionID === '_DESCRIPTION' ? (
              <Typography variant="h6" className={subContent}>
                {displayContent?.description}
              </Typography>
            ) : (
              <Typography variant="h6" className={subContent}>
                <FormattedMessage
                  id={descriptionID}
                  defaultMessage={displayContent?.description}
                />
              </Typography>
            )}
          </Transition>
        </div>
      </div>
    </div>
  );
};

InfoScreen.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  displayContent: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool,
  loadingText: PropTypes.string
};

export default withStyles(styles)(InfoScreen);
