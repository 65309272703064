const styles = (theme) => ({
  sectionsComponent: {
    width: '33%',
    marginTop: 16,
    [theme.breakpoints.up('xs')]: {
      width: '100%'
    },
    [theme.breakpoints.up('sm')]: {
      width: '60%'
    },
    [theme.breakpoints.up('md')]: {
      width: '33%'
    }
  },
  skeletonWrapper: {
    width: '100%'
  },
  containerBox: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: 16
  }
});

export default styles;
