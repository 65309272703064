const styles = () => ({
  container: {},
  innerContainer: {
    padding: 24,
    display: 'flex',
    // alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: 16
  },
  titleText: {
    color: '#333',
    fontSize: 20,
    fontWeight: 400
  },
  descText: {
    color: '#414042',
    fontSize: 14,
    fontWeight: 400
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 16
  }
});

export default styles;
