import decode from 'jwt-decode';

export const getIamAuthToken = (token) => {
  try {
    const decoded = decode(token);
    return decoded['iam-token'];
  } catch (err) {
    return '';
  }
};
