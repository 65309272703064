const styles = (theme) => ({
  textField: {
    width: 342.44,
    margin: '8px 8px 8px 0',
    fontSize: 14,
    borderRadius: 4,
    backgroundColor: '#ffffff',
    [theme.breakpoints.up('sm')]: {
      height: 54,
      fontSize: 14
    },
    [theme.breakpoints.up('md')]: {
      height: 54
    },
    [theme.breakpoints.down(600)]: {
      width: '100%'
    }
  },
  textFieldOnError: {
    marginBottom: 25
  }
});

export default styles;