const styles = (theme) => ({
  editButton: {
    height: 20,
    fontSize: 14,
    color: '#0071ce',
    [theme.breakpoints.up('sm')]: {
      marginRight: 32
    },
    [theme.breakpoints.up('md')]: {
      marginRight: 0
    }
  },
  saveButton: {
    fontSize: 14,
    textTransform: 'none'
  },
  buttonRoot: {
    textTransform: 'none',
    textAlign: 'center',
    fontSize: 14,
    fontFamily: 'Bogle',
    fontWeight: 'bold',
    wordWrap: 'break-word'
  },
  roundedFilled: {
    color: 'white',
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 10,
    paddingBottom: 10,
    background: '#0071CE',
    borderRadius: 20,
    '&:disabled': {
      backgroundColor: '#6D6E71',
      color: '#FFFFFF'
    }
  }
});

export default styles;
