import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import styles from './style';
import { Avatar, Button, Snackbar } from '@material-ui/core';
import AvatarModal from '../AvatarModal';
import getCurrentEnv from '../../../../utils/env';
import apiCall from '../../../../utils/api';
import api from '../../../../constants/api';
import API, {
  API_STATUS_CODE,
  AUTO_HIDE_DURATION,
  TOAST_MSGS
} from '../../../../constants/common';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(styles);

const UserAvatar = () => {
  const classes = useStyles();
  const [userLevel, setUserLevel] = useState({});
  const [userSelectedImg, setUserSelectedImg] = useState(null);
  const [userAvatarList, setUserAvatarList] = useState(null);
  const [profileImgSrc, setProfileImageSrc] = useState(null);
  const [activityDetails, setActivityDetails] = useState(null);
  const [baseUrl, setBaseUrl] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState(null);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    let cancel = false;
    apiCall(api.userAvatarMapping, API.METHOD.GET, {}, false).then((res) => {
      if (res.status === API_STATUS_CODE.SUCCESS && !cancel) {
        const response = res?.data;
        setUserAvatarList(response);
      } else {
        const errMsg = res?.error?.message;
        setSnackbarMessage(errMsg ? errMsg : TOAST_MSGS.generic_error);
      }
    });
    return () => {
      cancel = true;
    };
  }, []);

  useEffect(() => {
    getAvatarDetails();
  }, []);

  useEffect(() => {
    if (userAvatarList && userSelectedImg) {
      const env = getCurrentEnv();
      const baseUrl = `https://${env === 'prod' ? '' : `${env}.`}${
        userAvatarList?.domain
      }${userAvatarList?.path}`;
      setBaseUrl(baseUrl);
      const imgNumber = userAvatarList?.data?.filter((e) => {
        return e.level === Number(userSelectedImg?.selectedList?.[0]);
      })[0];

      // to get Image
      const imgSrc = `${baseUrl}${imgNumber?.images?.[0]}${userAvatarList?.extension}`;
      setProfileImageSrc(imgSrc);
    }
  }, [userAvatarList, userSelectedImg]);

  const getAvatarDetails = () => {
    let canceldata = false;
    apiCall(`${api.profile}?type=icon`, API.METHOD.GET, {}, false).then(
      (res) => {
        const responseUser = res?.data?.profile?.[0]?.profileTypeDetailList;
        if (
          res.status === API_STATUS_CODE.SUCCESS &&
          !canceldata &&
          responseUser
        ) {
          setActivityDetails({
            completed: responseUser[2],
            default: responseUser[3]
          });
          setUserLevel(responseUser[0]);
          const selectedUser = responseUser[1];
          setUserSelectedImg(selectedUser);
        } else {
          const errMsg = res?.error?.message;
          setSnackbarMessage(errMsg ? errMsg : TOAST_MSGS.generic_error);
        }
      }
    );

    return () => {
      canceldata = true;
    };
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (reload) => {
    setOpen(false);
    if (reload) {
      // if selected image is updated
      getAvatarDetails();
    }
  };

  const snackBanner = (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={snackbarMessage ? true : false}
      message={snackbarMessage}
      autoHideDuration={AUTO_HIDE_DURATION}
      onClose={() => setSnackbarMessage(null)}
      ContentProps={{
        classes: {
          root: classes.snackbarRoot
        }
      }}
    />
  );

  return (
    <div className={classes.root}>
      {snackBanner}
      <Avatar src={`${profileImgSrc}`} className={classes.avatar} />
      <Button
        className={classes.avatarEdit}
        disabled={!profileImgSrc}
        onClick={handleClickOpen}
        aria-label="Edit Avatar"
      >
        <FormattedMessage id="EDIT" defaultMessage="Edit" />
      </Button>
      {open ? (
        <AvatarModal
          open={open}
          handleClose={handleClose}
          avatarList={userAvatarList}
          userLevel={userLevel}
          userSelectedImg={userSelectedImg}
          activityDetails={activityDetails}
          baseUrl={baseUrl}
          setSnackbarMessage={setSnackbarMessage}
        />
      ) : null}
    </div>
  );
};

export default UserAvatar;
