/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
import { makeStyles, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import styles from './styles';
import clsx from 'clsx';

const InputTextField = (props) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const {
    id,
    label,
    handleInputChange,
    value,
    errorStatus,
    helperText = null
  } = props;

  const [textInputValue, setTextInputValue] = useState(value);

  const onInputChange = (event) => {
    setTextInputValue(event.target.value);
    handleInputChange(event.target.value);
  };

  useEffect(() => {
    setTextInputValue(value)
  }, [value])

  return (
    <TextField
      id={id}
      className={clsx(
        classes.textField,
        errorStatus && classes.textFieldOnError
      )}      variant="outlined"
      label={label}
      onChange={onInputChange} //{handleInputChange(type, field)}
      value={textInputValue}
      error={errorStatus}
      data-testid={`${id}-testId`}
      helperText={helperText}
    />
  );
};

export default InputTextField;
