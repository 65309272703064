import React from 'react';
import ArrowTooltip from '../../../ArrowTooltip';
import { Grid, Box, Typography, Button, withStyles } from '@material-ui/core';
import { activity, activitySectionStyling } from '../../constants';
import PropTypes from 'prop-types';
import styles from './styles';

const ActivityTypeCard = ({
  id,
  optedIn,
  title,
  description,
  onClick,
  showPopover,
  popoverTitle,
  popoverSubTitle,
  activityCount,
  classes,
  disabled,
  hasFeedbackSurvey
}) => {
  const showFeedbackLabel =
    id === 'weekly-quests' && hasFeedbackSurvey && activityCount === 0;

  return (
    <Button
      classes={{ root: classes.contentBox }}
      onClick={onClick}
      key={title}
    >
      <Box
        className={`${classes.paper} ${disabled && classes.disabledContainer}`}
        sx={activitySectionStyling}
      >
        <Grid
          container
          className={classes.activityContainer}
          data-testid={`TEST-CARD-${title}`}
        >
          <Grid item xs={10} className={disabled && classes.disabledText}>
            <Grid container className={classes.sectionText}>
              <Grid item xs={12} className={classes.sectionTitle}>
                <Typography className={classes.sectionTitleText}>
                  {title}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.sectionDescription}>
                <Typography className={classes.sectionDescriptionText}>
                  {description}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2} className={classes.sectionPointsContainer}>
            <Grid container className={classes.sectionInnerContainer}>
              {optedIn ? (
                showFeedbackLabel ? (
                  <Box className={classes.shareFeedbackContainer}>
                    <Typography className={classes.shareFeedbackLabel}>
                      {activity.activityFreeTexts.share}
                    </Typography>
                    <Typography className={classes.shareFeedbackLabel}>
                      {activity.activityFreeTexts.feedback}
                    </Typography>
                  </Box>
                ) : (
                  <>
                    <Box className={classes.shareFeedbackContainer}>
                      <Typography className={classes.sectionPointsNumber}>
                        {activityCount}
                      </Typography>
                      <Typography className={classes.sectionPointsText}>
                        {activity.activityFreeTexts.available}
                      </Typography>
                    </Box>
                  </>
                )
              ) : (
                <>
                  <Box className={classes.shareFeedbackContainer}>
                    <Typography className={classes.sectionPointsText}>
                      {activity.activityFreeTexts.optOutLabel}
                    </Typography>
                    <Typography className={classes.sectionPointsText}>
                      {activity.activityFreeTexts.optOutSubLabel}
                    </Typography>
                  </Box>
                  {showPopover && (
                    <ArrowTooltip
                      title={popoverTitle}
                      subTitle={popoverSubTitle}
                      alignment="right"
                    />
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Button>
  );
};

ActivityTypeCard.defaultProps = {
  classes: {},
  hasFeedbackSurvey: false
};

ActivityTypeCard.propTypes = {
  classes: PropTypes.object,
  hasFeedbackSurvey: PropTypes.bool
};

export default withStyles(styles)(ActivityTypeCard);
