export const freeTexts = {
  firstPageHeading:
    'To complete your registration process please fill out the following information',
  passwordInstructionHeading: 'Strong password required: ',
  passwordLines: [
    'Include 8 to 30 characters',
    'Include at least one upper case letter',
    'Include at least one lower case letter',
    'Include at least one symbol (ie: #, &, $, %)',
    'Cannot contain more than 3 consecutive identical characters'
  ],
  footerText: {
    completeRegsitration: 'Complete Registration'
  },
  registrationPageHeading:
    'To log in, you can request a one-time verification code via email or text message',
  registrationRadioBtnText: 'Send verification code via email',
  enterCodeTextFieldDefaultValue: 'Enter the code we sent you',
  enterValidOtp: 'Please enter a valid OTP',
  otpIsValid: 'OTP is Valid',
  resentOtpText: 'Didn’t get a code?'
};

export const newRegistrationForm1 = {
  formFields: {
    firstName: 'firstName',
    lastName: 'lastName',
    email: 'email',
    password: 'password',
    confirmPassword: 'confirmPassword'
  }
};

export const newRegistrationForm = {
  formFields: {
    firstName: {
      id: 'firstName',
      name: 'firstName',
      type: '',
      label: 'First Name'
    },
    lastName: {
      id: 'lastName',
      name: 'lastName',
      type: '',
      label: 'Last Name'
    },
    email: {
      id: 'email',
      name: 'email',
      type: '',
      label: 'Email'
    },
    password: {
      id: 'password',
      name: 'password',
      type: 'password',
      label: 'Password'
    },
    confirmPassword: {
      id: 'confirmPassword',
      name: 'confirmPassword',
      type: 'password',
      label: 'Confirm Password'
    }
  }
};
