const styles = theme => ({
  accordion: {
    margin: '0px !important',
    boxShadow: 'none'
  },
  accordionDetails: {
    padding: 0,
    margin: '0px !important'
  },
  profileHead: {
    display: 'flex',
    alignItems: 'baseline',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'start'
    }
  },
  summary: {
    fontSize: 20,
    [theme.breakpoints.up('md')]: {
      marginRight: 100
    }
  },
  MuiAccordionroot: {
    "&.MuiAccordion-root:before": {
      height: '0px'
    }
  }
});

export default styles;