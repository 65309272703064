const styles = (theme) => ({
  headerIcon: {
    marginRight: '16px',
    display: 'flex'
  },
  profileHead: {
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    height: 20,
    marginBottom: 40,
    marginTop: 0,
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      justifyContent: 'space-between'
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'start'
    }
  },
  titleText: {
    display: 'flex',
    fontFamily: 'Bogle',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 18
  },
  breadcrumbText: {
    display: 'flex',
    alignItems: 'center',
    color: '#333333',
    '&:hover': { cursor: 'pointer' },
    textDecoration: 'none'
  },
  skeletonWrapper: {
    width: '100%'
  }
});

export default styles;
