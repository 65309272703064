/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-console */
import React, { useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  Switch,
  makeStyles
} from '@material-ui/core';
import styles from './styles';
import propTypes from 'prop-types';

const ActivityPreferenceSwitch = props => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const { onSwitch, detailObj, values } = props;

  const [checkedState, setCheckedState] = useState(
    values[detailObj.detailName]
  );

  const onChange = event => {
    setCheckedState(event.target.checked);
    onSwitch(event.target.checked, detailObj.detailName);
  };

  const testId = `${detailObj.detailName}-switch`;

  return (
    <FormControl component="fieldset" disabled={false}>
      <FormControlLabel
        className={classes.switchLabel}
        value={detailObj.displayName}
        control={
          <Switch
            checked={checkedState}
            onChange={onChange}
            color="primary"
            name={testId}
            data-testid={testId}
            inputProps={{ role: 'switch' }}
            disabled={!detailObj.editable}
          />
        }
        label={values[detailObj.detailName] ? 'Opt in' : 'Opt out'}
        labelPlacement="end"
      />
    </FormControl>
  );
};

ActivityPreferenceSwitch.propTypes = {
  onSwitch: propTypes.func,
  detailObj: propTypes.object,
  values: propTypes.object
};

export default ActivityPreferenceSwitch;
