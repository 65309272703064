const styles = (theme) => ({
  tickIcon: {
    height: 12,
    width: 12,
    marginRight: 7
  },
  crossIconRoot: {
    fill: '#CB2022'
  },
  passwordInstructionsText: {
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: 0,
    textAlign: 'left',
    display: 'inline'
  }
});

export default styles;
