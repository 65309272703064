/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import { SaveButton } from '../../components/Buttons/index.jsx';
import FormSelect from '../../components/FormSelect/index.js';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import InputTextField from '../../components/TextField/index.js';
import styles from './styles';
import {
  userProfileFreeTexts,
  monthNames,
  monthMap,
  APIConstants
} from './constanst.js';
import {
  convertToAddrObj,
  getDobYearsRange,
  zipCodeValidityCheck
} from './utils.js';
import { Error } from '@material-ui/icons';
import CircularLoader from '../../components/CircularLoader/index.js';
import AddressForm from './AddressForm/index.js';
import moment from 'moment/moment.js';

const ProfileDetailEditor = (props) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const { detailObj, displayValues, onSave, isSaveLoading } = props;

  const [valueToBeSaved, setValueToBeSaved] = useState(
    displayValues[detailObj.detailName]
  );

  const yearValues = getDobYearsRange();

  const saveTestId = `save-${detailObj.detailName}`;

  const check = moment(displayValues[APIConstants.detailNames.DOB], 'MM/DD/YYYY');
  let monthValueToSave = check.format('MM') ?? '';
  let yearValueToSave = check.format('Y') ?? '';
  let finalDobValueToSave = `${yearValueToSave}-${monthValueToSave}-01`;

  const createDobSaveValue = (value, type) => {
    if (type === 'month') {
      monthValueToSave = monthMap[value];
    }
    if (type === 'year') {
      yearValueToSave = value;
    }
    finalDobValueToSave = `${yearValueToSave}-${monthValueToSave}-01`;
  };

  const getDobMonthValue = (value) => {
    createDobSaveValue(value, 'month');
  };

  const getDobYearValue = (value) => {
    createDobSaveValue(value, 'year');
  };

  const passBackSelection = (value) => {
    setValueToBeSaved(value);
  };

  const onDetailSave = (newDetail) => {
    if (
      detailObj.selectionType === APIConstants.selectionTypes.DATE &&
      detailObj.detailName === APIConstants.detailNames.DOB
    ) {
      onSave(finalDobValueToSave, detailObj.detailName);
    } else if (
      detailObj.detailName === APIConstants.detailNames.address &&
      newDetail !== null
    ) {
      onSave(newDetail, detailObj.detailName);
    } else {
      onSave(valueToBeSaved, detailObj.detailName);
    }
  };

  const circularLoader = <CircularLoader size="small" />;

  if (detailObj.selectionType === 'open_text') {
    return (
      <Grid container>
        <Grid item xs={12}>
          <InputTextField
            id={detailObj.detailName}
            label={detailObj.displayName}
            value={displayValues[detailObj.detailName]}
            handleInputChange={passBackSelection}
          />
        </Grid>
        <Grid item xs={1}>
          {isSaveLoading ? (
            circularLoader
          ) : (
            <SaveButton
              onButtonClick={onDetailSave}
              testId={saveTestId}
            />
          )}
        </Grid>
      </Grid>
    );
  }

  if (detailObj.selectionType === APIConstants.selectionTypes.DATE) {
    const dob = new Date(displayValues[detailObj.detailName]);
    const dobYear = dob.getFullYear();
    const dobMonth = monthNames[dob.getUTCMonth()];

    return (
      <Grid container className={classes.activityEditWrapper}>
        <Grid container>
          <Grid item xs={5}>
            <FormSelect
              id="month"
              label="Month"
              value={dobMonth}
              selectedValue={getDobMonthValue}
              menuItems={monthNames}
            />
          </Grid>
          <Grid container item xs={2} justifyContent="center" />
          <Grid item xs={5}>
            <FormSelect
              id="year"
              label="Year"
              value={dobYear}
              selectedValue={getDobYearValue}
              menuItems={yearValues}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={1}>
            {isSaveLoading ? (
              circularLoader
            ) : (
              <SaveButton onButtonClick={onDetailSave} testId={saveTestId} />
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  if (detailObj.selectionType === APIConstants.selectionTypes.SINGLE) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <FormSelect
            label={detailObj.displayName}
            id={detailObj.detailName}
            selectedValue={passBackSelection}
            menuItems={detailObj.optionsList}
            value={displayValues[detailObj.detailName]}
          />
        </Grid>
        <Grid item xs={1}>
          {isSaveLoading ? (
            circularLoader
          ) : (
            <SaveButton onButtonClick={onDetailSave} testId={saveTestId} />
          )}
        </Grid>
      </Grid>
    );
  }

  if (detailObj.selectionType === APIConstants.selectionTypes.MULTIPLE) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <FormSelect
            label={detailObj.displayName}
            id={detailObj.detailName}
            selectedValue={passBackSelection}
            menuItems={detailObj.optionsList}
            value={displayValues[detailObj.detailName]}
            multiple
            disableOthers={detailObj.exclusiveOptionsList}
          />
        </Grid>
        <Grid item xs={1}>
          {isSaveLoading ? (
            circularLoader
          ) : (
            <SaveButton
              onButtonClick={onDetailSave}
              testId={saveTestId}
              disabled={valueToBeSaved.length === 0}
            />
          )}
        </Grid>
      </Grid>
    );
  }

  if (detailObj.selectionType === APIConstants.selectionTypes.JSON) {
    if (detailObj.detailName === APIConstants.detailNames.address) {
      return (
        <AddressForm
          addressData={convertToAddrObj(displayValues[detailObj.detailName])}
          isSaveLoading={isSaveLoading}
          onSave={onDetailSave}
          saveTestId={saveTestId}
        />
      );
    }
  }
};

export default ProfileDetailEditor;
