/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import AssignmentOutlined from '@material-ui/icons/AssignmentOutlined';
import styles from './styles';
import { EditButton, CancelButton } from '../../components/Buttons/index.jsx';
import ActivityFrequencyEditor from './ActivityFrequencyEditor';

const ActivityFrequencyDetails = (props) => {
  const { activityData, saveActFreq, isActivityDataLoading } = props;
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const [editing, setEditing] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  useEffect(() => {
    if (isActivityDataLoading === false) {
      setSaveLoading(false);
      setEditing(false);
    }
  }, [isActivityDataLoading]);

  const getActFreqData = (activity) => {
    const frequencyText = `${
      !activity?.frequency || activity?.frequency === '0'
        ? 'Unlimited'
        : activity?.frequency
    }`;
    const periodText =
      frequencyText === 'Unlimited'
        ? ''
        : ` per ${activity?.period ? activity?.period.toLowerCase() : 'month'}`;
    return frequencyText + ' activity(ies)' + periodText;
  };

  const editHandler = () => {
    setEditing(true);
  };

  const cancelEditing = () => {
    setEditing(false);
  };

  const onSaveEdit = (valuesToSave) => {
    setSaveLoading(true);
    saveActFreq(valuesToSave);
  };

  return (
    <Grid container className={classes.detailWrapper}>
      <Grid container direction="row" alignItems="center">
        <Grid item xs={1}>
          <AssignmentOutlined className={classes.icon} />
        </Grid>
        <Grid item xs={9} className={classes.profileH3Name}>
          Activity Frequency
        </Grid>
        <Grid item container xs={2} direction="row-reverse">
          {editing ? (
            <CancelButton
              onButtonClick={cancelEditing}
              testId="cancel-activityFreq"
            />
          ) : (
            <EditButton
              onClickHandler={editHandler}
              testId="edit-activityFreq"
              ariaLabel="Edit Activity Frequency"
            />
          )}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={1} />
        {!editing ? (
          <Typography className={classes.profileH3Value}>
            {getActFreqData(activityData)}
          </Typography>
        ) : (
          <Grid item xs={10}>
            <ActivityFrequencyEditor
              onSave={onSaveEdit}
              frequency={
                activityData?.frequency === 0
                  ? 'Unlimited'
                  : activityData?.frequency
              }
              period={activityData?.period}
              isSaveLoading={saveLoading}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default ActivityFrequencyDetails;
