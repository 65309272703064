import React, { useEffect, useState } from 'react';
import styles from './styles';
import { Snackbar, withStyles } from '@material-ui/core';
import { useHistory } from 'react-router';
import apiCall from '../../utils/api';
import API, {
  API_STATUS_CODE,
  AUTO_HIDE_DURATION
} from '../../constants/common';
import ApiPath from '../../constants/api';
import Header from '../Header';
import { useWindowSize } from '../../hooks';
import TopBar from '../TopBar';
import { FormattedMessage } from 'react-intl';

const Iframe = ({ url, classes, title, type }) => {
  const platformName = localStorage.getItem('platform');
  const [userName, setUserName] = useState('');
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const { push } = useHistory();
  const { width } = useWindowSize();

  const getConfState = (user) => {
    apiCall(`${ApiPath.status}`, API.METHOD.GET, {}, false).then((response) => {
      if (response?.error) {
        setIsSnackbarOpen(true);
        setSnackbarMessage(`Error in ${title}: ${response?.error?.message}`);
      }
      if (response?.status === API_STATUS_CODE.SUCCESS) {
        const { confAgree } = response?.data;
        if (confAgree === 'UNSET' || confAgree === 'NO') {
          push('/home', 'termsUseClose');
        } else {
          setUserName(user);
        }
      }
    });
  };

  useEffect(() => {
    apiCall(`${ApiPath.authValidation}`, API.METHOD.GET, {}, false).then(
      (response) => {
        if (response?.error) {
          setIsSnackbarOpen(true);
          setSnackbarMessage(`Auth Error: ${response?.error?.message}`);
          push('/login', type);
        }
        if (response?.status === API_STATUS_CODE.SUCCESS) {
          getConfState(response?.data?.message?.split(/(?:[ ])+/)[1]);
        }
      }
    );
  }, []);

  const handleBackClick = () => {
    push('/home', 'backFromProfile');
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isSnackbarOpen}
        message={snackbarMessage}
        autoHideDuration={AUTO_HIDE_DURATION}
        onClose={() => setIsSnackbarOpen(false)}
        ContentProps={{
          classes: {
            root: classes.snackbarRoot
          }
        }}
      />
      {width <= 600 ? <Header /> : <Header user={userName} />}

      {userName ? (
        <>
          {platformName === 'web' ? (
            <div className={classes.marginTop}>
              <TopBar
                title={
                  <FormattedMessage
                    id="BACK_TO_COMMUNITY"
                    defaultMessage="Back to the Community"
                  />
                }
                onClose={handleBackClick}
              />
            </div>
          ) : (
            <></>
          )}
          <iframe src={url} className={classes.iframe} title={title} />
        </>
      ) : null}
    </>
  );
};

export default withStyles(styles)(Iframe);
