import { WebLogger } from 'cp-web-pulse-logger';
import getCurrentEnv from './env';
import { PULSE_DETAILS } from '../constants/common';

const APP_CONTEXT = 'customerSparkWeb';
const currentEnv = getCurrentEnv();
const beaconConfig = PULSE_DETAILS[currentEnv] ?? PULSE_DETAILS['dev'];
export const logger = new WebLogger({
  appContext: APP_CONTEXT,
  beaconConfig,
  debugMode: currentEnv === 'dev',
  enabled: !window?.location?.host?.includes('localhost'),
  logLevel: 'info'
});

logger.setUserInfo({
  env: currentEnv
});
