const styles = (theme) => ({
  root: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 87,
    color: '#333333',
    [theme.breakpoints.up('sm')]: {
      marginTop: 92
    }
  },
  buttonHolder: {
    display: 'flex',
    marginTop: 32,
    [theme.breakpoints.up('sm')]: {
      width: 344,
      justifyContent: 'space-around'
    },
    [theme.breakpoints.up('md')]: {
      width: 300
    },
    [theme.breakpoints.up('lg')]: {
      width: 412
    }
  },
  button: {
    width: 312,
    height: 40,
    fontSize: 14,
    fontWeight: 500,
    textTransform: 'none',
    [theme.breakpoints.up('sm')]: {
      width: 171
    }
  },
  content: {
    width: 253,
    height: 54,
    fontSize: 20,
    margin: '56px 0',
    [theme.breakpoints.up('sm')]: {
      width: 435,
      height: 27
    }
  },
  header: {
    width: 328,
    height: 34,
    fontSize: 24,
    marginBottom: 56
  }
});

export default styles;
