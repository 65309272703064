import React, { useState } from 'react';
import { Grid, Link, Typography, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import { LANDING_FAQ_CONTENT, LANDING_FAQ_LINKS } from '../../constants/common';
import styles from './styles';
import { FormattedMessage } from 'react-intl';

const LandingFaqContent = ({ classes }) => {
  const { contentBody, contentHead, faqLink, faqWrapper, note, seeMore } =
    classes;

  const [readMore, setReadMore] = useState(false);

  const handleSeeMore = () => {
    setReadMore(!readMore);
  };

  const getFaqLinks = (text) => {
    const data = {
      text: '',
      link: ''
    };
    if (text.includes('{%helpCenter%}')) {
      return {
        ...data,
        text: text.replace('{%helpCenter%}', ''),
        link: LANDING_FAQ_LINKS.HELP
      };
    } else if (text.includes('{%privacy%}')) {
      return {
        ...data,
        text: text.replace('{%privacy%}', ''),
        link: LANDING_FAQ_LINKS.PRIVACY
      };
    } else {
      return { ...data, text };
    }
  };

  const getFaqContent = () =>
    LANDING_FAQ_CONTENT.map((faq, index) => {
      const content = (
        <>
          <Typography className={classNames(contentHead, 'smallHead')}>
            {/* <FormattedMessage key={`FAQ_${faq.question}`} id={`FAQ_QUESTION_${index}`} defaultMessage={faq.question}/> */}
            {faq.question}
          </Typography>
          <Typography className={contentBody}>
            {/* <FormattedMessage id={`FAQ_ANSWER_${index}`} defaultMessage={getFaqLinks(faq.answer).text}/> */}
            {getFaqLinks(faq.answer).text}
            {(index === 5 || index === 6) && (
              <>
                <Link
                  href={getFaqLinks(faq.answer).link}
                  className={faqLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  color="inherit"
                  underline="always"
                >
                  <FormattedMessage id="HERE" defaultMessage="here" />
                </Link>
                <span>.</span>
              </>
            )}
          </Typography>
          {faq.note && (
            <Typography className={note}>
              <FormattedMessage
                id={`FAQ_NOTE_${index}`}
                defaultMessage={faq.note}
              />
            </Typography>
          )}
        </>
      );
      if (index <= 2 && !readMore) {
        return content;
      } else if (readMore) {
        return content;
      }
    });

  const getSeeMoreLink = () => (
    <Link
      className={seeMore}
      onClick={handleSeeMore}
      target="_blank"
      rel="noopener noreferrer"
      color="inherit"
    >
      <FormattedMessage id="SEE" defaultMessage="See" />{' '}
      {!readMore ? (
        <FormattedMessage id="MORE" defaultMessage="more" />
      ) : (
        <FormattedMessage id="LESS" defaultMessage="less" />
      )}
    </Link>
  );

  return (
    <>
      <Grid item className={faqWrapper}>
        <Typography className={contentHead} style={{ textAlign: 'center' }}>
          <FormattedMessage
            id="FAQ"
            defaultMessage="Frequently Asked Questions"
          />
        </Typography>
        {getFaqContent()}
      </Grid>
      <Grid item style={{ textAlign: 'center' }}>
        {getSeeMoreLink()}
      </Grid>
    </>
  );
};

export default withStyles(styles)(LandingFaqContent);
