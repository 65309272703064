import React from 'react';
import PropTypes from 'prop-types';
import analytics from '../../../utils/analytics';
import styles from './styles';
import { FOOTER_LINKS, FOOTER_LINK_NAMES } from '../../../constants/common';
import { FormattedMessage } from 'react-intl';
import { Grid, Link, Typography, withStyles } from '@material-ui/core';
import { PAGES } from '../../../constants/analytics';
import { FOOTER_HEADER } from '../../../constants/keywords';
import { IMAGE_PATH } from '../../../utilities/utils';

const HomeFooter = ({ classes, type }) => {
  const constructLinkProps = (link, index, type) => {
    let props = {
      key: index,
      'data-testid': `TEST_LINK-${link.name}`,
      href: link.path,
      rel: 'noopener noreferrer',
      color: 'inherit',
      underline: 'none',
      target: '_blank',
      onClick: () => {
        analytics.trackClick(PAGES.LANDING, `$Footer-{link.name}`);
      }
    };
    if (
      type !== 'landing' &&
      (link.name === FOOTER_LINK_NAMES.UNSUBSCRIBE ||
        link.name === FOOTER_LINK_NAMES.PRIVACY_NOTICE ||
        link.name === FOOTER_LINK_NAMES.TERMS_OF_USE)
    ) {
      props = { ...props, target: '_self' };
    } else if (type === 'landing' && link.name === FOOTER_LINK_NAMES.FAQ) {
      props = { ...props, target: '_self' };
    }
    return props;
  };

  const getLinks = (title) => {
    return FOOTER_LINKS.map((link, index) => {
      if (link.type.indexOf(title) === -1) {
        return null;
      }
      if (
        (type !== 'landing' && link.name === FOOTER_LINK_NAMES.FAQ) ||
        (type === 'landing' && link.name === FOOTER_LINK_NAMES.UNSUBSCRIBE)
      ) {
        return null;
      }

      let props = constructLinkProps(link, index, type);

      return link.name !== FOOTER_LINK_NAMES.YOUR_PRIVACY_CHOICES ? (
        <Link {...props} className={classes.link}>
          {link.name}
        </Link>
      ) : (
        <Link {...props} className={classes.link}>
          {link.name}
          <img
            src={`${IMAGE_PATH}/icons/privacy-choices.svg`}
            alt="privacy choices"
            className={classes.privacyIcon}
          />
        </Link>
      );
    });
  };
  return (
    <Grid container className={classes.wrapper}>
      <Grid
        container
        item
        className={classes.linkWrapper}
        justifyContent="center"
      >
        {/* Customer Spark Section */}
        <Grid container item direction="column">
          <Typography className={classes.footerSubtitle} align="left">
            {FOOTER_HEADER.CUSTOMER_SPARK}
          </Typography>
          <Grid container item justifyContent="flex-start" direction="column">
            {getLinks(FOOTER_HEADER.CUSTOMER_SPARK)}
          </Grid>
        </Grid>
        {/* Walmart Section */}
        <Grid container item direction="column">
          <Typography className={classes.footerSubtitle} align="left">
            {FOOTER_HEADER.WALMART}
          </Typography>
          <Grid container item justifyContent="flex-start" direction="column">
            {getLinks(FOOTER_HEADER.WALMART)}
          </Grid>
        </Grid>
      </Grid>
      <Grid item data-testid="FOOTER-MSG">
        <Typography className={classes.copyright}>
          © {new Date().getFullYear()} Walmart.{' '}
          <FormattedMessage id="RIGHTS" defaultMessage="All Rights Reserved." />
        </Typography>
      </Grid>
    </Grid>
  );
};

HomeFooter.defaultProps = {
  classes: {},
  type: ''
};

HomeFooter.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(HomeFooter);
