/* eslint-disable react/prop-types */
/* eslint-disable no-console */
import React from 'react';
import { Breadcrumbs, makeStyles, Typography, Link } from '@material-ui/core';
import styles from './styles';

const CSBreadcrumbs = ({ breadcrumbs }) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const crumbs = breadcrumbs.map((item, index) => {
    const { titleText, titleIcon, onClickAction, href } = item;
    const key = `breadcrumb-${index}`;

    return onClickAction || href ? (
      <Link
        to={{ pathname: href }}
        onClick={onClickAction}
        key={key}
        component="button"
        underline="none"
        data-testid={`TEST-${titleText}-BTN`}
      >
        <div className={classes.breadcrumbText}>
          {titleIcon}
          <Typography>{titleText}</Typography>
        </div>
      </Link>
    ) : (
      <div key={key} className={classes.breadcrumbText}>
        {titleIcon}
        <Typography>{titleText}</Typography>
      </div>
    );
  });

  return (
    <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
      {crumbs}
    </Breadcrumbs>
  );
};

export default CSBreadcrumbs;
