import { Grid, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { APP_STORE_LINK } from '../../../constants/common';
import useConfigContext from '../../../utils/useConfigContext';

const { android: playStoreLink, ios: appStoreLink } = APP_STORE_LINK;

function MobileDownloadBanner() {
  const classes = useStyles();

  const showMobileBanner = useConfigContext('showMobileBanner') === true;

  if (!showMobileBanner) return null;

  return (
    <Grid item className={classes.appDownloadBtnContainer}>
      <Typography className={classes.appDownloadTitle}>
        <FormattedMessage
          id="GET_MOBILE_APP"
          defaultMessage="Get the Customer Spark App"
        />
      </Typography>
      <Grid className={classes.appStoreBtnContainer}>
        <a href={playStoreLink} target="_blank" rel="noopener noreferrer">
          <img
            src="/assets/images/mobile/playstore.png"
            alt="playstore link"
            className={classes.storeButton}
          />
        </a>
        <a href={appStoreLink} target="_blank" rel="noopener noreferrer">
          <img
            src="/assets/images/mobile/appstore.png"
            alt="appstore link"
            className={classes.storeButton}
          />
        </a>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  appDownloadBtnContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 32,
    [theme.breakpoints.down('600')]: {
      marginTop: 0
    }
  },
  appDownloadTitle: {
    fontSize: 16,
    fontWeight: '400',
    color: theme.palette.text.primary,
    textAlign: 'center'
  },
  appStoreBtnContainer: {
    marginTop: 24,
    display: 'flex',
    columnGap: 32,
    flexDirection: 'row',
    justifyContent: 'center',
    [theme.breakpoints.down('600')]: {
      columnGap: 16
    }
  },
  storeButton: {
    width: '140px'
  }
}));

export default MobileDownloadBanner;
