const styles = (theme) => ({
  menuItems: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left'
    },
    getContentAnchorEl: null
  },
  formControl: {
    margin: '8px 8px 8px 0',
    width: '100%',
    [theme.breakpoints.up('xs')]: {
      'max-width': '300px'
    },
    [theme.breakpoints.up('sm')]: {
      'max-width': '700px'
    }
  },
  checked: {
    '&.MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#0071ce'
    }
  }
});

export default styles;
