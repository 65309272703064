import React, { useState, useEffect } from 'react';
import ApiPath from '../../constants/api';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import DropzoneComponent from '../../components/DropzoneComponent';
import DropzoneContent from '../../components/DropzoneContent';
import GenerateField from '../../components/GenerateField';
import Header from '../../components/Header';
import HomeFooter from '../../components/Footer/HomeFooter';
import InfoDialog from '../../components/InfoDialog';
import analytics from '../../utils/analytics';
import apiCall from '../../utils/api';
import clsx from 'clsx';
import styles from './styles';
import { ALL_FORMAT_SUPPORT } from '../../constants/keywords';
import { FormattedMessage } from 'react-intl';
import { Info } from '@material-ui/icons';
import { PAGES } from '../../constants/analytics';
import { combineStyles } from '../../components/utils';
import { handleFileUpload } from '../../utilities/utils';
import { isValidEmailId } from '../../utils/common';
import {
  Box,
  CircularProgress,
  Fade,
  Snackbar,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core';
import {
  SELECT_MENU_POPOVER_STYLE,
  fieldsStyles
} from '../../constants/styles';
import API, {
  API_STATUS_CODE,
  ASSETS_COUNT,
  AUTO_HIDE_DURATION,
  CONTACT_US,
  DUPLICATE_FILE,
  ERROR_RAISING_TICKET,
  FIELDS_PLACEHOLDER,
  FILE_COUNT_EXCEED_WARNING,
  FILE_FORMAT_UNSUPPORTED,
  FILE_SIZE_EXCEEDED_WARNING,
  FILE_SIZE_SUPPORTED,
  ISSUES_SUPPORT,
  ISSUE_CATEGORY,
  LIMIT_EXCEEDED_TICKET,
  SIZE_IN_MB,
  SUCCESS_DIALOG,
  VALIDATION_ERROR,
  TEXTFIELD_CHARACTER_LIMIT_VALUE,
  FORM_FIELD_NAME_ATTRIBUTES,
  FORM_FIELD_CHAR_LIMIT
} from '../../constants/common';
import { getContactUsFieldValidation } from '../../utilities/formValidation';

const ContactUs = ({ classes }) => {
  const {
    btn,
    flexContainer,
    flexDisplay,
    flexDisplayCenter,
    helperText,
    iconResize,
    innerContainer,
    label,
    lightFont,
    outerContainer,
    regular14,
    root,
    selectMenu,
    selectMenuHeight,
    selectRoot,
    submitBtn,
    textFieldRoot,
    titleText,
    errText,
    boxRoot,
    countWrapStyles,
    countValuestyles
  } = classes;

  const INTIAL_PAYLOAD = {
    activityType: '',
    contentIds: [],
    description: '',
    egiftcardUrl: '',
    emailId: '',
    folderId: '',
    issueCategory: '',
    panelistId: '',
    status: ''
  };

  const fileSize = React.useRef(0);
  const formId = React.useRef(null);

  const [data, setData] = useState({ ...INTIAL_PAYLOAD });
  const [dialogContent, showDialogContent] = useState(false);
  const [errorsList, setErrorsList] = useState({});
  const [imageErrorMessages, setImageErrorMessages] = useState('');
  const [imagesList, setImageList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState({
    isError: false,
    message: '',
    open: false
  });
  const [fieldErrors, setFieldErrors] = useState({});

  useEffect(() => {
    const emailId = localStorage.getItem(ISSUE_CATEGORY.EMAIL);
    const panelistId = localStorage.getItem('panelistId');
    setLoggedInUser(!!emailId);
    if (emailId || panelistId) {
      setData((curr) => ({
        ...curr,
        emailId,
        panelistId
      }));
    }
    if (showForm) {
      analytics.trackPageView(PAGES.CONTACT_US);
    }
  }, [showForm]);

  useEffect(() => {
    setShowForm(true);
  }, []);

  const SELECT_MENU_POPOVER_SCROLL_STYLE = {
    ...SELECT_MENU_POPOVER_STYLE,
    className: selectMenuHeight
  };

  const selectProps = {
    MenuProps: SELECT_MENU_POPOVER_SCROLL_STYLE,
    classes: {
      root: selectRoot,
      selectMenu,
      label
    },
    size: 'small'
  };

  const formPropsOneFourth = {
    formControl: {
      props: {
        className: clsx(selectMenu, textFieldRoot)
      }
    }
  };

  const handleChange = (event) => {
    if (event?.target?.name) {
      setData((curr) => ({
        ...curr,
        [event?.target?.name]:
          event?.target?.name === ISSUE_CATEGORY.EMAIL
            ? event?.target?.value?.toLowerCase()
            : event?.target?.value
      }));

      setShowError((curr) => {
        if (curr) return curr;
        return false;
      });

      setSnackbarMessage((curr) => ({
        ...curr,
        open: false
      }));

      const tempMailId =
        event?.target?.name === ISSUE_CATEGORY.EMAIL
          ? event?.target?.value?.toLowerCase()
          : data?.emailId;
      if (tempMailId) {
        if (isValidEmailId(tempMailId)) {
          if (event?.target?.name === ISSUE_CATEGORY.EMAIL) {
            setErrorsList((curr) => ({
              ...curr,
              emailId: ''
            }));
          }
        } else {
          setErrorsList((curr) => ({
            ...curr,
            emailId: VALIDATION_ERROR.EMAIL
          }));
        }
      } else {
        setErrorsList((curr) => ({
          ...curr,
          emailId: VALIDATION_ERROR.EMAIL_MISSING
        }));
      }

      if (event?.target?.name === ISSUE_CATEGORY.CATEGORY) {
        if (event?.target?.value === ISSUE_CATEGORY.OTHER) {
          setData((curr) => ({
            ...curr,
            egiftcardUrl: '',
            activityType: ''
          }));
          setFieldErrors((curr) => {
            const fieldErrorsCopy = { ...curr };
            delete fieldErrorsCopy?.[FORM_FIELD_NAME_ATTRIBUTES.EGIFT_CARD_URL];
            delete fieldErrorsCopy?.[FORM_FIELD_NAME_ATTRIBUTES.ACTIVITY_TYPE];
            return fieldErrorsCopy;
          });
        } else if (event?.target?.value === ISSUE_CATEGORY.ACTIVITY) {
          setData((curr) => ({
            ...curr,
            egiftcardUrl: ''
          }));

          setFieldErrors((curr) => {
            const fieldErrorsCopy = { ...curr };
            delete fieldErrorsCopy?.[FORM_FIELD_NAME_ATTRIBUTES.EGIFT_CARD_URL];
            return fieldErrorsCopy;
          });
        } else if (event?.target?.value === ISSUE_CATEGORY.EGIFT) {
          setData((curr) => ({
            ...curr,
            activityType: ''
          }));

          setFieldErrors((curr) => {
            const fieldErrorsCopy = { ...curr };
            delete fieldErrorsCopy?.[FORM_FIELD_NAME_ATTRIBUTES.ACTIVITY_TYPE];
            return fieldErrorsCopy;
          });
        }
      }

      if (![ISSUE_CATEGORY.CATEGORY].includes(event?.target?.name)) {
        const fieldValidationErrors = getValidationErrors({
          name: event?.target?.name,
          value: event?.target?.value
        });

        const stateFieldValidationError = {
          ...fieldErrors
        };

        if (fieldValidationErrors?.[event?.target?.name]?.isInValid) {
          stateFieldValidationError[event?.target?.name] = {
            ...fieldValidationErrors[event?.target?.name]
          };
        } else if (stateFieldValidationError[event?.target?.name]) {
          delete stateFieldValidationError[event?.target?.name];
        }

        setFieldErrors({ ...stateFieldValidationError });
      }
    }
  };

  const condition = {
    type: 'select',
    label: 'Issue Type',
    menuList: ISSUES_SUPPORT,
    ...formPropsOneFourth,
    props: {
      ...selectProps,
      name: ISSUE_CATEGORY.CATEGORY,
      defaultValue: data?.activityType,
      classes: {
        label: textFieldRoot
      },
      onChange: handleChange,
      'data-testId': 'issue-category'
    },
    disabled: false
  };

  useEffect(() => {
    const checkIsValid = () => {
      if (
        !data?.emailId ||
        !isValidEmailId(data?.emailId) ||
        !data?.issueCategory
      )
        return false;

      if (!data?.description) return false;

      if ([ISSUE_CATEGORY.ACTIVITY].includes(data?.issueCategory)) {
        if (!data?.activityType) return false;
      }
      if ([ISSUE_CATEGORY.EGIFT].includes(data?.issueCategory)) {
        if (!data?.egiftcardUrl) return false;
      }
      if ([ISSUE_CATEGORY.POINTS].includes(data?.issueCategory)) {
        if (!data?.egiftcardUrl || !data?.activityType) return false;
      }

      if (Object?.keys(fieldErrors)?.length) {
        return false;
      }

      return true;
    };

    setIsValid(checkIsValid());
  }, [data, errorsList.emailId, fieldErrors]);

  useEffect(() => {
    if (imagesList.length < ASSETS_COUNT) {
      setImageErrorMessages((curr) =>
        curr === FILE_FORMAT_UNSUPPORTED ? curr : ''
      );
    }
  }, [imagesList]);

  const onDrop = (accepted) => {
    const validList = accepted?.filter(
      (itm) => itm?.type?.includes('image') || itm?.type?.includes('pdf')
    );
    let newAccepted = [...validList];
    if (validList?.length !== accepted?.length) {
      const currentErr = imageErrorMessages;
      setImageErrorMessages(FILE_FORMAT_UNSUPPORTED);

      setTimeout(() => {
        setImageErrorMessages(currentErr);
      }, 5000);
    }

    if (ASSETS_COUNT <= validList?.length + imagesList?.length) {
      newAccepted = validList.slice(0, ASSETS_COUNT - imagesList.length);
    }

    const newFilesSize = newAccepted.reduce(
      (acc, curr) => acc + curr?.size || 0,
      0
    );

    if (newFilesSize + fileSize.current > FILE_SIZE_SUPPORTED) {
      setImageErrorMessages(FILE_SIZE_EXCEEDED_WARNING);
    } else {
      if (newAccepted?.length) {
        const newImageList = newAccepted?.map((itm) => ({
          file: itm,
          id: itm?.name,
          loading: true,
          error: imagesList?.map((imgs) => imgs?.id)?.includes(itm?.name)
            ? DUPLICATE_FILE
            : ''
        }));
        setImageList((curr) => [...curr, ...newImageList]);
        setIsLoading(true);
        handleFileUpload(
          `${ApiPath.v1}${`${
            loggedInUser ? 'panelist/secure-upload' : 'support/public-upload'
          }${formId?.current ? `?blobId=${formId?.current}` : ''}`}`,
          newAccepted
        )
          .then((response) => {
            if (response?.status === API_STATUS_CODE.CREATED) {
              if (!formId.current) formId.current = response?.data?.formId;
              const newList = response?.data?.attachments;
              const newListObj = {};
              newList.forEach((itm) => {
                newListObj[itm?.orgFileName] = itm?.fileName;
              });

              setImageList((curr) =>
                curr?.map((itm) =>
                  newImageList?.map((imgItm) => imgItm.id)?.includes(itm?.id)
                    ? { ...itm, loading: false, fileId: newListObj[itm?.id] }
                    : itm
                )
              );
            }
          })
          .catch((err) => {
            err?.response?.data?.message &&
              setSnackbarMessage({
                message: err?.response?.data?.message,
                isError: true,
                open: true
              });
            setImageList((curr) =>
              curr?.map((itm) =>
                newImageList?.map((imgItm) => imgItm.id)?.includes(itm?.id)
                  ? { ...itm, loading: false, error: 'Upload failed' }
                  : itm
              )
            );
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  };

  const onClear = (item, indx) => {
    if (item === 'reset') {
      setImageList([]);
    } else {
      setImageList((curr) => curr.filter((itm, idx) => indx !== idx));
    }
  };

  const onSubmit = () => {
    analytics.trackClick(PAGES.CONTACT_US, 'submit');
    const payload = {
      ...data,
      formId: formId.current,
      contentIds: imagesList?.map((imgList) => imgList?.fileId),
      issueCategory: ISSUES_SUPPORT?.find(
        (itm) => itm?.value === data?.issueCategory
      )?.name
    };
    setIsSaving(true);

    apiCall(
      `${ApiPath.v1}${
        loggedInUser ? 'panelist/secure-case' : 'support/public-case'
      }`,
      API.METHOD.POST,
      { ...payload },
      false
    )
      .then((response) => {
        if (response?.status === API_STATUS_CODE.CREATED) {
          showDialogContent(true);
          setIsValid(false);
        } else {
          setSnackbarMessage({
            message:
              response?.status === API_STATUS_CODE.RATE_LIMIT
                ? LIMIT_EXCEEDED_TICKET
                : response?.error?.message || ERROR_RAISING_TICKET,
            isError: true,
            open: true
          });
        }
      })
      .catch((err) => {
        setSnackbarMessage({
          message: ERROR_RAISING_TICKET,
          isError: true,
          open: true
        });
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const onFieldBlur = () => {
    setShowError(true);
  };
  const goBack = () => {
    setData((curr) => ({
      ...curr,
      ...INTIAL_PAYLOAD
    }));

    formId.current = null;
    setImageList([]);
    setShowForm(false);
    setTimeout(() => {
      setShowForm(true);
    }, 1000);
    showDialogContent(false);
  };

  const getValidationErrors = ({ name, value }) => {
    const errors = {};
    const { isInValid, message } = getContactUsFieldValidation({
      value,
      name
    });
    if (isInValid) {
      errors[name] = {
        isInValid,
        message
      };
    } else if (errors?.[name]) {
      delete errors?.[name];
    }

    return errors;
  };

  const DESCRIPTION_ERROR_HANDLE =
    fieldErrors?.[FORM_FIELD_NAME_ATTRIBUTES.DESCRIPTION];
  const EGIFT_ERROR_HANDLE =
    fieldErrors?.[FORM_FIELD_NAME_ATTRIBUTES.EGIFT_CARD_URL];
  const ACTIVITY_TYPE_ERROR_HANDLE =
    fieldErrors?.[FORM_FIELD_NAME_ATTRIBUTES.ACTIVITY_TYPE];
  const EMAIL_ERROR_HANDLE = fieldErrors?.[FORM_FIELD_NAME_ATTRIBUTES.EMAIL_ID];

  return (
    <div>
      <InfoDialog
        dialogProps={{
          open: dialogContent
        }}
        title={SUCCESS_DIALOG.title}
        description={SUCCESS_DIALOG.description}
        buttonConfig={{
          text: 'Got it',
          props: {
            onClick: goBack,
            variant: 'contained',
            color: 'primary',
            className: btn,
            'data-testid': 'go-back'
          }
        }}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
        autoHideDuration={AUTO_HIDE_DURATION}
        message={snackbarMessage?.message}
        onClose={() => setSnackbarMessage((curr) => ({ ...curr, open: false }))}
        open={!!snackbarMessage?.open}
        ContentProps={{
          classes: {
            root: clsx(
              classes.snackbarRoot,
              snackbarMessage?.isError
                ? classes.errorSnackBar
                : classes.successRoot
            ),
            message: classes.messageLabel
          }
        }}
        transitionDuration={750}
      />
      <title>
        <FormattedMessage
          defaultMessage="Walmart - Customer Spark: Contact us"
          id="CONTACT_US"
        />
      </title>
      <Header />
      <div className={root}>
        <div className={outerContainer}>
          <Typography variant="h3" className={titleText}>
            <FormattedMessage
              defaultMessage={CONTACT_US.TITLE}
              id="WELCOME_CONTACT_US"
            />
          </Typography>
          <div className={flexContainer}>
            <Typography variant="h4" className={lightFont}>
              <FormattedMessage
                defaultMessage={CONTACT_US.INFO}
                id="PROVIDE_MORE_INFO"
              />{' '}
            </Typography>
            <Typography variant="subtitle1">
              <FormattedMessage
                defaultMessage={CONTACT_US.REQUIRED}
                id="ALL_FIELDS_REQUIRED"
              />
            </Typography>
          </div>
        </div>

        <Fade in={showForm} timeout={500} unmountOnExit>
          <div className={innerContainer}>
            <TextField
              classes={{ root: textFieldRoot }}
              data-testid="emailId"
              defaultValue={data?.emailId}
              disabled={loggedInUser}
              error={
                (showError && !!errorsList?.emailId) ||
                EMAIL_ERROR_HANDLE?.isInValid
              }
              label="Email"
              name={FORM_FIELD_NAME_ATTRIBUTES.EMAIL_ID}
              onBlur={onFieldBlur}
              onChange={handleChange}
              placeholder={FIELDS_PLACEHOLDER.EMAIL}
              variant="outlined"
              FormHelperTextProps={{
                className: helperText
              }}
              helperText={
                (showError && errorsList?.emailId) ||
                (EMAIL_ERROR_HANDLE?.isInValid &&
                  EMAIL_ERROR_HANDLE?.message) ? (
                  <div className={flexDisplay}>
                    <Info fontSize="small" className={iconResize} />
                    <Typography variant="subtitle1">
                      {errorsList?.emailId || EMAIL_ERROR_HANDLE?.message}
                    </Typography>
                  </div>
                ) : null
              }
            />
            <GenerateField field={condition} />
            <Fade
              in={[ISSUE_CATEGORY.ACTIVITY, ISSUE_CATEGORY.POINTS].includes(
                data?.issueCategory
              )}
              timeout={500}
              unmountOnExit
            >
              <TextField
                classes={{ root: textFieldRoot }}
                label="Activity URL"
                name={FORM_FIELD_NAME_ATTRIBUTES.ACTIVITY_TYPE}
                onChange={handleChange}
                placeholder={FIELDS_PLACEHOLDER.ACTIVITY}
                variant="outlined"
                data-testid="activityType"
                FormHelperTextProps={{
                  className: helperText
                }}
                error={ACTIVITY_TYPE_ERROR_HANDLE?.isInValid}
                helperText={
                  ACTIVITY_TYPE_ERROR_HANDLE?.isInValid &&
                  ACTIVITY_TYPE_ERROR_HANDLE?.message ? (
                    <div className={flexDisplay}>
                      <Info fontSize="small" className={iconResize} />
                      <Typography variant="subtitle1">
                        {ACTIVITY_TYPE_ERROR_HANDLE?.message}
                      </Typography>
                    </div>
                  ) : null
                }
              />
            </Fade>
            <Fade
              in={[ISSUE_CATEGORY.EGIFT, ISSUE_CATEGORY.POINTS].includes(
                data?.issueCategory
              )}
              timeout={500}
              unmountOnExit
            >
              <TextField
                classes={{ root: textFieldRoot }}
                label="Enter Walmart eGift Card Link"
                name={FORM_FIELD_NAME_ATTRIBUTES.EGIFT_CARD_URL}
                onChange={handleChange}
                placeholder={FIELDS_PLACEHOLDER.GIFTCARD}
                variant="outlined"
                data-testid="giftcard"
                FormHelperTextProps={{
                  className: helperText
                }}
                error={EGIFT_ERROR_HANDLE?.isInValid}
                helperText={
                  EGIFT_ERROR_HANDLE?.isInValid &&
                  EGIFT_ERROR_HANDLE?.message ? (
                    <div className={flexDisplay}>
                      <Info fontSize="small" className={iconResize} />
                      <Typography variant="subtitle1">
                        {EGIFT_ERROR_HANDLE?.message}
                      </Typography>
                    </div>
                  ) : null
                }
              />
            </Fade>
            <Box component="div" classes={{ root: boxRoot }}>
              <TextField
                label={
                  data?.issueCategory === ISSUE_CATEGORY.POINTS
                    ? FIELDS_PLACEHOLDER.DETAILS
                    : FIELDS_PLACEHOLDER.DESCRIPTION
                }
                minRows={4}
                maxRows={4}
                multiline
                name={FORM_FIELD_NAME_ATTRIBUTES.DESCRIPTION}
                onChange={handleChange}
                placeholder={
                  data?.issueCategory === ISSUE_CATEGORY.POINTS
                    ? FIELDS_PLACEHOLDER.MORE_DETAILS
                    : FIELDS_PLACEHOLDER.DESCRIPTION
                }
                variant="outlined"
                data-testid="description"
                error={DESCRIPTION_ERROR_HANDLE?.isInValid}
                FormHelperTextProps={{
                  className: helperText
                }}
                helperText={
                  DESCRIPTION_ERROR_HANDLE?.isInValid &&
                  DESCRIPTION_ERROR_HANDLE?.message ? (
                    <div className={flexDisplay}>
                      <Info fontSize="small" className={iconResize} />
                      <Typography variant="subtitle1">
                        {DESCRIPTION_ERROR_HANDLE?.message}
                      </Typography>
                    </div>
                  ) : null
                }
              />
              <Box component="div" className={countWrapStyles}>
                <Box component="span" className={countValuestyles}>
                  {!!data?.description?.length ? data?.description?.length : 0}/
                  {
                    TEXTFIELD_CHARACTER_LIMIT_VALUE?.DESCRIPTION_OR_DETAILS_LIMIT
                  }
                </Box>
              </Box>
            </Box>
            <DropzoneComponent
              config={{
                multiple: true,
                disabled: imagesList?.length === ASSETS_COUNT || isLoading
              }}
              data-testid="dropzone-content"
              onDrop={onDrop}
              supportTypes={ALL_FORMAT_SUPPORT}
            >
              <DropzoneContent
                data={imagesList}
                onClear={onClear}
                config={{
                  subText: [
                    '(.jpg/jpeg, .png, .pdf files only)',
                    `${SIZE_IN_MB} MB / ${
                      SIZE_IN_MB * 1000
                    } KB maximum upload size`,
                    imagesList?.length === ASSETS_COUNT ? (
                      <span className={errText}>Maximum limit reached</span>
                    ) : (
                      `No more than ${ASSETS_COUNT} files`
                    )
                  ],
                  error: imageErrorMessages,
                  disabled: imagesList?.length === ASSETS_COUNT
                }}
              />
            </DropzoneComponent>
            <ButtonWithLoader
              btnText="Submit"
              isLoading={isSaving}
              btnProps={{
                onClick: isValid ? onSubmit : () => {},
                variant: 'contained',
                color: 'primary',
                className: clsx(regular14, submitBtn),
                disabled: isLoading || !isValid || isSaving,
                'data-testid': 'submit-btn'
              }}
            />
          </div>
        </Fade>
        <Fade in={!showForm} timeout={500} unmountOnExit>
          <div className={flexDisplayCenter}>
            <CircularProgress size={40} thickness={3} />
          </div>
        </Fade>
      </div>
      <HomeFooter />
    </div>
  );
};

export default withStyles(combineStyles(fieldsStyles, styles))(ContactUs);
