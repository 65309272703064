const styles = theme => ({
  breadcrumb: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    height: 60,
    background: '#fff',
    zIndex: 2,
    boxShadow: '0 0px 10px 0px rgb(0 0 0 / 10%)',
    color: '#333333',
    '& p': {
      fontSize: 18,
      [theme.breakpoints.up('sm')]: {
        fontSize: 24
      }
    }
  },
  breadcrumbText: {
    display: 'flex',
    alignItems: 'center',
    color: '#333333'
  },
  breadcrumbIcon: {
    display: 'flex',
    justifyContent: 'center',
    margin: '0 16px',
    [theme.breakpoints.up('sm')]: {
      margin: '0 16px 0 30px',
      width: 24,
      height: 24
    }
  }
});

export default styles;
