const styles = (theme) => ({
  card: {
    minWidth: 93,
    minHeight: 104
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  hr: {
    width: '60%',
    margin: 10
  },
  icon: {
    display: 'flex',
    justifyContent: 'center'
  },
  iconName: {
    fontSize: 10,
    color: '#6d6e71',
    display: 'flex',
    flexWrap: 'wrap',
    width: 'min-content',
    justifyContent: 'center',
    '&.active': {
      color: '#0071ce'
    }
  },
  layout: {
    bottom: 0,
    left: 0,
    height: 56,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    position: 'fixed',
    boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
    backgroundColor: '#ffffff'
  },
  mainUser: {
    width: 45,
    height: 45,
    borderRadius: 23
  },
  subText: {
    fontSize: 12,
    color: '#0071ce'
  },
  userName: {
    background: '#d8d8d8',
    width: 24,
    height: 22,
    borderRadius: 16,
    fontSize: 16,
    fontWeight: 500,
    color: '#000000',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      width: 32,
      height: 32
    }
  },
  btnStyle: {
    width: '100%',
    height: 48,
    fontSize: 14,
    color: '#282828',
    textTransform: 'none',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: '#f2f2f2'
    }
  }
});

export default styles;
