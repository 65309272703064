const styles = (theme) => ({
  base: {
    width: '430px',
    minHeight: '20vh',
    borderRadius: 8,
    height: '282px',
    [theme.breakpoints.down(1380)]: {
      width: '100%'
    },
    [theme.breakpoints.down(600)]: {
      height: '200px'
    }
  },
  cardContentRoot: {
    display: 'flex',
    height: '80%',
    padding: "20px 10px 10px 20px",
    flexDirection: 'row',
    flexWrap: 'noWrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down(600)]: {
      height: '90%',
      padding: '12px',
      position: 'relative'
    }
  },
  action: {
    fontSize: 18,
    letterSpacing: 0.32,
    color: '#0071ce'
  },
  actionRoot: {
    padding: 0,
    height: '20%',
    [theme.breakpoints.down(600)]: {
      display: 'none'
    }
  },
  mainContentWrapper: {
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%'
  },
  contentWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    height: '100%',
    width: '90%',
    [theme.breakpoints.down('600')]: {
      flexDirection: 'column'
    }
  },
  cardTextContainer: {
    flexWrap: 'wrap',
    alignItems: 'center',
    height: '100%',
    alignContent: 'space-between'
  },
  mobileView: {
    [theme.breakpoints.down('600')]: {
      position: 'absolute',
      bottom: 10,
      right: 10
    }
  },
  cardInnerTextContainer: {
    flexWrap: 'wrap',
    height: '100%',
    alignContent: 'space-around'
  },
  nameItem: {},
  pointsItem: {},
  shortIdItem: {},
  imgWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignContent: 'center',
    height: '100%',
    width: '100%',
    [theme.breakpoints.down('600')]: {
      justifyContent: 'end'
    }
    // margin: '30.5px 9px 0 0',
    // [theme.breakpoints.up('sm')]: {
    //   margin: '32px 10px 0 0'
    // },
    // [theme.breakpoints.up('md')]: {
    //   margin: '28px 7px 0 0'
    // },
    // [theme.breakpoints.up('lg')]: {
    //   margin: '51px 24px 0 0'
    // }
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    borderTop: 'solid 1px #e6e6e6',
    backgroundColor: '#ffffff'
    // marginTop: 30,
    // '&:hover': {
    //   backgroundColor: '#e5f0fa'
    // },
    // [theme.breakpoints.up('sm')]: {
    //   marginTop: 35,
    //   height: 49
    // },
    // [theme.breakpoints.up('md')]: {
    //   marginTop: 29,
    //   height: 43
    // },
    // [theme.breakpoints.up('lg')]: {
    //   marginTop: 69,
    //   height: 45
    // }
  },
  linkWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    marginTop: '5%',
    marginBottom: '5%'
  },
  points: {
    // marginTop: 19,
    fontFamily: 'Bogle-Light',
    fontSize: 45,
    fontWeight: 300,
    letterSpacing: 0.43,
    color: '#333333',
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    [theme.breakpoints.down('1440')]: {
      fontSize: 35
    }
    // [theme.breakpoints.up('sm')]: {
    //   marginTop: 25
    // },
    // [theme.breakpoints.up('md')]: {
    //   marginTop: 16
    // },
    // [theme.breakpoints.up('lg')]: {
    //   marginTop: 24
    // }
  },
  title: {
    fontSize: 18,
    letterSpacing: 0.32,
    color: '#333333',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    wordBreak: 'break-word',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down(760)]: {
      width: '100%'
    },
    [theme.breakpoints.down(600)]: {
      fontSize: 17,
      width: '100%'
    }
    // [theme.breakpoints.up('sm')]: {
    //   minWidth: 161,
    //   marginTop: 20
    // },
    // [theme.breakpoints.up('md')]: {
    //   minWidth: 147,
    //   marginTop: 13
    // },
    // [theme.breakpoints.up('lg')]: {
    //   minWidth: 202,
    //   marginTop: 16
    // }
  },
  shortID: {
    // marginTop: 20,
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.3214285671710968,
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'left',
    width: '100%',
    [theme.breakpoints.down(870)]: {
      fontSize: 11
    },
    [theme.breakpoints.down(600)]: {
      fontSize: 18,
      marginBottom: 15
    },
    [theme.breakpoints.down(400)]: {
      fontSize: 12,
      width: '50%'
    }
  },
  textContent: {
    overflow: 'hidden',
    height: '100%'
  },
  textWrapper: {
    overflow: 'hidden',
    width: '100%'
  }
});

export default styles;
