import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Typography,
  Grid,
  IconButton
} from '@material-ui/core';
import styles from '../styles';
import PropTypes from 'prop-types';
import Logo from '../../../Logo';
import { Close } from '@material-ui/icons';

const UnsubscribedDialog = (props) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const { openDialog, handleClose } = props;

  const width = 'sm';
  const TITLE = 'Thank you';
  const CONTENT_TEXT = [
    { key: 1, text: `We’re sorry to see you go!` },
    {
      key: 2,
      text: `Please give us 72 hours to process your request to unsubscribe.`
    },
    { key: 3, text: `Thanks for being a Walmart customer.` }
  ];

  const generateContent = CONTENT_TEXT.map((x) => (
    <Typography align="center" key={x.key}>
      {x.text}
    </Typography>
  ));

  return (
    <Dialog open={openDialog} onClose={handleClose} maxWidth={width}>
      <DialogTitle>
        <Grid container className={classes.dialogTitleCloseContainer}>
          <IconButton onClick={handleClose} aria-label="clase-button">
            <Close />
          </IconButton>
        </Grid>
        <Grid container className={classes.dialogTitleImageContainer}>
          <Logo type="walmart-logo" />
        </Grid>
        <Grid container className={classes.dialogTitleTextContainer}>
          <Typography className={classes.dialogTitleText}>{TITLE}</Typography>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container className={classes.dialogContentContainer}>
          <Grid item xs={12}>
            {generateContent}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

UnsubscribedDialog.propTypes = {
  openDialog: PropTypes.bool,
  handleClose: PropTypes.func
};

export default UnsubscribedDialog;
