const styles = (theme) => ({
  headerContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%',
    marginTop: 40
  },
  headerInnerContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '80%'
  },
  headerContentContainer: {
    width: '100%',
    justifyContent: 'center',
    marginTop: 16,
    marginBottom: 16
  },
  dialogHeaderText: {
    fontSize: 24,
    fontWeight: 400,
    letterSpacing: 0.1875,
    textAlign: 'center'
  },
  bodyContainer: {
    // display: 'flex',
    // flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%'
  },
  bodyInnerContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '80%',
    marginBottom: 20
  },
  bodyContentText: {
    fontSize: 20,
    fontWeight: 400,
    letterSpacing: 0,
    textAlign: 'left'
  },
  contentSignatureContainer: {
    marginTop: 8,
    width: '100%',
    justifyContent: 'flex-end'
  },
  signatureText: {
    fontSize: 20,
    fontWeight: 400,
    textAlign: 'left'
  },
  agreementFormContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '80%',
    marginBottom: 20
  },
  radioGroupLabel: {
    fontSize: 20,
    fontWeight: 400,
    letterSpacing: 0,
    textAlign: 'left',
    color: '#414042'
  },
  radioOption: {
    marginTop: 8,
    marginBottom: 8,
    alignItems: 'flex-start'
  },
  radioLabelText: {
    fontSize: 18,
    fontWeight: 300,
    textAlign: 'left',
    color: '#414042'
  },
  radioLabelRoot: {
    paddingTop: 4
  },
  actionContainer: {
    justifyContent: 'center',
    width: '100%'
  },
  actionInnerContainer: {
    width: '80%'
  },
  buttonContainer: {
    justifyContent: 'flex-end',
    marginBottom: 40
  },
  buttonLabel: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0,
    textAlign: 'center'
  }
});

export default styles;
