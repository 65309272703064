import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  SquareCard,
  WideRectangularCard
} from '../../../../components/ClickableTextCards';
import { Grid, TextField, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { SurveySelectors, DataTypes } from '@walmart/survey-taker-api-js-sdk';

const useStyles = makeStyles(() => ({
  outerContainer: {
    width: '100%',
    display: 'flex',
    rowGap: 32,
    justifyContent: 'center'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '726px',
    rowGap: 24,
    alignItems: 'center'
  },
  squarecardConatiner: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 328,
    gap: 16
  },
  otherTextFiled: {
    width: '100%'
  }
}));

const SingleSelect = (props) => {
  const classes = useStyles();
  const { onAnswerChange, allAnswers } = props;

  const currentQuestion = useSelector(SurveySelectors.selectCurrentQuestion);

  const [selected, setSelected] = useState(
    allAnswers[currentQuestion.questionId] ?? ''
  );
  const [otherValue, setOtherValue] = useState(
    allAnswers[currentQuestion.otherQuestionId] ?? ''
  );

  const initialRender = useRef(true);

  const showOtherTextArea =
    currentQuestion.options.some(
      (option) =>
        option.key === DataTypes.keywords.OTHERS_KEY && option.key === selected
    ) && !!currentQuestion.otherQuestionId;

  const renderFullWidthOption = currentQuestion.options.some(
    (option) => option.value.length > 45
  );

  useEffect(() => {
    const answer = { [currentQuestion.questionId]: selected };
    if (currentQuestion.otherQuestionId) {
      const otherOption = currentQuestion.options.find(
        (option) => option.key === DataTypes.keywords.OTHERS_KEY
      );
      if (selected === otherOption?.key) {
        answer[currentQuestion.otherQuestionId] = otherValue;
      }
    }
    onAnswerChange(answer);
  }, [selected, otherValue, onAnswerChange, currentQuestion]);

  useEffect(() => {
    // skip the first render
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    // reset other value when selected changes
    setOtherValue('');
  }, [selected]);

  return (
    <Grid container className={classes.outerContainer}>
      <Grid
        container
        className={
          renderFullWidthOption
            ? classes.container
            : classes.squarecardConatiner
        }
      >
        {renderFullWidthOption
          ? currentQuestion.options.map((option) => (
              <WideRectangularCard
                key={option.key}
                cardText={option.value}
                selected={option.key === selected}
                onSelect={() => setSelected(option.key)}
              />
            ))
          : currentQuestion.options.map((option) => (
              <SquareCard
                key={option.key}
                cardText={option.value}
                selected={option.key === selected}
                onSelect={() => setSelected(option.key)}
              />
            ))}
      </Grid>
      {showOtherTextArea && (
        <TextField
          className={classes.otherTextFiled}
          autoFocus
          data-testid="other-question"
          minRows={4}
          multiline
          onChange={(e) => setOtherValue(e.target.value)}
          placeholder={'Please specify'}
          value={otherValue}
          variant="outlined"
        />
      )}
    </Grid>
  );
};

SingleSelect.propTypes = {
  onAnswerChange: PropTypes.func.isRequired,
  allAnswers: PropTypes.object.isRequired
};

export default SingleSelect;
