import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Link,
  Radio,
  RadioGroup,
  withStyles
} from '@material-ui/core';
import Logo from '../../Logo';
import OkCancel from '../../AlertDialog/OkCancel';
import { confAgreeContent } from '../../../constants/common';
import { isEmptyString } from '../../../utils/common';
import styles from './styles';
import { useHistory } from 'react-router';

const ConfAgree = ({
  classes,
  openDialog,
  setOpenDialog,
  radioOption = '',
  setRadioOption = () => {},
  readOnly = false
}) => {
  const title = 'confidentiality-agreement-dialog';
  const desc = 'confidentiality-agreement';
  const history = useHistory();

  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleChange = (event) => {
    event.preventDefault();
    setRadioOption(event.target.value);
    if (event.target.value === 'NO') {
      setConfirmOpen(true);
    }
  };

  const handleClose = () => {
    history.push('/home', 'termsUseClose');
    setOpenDialog(false);
  };

  const handleConfirmModal = () => {
    setConfirmOpen(false);
    handleClose();
  };

  const getLink = (linkName, link) => {
    return (
      <Link
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        underline="always"
      >
        {linkName}
      </Link>
    );
  };

  return (
    <>
      <OkCancel
        open={confirmOpen}
        primaryBtnClick={handleConfirmModal}
        secondaryBtnClick={() => setConfirmOpen(false)}
        content={confAgreeContent.CONFIRM_DIALOG}
        primaryBtnText="Leave community"
        secondaryBtnText="Cancel"
        type="mini"
      />
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby={title}
        aria-describedby={desc}
        disableBackdropClick
        disableEscapeKeyDown
        classes={{
          paper: classes.confDialog
        }}
      >
        <DialogTitle id={title} classes={{ root: classes.dialogTitle1 }}>
          <Logo type={'confAgree'} />
        </DialogTitle>
        <DialogTitle id={title} classes={{ root: classes.dialogTitle2 }}>
          Terms of Use
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
          <DialogContentText id={desc} classes={{ root: classes.contentText }}>
            Last Updated: July 28, 2021
          </DialogContentText>
          <DialogContentText id={desc} classes={{ root: classes.contentText }}>
            Please read these terms carefully. This is an agreement between you
            and Walmart Inc. (“<b>Walmart</b>”) concerning your participation in
            the Walmart Customer Spark Community (“<b>Spark Community</b>”) and
            access and use of the Spark Community Sites (“<b>Agreement</b>”).
            The “<b>Spark Community Sites</b>” include the website associated
            with the Spark Community currently located at{' '}
            {getLink(
              'Walmart - Customer Spark',
              confAgreeContent.CUST_PORT_URL
            )}{' '}
            and the materials and services available on it, as well as any
            mobile applications offered by Walmart that provide access to the
            Spark Community’s functionality and services.
          </DialogContentText>
          <DialogContentText id={desc} classes={{ root: classes.contentText }}>
            <DialogContentText
              id={desc}
              classes={{ root: classes.contentText }}
            >
              <b>•</b> By registering for a Spark Community Account (“
              <b>Spark Account</b>”), and by continuing to use your Spark
              Community Account and accessing the Spark Community Sites, you
              consent to this Agreement and affirm that (1) you meet the
              eligibility criteria for registering for a Spark Account (as
              discussed below), and (2) you are of legal age to enter into this
              Agreement. If you violate or do not agree to these terms, do not
              use the Spark Community Sites as your use of the Spark Community
              Sites would be unauthorized.
            </DialogContentText>
            <DialogContentText
              id={desc}
              classes={{ root: classes.contentText }}
            >
              <b>•</b> This Agreement incorporates by reference the Walmart
              Terms of Use and any other terms and conditions posted by Walmart
              through the Spark Community Sites. The Spark Community Sites are
              deemed to be “Walmart Sites” under the{' '}
              {getLink('Walmart Terms of Use', confAgreeContent.TERMS_URL)}. If
              any conflict arises between this Agreement and any of the
              foregoing terms, this Agreement, including defined terms, will
              control and the other terms will not limit the rights granted to
              or expand the obligations of Walmart under this Agreement.
              Walmart’s liability under this Agreement is limited by the
              limitations of liability set forth in the Walmart Terms of Use.
            </DialogContentText>
            <DialogContentText
              id={desc}
              classes={{ root: classes.contentText }}
            >
              <b>•</b> You acknowledge that any personal information that you
              provide through or in connection with the Spark Community Sites
              will be used by Walmart in accordance with the{' '}
              {getLink(
                'Spark Community Privacy Policy',
                confAgreeContent.PRIV_POLICY_URL
              )}
              .
            </DialogContentText>
          </DialogContentText>
          <DialogContentText id={desc} classes={{ root: classes.contentText }}>
            <DialogContentText
              id={desc}
              classes={{ root: classes.contentText }}
            >
              <b>1. Changes.</b> Walmart may change this Agreement from time to
              time by notifying you of such changes by any reasonable means,
              including by posting a revised Agreement through the Spark
              Community Sites. Those changes will not apply to a dispute between
              you and Walmart arising prior to the date on which Walmart posted
              the revised Agreement incorporating such changes or otherwise
              notified you of such changes. <br />
              <br />
              Your use of the Spark Community Sites following any changes to
              this Agreement will constitute your acceptance of such changes.
              The “Last Updated” legend above indicates when this Agreement was
              last changed. Walmart may, at any time and without liability,
              modify or discontinue all or part of the Spark Community Sites
              (including access to the Spark Community Sites via any third-party
              links); change or modify rewards or payments related to use of the
              Spark Community Sites; or offer opportunities to some or all Spark
              Community Sites users.
            </DialogContentText>
            <DialogContentText
              id={desc}
              classes={{ root: classes.contentText }}
            >
              <b>2. Confidentiality of Survey Information.</b> You will, from
              time to time, receive information relating to the current and
              future products, business plans and other sensitive information of
              Walmart, and Walmart’s suppliers or third parties who participate
              in the Spark Community (<b>“Walmart Clients”</b>). In order to
              qualify to participate in the Spark Community, and by registering
              to participate in it, you agree as follows: As between you and
              Walmart, all survey questions, results, newsletters, emails,
              content and other information that you receive from or through
              Walmart or the Spark Community Sites in the course of your
              participation in the Spark Community (<b>“Survey Information”</b>)
              is the property of Walmart. You agree that you will not use Survey
              Information for any purpose other than to participate in the Spark
              Community in accordance with Walmart’s instructions. You agree
              that you will keep Survey Information confidential and will not
              disclose it to any other entity or person, including any member of
              your family. You will also take reasonable measures to protect
              Survey Information that you have received from disclosure to
              anyone else without Walmart’s express, written permission.
            </DialogContentText>
            <DialogContentText
              id={desc}
              classes={{ root: classes.contentText }}
            >
              <b>3. Release.</b> In connection with the Spark Community, you may
              provide, submit or make available your likeness, including without
              limitation your name, image, persona, biographical information,
              performance, actions, statements and other indicia of your
              identity (your
              <b>“Likeness”</b>) and content in connection with your Likeness,
              including photographs, audio, audio-visual recordings, and other
              materials and content related to your Likeness (<b>“Content”</b>).
              <br />
              <br />
              For good and valuable consideration, receipt of which is hereby
              acknowledged, and with the intention to induce reliance, you and
              grant the following authority, grant of rights, waiver, and
              releases (collectively, the <b>“Release”</b>): (a) you consent,
              authorize, and grants the right to Walmart, its affiliates, and
              Walmart Clients (the <b>“Walmart Group”</b>) to record Your
              Likeness and Content; (b) you consent to, authorize, and grant the
              Walmart Group the worldwide, royalty-free, fully paid-up,
              non-exclusive, perpetual, irrevocable, transferable and fully
              sublicensable (through multiple tiers) right, without additional
              consideration to you or any third party, to reproduce, distribute,
              perform and display (publicly or otherwise), create derivative
              works of, adapt, modify and otherwise use your Likeness and
              Content, whether modified or unmodified, in any format or media
              now known or hereafter developed for any purpose (including
              promotional purposes, such as testimonials) and whether using your
              name, a fictitious name, or no name, (c) you waive any right to
              see and approve any recording or uses of your Likeness and Content
              and any materials using your Likeness and/or Content, (d) you
              agree that your Content and any materials using your Likeness
              and/or Content are the property of Walmart and you irrevocably
              assign to Walmart any and all right, title, or interest you may
              have in your Content and any those materials, including, but not
              limited to, all copyright and publicity interests; and (e) you
              irrevocably release, discharge, and hold harmless each member of
              the Walmart Group from any claims, demands, or causes of action
              that you may now have or may hereafter have, including but not
              limited to, claims for defamation, slander, libel, invasion of
              privacy or right of publicity, copyright infringement, or any
              other right arising out of or relating to your Likeness and/or
              Content <br />
              <br />
              You further represent and confirm that (i) you can enter a
              contract in your own name, that you have read and fully understand
              the terms of this Release, (ii) this Release will be perpetual and
              binding upon you and your heirs, legal representatives, and
              assigns, (iii) your granting of the Release do not violate
              applicable law or any right of any third party; and (iv) any
              member of the Walmart Group may, at its sole discretion, elect to
              exercise or not exercise any of its rights under this Release.
            </DialogContentText>
            <DialogContentText
              id={desc}
              classes={{ root: classes.contentText }}
            >
              <b>4. License to Submissions and Feedback.</b> In addition to the
              Content, you may make provide or submit to the Walmart Group
              survey responses and other materials through or in connection with
              your use of the Spark Community Sites (<b>“Submissions”</b>), as
              well as ideas, proposals, or suggestions related to the Spark
              Community Sites itself (<b>“Feedback”</b>). For all Submissions
              and Feedback, you hereby grant to the Walmart Group and its
              affiliates a worldwide, royalty-free, fully paid-up,
              non-exclusive, perpetual, irrevocable, transferable and fully
              sublicensable (through multiple tiers) license, without additional
              consideration to you or any third party, to reproduce, distribute,
              perform and display (publicly or otherwise), create derivative
              works of, adapt, modify and otherwise use, analyze and exploit
              such Submissions and Feedback, in any format or media now known or
              hereafter developed, and for any purpose (including promotional
              purposes, such as testimonials). You further agree that your
              Submissions and Feedback are not confidential, and that each
              member of the Walmart Group may use your Submissions and Feedback
              without attribution. <br />
              <br />
              You represent and warrant that you have all rights necessary to
              grant the licenses and rights granted in this section. You agree
              only to provide Submissions and Feedback that are complete,
              accurate, and that do not violate applicable law or any right of
              any third party.
            </DialogContentText>
            <DialogContentText
              id={desc}
              classes={{ root: classes.contentText }}
            >
              <b>5. Spark Account Eligibility.</b> You are not eligible for a
              Spark Account if: (a) you violate or do not accept the terms of
              this Agreement; (b) you live outside the 50 United States; (c) you
              are under 18 years of age; (d) you are a Walmart employee or
              associate; (e) you work for (i) for a marketing research firm (or
              within a company’s marketing research department), (ii) an
              advertising agency (or within a company’s advertising department),
              (iii) a mass retailer (e.g., Amazon, Target, etc.), (iv) a
              warehouse or discount club (e.g., Sam’s Club, Costco, BJ’s, etc.),
              (v) an online retailer, or (vi) within the corporate offices of a
              retail company.
            </DialogContentText>
            <DialogContentText
              id={desc}
              classes={{ root: classes.contentText }}
            >
              <b>6. Rewards Program.</b> As a member of the Spark Community, you
              are eligible to participate in the Spark Community rewards program
              and collect points by completing certain activities. You can earn
              points in the following ways:
              <br />
              <br />
              <b>• Survey Completion.</b> At Walmart’s discretion, you may be
              offered the opportunity to complete surveys to earn points. To
              complete a survey, you must answer all required questions. You
              will earn points only for each survey successfully completed.
              <br />
              <br />
              <b>• Other Activities.</b> At Walmart’s discretion, you may be
              offered the opportunity to participate in other activities
              including but not limited to in-depth interviews, focus groups,
              shop-alongs, and submitting photos and/or videos to earn points.
              To earn points, you must fully participate for the entire duration
              of the activity. You will earn points only for each activity
              successfully completed.
              <br />
              <br />
              The number of points you can earn for completing a given activity
              depends on both the length of the activity and the effort required
              to complete the activity. Walmart may change the number of points
              awarded for each activity, the activities eligible for points, and
              the prerequisites for earning or maintaining points previously
              awarded in its sole discretion. The points you accumulate will be
              tracked in your Spark Account. Points have no cash value and do
              not constitute property. You must maintain an active Spark
              Account, in accordance with Walmart’s Spark Account eligibility
              criteria (set forth above), to be eligible to earn and redeem
              points. If you believe that points were not properly accrued to
              your Spark Account, you must contact sparkcommunity@walmart.com.
              <br />
              <br />
              Points may be redeemed by clicking the "<b>Redeem</b>" button on
              the “<b>Rewards</b>” page within your Spark Account. The number of
              points required to redeem a given reward may be modified by
              Walmart at any time in its sole discretion. All points redemptions
              are final. In the event your Spark Account is terminated, your
              unredeemed points will expire.
            </DialogContentText>
            <DialogContentText
              id={desc}
              classes={{ root: classes.contentText }}
            >
              <b>7. Termination.</b> This Agreement is effective until
              terminated. Walmart may terminate or suspend your Spark Account or
              your use of the Spark Community Sites (or any portion thereof) at
              any time and without prior notice, for any or no reason, including
              if Walmart believes that you have violated or acted inconsistently
              with the letter or spirit of this Agreement. Upon any such
              termination or suspension, your right to use the Spark Community
              Sites (or any portion thereof) will immediately cease, and Walmart
              may, without liability to you or any third party, immediately
              deactivate or delete your user name, password and Spark Account,
              and all associated materials, without any obligation to provide
              any further access to such materials. Sections 1-7 will survive
              any expiration or termination of this Agreement.
            </DialogContentText>
          </DialogContentText>
          <DialogContentText id={desc} classes={{ root: classes.contentText }}>
            Spark Community Sites <b>©2020–{new Date().getFullYear()}</b>{' '}
            Walmart unless otherwise noted. All rights reserved.
          </DialogContentText>
        </DialogContent>
        {!readOnly && (
          <DialogActions classes={{ root: classes.dialogActions }}>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="confAgree"
                name="confAgree"
                value={radioOption}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="YES"
                  control={<Radio color="primary" />}
                  label={confAgreeContent.LABEL_YES}
                  classes={{
                    root: classes.radioLabelRoot,
                    label: classes.contentText
                  }}
                />
                <FormControlLabel
                  value="NO"
                  control={<Radio color="primary" />}
                  label={confAgreeContent.LABEL_NO}
                  classes={{
                    root: classes.radioLabelRoot,
                    label: classes.contentText
                  }}
                />
              </RadioGroup>
            </FormControl>
          </DialogActions>
        )}
        <DialogActions classes={{ root: classes.dialogActions2 }}>
          <Button
            id="alert-submit"
            className={classes.confirm}
            color="primary"
            variant="contained"
            onClick={handleClose}
            disabled={!readOnly && isEmptyString(radioOption)}
          >
            {readOnly ? 'Close' : 'Submit'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ConfAgree.defaultProps = {
  classes: {}
};

ConfAgree.propTypes = {
  classes: PropTypes.object,
  openDialog: PropTypes.bool.isRequired,
  setOpenDialog: PropTypes.func.isRequired,
  radioOption: PropTypes.string,
  setRadioOption: PropTypes.func,
  readOnly: PropTypes.bool
};

export default withStyles(styles)(ConfAgree);
