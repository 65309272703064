const styles = (theme) => ({
  arrowIcon: {
    fill: '#000000',
    '&.disabled': {
      fill: '#6c6d6c'
    }
  },
  autoRedeem: {
    display: 'flex',
    margin: '40px 0 250px 0',
    alignItems: 'center'
  },
  autoRedeemText: {
    fontSize: 16,
    marginLeft: 4,
    lineHeight: 1,
    color: '#6c6d6c',
    [theme.breakpoints.up('sm')]: {
      color: '#616161'
    }
  },
  buttonHolder: {
    display: 'flex',
    marginTop: 32,
    [theme.breakpoints.up('sm')]: {
      width: 344,
      justifyContent: 'space-around'
    },
    [theme.breakpoints.up('md')]: {
      width: 300
    },
    [theme.breakpoints.up('lg')]: {
      width: 412
    }
  },
  button: {
    width: 312,
    height: 40,
    fontSize: 14,
    textTransform: 'none',
    [theme.breakpoints.up('sm')]: {
      width: 99
    },
    '&.Mui-disabled': {
      backgroundColor: '#6d6e71',
      color: '#ffffff'
    }
  },
  confetti: {
    zIndex: 1
  },
  gcSelector: {
    width: 200,
    height: 40,
    borderRadius: 20
  },
  gcSelectorEnabled: {
    border: '1px solid #333333'
  },
  gcSelectorDisabled: {
    border: '1px solid #dadce0'
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  redeemContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start'
    //marginLeft: 10
  },
  rectangle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 0,
    //marginTop: 86,
    backgroundColor: '#ffffff',
    width: '90%',
    maxWidth: 444,
    minHeight: 800,

    '&.adjustSpace': {
      marginTop: 0
    }
  },
  pointsEarnedText: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 40,
    alignSelf: 'flex-start',
    fontWeight: 400,
    fontSize: 18,
    marginBottom: 24
  },
  chooseRewardsTextGrid: {
    alignSelf: 'flex-start'
  },
  chooseRewardsText: {
    fontFamily: 'Bogle-light',
    fontWeight: 300,
    fontSize: 16
  },
  giftCardContainer: {
    marginTop: 16
  },
  rewardsContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'row',
    gap: 16
  },
  rewardCard: {
    width: 210,
    height: 72,
    borderRadius: 8,
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    background: '#FFFFFF',
    [theme.breakpoints.down('xs')]: {
      width: 150
    }
  },
  disabledRedeemRewardCard: {
    opacity: 0.1
  },
  giftCardTextContainer: {
    maxWidth: '90%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: 8
  },
  giftCardDollarValue: {
    fontWeight: 700,
    fontSize: 20,
    color: '#0071CE'
  },
  giftCardRedeemPointsContainer: {
    display: 'flex',
    justifyContent: 'center',
    textTransform: 'none'
  },
  giftCardRedeemPointsText: {
    fontWeight: 300,
    fontSize: 10,
    color: '#333333'
  },
  selectGrid: {
    marginTop: 6,
    flexBasis: 'auto'
  },
  selectLabel: {
    display: 'flex',
    transform: 'translate(85%, 80%) scale(1)',
    color: '#000000',
    backgroundColor: '#ffffff',
    padding: '0 5px 0px 5px'
  },
  selectRoot: {
    borderRadius: 20,
    border: 'solid 1px #333333',
    backgroundColor: '#ffffff'
  },
  snackbarRoot: {
    color: '#0089e2',
    backgroundColor: '#e5f0fa',
    fontSize: 16,
    fontWeight: '500',
    maxWidth: 444,
    textAlign: 'center'
  },
  successBanner: {
    width: 328,
    height: 60,
    borderRadius: 4,
    marginTop: 8,
    fontSize: 16,
    fontWeight: 500,
    zIndex: 1,
    backgroundColor: '#e5f0fa',
    color: '#007dd9',
    boxShadow:
      '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)',
    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
      width: 344
    }
  },
  successText: {
    margin: '8px 20px 8px 20px'
  },
  trackGrid: {
    [theme.breakpoints.up('sm')]: {
      //marginTop: 46
    }
  },
  buttonView: {
    marginTop: 24,
    marginBottom: 24
    //marginLeft: 350,
  },
  closeIconHolder: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: 16,
    marginRight: 16
  },
  closeIcon: {
    color: 'grey'
  },
  mainTitle: {
    color: '#333333',
    fontSize: 16,
    fontWeight: '400'
  },
  buttonText: {
    borderRadius: 20,
    marginLeft: 24
  },
  logo: {
    position: 'relative',
    paddingBottom: 20,
    marginLeft: 1,
    marginBottom: 10
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'column'
  }
});

export default styles;
