import { Box } from '@material-ui/core';
import {
  Body,
  ErrorMessage,
  Button
} from '@walmart-web/livingdesign-components';
import React from 'react';
import { extractSurveyId } from '../../../utils';
import { useHistory } from 'react-router-dom';
import { activity } from '../../../constants';

function ShareFeedback({ feedbackSurvey }) {
  const history = useHistory();

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      width={'100%'}
      maxWidth={'460px'}
      justifyContent={'flex-start'}
      alignItems={'center'}
    >
      <Box my={'32px'}>
        <ErrorMessage
          title={activity.activityFreeTexts.thankYou}
          media={
            <img
              alt="spark logo"
              height="100"
              src="/assets/images/spark.svg"
              width="100"
            />
          }
          actions={
            <Button
              variant="primary"
              size="medium"
              onClick={() => {
                const surveyId = extractSurveyId(feedbackSurvey?.surveyLink);
                const panelistId = localStorage.getItem('panelistId') ?? '';
                history.replace(
                  `/activities/weekly-quests/${surveyId}?panelist_hash=${panelistId}`,
                  'feedbackSurvey'
                );
              }}
            >
              {activity.activityFreeTexts.shareFeedback}
            </Button>
          }
        >
          <Box display="flex" flexDirection={'column'} gridRowGap={'24px'}>
            <Body as="p" UNSAFE_style={{ color: '#74767C' }}>
              {activity.activityFreeTexts.shareFeedbackText1}
            </Body>
            <Body as="p" UNSAFE_style={{ color: '#74767C' }}>
              {activity.activityFreeTexts.shareFeedbackText2}
            </Body>
          </Box>
        </ErrorMessage>
      </Box>
    </Box>
  );
}

export default ShareFeedback;
