import { Button, Dialog, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import styles from './styles';

function InfoDialog({
  buttonConfig,
  classes,
  description,
  dialogProps,
  title
}) {
  const { container, titleText, innerContainer, flexEnd } = classes;
  return (
    <Dialog {...dialogProps} className={container}>
      <div className={innerContainer}>
        {title ? <Typography className={titleText}>{title}</Typography> : null}
        {description ? <Typography>{description}</Typography> : null}
        <div className={flexEnd}>
          <Button {...buttonConfig.props}>{buttonConfig?.text}</Button>
        </div>
      </div>
    </Dialog>
  );
}

export default withStyles(styles)(InfoDialog);
