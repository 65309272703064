import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import styles from './styles';

const Skeleton = ({ classes, type, count }) => {
  const elements = [];

  for (let index = 0; index < count; index += 1) {
    elements.push(
      <div key={index} className={`${classes.skeleton} ${classes[type]}`} />,
    );
  }

  return <>{elements}</>;
};

Skeleton.defaultProps = {
  classes: {},
  type: 'block',
  count: 1,
};

Skeleton.propTypes = {
  classes: PropTypes.object,
  type: PropTypes.string,
  count: PropTypes.number,
};

export default withStyles(styles)(Skeleton);
