import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ breakpoints }) => ({
  container: {
    width: '100%',
    display: 'flex',
    height: '99.9vh',
    overflow: 'hidden',
    flexDirection: 'column',
    marginTop: 100
  },
  logo: {
    width: 328,
    [breakpoints.up('sm')]: {
      width: 492
    }
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 40,
    [breakpoints.up('sm')]: {
      marginTop: 56
    }
  },
  titleText: {
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: '700',
    textAlign: 'center',
    [breakpoints.up('sm')]: {
      fontSize: '24px',
      lineHeight: '36px'
    }
  },
  contentText: {
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
    fontWeight: 400,
    [breakpoints.up('sm')]: {
      fontSize: '18px',
      lineHeight: '24px'
    }
  }
}));
