import React, { useEffect } from 'react';
import Header from '../../components/Header';
import Logo from '../../components/Logo';
import PropTypes from 'prop-types';
import analytics from '../../utils/analytics';
import styles from './styles';
import { Button, withStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { CLICK_EVENTS, PAGES } from '../../constants/analytics';
import { useHistory } from 'react-router-dom';

const PageNotFound = ({ classes }) => {
  const { push } = useHistory();
  const handleSuccess = () => {
    analytics.trackClick(PAGES.PAGE_NOT_FOUND, CLICK_EVENTS.BACK_TO_HOME_PAGE);
    push('/');
  };

  useEffect(() => {
    analytics.trackPageView(PAGES.PAGE_NOT_FOUND);
  }, []);

  return (
    <>
      <title>
        <FormattedMessage
          defaultMessage="Walmart - Customer Spark: Page Not Found"
          id="PAGE_NOT_FOUND_TITLE"
        />
      </title>
      <Header />
      <div className={classes.root}>
        <p className={classes.header}>
          <FormattedMessage
            defaultMessage="Page not found"
            id="PAGE_NOT_FOUND"
          />
        </p>
        <Logo type="pageNotFound" />
        <p className={classes.content}>
          <FormattedMessage
            defaultMessage="Sorry, we can’t find the page you were looking for."
            id="PAGE_NOT_FOUND_CONTENT"
          />
        </p>
        <div className={classes.buttonHolder}>
          <Button
            className={classes.button}
            color="primary"
            id="back-to-home"
            onClick={handleSuccess}
            type="submit"
            value="pageNotFound"
            variant="contained"
          >
            <FormattedMessage
              defaultMessage="Back to Home Page"
              id="BACK_TO_HOME_PAGE"
            />
          </Button>
        </div>
      </div>
    </>
  );
};

PageNotFound.defaultProps = {
  classes: {}
};

PageNotFound.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(PageNotFound);
