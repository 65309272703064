import React, { useState } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import API, { API_STATUS_CODE } from '../../../constants/common';
import CSLayout from '../../../components/CSLayout';
import OkCancel from '../../../components/AlertDialog/OkCancel';
import SnackBanner from '../../../components/SnackBanner';
import api from '../../../constants/api';
import apiCall from '../../../utils/api';
import styles from './styles';
import { confirmationDialogMessage } from '../utils';
import { emailChangeConstants } from '../constanst';
import { useHistory } from 'react-router-dom';
import EmailForm from './EmailForm';
import OTPForm from './OTPForm';

const ChangeProfileEmail = () => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const [enteredEmail, setEnteredEmail] = useState('');
  const [enteredOtp, setEnteredOtp] = useState('');
  const [resendEnabledFlag, setResendEnabledFlag] = useState(true);
  const [formState, setFormState] = useState(
    emailChangeConstants.formState.email
  );
  const [snackBanner, setSnackBanner] = useState({
    displaySnackBanner: false,
    snackBannerMessage: '',
    isErrorSnack: false
  });
  const [confirmationAlert, setConfirmationAlert] = useState({
    open: false,
    alertMessage: '',
    alertType: ''
  });

  const history = useHistory();

  const openErrorSnackBanner = (message) => {
    setSnackBanner({
      displaySnackBanner: true,
      snackBannerMessage: message,
      isErrorSnack: true
    });
  };

  const handleSnackBannerClose = () => {
    setSnackBanner({
      displaySnackBanner: false,
      snackBannerMessage: ''
    });
  };

  const openAlertDialogBox = (content, type) => {
    setConfirmationAlert({
      open: true,
      alertMessage: content,
      alertType: type
    });
  };

  const closeEmailAlertDialogBox = () => {
    setFormState(emailChangeConstants.formState.otp);
    setConfirmationAlert({
      open: false,
      alertMessage: '',
      alertType: ''
    });
  };

  const closeOtpAlertDialogBox = () => {
    setConfirmationAlert({
      open: false,
      alertMessage: ''
    });

    history.push('/login', { prevPath: 'emailUpdate' });
  };

  const handleEmailInputChange = (event) => {
    setEnteredEmail(event.target.value);
  };

  const handleAuthCodeChange = (event) => {
    setEnteredOtp(event.target.value);
  };

  const handleEmailContinueClick = () => {
    setResendEnabledFlag(false);
    setTimeout(() => {
      setResendEnabledFlag(true);
    }, 60000);

    const payload = {
      newUserId: enteredEmail
    };

    apiCall(api.sendEmailChangeOtp, API.METHOD.POST, payload, false).then(
      (response) => {
        if (response.error) {
          openErrorSnackBanner(response?.error?.message);
          console.error(
            `Error occurred while calling OTP API for Email change. Error details : /n ${response.error} `
          );
        }
        if (response.status === API_STATUS_CODE.SUCCESS) {
          openAlertDialogBox(
            confirmationDialogMessage(enteredEmail),
            emailChangeConstants.alertTypes.sentOtp
          );
        }
      }
    );
  };

  const handleOtpContinueClick = () => {
    const payload = [
      {
        detailName: 'ap_email',
        selectedList: [enteredEmail, enteredOtp]
      }
    ];

    apiCall(api.profile, API.METHOD.PUT, payload, false).then((response) => {
      if (response.error) {
        openErrorSnackBanner(response?.error?.message);
        console.error(
          `Error occurred while calling Profile PUT API for Email change. Error details : /n ${response.error} `
        );
      }
      if (response.status === API_STATUS_CODE.SUCCESS) {
        openAlertDialogBox(
          emailChangeConstants.submitOtpDialogMsg,
          emailChangeConstants.alertTypes.emailChanged
        );
      }
    });
  };

  return (
    <CSLayout>
      <SnackBanner
        isSnackbarOpen={snackBanner.displaySnackBanner}
        snackbarMessage={snackBanner.snackBannerMessage}
        onSnackBarClose={handleSnackBannerClose}
        errorMessage={snackBanner.isErrorSnack}
      />
      <OkCancel
        open={
          confirmationAlert.open &&
          confirmationAlert.alertType ===
            emailChangeConstants.alertTypes.sentOtp
        }
        primaryBtnClick={closeEmailAlertDialogBox}
        content={confirmationAlert.alertMessage || ''}
        primaryBtnText="OK"
      />
      <OkCancel
        open={
          confirmationAlert.open &&
          confirmationAlert.alertType ===
            emailChangeConstants.alertTypes.emailChanged
        }
        primaryBtnClick={closeOtpAlertDialogBox}
        content={confirmationAlert.alertMessage || ''}
        primaryBtnText="OK"
      />
      <Grid container className={classes.formRoot}>
        <Grid container className={classes.formContainer}>
          <Grid container className={classes.headerContainer}>
            <Grid item className={classes.headerItem} xs={12}>
              <Typography className={classes.headerText}>
                {emailChangeConstants.pageTitle}
              </Typography>
            </Grid>
            <Grid item className={classes.subHeaderItem} xs={12}>
              <Typography className={classes.subHeaderText}>
                {emailChangeConstants.pageSubTitle}
              </Typography>
            </Grid>
            {formState === emailChangeConstants.formState.email && (
              <EmailForm
                handleEmailInputChange={handleEmailInputChange}
                enteredEmail={enteredEmail}
                handleEmailContinueClick={handleEmailContinueClick}
              />
            )}
            {formState === emailChangeConstants.formState.otp && (
              <OTPForm
                handleAuthCodeChange={handleAuthCodeChange}
                enteredOtp={enteredOtp}
                handleResendOtpClick={handleEmailContinueClick}
                resendEnabledFlag={resendEnabledFlag}
                handleOtpContinueClick={handleOtpContinueClick}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </CSLayout>
  );
};

export default ChangeProfileEmail;
