import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Tabs, Tab, withStyles, Fade } from '@material-ui/core';
import ListContainer from '../ListContainer';
import { TAB_INDICATOR_STYLE } from '../../constants/styles';
import styles from './styles';
import { FormattedMessage } from 'react-intl';
import analytics from '../../utils/analytics';
import { PAGES } from '../../constants/analytics';
import { Link, Route, Switch, useLocation } from 'react-router-dom';
import { HISTORY_TAB_ITEMS } from '../../constants/common';

const History = ({ classes }) => {
  const location = useLocation();
  const {
    gridContainer,
    innerGrid,
    innerSelected,
    innerTab,
    tabContainer,
    wrapper
  } = classes;

  const currentTab = location?.pathname.includes('completed-activities')
    ? 1
    : 0;

  useEffect(() => {
    analytics.trackPageView(PAGES.HISTORY);
  }, []);

  return (
    <Fade in timeout={1000}>
      <Grid container className={`${gridContainer} ${innerGrid}`}>
        <Grid>
          <div className={wrapper}>
            <div className={tabContainer}>
              <Tabs
                value={currentTab}
                indicatorColor="primary"
                TabIndicatorProps={TAB_INDICATOR_STYLE}
              >
                {HISTORY_TAB_ITEMS.map((item, index) => (
                  <Tab
                    key={index}
                    className={innerTab}
                    classes={{ selected: innerSelected }}
                    label={
                      <FormattedMessage
                        id={`HISTORY_TAB_DISPLAY_${index}`}
                        defaultMessage={item.display}
                      />
                    }
                    data-testid={`${item.display} Tab`}
                    component={Link}
                    to={
                      item.display === 'Activities History'
                        ? '/history/completed-activities'
                        : '/history/redeemed-rewards'
                    }
                  />
                ))}
              </Tabs>
            </div>
          </div>
        </Grid>
        <div
          className={classes.innerWrapper}
          role="tabpanel"
          data-testid={'tabData'}
        >
          <Switch>
            <Route exact path={['/history', '/history/redeemed-rewards']}>
              <ListContainer type="rewardsHistory" />
            </Route>
            <Route path="/history/completed-activities">
              <ListContainer type="activityHistory" />
            </Route>
          </Switch>
        </div>
      </Grid>
    </Fade>
  );
};

History.defaultProps = {
  classes: {}
};

History.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(History);
