import React from 'react';
import PropTypes from 'prop-types';
import {
  ButtonBase,
  IconButton,
  Typography,
  withStyles
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import styles from './styles';

const TopBar = ({ title, classes, onClose }) => {
  const { container } = classes;
  return (
    <ButtonBase
      className={container}
      onClick={() => onClose()}
      data-testid="close"
    >
      {onClose && (
        <IconButton>
          <ArrowBackIcon />
        </IconButton>
      )}
      <Typography className={classes.content}>{title}</Typography>
    </ButtonBase>
  );
};

TopBar.propTypes = {
  title: PropTypes.string.isRequired,
  classes: PropTypes.shape({}).isRequired,
  onClose: PropTypes.func.isRequired
};

export default withStyles(styles)(TopBar);
