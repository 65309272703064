import {
  Grid,
  FormControl,
  FormGroup,
  makeStyles,
  TextField,
  Box
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SurveySelectors, DataTypes } from '@walmart/survey-taker-api-js-sdk';
import { Checkbox } from '@walmart-web/livingdesign-components';

const { NONE_OF_THE_ABV_KEY, OTHERS_KEY } = DataTypes.keywords;

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    rowGap: 32
  },
  multiselectFormGroup: {
    rowGap: 16
  },
  otherTextFiled: {
    width: '100%'
  }
}));

const MultiSelect = (props) => {
  const classes = useStyles();

  const currentQuestion = useSelector(SurveySelectors.selectCurrentQuestion);

  const { onAnswerChange, allAnswers } = props;

  const [selected, setSelected] = useState(
    allAnswers[currentQuestion.questionId] ?? []
  );
  const [otherValue, setOtherValue] = useState(
    allAnswers[currentQuestion.otherQuestionId] ?? ''
  );

  const showOtherTextArea =
    currentQuestion.options.some(
      (option) => option.key === OTHERS_KEY && selected.includes(option.key)
    ) && !!currentQuestion.otherQuestionId;

  const handleChange = (event) => {
    const selectedOption = event.target.name;
    if (event.target.checked) {
      if (selectedOption === NONE_OF_THE_ABV_KEY) {
        setSelected([NONE_OF_THE_ABV_KEY]);
      } else {
        if (selectedOption === OTHERS_KEY) {
          // reset other value before other is checked
          setOtherValue('');
        }
        setSelected([
          ...selected.filter((i) => i !== NONE_OF_THE_ABV_KEY),
          selectedOption
        ]);
      }
    } else {
      setSelected(selected.filter((value) => value !== selectedOption));
    }
  };

  useEffect(() => {
    const answer = { [currentQuestion.questionId]: selected };
    if (currentQuestion.otherQuestionId && selected.includes(OTHERS_KEY)) {
      answer[currentQuestion.otherQuestionId] = otherValue;
    }
    onAnswerChange(answer);
  }, [selected, otherValue, onAnswerChange, currentQuestion]);

  return (
    <Grid container className={classes.container}>
      <Box>
        <FormControl component="fieldset">
          <FormGroup
            name="MultiSelect"
            className={classes.multiselectFormGroup}
          >
            {currentQuestion.options.map((option) => {
              const isChecked = selected.includes(option.key);
              return (
                <Checkbox
                  key={option.key}
                  label={option.value}
                  value={option.key}
                  checked={isChecked}
                  onChange={handleChange}
                  name={option.key}
                  data-testid={`${option.value}-checkbox-option`}
                />
              );
            })}
          </FormGroup>
        </FormControl>
      </Box>
      {showOtherTextArea && (
        <TextField
          className={classes.otherTextFiled}
          autoFocus
          data-testId="other-question"
          minRows={4}
          multiline
          onChange={(e) => setOtherValue(e.target.value)}
          placeholder={'Please specify'}
          value={otherValue}
          variant="outlined"
        />
      )}
    </Grid>
  );
};

MultiSelect.propTypes = {};

export default MultiSelect;
