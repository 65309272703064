import React, { useState } from 'react';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import PropTypes from 'prop-types';
import ReplayIcon from '@material-ui/icons/Replay';
import Vimeo from '@u-wave/react-vimeo';
import analytics from '../../utils/analytics';
import clsx from 'clsx';
import styles from './styles';
import { IconButton, withStyles } from '@material-ui/core';

const VideoPlayer = ({ classes, videoId, autoPlay, index, config }) => {
  const [startedOnce, setStartedOnce] = useState(false);
  const [pauseVideo, setPauseVideo] = useState(false);
  const [isError, setError] = useState(false);

  const errorImageFallback = [
    {
      alt: 'video not found',
      url: '/assets/images/customer-spark-hero-768.jpg'
    },
    {
      alt: 'video not found',
      url: '/assets/images/page-not-found/page-not-found-360.jpg',
      height: 200
    },
    {
      alt: 'video not found',
      url: '/assets/images/list-no-results/no-results-found-360.jpg',
      height: 200
    }
  ];

  const onEndPlayer = () => {
    analytics.trackEvents('player', 'player ended', config?.page);
    setPauseVideo(true);
    if (!startedOnce) {
      setStartedOnce(true);
    }
  };

  const handleButtonClick = (evt) => {
    evt.stopPropagation();
    setStartedOnce(true);
    setPauseVideo(!pauseVideo);
  };

  const handleKeyDownPress = (evt) => {
    if (evt.code !== 'Tab') {
      handleButtonClick(evt);
    }
  };

  const getIcon = (icon) => {
    switch (icon) {
      case 'play':
        return <PlayCircleFilledIcon className={classes.replayButton} />;
      case 'pause':
        return <PauseCircleFilledIcon className={classes.pauseButton} />;
      case 'replay':
        return <ReplayIcon className={classes.replayButton} />;
      default:
        return;
    }
  };

  const getIconBtnContainer = (icon) => (
    <div className={classes.btnContainer}>
      <IconButton
        id="playVideoBtn"
        className={classes.paddingTop}
        onClick={(evt) => handleButtonClick(evt)}
        onKeyDown={(evt) => handleKeyDownPress(evt)}
        aria-label="Play Video"
      >
        {getIcon(icon)}
      </IconButton>
    </div>
  );

  const onError = (e) => {
    analytics.trackEvents('player', 'error', config?.page);
    setError(true);
  };

  const getErrorIcon = () => (
    <img
      src={errorImageFallback?.[index]?.url}
      alt={errorImageFallback?.[index]?.alt}
      height={errorImageFallback?.[index]?.height}
    />
  );

  const startOnReady = () => {
    setPauseVideo(!autoPlay);
    setStartedOnce(autoPlay);
  };

  return (
    <div className={clsx(classes.container)}>
      <Vimeo
        autoplay={autoPlay}
        className={`${isError ? '' : classes.videoContainer}`}
        controls={false}
        onEnd={onEndPlayer}
        onError={onError}
        onReady={startOnReady}
        paused={pauseVideo}
        responsive={true}
        video={`https://vimeo.com/${videoId}`}
      />
      {isError ? (
        getErrorIcon()
      ) : (
        <>
          {startedOnce ? (
            <>
              {pauseVideo
                ? getIconBtnContainer('replay')
                : getIconBtnContainer('pause')}
            </>
          ) : (
            getIconBtnContainer('play')
          )}
        </>
      )}
    </div>
  );
};

VideoPlayer.defaultProps = {
  classes: {}
};

VideoPlayer.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(VideoPlayer);
