import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import { Button, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';

const SubmitButton = (props) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const {
    id = 'submit',
    label,
    variant,
    disabled = false,
    onSubmit,
    labelClass,
    buttonClass
  } = props;

  return (
    <Button
      id={id}
      data-testid={`TEST-${id}`}
      color="primary"
      variant={variant}
      className={
        disabled
          ? clsx(
              classes.submitButton,
              classes.disabledSubmitButton,
              buttonClass
            )
          : clsx(classes.submitButton, buttonClass)
      }
      disabled={disabled}
      onClick={onSubmit}
    >
      <Typography className={labelClass}>{label}</Typography>
    </Button>
  );
};

SubmitButton.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['contained', 'text', 'outlined']),
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  labelClass: PropTypes.string,
  buttonClass: PropTypes.string
};

export default SubmitButton;
