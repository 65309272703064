import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
// import { getDotLocation } from '../../utils/common';
import styles from './styles';

const Tracker = ({ classes, loading, points = 0, strokeWidth, sqSize }) => {
  //let percentage = 0;
  const maxPoints = 10000;
  const minPoints = 0;
  const percentage =
    (points / maxPoints) * 100 > 100 ? 100 : (points / maxPoints) * 100;

  // const delta = (points / 100) % 5;
  // const dollar = Math.floor(points / 100);
  // const radius = (sqSize - strokeWidth) / 2;
  const dashArray = 100 * Math.PI * 2;
  const dashOffset = (dashArray * (50 - percentage / 2)) / 100;
  // const viewBox = `0 0 ${sqSize} ${sqSize}`;
  // const { dotX, dotY } = getDotLocation(percentage);

  return (
    <Grid container className={classes.mainContainer}>
      <div className={classes.svgContainer}>
        <svg width="100%" height="100%" className={classes.svg}>
          <circle
            cx="50%"
            cy="100%"
            r="100"
            stroke="#D8D8D8"
            strokeWidth="1"
            fill="none"
          />
          <text
            x="50%"
            y="80%"
            textAnchor="middle"
            alignmentBaseline="middle"
            className={classes.trackerPointsValue}
          >
            {`$${(Number(points) / 100).toFixed(2).replace(/\.00$/, '')}`}
          </text>
        </svg>
        <svg width="100%" height="100%" className={classes.svg}>
          <defs>
            <linearGradient
              id="rewardPointsGradient"
              gradientTransform="176.95deg"
            >
              <stop offset="-2.83%" stopColor="#E5F0FA" />
              <stop offset="107.34%" stopColor="#0071CE" />
            </linearGradient>
          </defs>
          <circle
            cx="50%"
            cy="100%"
            r="100"
            stroke="url('#rewardPointsGradient')"
            strokeWidth="5"
            fill="none"
            style={{
              strokeDasharray: dashArray,
              strokeDashoffset: dashOffset
            }}
          />
        </svg>
        <Grid container className={classes.pointsContainer}>
          <Grid container className={classes.imgContainer}>
            <img
              src="/assets/images/walmart-icon.png"
              alt="spark-logo"
              className={classes.logoImage}
            />
          </Grid>
          <Typography
            className={classes.pointsValue}
          >{`${points.toLocaleString()} points`}</Typography>
        </Grid>
      </div>

      <Grid container className={classes.minMaxPointsContainer}>
        <Grid className={classes.trackerMinPoints}>{minPoints}</Grid>
        <Grid className={classes.trackerMaxPoints}>{`${maxPoints}+`}</Grid>
      </Grid>
    </Grid>
  );
};

Tracker.defaultProps = {
  disabled: false,
  points: 0,
  strokeWidth: 3,
  sqSize: 280
};

export default withStyles(styles)(Tracker);
