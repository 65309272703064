const styles = (theme) => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    flexWrap: 'wrap'
  },
  homeLinkContainer: {
    justifyContent: 'flex-start',
    width: '100%'
  },
  homeLinkButton: {
    marginTop: 10,
    marginBottom: 10,
    [theme.breakpoints.down('xs')]: {
      marginTop: 5,
      marginBottom: 5
    }
  },
  footerContainer: {
    marginTop: 'auto',
    width: '100%'
  }
});

export default styles;
