import React, { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  Typography
} from '@material-ui/core';
import { freeTexts } from '../constants';
import styles from './styles';
import NewRegistrationLayout from '../Layout';
import NRTextField from '../NRTextField';
import apiCall from '../../../utils/api';
import api from '../../../constants/api';
import API from '../../../constants/common';
import SnackBanner from '../../../components/SnackBanner';
import { useHistory } from 'react-router-dom';
import { API_STATUS_CODE } from '../../../constants/common';
import analytics from '../../../utils/analytics';
import { CLICK_EVENTS, PAGES } from '../../../constants/analytics';

const NewRegistrationRegister = (props) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const history = useHistory();

  const emailId = props.location.state?.emailId;
  const pDetail = props.location.state?.pDetail;

  const [callNewRegistrationApi, setCallNewRegistrationApi] = useState(false);
  const [callSendOtpApi, setCallSendOtpApi] = useState(false);
  const [otp, setOtp] = useState('');
  const [otpFieldInFocus, setOtpFieldInFocus] = useState(false);
  const [showOtpSentMsg, setShowOtpSentMsg] = useState(false);
  const [resendEnabledFlag, setResendEnabledFlag] = useState(true);

  const [snackBanner, setSnackBanner] = useState({
    displaySnackBanner: false,
    snackBannerMessage: ''
  });

  const openSnackBanner = (message) => {
    setSnackBanner({
      displaySnackBanner: true,
      snackBannerMessage: message
    });
  };

  const handleSnackBannerClose = () => {
    setSnackBanner(false);
  };

  useEffect(() => {
    analytics.trackPageView(PAGES.REGISTRATION);
  }, []);

  useEffect(() => {
    // if emailId or pDetail is not present in location state, redirect to external landing page
    if (!emailId || !pDetail) {
      history.replace('/');
    }
  }, [history, emailId, pDetail]);

  useEffect(() => {
    if (callSendOtpApi) {
      const payload = { userId: emailId, registration: true };

      try {
        apiCall(api.sendRegistrationOtp, API.METHOD.POST, payload, true).then(
          (response) => {
            if (response?.error) {
              openSnackBanner(
                `Error status:  ${response.error.status} \n Error: ${response.error.error}`
              );
            } else {
              setShowOtpSentMsg(true);
            }
          }
        );
        setCallSendOtpApi(false);
      } catch (error) {
        console.error(
          `Error occurred while calling send OTP API. Error Details : \n\n\n  ${error.stack} \n\n\n`
        );
      }
    }

    if (callNewRegistrationApi) {
      const payload = { userId: emailId, password: pDetail, otp: otp };
      try {
        apiCall(api.newUserRegistration, API.METHOD.POST, payload, true).then(
          (response) => {
            if (response?.error) {
              openSnackBanner(
                `${response.error.error} \n ${response.error.message}`
              );
            } else {
              const signInPayload = {
                userId: emailId,
                password: pDetail
              };

              apiCall(api.signIn, API.METHOD.POST, signInPayload, true).then(
                (response) => {
                  if (response?.error) {
                    openSnackBanner(
                      `${response.error.error} \n ${response.error.message}`
                    );
                  }
                  if (response?.status === API_STATUS_CODE.SUCCESS) {
                    localStorage.setItem('platform', 'web');
                    localStorage.setItem('token', response.data?.authToken);
                    localStorage.setItem(
                      'refreshToken',
                      response.data?.refreshToken
                    );
                    localStorage.setItem(
                      'panelistId',
                      response.data?.panelistId
                    );

                    history.push('/home', 'newRecruitment');
                  }
                }
              );
            }
          }
        );
      } catch (error) {
        console.error(
          `Error occurred while calling send OTP API. Error Details : \n\n\n  ${error.stack} \n\n\n`
        );
      }
      setCallNewRegistrationApi(false);
    }
  }, [callSendOtpApi, callNewRegistrationApi]);

  const selectRadioBtnHandler = (event) => {
    setResendEnabledFlag(false);
    setTimeout(() => {
      setResendEnabledFlag(true);
    }, 60000);
    if (event.target.checked) {
      setCallSendOtpApi(true);
    }
  };

  const resendOtpClickHandler = () => {
    setResendEnabledFlag(false);
    setTimeout(() => {
      setResendEnabledFlag(true);
    }, 60000);
    analytics.trackClick(PAGES.REGISTRATION, CLICK_EVENTS.RESEND_OTP);
    setCallSendOtpApi(true);
  };

  const isEnteredOtpValid = () => {
    return otp?.length > 4 && !isNaN(otp);
  };

  const handleRegisterClick = () => {
    setCallNewRegistrationApi(true);
  };

  const otpHelperText = () =>
    !isEnteredOtpValid() && otp.length > 0
      ? freeTexts.enterValidOtp
      : freeTexts.enterCodeTextFieldDefaultValue;

  const radioButtonLabel = (
    <Typography className={classes.radioButtonLabel}>
      {freeTexts.registrationRadioBtnText}
    </Typography>
  );

  return (
    <NewRegistrationLayout
      footerButtonLabel="Register"
      footerProgressPercentage="87.5"
      disableFooterButton={!isEnteredOtpValid()}
      footerBtnclickAction={handleRegisterClick}
    >
      <SnackBanner
        isSnackbarOpen={snackBanner.displaySnackBanner}
        snackbarMessage={snackBanner.snackBannerMessage}
        onSnackBarClose={handleSnackBannerClose}
      />
      <Grid container className={classes.root}>
        <Grid container className={classes.rootContainer}>
          <Grid container className={classes.formContainer}>
            <Grid container className={classes.headingContainer}>
              <Grid item>
                <Typography className={classes.headingTypography}>
                  {freeTexts.registrationPageHeading}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className={classes.radioOptionsContainer}>
              <Grid item className={classes.radioButton}>
                <FormControl>
                  <RadioGroup onChange={selectRadioBtnHandler}>
                    <FormControlLabel
                      value="sendOtpByEmail"
                      control={
                        <Radio
                          classes={{ root: classes.radioBtnRoot }}
                          name="send-otp-by-email-radio"
                        />
                      }
                      label={radioButtonLabel}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          {showOtpSentMsg && (
            <Grid container className={classes.conditionalContainer}>
              <Grid container className={classes.otpMessageContainer}>
                <Grid item className={classes.otpMessageItem}>
                  <Typography className={classes.otpMessageTypography}>
                    We sent you a one-time authorization code to{' '}
                    <b>{emailId}</b>. If you do not receive the code within a
                    few minutes, please click “Resend” to send a code to your
                    email.
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className={classes.authCodeContainer}>
                <Grid item xs={12} sm={6} className={classes.authCodeItem}>
                  <NRTextField
                    label="Authorization Code"
                    id="authorizationCode"
                    onTextChange={(event) => {
                      setOtp(event.target.value);
                    }}
                    isError={
                      !isEnteredOtpValid() && otp.length > 0 && otpFieldInFocus
                    }
                    onTextFieldFocus={() => {
                      setOtpFieldInFocus(true);
                    }}
                    helperText={otpHelperText()}
                  />
                  <Grid container className={classes.resendOtpContainer}>
                    <Grid item>
                      <Typography
                        className={classes.resendOtpTypography}
                        classes={{ root: classes.resendOtpText }}
                      >
                        {freeTexts.resentOtpText}
                      </Typography>
                    </Grid>
                    <Grid>
                      <Button
                        classes={{
                          root: classes.resendButton,
                          label: classes.resendButtonLabel
                        }}
                        onClick={resendOtpClickHandler}
                        disabled={!resendEnabledFlag}
                      >
                        Resend
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </NewRegistrationLayout>
  );
};

export default NewRegistrationRegister;
