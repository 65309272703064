import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, Link as RouterLink, useLocation } from 'react-router-dom';
import { Button, Card, Link, Popover, withStyles } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import RedeemOutlinedIcon from '@material-ui/icons/RedeemOutlined';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import { getOrigin } from '../../constants/styles';

import styles from './styles';
import { FormattedMessage } from 'react-intl';
import { removeLoginTokens } from '../../utilities/utils';

const BottomNav = ({ classes, handleTab, resetInnerTab, user }) => {
  const history = useHistory();
  const location = useLocation();
  const [userProfile, setUserProfile] = useState(null);

  const openUserProfile = (event) => {
    setUserProfile(event.currentTarget);
  };

  const closeUserProfile = () => {
    setUserProfile(null);
  };

  const userOpen = Boolean(userProfile);
  const userId = userOpen ? 'user-detail' : undefined;

  const logout = () => {
    removeLoginTokens();

    history.replace('/home', '');
    history.push('/login', 'logOff');
  };

  const handleProfile = () => {
    history.push('/profile');
  };

  return (
    <div className={classes.layout}>
      <Link
        component={RouterLink}
        className={`${classes.iconName} ${
          location.pathname.includes('/home') ||
          location.pathname.includes('/rewards')
            ? 'active'
            : ''
        }`}
        underline="none"
        data-testid={'rewards'}
        to={'/home'}
      >
        <RedeemOutlinedIcon className={classes.icon} />
        <FormattedMessage id="REWARDS" defaultMessage="Rewards" />
      </Link>
      <Link
        component={RouterLink}
        className={`${classes.iconName} ${
          location.pathname.includes('/activities') ? 'active' : ''
        }`}
        underline="none"
        data-testid={'activities'}
        to={'/activities'}
      >
        <ViewListOutlinedIcon className={classes.icon} />
        <FormattedMessage id="ACTIVITIES" defaultMessage="Activities" />
      </Link>
      <Link
        component={RouterLink}
        className={`${classes.iconName} ${
          location.pathname.includes('/history') ? 'active' : ''
        }`}
        underline="none"
        data-testid={'history'}
        to={'/history'}
      >
        <ViewHeadlineIcon className={classes.icon} />
        <FormattedMessage id="HISTORY" defaultMessage="History" />
      </Link>
      <Link
        component="button"
        onClick={openUserProfile}
        className={classes.iconName}
        underline="none"
        id={userId}
        data-testid={'profile'}
      >
        <MoreHorizIcon className={classes.icon} />
        <FormattedMessage id="MORE_EXTRA" defaultMessage="More" />
        <Popover
          id={userId}
          open={userOpen}
          anchorEl={userProfile}
          onClose={closeUserProfile}
          onBlur={closeUserProfile}
          anchorOrigin={getOrigin('bottom', 'left')}
          transformOrigin={getOrigin('top', 'center')}
          data-testid={'profile-pop'}
        >
          <Card
            className={`${classes.typography} ${classes.center} ${classes.card}`}
            role="dialog"
          >
            <Button
              id="profile"
              className={classes.btnStyle}
              onClick={handleProfile}
              data-testid={'profile'}
            >
              <FormattedMessage
                id="VIEW_PROFILE"
                defaultMessage="View Profile"
              />
            </Button>
            <Button
              id="logout"
              className={classes.btnStyle}
              onClick={logout}
              data-testid={'logout'}
            >
              <FormattedMessage id="LOG_OUT" defaultMessage="Log Out" />
            </Button>
          </Card>
        </Popover>
      </Link>
    </div>
  );
};

BottomNav.defaultProps = {
  classes: {}
};

BottomNav.propTypes = {
  classes: PropTypes.object,
  handleTab: PropTypes.func.isRequired,
  resetInnerTab: PropTypes.func.isRequired,
  user: PropTypes.array.isRequired
};

export default withStyles(styles)(BottomNav);
