const styles = (theme) => ({
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -10,
    marginLeft: -8,
  },
  btn: {
    textTransform: "none",
    minWidth: 90,
    height: 40,
  },
  zeroOpacity: {
    opacity: 0,
  },
});

export default styles;
