const styles = (theme) => ({
  formRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  formContainer: {
    width: '22%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginBottom: '10vh',
    [theme.breakpoints.down('md')]: {
      width: '70%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '85%'
    },
    [theme.breakpoints.down('xs')]: {
      width: '95%'
    }
  },
  headerContainer: {
    //flexWrap: 'wrap',
    //justifyContent: 'center',
    marginTop: 5,
    marginBottom: 64
  },
  headerItem: {
    marginTop: 20,
    marginBottom: 20
  },
  headerText: {
    fontSize: 32,
    fontWeight: 400,
    letterSpacing: 0,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: 24
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 20
    }
  },
  subHeaderContainer: {
    flexWrap: 'wrap',
    justifyContent: 'center',
    justify: 'center',
    marginTop: 5,
    marginBottom: 64
  },
  subHeaderItem: {
    marginTop: 20,
    marginBottom: 20
  },
  subHeaderText: {
    fontSize: 20,
    fontWeight: 400,
    letterSpacing: 0,
    textAlign: 'center'
  },
  textFieldContainer: {},
  textFieldItem: {
    marginBottom: 20,
    marginTop: 20
  },
  textField: {
    width: '100%'
  },
  resendOtpItem: {
    marginTop: 20,
    marginBottom: 20
  },
  resendOtpContainer: {
    justifyContent: 'flex-end',
    alignItems: 'baseline'
  },
  resendOtpTypography: {
    fontSize: '20px',
    fontWeight: '400',
    letterSpacing: '0px',
    textAlign: 'left'
  },
  buttonItem: {
    paddingBottom: '2px'
  },
  resendButton: {
    color: '#0071CE',
    textTransform: 'none',
    fontSize: '20px',
    fontWeight: '500',
    letterSpacing: '0px',
    textAlign: 'left'
  },
  resendButtonLabel: {
    paddingBottom: 7
  },
  userNoteItem: {
    marginTop: 20,
    marginBottom: 20
  },
  userNoteItemBold: {
    marginBottom: 20
  },
  userNoteText: {
    fontSize: 18,
    fontWeight: 400,
    letterSpacing: 0,
    textAlign: 'left'
  },
  userNoteTextBold: {
    fontSize: 18,
    fontWeight: 500,
    letterSpacing: 0,
    textAlign: 'left'
  },
  continueButtonContainer: {
    justifyContent: 'center',
    marginTop: 20,
    marginBottom: 20
  },
  continueButton: {
    backgroundColor: '#0071CE',
    color: '#FFFFFF'
  },
  continueButtonRoot: {
    textTransform: 'none'
  }
});

export default styles;
