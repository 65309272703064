/* eslint-disable import/no-anonymous-default-export */
import config from '../config/config';

const url = config.api.customerSpark.domain + config.api.customerSpark.path;

export default {
  addressValidate: `${url}/v1/recruitment/address/validate`,
  addressDetail: `${url}/v1/recruitment/address/details`,
  addressAutoComplete: `${url}/v1/recruitment/address/autocomplete`,
  activityHistoryListing: `${url}/v1/panelist/activity-history`,
  activityPref: `${url}/v1/panelist/settings/activity-preference`,
  authValidation: `${url}/v1/action/hello`,
  autoRedeem: `${url}/v1/panelist/settings/auto-redeem`,
  availActivities: `${url}/v1/panelist/available-activities`,
  profile: `${url}/v1/panelist/v2/profile`,
  redeem: `${url}/v1/panelist/redeem`,
  refreshToken: `${url}/v1/account/refresh-token`,
  register: `${url}/v1/account/register`,
  resetPassword: `${url}/v1/account/reset-password`,
  rewardHistoryListing: `${url}/v1/panelist/redeem-history`,
  rewardStatus: `${url}/v1/panelist/rewards`,
  sendOtp: `${url}/v1/account/password-reset-code`,
  sendRegistrationOtp: `${url}/v1/account/registration-code`,
  newUserRegistration: `${url}/v1/account/registration/verify`,
  newUserRegistrationAuth: `${url}/v1/recruitment/registration/auth`,
  settings: `${url}/v1/panelist/settings`,
  signIn: `${url}/v1/account/sign-in`,
  termsAgreement: `${url}/v1/panelist/terms-agreement`,
  status: `${url}/v1/panelist/status`,
  updateDob: `${url}/v1/panelist/updateDob`,
  unsubscribe: `${url}/v1/panelist/unsubscribe`,
  userAvatarMapping: `${url}/v1/panelist/v2/profile/avatar-mapping`,
  sendEmailChangeOtp: `${url}/v1/panelist/v2/email/send-otp`,
  profileUpdatePassword: `${url}/v1/panelist/v2/update-password`,
  profileZipCCPAValidate: `${url}/v1/panelist/v2/validate/terms-agreed`,
  v1: `${url}/v1/`,
  reviewProfile: (taskId) => `${url}/v1/panelist/activities/${taskId}/status`
};
