import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles } from '@material-ui/core';
import { TextArea } from '@walmart-web/livingdesign-components';
import { useSelector } from 'react-redux';
import { SurveySelectors } from '@walmart/survey-taker-api-js-sdk';

const useStyles = makeStyles(() => ({
  textarea: {
    '&:focus-visible': {
      outline: 'none'
    }
  }
}));

function OpenEndedText(props) {
  const classes = useStyles();
  const { onAnswerChange, allAnswers, maxLength } = props;

  const currentQuestion = useSelector(SurveySelectors.selectCurrentQuestion);

  const [text, setText] = useState(
    allAnswers[currentQuestion.questionId] ?? ''
  );

  useEffect(() => {
    onAnswerChange({ [currentQuestion.questionId]: text });
  }, [text, onAnswerChange, currentQuestion]);

  return (
    <Box display={'flex'} width={'100%'}>
      <TextArea
        onChange={(event) => setText(event.target.value)}
        value={text}
        maxLength={maxLength}
        placeholder="Type your answer here"
        textAreaProps={{ className: classes.textarea }}
      />
    </Box>
  );
}

OpenEndedText.propTypes = {
  onAnswerChange: PropTypes.func.isRequired,
  allAnswers: PropTypes.object.isRequired,
  maxLength: PropTypes.number.isRequired
};

export default OpenEndedText;
