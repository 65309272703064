const styles = (theme) => ({
  container: {
    [theme.breakpoints.up('sm')]: {
      maxHeight: 440,
      minHeight: 390,
      width: 736
    },
    [theme.breakpoints.up('md')]: {
      width: 827
    },
    [theme.breakpoints.up('lg')]: {
      maxHeight: 540,
      width: 1146
    }
  },
  date: {
    [theme.breakpoints.up('sm')]: {
      width: 62
    }
  },
  dateBig: {
    [theme.breakpoints.up('sm')]: {
      width: 183
    }
  },
  name: {
    [theme.breakpoints.up('sm')]: {
      width: 253
    }
  },
  shortId: {
    [theme.breakpoints.up('sm')]: {
      width: 191
    }
  },
  points: {
    [theme.breakpoints.up('sm')]: {
      width: 153
    }
  },
  sortIcon: {
    backgroundImage: "url('/assets/images/icons/ic-sort.svg')",
    backgroundSize: 'auto',
    cursor: 'pointer'
  },
  skeletonWrapper: {
    width: '90%'
  },
  status: {
    [theme.breakpoints.up('sm')]: {
      width: 191
    }
  },
  tableCellBody: {
    height: 20,
    fontSize: 14,
    fontWeight: 300,
    borderBottom: 'none !important',
    padding: '14px 0'
  },
  tableCellHead: {
    height: 20,
    padding: '32px 0 8px 0',
    fontSize: 14,
    fontWeight: 'normal',
    borderBottom: 'none !important',
    backgroundColor: 'white',
    color: '#000000'
  },
  tableCellShowMore: {
    [theme.breakpoints.up('sm')]: {
      borderBottom: 'none !important',
      paddingTop: 100
    }
  },
  tableWrapper: {
    [theme.breakpoints.up('sm')]: {
      padding: '0 39px'
    },
    [theme.breakpoints.up('md')]: {
      padding: '0 86px'
    },
    [theme.breakpoints.up('lg')]: {
      padding: '0 30px 0 117px'
    }
  },
  clickable: {
    cursor: 'pointer',
    color: theme?.palette?.primary?.main,
    textDecoration: 'underline',
    [theme.breakpoints.up('sm')]: {
      width: 153
    }
  },
  clickableHeader: {
    [theme.breakpoints.up('sm')]: {
      width: 153
    }
  },
  linkButton: {
    fontSize: 14,
    textDecoration: 'underline',
    textTransform: 'none',
    color: theme?.palette?.primary?.main,
    padding: '0 16px',
    lineHeight: 1.5,
    fontWeight: 100
  }
});

export default styles;
