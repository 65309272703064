const styles = (theme) => ({
  noResultWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: 64,
    [theme.breakpoints.up('sm')]: {
      height: 325
    },
    [theme.breakpoints.up('lg')]: {
      height: 390
    }
  },
  noResultImageWrapper: {
    width: 320,
    height: 320,
    borderRadius: '50%',
    backgroundColor: '#f5f5f5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    width: 130,
    height: 25,
    fontSize: 18,
    marginTop: 32,
    color: '#333333'
  }
});

export default styles;
