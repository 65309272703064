import React from 'react';
import { Fade } from '@material-ui/core';

const Transition = (props) => (
  <Fade in={props?.in ?? true} timeout={props?.timeout ?? 1000}>
    {props.children}
  </Fade>
);

export default Transition;
