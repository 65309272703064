import React from 'react';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import styles from './styles';
import { Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import MicroSurveyActivitiesGrid from './MicroSurveyActivitiesGrid';
import ActivitiesGrid from './ActivitiesGrid';
import { useParams, Switch, Route, useHistory } from 'react-router-dom';
import { activityCategories } from '../constants';
import { getFeedbackSurvey, getRelevantActivities } from '../utils';
import Skeleton from '../../Skeleton';

const ActivitiesList = ({ activities, isLoading }) => {
  const history = useHistory();
  const { categoryId } = useParams();
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const relevantActivities = getRelevantActivities(activities, categoryId);
  const title =
    activityCategories.find((x) => x.id === categoryId)?.title ?? '';

  const feedbackSurvey = getFeedbackSurvey(activities);

  return (
    <>
      <Grid container className={classes.profileHead}>
        <div
          className={classes.breadcrumbText}
          data-testid={`TEST-BUTTON-${title}`}
          onClick={() => history.replace('/activities')}
        >
          <IconButton size="small" className={classes.headerIcon}>
            <ChevronLeftIcon />
          </IconButton>
          <Typography className={classes.titleText}>{title}</Typography>
        </div>
      </Grid>
      {isLoading ? (
        <div className={classes.skeletonWrapper}>
          <Skeleton count={10} />
        </div>
      ) : (
        <Switch>
          <Route path={'/activities/weekly-quests'}>
            <MicroSurveyActivitiesGrid
              activities={relevantActivities}
              feedbackSurvey={feedbackSurvey}
            />
          </Route>
          <Route path={['/activities/surveys', '/activities/videos']}>
            <ActivitiesGrid activities={relevantActivities} />
          </Route>
        </Switch>
      )}
    </>
  );
};

export default ActivitiesList;
