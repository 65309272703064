/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox
} from '@material-ui/core';
import styles from './styles';
import PropTypes from 'prop-types';
import SelectionSearchBar from './SelectionSearchBar';

const FormSelect = (props) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const {
    label,
    id,
    value,
    selectedValue,
    menuItems,
    multiple = false,
    disableOthers,
    disabled = false,
    className = classes.formControl,
    hasSearchBar = false,
    maxHeight = 400
  } = props;

  const [openOption, setOpenOption] = useState(value);
  const [options, setOptions] = useState(menuItems);

  useEffect(() => {
    setOpenOption(value);
  }, [value]);

  const handleSelectChange = (event) => {
    if (
      multiple &&
      disableOthers &&
      event?.target?.value?.find((item) =>
        disableOthers.find((x) => x === item)
      )
    ) {
      const deselctOthers = event?.target?.value?.filter((item) =>
        disableOthers?.find((x) => x === item)
      );
      setOpenOption(deselctOthers);
      selectedValue(deselctOthers);
    } else {
      setOpenOption(event.target.value);
      selectedValue(event.target.value);
    }
  };

  const checkDisabled = () => {
    return openOption?.find(
      (item) => item === disableOthers?.find((x) => x === item)
    )
      ? true
      : false;
  };

  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: maxHeight
      }
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left'
    },
    getContentAnchorEl: null
  };

  return (
    <FormControl variant="outlined" className={className} disabled={disabled}>
      <InputLabel id={`${label}-label`}>{label}</InputLabel>
      <Select
        labelId={`${id}-label`}
        id={id}
        value={openOption}
        multiple={multiple}
        onChange={handleSelectChange}
        onClose={() => setOptions(menuItems)}
        label={label}
        renderValue={(selected) =>
          Array.isArray(selected) ? selected.join(', ') : selected
        }
        MenuProps={menuProps}
        data-testid={`${id}-test-id`}
      >
        {hasSearchBar ? (
          <SelectionSearchBar options={options} setOptions={setOptions} />
        ) : null}
        {options?.map((option) => (
          <MenuItem
            value={option}
            key={option}
            disabled={
              multiple &&
              disableOthers &&
              openOption?.find((x) => x !== option) &&
              checkDisabled()
            }
          >
            {multiple && (
              <Checkbox
                checked={openOption?.indexOf(option) > -1}
                classes={{ root: classes.checked }}
              />
            )}
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

FormSelect.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number
  ]),
  selectedValue: PropTypes.func,
  menuItems: PropTypes.array,
  multiple: PropTypes.bool,
  /** disableOthers is an array of values that will disable all other values in the multi select dropdown
   *  when any of the values in the array are selected.
   *  Needed for multi select component.
   *  Not - compulsory
   */
  disableOthers: PropTypes.array,
  hasSearchBar: PropTypes.bool
};

export default FormSelect;
