const styles = (theme) => ({
  bottomCell: {
    width: '50%',
    fontSize: 12,
    fontWeight: 300,
    [theme.breakpoints.up('sm')]: {
      width: '25%',
      fontSize: 14,
      fontWeight: 300
    }
  },
  buttonHolder: {
    textAlign: 'center',
    marginTop: 80,
    fontSize: 14,
    marginBottom: 16,
    [theme.breakpoints.up('sm')]: {
      marginTop: 100,
      marginBottom: 0
    }
  },
  dateWidth: {
    [theme.breakpoints.up('sm')]: {
      width: '10%'
    }
  },
  evenCell: {
    textAlign: 'left'
  },
  listBottom: {
    marginBottom: 62,
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0
    }
  },
  oddCell: {
    textAlign: 'right',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left'
    }
  },
  nameWidth: {
    [theme.breakpoints.up('sm')]: {
      width: '40%'
    }
  },
  skeletonWrapper: {
    width: '90%'
  },
  status: {
    display: 'inline',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  topCell: {
    width: '50%',
    fontSize: 14,
    fontWeight: 'normal',
    [theme.breakpoints.up('sm')]: {
      width: '25%',
      fontWeight: 300
    }
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '90%',
    justifyContent: 'space-between',
    margin: '32px auto',
    flexWrap: 'wrap',
    '&:hover': {
      backgroundColor: '#e5f0fa'
    },
    [theme.breakpoints.down('sm')]: {
      margin: 'auto',
      padding: 18
    },
    [theme.breakpoints.up('sm')]: {
      width: 680
    },
    [theme.breakpoints.up('md')]: {
      width: 768
    },
    [theme.breakpoints.up('lg')]: {
      width: 820
    }
  }
});

export default styles;
