const styles = (theme) => ({
  base: {
    width: '328px',
    borderRadius: 8,
    height: '128px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  cardContentRoot: {
    display: 'flex',
    padding: '20px 10px 10px 20px',
    flexDirection: 'row',
    flexWrap: 'noWrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down(600)]: {
      height: '90%',
      padding: '12px',
      position: 'relative'
    }
  },
  action: {
    fontSize: 18,
    letterSpacing: 0.32,
    color: '#0071ce'
  },
  cardTextContainer: {
    flexWrap: 'wrap',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'flex-start'
  },
  imgWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignContent: 'center',
    height: 80,
    width: 80,
    borderRadius: 40,
    overflow: 'hidden'
  },
  points: {
    fontFamily: 'Bogle',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    color: '#515357',
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%'
  },
  title: {
    fontSize: 16,
    letterSpacing: 0.32,
    color: '#2E2F32',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    wordBreak: 'break-word',
    textOverflow: 'ellipsis',
    fontFamily: 'Bogle',
    fontWeight: 700,
    lineHeight: '24px'
  },
  shortID: {
    fontSize: 10,
    fontWeight: 400,
    letterSpacing: 0.3214285671710968,
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'left',
    width: '100%',
    fontFamily: 'Bogle',
    color: '#333333'
  },
  textWrapper: {
    overflow: 'hidden',
    width: '100%'
  },
  completedTag: {
    position: 'absolute',
    float: 'right',
    width: 78,
    height: 24,
    backgroundColor: '#E6F1FC',
    right: 5,
    top: 5,
    padding: '4px 8px 4px 8px',
    borderRadius: 2,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center'
  },
  completedTagText: {
    fontSize: '12px',
    lineHeight: '16px',
    fontFamily: 'Bogle',
    fontWeight: 400,
    color: '#004F9A',
    textAlign: 'center'
  }
});

export default styles;
