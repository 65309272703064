import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  withStyles
} from '@material-ui/core';
import Logo from '../../../../Logo';
import styles from './styles';
import { SOURCE } from '../../../../../constants/common';
import { activity } from '../../../constants';
import analytics from '../../../../../utils/analytics';
import { PAGES } from '../../../../../constants/analytics';
import { useHistory } from 'react-router-dom';

const MicroSurveyActivityCard = ({
  classes,
  imageType,
  name,
  points,
  surveyLink,
  shortId,
  isCompleted
}) => {
  const history = useHistory();

  /** Internal survey links will be having panelist hash already
   * So appending only the source & separated
   */
  const framedSurveyLink = `${surveyLink}${
    surveyLink?.includes('https') ? '' : SOURCE
  }`;

  const getWeeklyQuestLink = (link) => {
    if (link.includes('panelist_hash=')) {
      const splitByPanelist = link.split('?panelist_hash=') || [];
      const panelist = splitByPanelist[1] ?? '';

      const splitForSurvey = splitByPanelist[0].split('/');
      const surveyId = splitForSurvey[splitForSurvey.length - 1];

      return `/activities/weekly-quests/${surveyId}?panelist_hash=${panelist}`;
    }
  };

  const surveyClicked = (evt, surveyUrl) => {
    const surveyLinkEnd = surveyUrl?.split('/');
    const panelistHash = surveyUrl?.split('?panelist_hash');

    analytics.trackClick(PAGES.ACTIVITIES, 'survey', {
      survey: surveyLinkEnd?.length
        ? surveyLinkEnd?.[surveyLinkEnd?.length - 1]
        : '',
      panelistHash: panelistHash?.length > 1 ? panelistHash?.[1] : ''
    });

    history.replace(getWeeklyQuestLink(surveyUrl));
    // Default Behaviour of the div onClick event is to open the link
    // It was being prevented using the below event preventDefault()
    // evt.preventDefault();
  };

  return (
    <Card
      className={classes.base}
      variant="outlined"
      onClick={(evt) => surveyClicked(evt, framedSurveyLink)}
    >
      {isCompleted && (
        <div className={classes.completedTag}>
          <div className={classes.completedTagText}>Completed!</div>
        </div>
      )}
      <CardContent classes={{ root: classes.cardContentRoot }}>
        <Grid item xs={5}>
          <Grid container className={classes.imgWrapper}>
            <Logo type={isCompleted ? 'celebration' : imageType} />
          </Grid>
        </Grid>
        <Grid container xs={12} className={classes.cardTextContainer}>
          <Grid item className={classes.textWrapper}>
            <Typography className={classes.shortID}>{`ID: ${
              shortId || null
            }`}</Typography>
          </Grid>
          <Grid item className={classes.textWrapper}>
            <Typography className={classes.title}>{name}</Typography>
          </Grid>
          <Grid item className={classes.textWrapper}>
            <Typography className={classes.points}>
              {`${points || 0} ${activity.activityFreeTexts.points}`}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

MicroSurveyActivityCard.defaultProps = {
  classes: {}
};

MicroSurveyActivityCard.propTypes = {
  classes: PropTypes.object,
  imageType: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  points: PropTypes.number.isRequired,
  surveyLink: PropTypes.string,
  shortId: PropTypes.string.isRequired,
  isCompleted: PropTypes.bool
};

export default withStyles(styles)(MicroSurveyActivityCard);
