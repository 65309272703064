const styles = (theme) => ({
  root: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 87,
    color: '#333333',
    [theme.breakpoints.up('sm')]: {
      marginTop: 104
    }
  },
  centerText: {
    textAlign: 'center'
  },
  outerContainer: {
    maxWidth: 560
  },
  titleText: {
    lineHeight: 1.375
  },
  flexContainer: {
    display: 'flex',
    gap: 8,
    marginTop: 16,
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  },
  lightFont: {
    fontFamily: theme?.font?.light
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '40px 0',
    gap: 40,
    width: 412,
    [theme.breakpoints.down('md')]: {
      width: 328,
      gap: 32
    }
  },
  submitBtn: {
    borderRadius: 20,
    width: 'max-content',
    padding: '10.25px 30px',
    textAlign: 'center',
    alignSelf: 'center'
  },
  regular14: {
    fontSize: 14,
    fontFamily: 'Bogle',
    fontStyle: 'normal',
    textTransform: 'capitalize',
    fontWeight: 500,
    lineHeight: 'normal'
  },
  textFieldRoot: {
    fontSize: 16,
    textAlign: 'justify',
    '& label': {
      fontSize: 16
    }
  },
  flexDisplay: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 4
  },
  helperText: {
    // position: 'absolute',
    // bottom: -24
  },
  iconResize: {
    fontSize: '1rem'
  },
  successRoot: {
    borderLeft: '2px solid #0089e2',
    border: '1px solid #0089e2',
    color: '#0089e2',
    backgroundColor: '#e5f0fa',
    fontWeight: '500'
  },
  snackbarRoot: {
    fontSize: 14,
    textAlign: 'center',
    borderRadius: 4,
    marginTop: 54,
    maxWidth: 384
  },
  errorSnackBar: {
    color: '#9B1419',
    borderLeft: '2px solid #9B1419',
    border: '1px solid #EF8E92',
    background: '#FCE8E9',
    fontWeight: 400
  },
  flexDisplayCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 200
  },
  messageLabel: {
    overflow: 'auto'
  },
  btn: {
    textTransform: 'none',
    minWidth: 90,
    height: 40,
    borderRadius: 20
  },
  errText: {
    color: '#CB2022',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 'normal'
  },
  boxRoot: {
    position: 'relative',
    '& div': {
      width: '100%'
    }
  },
  countWrapStyles: {
    position: 'relative',
    width: '100%'
  },
  countValuestyles: {
    float: 'right'
  }
});

export default styles;
