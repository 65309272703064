/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  withStyles
} from '@material-ui/core';
import styles from './styles';
import clsx from 'clsx';

const GenerateFields = ({ field, classes }) => {
  const { menuItem, minPadding } = classes;
  const generateFields = () => {
    if (field?.type) {
      return (
        <FormControl
          variant={field?.variant || 'outlined'}
          id={field.id || 'fc-select'}
          {...field?.formControl?.props}
          key={`select-${field?.label || 'select'}`}
        >
          {field.label ? <InputLabel>{field.label}</InputLabel> : null}
          <Select
            label={field.label}
            {...field.props}
            disabled={field?.disabled}
          >
            {!field?.props?.multiple
              ? field?.menuList?.map((item, index) => (
                  <MenuItem
                    key={field.id || index}
                    value={
                      item.value !== null || item.value !== undefined
                        ? item.value
                        : item.name
                    }
                    classes={{
                      root: clsx(field?.props?.addOnClasses?.menuItem, menuItem)
                    }}
                    data-test="single-select"
                  >
                    {item.name}
                  </MenuItem>
                ))
              : field?.menuList?.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item.name}
                    data-test="multi-select"
                    classes={{ root: clsx(menuItem, minPadding) }}
                  >
                    <Checkbox
                      checked={field?.optionsSelected?.indexOf(item?.name) > -1}
                      color="primary"
                      size="small"
                    />
                    <ListItemText primary={item?.name} />
                  </MenuItem>
                ))}
          </Select>
          {field?.props?.helperText ? (
            <FormHelperText>{field?.props?.helperText}</FormHelperText>
          ) : null}
        </FormControl>
      );
    }
  };
  return <>{generateFields()}</>;
};

GenerateFields.propTypes = {
  field: PropTypes.shape({}).isRequired,
  classes: PropTypes.shape({}).isRequired
};

export default withStyles(styles)(GenerateFields);
