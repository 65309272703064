import React, { useState } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import styles from '../styles';
import NewRegistrationLayout from '../Layout';
import { freeTexts, newRegistrationForm } from '../constants';
import NRTextField from '../NRTextField';
import PasswordValidation from '../../../components/PasswordValidation';
import { REGEXP_PASSWORD } from '../../../constants/common';

const NewRegistrationForm = (props) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const { formUpdateHandler, formData, onClickContinue } = props;
  const { firstName, lastName, email, password, confirmPassword } = formData;

  const [passwordInFocus, setPasswordInFocus] = useState(false);

  const handleContinueBtnClick = () => {
    onClickContinue();
  };

  const passwordFocusHandler = () => {
    setPasswordInFocus(true);
  };

  const passwordBlurHandler = () => {
    setPasswordInFocus(false);
  };

  const isContinueDisabled = () => {
    return (
      !password ||
      !REGEXP_PASSWORD.test(password) ||
      !firstName ||
      !lastName ||
      !confirmPassword ||
      confirmPassword !== password
    );
  };

  return (
    <NewRegistrationLayout
      footerButtonLabel="Continue"
      footerProgressPercentage="75"
      footerBtnclickAction={handleContinueBtnClick}
      disableFooterButton={isContinueDisabled()}
    >
      <Grid container className={classes.formRoot}>
        <Grid container className={classes.mainContainer}>
          <Grid container className={classes.mainHeadingContainer}>
            <Grid item>
              <Typography className={classes.mainHeadingTypography}>
                {freeTexts.firstPageHeading}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.formContainer}>
            <Grid container className={classes.nameContainer}>
              <Grid item sm={6} xs={12} className={classes.item}>
                <NRTextField
                  {...newRegistrationForm.formFields.firstName}
                  onTextChange={formUpdateHandler}
                />
              </Grid>
              <Grid item sm={6} xs={12} className={classes.item}>
                <NRTextField
                  {...newRegistrationForm.formFields.lastName}
                  onTextChange={formUpdateHandler}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.emailContainer}>
              <Grid item xs={12} className={classes.item}>
                <NRTextField
                  {...newRegistrationForm.formFields.email}
                  defaultValue={email}
                  disabled
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Grid container className={classes.passwordContainer}>
                  <Grid item xs={12} className={classes.item}>
                    <NRTextField
                      {...newRegistrationForm.formFields.password}
                      onTextChange={formUpdateHandler}
                      onTextFieldFocus={passwordFocusHandler}
                      onTextFieldBlur={passwordBlurHandler}
                      isError={
                        !!password &&
                        !REGEXP_PASSWORD.test(password) &&
                        !passwordInFocus
                      }
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.item}>
                    <NRTextField
                      {...newRegistrationForm.formFields.confirmPassword}
                      onTextChange={formUpdateHandler}
                      isError={
                        !!password &&
                        !!confirmPassword &&
                        !(password === confirmPassword)
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid
                  container
                  className={classes.passwordInstructionscontainer}
                >
                  {password && (
                    <Grid item xs={12} className={classes.item}>
                      <Typography
                        className={classes.passwordInstructionsHeadingText}
                      >
                        {freeTexts.passwordInstructionHeading}
                      </Typography>
                      <PasswordValidation password={password} />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </NewRegistrationLayout>
  );
};

export default NewRegistrationForm;
