import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  Typography
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import CloseIcon from '@material-ui/icons/Close';
import styles from './style';
import apiCall from '../../../../utils/api';
import api from '../../../../constants/api';
import API, { API_STATUS_CODE, TOAST_MSGS } from '../../../../constants/common';
import analytics from '../../../../utils/analytics';
import { CLICK_EVENTS, PAGES } from '../../../../constants/analytics';

const useStyles = makeStyles((theme) => styles(theme));

const AvatarModal = ({
  handleClose,
  open,
  avatarList,
  userLevel,
  userSelectedImg,
  activityDetails,
  baseUrl,
  setSnackbarMessage
}) => {
  const classes = useStyles();

  const completed = activityDetails?.completed?.selectedList?.[0]
    ? activityDetails.completed.selectedList[0]
    : 0;
  const defaultValue = activityDetails?.default?.selectedList?.[0]
    ? activityDetails.default.selectedList[0]
    : 0;

  const prevSelectedImg = userSelectedImg?.selectedList?.[0];
  const [selectedImage, setSelectedImg] = useState(
    userSelectedImg?.selectedList?.[0]
  );
  const avatarsList = avatarList?.data;

  const unLockedAvatarsList = avatarsList.filter(
    (e) => e.level <= Number(userLevel.selectedList?.[0]) || e.level === 1
  );
  const lockedAvatarsList = avatarsList.filter(
    (e) => e.level > Number(userLevel.selectedList?.[0]) && e.level !== 1
  );

  const getAvatarSection = (arr, isLocked) => {
    return arr.map((ele, index) => {
      const src = `${baseUrl}${ele?.images?.[0]}${avatarList.extension}`;
      const avataId = ele?.images?.[0];
      return (
        <Grid item sm={3} md={2} key={index} className={classes.avatarSection}>
          {isLocked ? (
            <>
              {getAvatar(avataId, src, isLocked)}
              <LockOutlinedIcon className={classes.lockIcon} />
            </>
          ) : (
            getAvatarButton(avataId, src, isLocked)
          )}
        </Grid>
      );
    });
  };

  const getAvatarButton = (avataId, src, isLocked) => (
    <IconButton
      onClick={() => updateSelectedImg(avataId)}
      data-testid="enabled-avatar"
    >
      {getAvatar(avataId, src, isLocked)}
    </IconButton>
  );

  const getAvatar = (avataId, src, isLocked) => (
    <Avatar
      alt={avataId}
      src={src}
      className={`${classes.avatar} ${isLocked ? classes.lockedAvatar : ''} ${
        selectedImage === avataId ? classes.selectedImage : ''
      }`}
    />
  );

  const getSubHeadingTypography = (text) => {
    return (
      <Typography variant="h2" component="h2" className={classes.subHeading}>
        {text}
      </Typography>
    );
  };

  const updateSelectedImg = (imageId) => {
    setSelectedImg(imageId);
  };

  const saveAvatar = async () => {
    analytics.trackClick(PAGES.PROFILE, CLICK_EVENTS.SAVE_AVATAR, {
      image: selectedImage
    });
    const payload = [
      {
        detailName: 'ic_avatar',
        selectedList: [selectedImage]
      }
    ];
    apiCall(api.profile, API.METHOD.PUT, payload, false).then((res) =>
      res.status === API_STATUS_CODE.SUCCESS
        ? handleClose(true)
        : setSnackbarMessage(res?.error?.message || TOAST_MSGS.generic_error)
    );
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth={'lg'}
        open={open}
        onClose={() => handleClose(false)}
        className={classes.container}
      >
        <Box className={`${classes.root} ${classes.stickyTop}`}>
          <Box className={classes.closeIcon}>
            <IconButton
              aria-label="Close"
              className={classes.iconButton}
              onClick={() => handleClose(false)}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box className={`${classes.modalHeader} ${classes.innerSection}`}>
            <Box>
              <Typography
                variant="h1"
                component="h1"
                className={classes.mainHeading}
              >
                My Avatars
              </Typography>
              {getSubHeadingTypography('Select an avatar for your profile.')}
            </Box>
            <Box className={classes.buttonSection}>
              <Button
                variant="text"
                onClick={() => handleClose(false)}
                className={`${classes.buttons} ${classes.cancelButton}`}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                onClick={saveAvatar}
                className={`${classes.buttons} ${classes.saveButton}`}
                disabled={selectedImage === prevSelectedImg}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
        <Box className={classes.root}>
          <Box className={classes.innerSection}>
            <Box className={classes.unlockedSection}>
              <Grid container className={classes.avatarDisplay}>
                {getAvatarSection(unLockedAvatarsList, false)}
              </Grid>
            </Box>
            <Box className={classes.modalHeader}>
              <Box>
                {getSubHeadingTypography(
                  'Complete more activities to unlock more avatars!'
                )}
                {getSubHeadingTypography(
                  `${completed}/${defaultValue} activities to unlock next avatar`
                )}
              </Box>
            </Box>
            <Box className={classes.lockedSection}>
              <Grid container className={classes.avatarDisplay}>
                {getAvatarSection(lockedAvatarsList, true)}
              </Grid>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default AvatarModal;
