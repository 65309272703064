import React, { useEffect, useState } from 'react';
import OkCancel from '../../components/AlertDialog/OkCancel';
import OnboardHeader from '../../components/OnboardHeader';
import PropTypes from 'prop-types';
import api from '../../constants/api';
import apiCall from '../../utils/api';
import styles from './styles';
import { FormattedMessage } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Button,
  Grid,
  LinearProgress,
  Snackbar,
  TextField,
  withStyles
} from '@material-ui/core';
import API, {
  API_STATUS_CODE,
  AUTO_HIDE_DURATION,
  EMAIL_FIELD_LENGTH,
  TOAST_MSGS
} from '../../constants/common';
import {
  decodeValue,
  getQueryStringValue,
  isEmptyString,
  isValidEmailId,
  maskEmailId
} from '../../utils/common';
import analytics from '../../utils/analytics';
import { PAGES } from '../../constants/analytics';
import { logger } from '../../utils/logger';

const ForgotPassword = ({ classes }) => {
  const { push } = useHistory();
  const location = useLocation();
  const initAlertBox = {
    open: false,
    primaryBtn: '',
    title: '',
    content: '',
    code: ''
  };
  const [alertBox, setAlertBox] = useState(initAlertBox);
  const [emailId, setEmailId] = useState('');
  const [isSnackbarOpen, setIsSnackbarOpen] = useState('');
  const [progress, setProgress] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [emailInvalid, setEmailInvalid] = useState({
    isError: false,
    message: ''
  });

  useEffect(() => {
    analytics.trackPageView(PAGES.FORGET_PASSWORD);
  }, []);

  useEffect(() => {
    const isForgotPasswordFlow =
      getQueryStringValue(location, 'isforgotpasswordflow') === 'true' || false;
    const extractedEmailId = getQueryStringValue(location, 'invite') || '';
    const decodedEmailId = decodeValue(extractedEmailId);
    setEmailId(decodedEmailId);
    if (!isValidEmailId(decodedEmailId) && !isForgotPasswordFlow) {
      setIsSnackbarOpen(true);
      setSnackbarMessage(
        <FormattedMessage
          id="FORGOT_PASSWORD_URL_ERROR"
          defaultMessage={TOAST_MSGS.forgot_password_url_error}
        />
      );
    }
  }, [location]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setAlertBox({
      open: true,
      primaryBtn: <FormattedMessage id="OK" defaultMessage="OK" />,
      content: (
        <FormattedMessage
          id="OTP_SUCCESS_MESSAGE"
          defaultMessage={TOAST_MSGS.otp_success_message(maskEmailId(emailId))}
          values={{ email: maskEmailId(emailId) }}
        />
      )
    });
  };

  const sendOtpCall = () => {
    const payload = {
      userId: emailId,
      registration: false
    };

    setProgress(true);
    apiCall(`${api.sendOtp}`, API.METHOD.POST, payload, true).then(
      (response) => {
        setProgress(false);
        if (response?.error) {
          logger.error('failed to send otp', { emailId });
          setAlertBox(initAlertBox);
          setEmailInvalid({
            ...emailInvalid,
            isError: true,
            message: response.error?.message
          });
        }

        if (response?.status === API_STATUS_CODE.SUCCESS) {
          push(
            `/reset-password?invite=${btoa(emailId)}&isforgotpasswordflow=true`
          );
        }
      }
    );
  };

  const handleEmailChange = (e) => {
    if (e.currentTarget.value.includes(' ')) {
      e.currentTarget.value = e.currentTarget.value.replace(/\s/g, '');
    }
    setEmailId(e.target.value?.toLowerCase());
    setEmailInvalid({ ...emailInvalid, isError: false });
    if (!isValidEmailId(e.target.value?.toLowerCase())) {
      setEmailInvalid({
        ...emailInvalid,
        isError: true,
        message: (
          <FormattedMessage
            id="VALID_EMAIL_ADDRESS"
            defaultMessage={TOAST_MSGS.valid_email_address}
          />
        )
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === ' ') {
      e.preventDefault();
    }
  };

  const cancel = () => {
    push(`/login${isValidEmailId(emailId) ? `?invite=${btoa(emailId)}` : ''}`);
  };

  return (
    <Grid className={classes.container}>
      <title>
        <FormattedMessage
          id="FORGOT_PASSWORD_TITLE"
          defaultMessage="Walmart - Customer Spark: Forgot Password"
        />
      </title>
      <Grid className={classes.formHolder}>
        <OkCancel
          content={alertBox.content || ''}
          isDisabled={progress}
          open={alertBox.open}
          primaryBtnClick={sendOtpCall}
          primaryBtnText={alertBox.primaryBtn}
        />
        {progress && (
          <div className={classes.progress}>
            <LinearProgress />
          </div>
        )}
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={AUTO_HIDE_DURATION}
          message={snackbarMessage}
          onClose={() => setIsSnackbarOpen(false)}
          open={isSnackbarOpen}
          ContentProps={{
            classes: {
              root: classes.snackbarRoot
            }
          }}
        />
        <OnboardHeader
          headerText={
            <FormattedMessage
              defaultMessage="Forgot your Password?"
              id="FORGOT"
            />
          }
          headerContent={
            <FormattedMessage
              defaultMessage={`We will send you a one-time authorization code to ${emailId}`}
              id="OTP"
              values={{ emailId: `${emailId}` }}
            />
          }
        />
        <form
          autoComplete="off"
          className={classes.form}
          noValidate
          onSubmit={handleSubmit}
        >
          <div className={classes.emailWrapper}>
            <TextField
              FormHelperTextProps={{ classes: { root: classes.helperText } }}
              className={classes.textField}
              data-testid="emailId"
              error={emailInvalid.isError}
              id="email"
              label={<FormattedMessage id="EMAIL" defaultMessage="Email" />}
              onChange={handleEmailChange}
              onKeyDown={handleKeyDown}
              value={emailId}
              variant="outlined"
              inputProps={{
                maxLength: EMAIL_FIELD_LENGTH
              }}
            />
            {emailInvalid.isError && (
              <p className={classes.helperText} role="alert">
                {emailInvalid.message}
              </p>
            )}
          </div>
          <div className={classes.buttonHolder}>
            <Button
              className={classes.button}
              color="primary"
              id="fp-cancel"
              onClick={cancel}
              value="Post"
              variant="outlined"
            >
              <FormattedMessage id="CANCEL" defaultMessage="Cancel" />
            </Button>
            <Button
              id="fp-send"
              variant="contained"
              className={classes.button}
              color="primary"
              type="submit"
              value="Post"
              disabled={
                progress || emailInvalid.isError || isEmptyString(emailId)
              }
            >
              <FormattedMessage id="SEND" defaultMessage="Send" />
            </Button>
          </div>
        </form>
      </Grid>
    </Grid>
  );
};

ForgotPassword.defaultProps = {
  classes: {}
};

ForgotPassword.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(ForgotPassword);
