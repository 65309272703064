import { activity } from './constants';
import moment from 'moment';
import { shuffle as _shuffle } from 'lodash';

export const countActivityType = (data, typeId) => {
  const initialvalue = 0;
  let count = 0;

  if (typeId === activity.activityTypes.VIDEO) {
    count = data?.reduce(
      (prevValue, currValue) =>
        currValue.type === activity.apiTypes.QUALITATIVE
          ? prevValue + 1
          : prevValue,
      initialvalue
    );
  }
  if (typeId === activity.activityTypes.SURVEY) {
    count = data?.reduce(
      (prevValue, currValue) =>
        currValue.type === activity.apiTypes.SURVEY ||
        currValue.type === activity.apiTypes.QUANTITATIVE
          ? prevValue + 1
          : prevValue,
      initialvalue
    );
  }
  if (typeId === activity.activityTypes.WEEKLY_QUEST) {
    count = data?.reduce(
      (prevValue, currValue) =>
        currValue.type === activity.apiTypes.MICRO_SURVEY &&
        currValue.status !== 'Completed'
          ? prevValue + 1
          : prevValue,
      initialvalue
    );
  }
  return count || 0;
};

export const getFeedbackSurvey = (activities) => {
  return activities?.find(
    (activity) =>
      activity.type === 'FEEDBACK_SURVEY' && activity.status !== 'Completed'
  );
};

export const SURVEY_PATH_REGEX = /^\/surveytaker\/survey\/(?<surveyId>.{15})$/;

export function extractSurveyId(surveyLink) {
  if (!surveyLink) {
    return '';
  }
  const url = new URL(surveyLink);
  const regexGroup = SURVEY_PATH_REGEX.exec(url.pathname)?.groups;
  return regexGroup?.surveyId ?? '';
}

export const getRelevantActivities = (allActivities, typeId) => {
  if (typeId === activity.activityTypes.VIDEO) {
    return allActivities.filter(
      (item) => item.type === activity.apiTypes.QUALITATIVE
    );
  }

  if (typeId === activity.activityTypes.SURVEY) {
    return allActivities.filter(
      (item) =>
        item.type === activity.apiTypes.SURVEY ||
        item.type === activity.apiTypes.QUANTITATIVE
    );
  }

  if (typeId === activity.activityTypes.WEEKLY_QUEST) {
    return allActivities.filter(
      (item) => item.type === activity.apiTypes.MICRO_SURVEY
    );
  }

  return [];
};

export const getActGridSpace = (width) =>
  width <= 600 ? 0 : width <= 960 ? 2 : 3;

///////
// This is needed for the alternating icons functionality for micro survey activity cards
//
// Uses start of 2024 since that falls on a Monday (same as our micro survey weeks)
// 1. Get weeks since then (day since then / 7) -> do math.ceil because you want to
//    find the current week value (9.3 weeks is the 10th week)
// 2. then return if the week is an even week or an odd week
const isEvenWeek = () => {
  return (
    Math.ceil(
      (moment().diff(moment('1/1/2024').startOf('year'), 'days') + 1) / 7
    ) %
      2 ===
    0
  );
};

//
export const getRandomIconsForMicroSurveyActivityCard = () => {
  if (isEvenWeek()) {
    let images = [
      'bag-post-shopping-1',
      'box',
      'car-trunk',
      'cart-post-shopping',
      'receipt-curled'
    ];
    return _shuffle(images);
  } else {
    let images = [
      'bag-post-shopping-2',
      'cart-while-shopping',
      'empty-basket',
      'truck'
    ];
    return _shuffle(images);
  }
};

export const weeklyQuestIcons = getRandomIconsForMicroSurveyActivityCard();
