import { Grid, makeStyles, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import InputTextField from '../../../components/TextField';
import {
  addressProperties,
  AddrLabels,
  requiredAddressProps,
  userProfileFreeTexts,
} from '../constanst';
import FormSelect from '../../../components/FormSelect';
import { SaveButton } from '../../../components/Buttons';
import CircularLoader from '../../../components/CircularLoader';
import StreetAutoComplete from './StreetAddressInput';
import { useEffect, useState } from 'react';
import AddressConfirmModal from './AddressConfirmModal/index';
import {
  cityValidityCheck,
  constructFullStateStr,
  extractStateAbbreviation,
  findState,
  zipCodeValidityCheck
} from '../utils';
import styles from './styles';
import { Error } from '@material-ui/icons';
import SnackBanner from '../../../components/SnackBanner';
import US_STATE from '../../../utils/usStates.json';
import apiCall from '../../../utils/api';
import api from '../../../constants/api';
import API, { API_STATUS_CODE } from '../../../constants/common';

const AddressForm = (props) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const { addressData, isSaveLoading, saveTestId, onSave } = props;

  const { register, handleSubmit, setValue, getValues, formState, watch } =
    useForm({
      defaultValues: addressData
    });
  const [isAddressConfirmOpen, setIsAddressConfirmOpen] = useState(false);
  const [isLoadingDetail, setIsLoadingDetail] = useState(false);
  const [snackBanner, setSnackBanner] = useState({
    displaySnackBanner: false,
    snackBannerMessage: ''
  });
  const [isNotValid, setIsNotValid] = useState(false);
  const [isCustomChange, setIsCustomChange] = useState();
  const [suggestedData, setSuggestedData] = useState();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    watch();
    setIsNotValid(checkValidity);
  });

  const checkValidity = () => {
    return (
      requiredAddressProps.filter((prop) => {
        return getValues()[prop] === null || getValues()[prop] === '';
      }).length > 0
    );
  };

  const handleSnackBannerClose = () => {
    setSnackBanner({
      displaySnackBanner: false,
      snackBannerMessage: ''
    });
  };

  const openSnackBanner = (message) => {
    setSnackBanner({
      displaySnackBanner: true,
      snackBannerMessage: message
    });
  };

  const handleAddrLine1Change = (value, isCustomValue = false) => {
    setIsCustomChange(isCustomValue);
    if (!isCustomValue && typeof value !== 'string') {
      handleCityChange(value?.[addressProperties.CITY], false);
      handleZipChange(value?.[addressProperties.ZIP], false);
      handleAddrLine2Change(value?.[addressProperties.ADDRESS_LINE2] ?? '');
      handleStateChange(value?.[addressProperties.STATE], false);

      setValue(
        addressProperties.ADDRESS_LINE1,
        value?.[addressProperties.MAIN_LINE],
        {
          shouldDirty: true
        }
      );
      setValue(addressProperties.LATITUDE, value?.[addressProperties.LATITUDE]);
      setValue(
        addressProperties.LONGITUDE,
        value?.[addressProperties.LONGITUDE]
      );
    } else {
      setValue(addressProperties.ADDRESS_LINE1, value, { shouldDirty: true });
    }
  };

  const handleAddrLine2Change = (value) => {
    setValue(addressProperties.ADDRESS_LINE2, value, { shouldDirty: true });
  };

  const handleCityChange = (value, isCustomValue = true) => {
    setIsCustomChange(isCustomValue);
    setValue(addressProperties.CITY, value, { shouldDirty: true });
  };

  const handleStateChange = (value, isCustomValue = true) => {
    setIsCustomChange(isCustomValue);
    const stateShortName = extractStateAbbreviation(value);
    setValue(
      addressProperties.STATE,
      constructFullStateStr(findState(stateShortName)),
      {
        shouldDirty: true
      }
    );
  };

  const handleZipChange = (value, isCustomValue = true) => {
    setIsCustomChange(isCustomValue);
    setValue(addressProperties.ZIP, value, { shouldDirty: true });
  };

  const circularLoader = <CircularLoader size="small" />;

  const onSubmit = () => {
    if (!isCustomChange) {
      onSave({
        ...getValues(),
        panelist_st: extractStateAbbreviation(
          getValues(addressProperties.STATE)
        )
      });
    } else {
      setIsLoadingDetail(true);
      const payload = {
        address: getAddressString(getValues())
      };
      fetchStreetValidation(payload);
    }
  };

  const fetchStreetValidation = (payload) => {
    apiCall(api.addressValidate, API.METHOD.GET, payload)
      .then(async (res) => {
        if (res.status === API_STATUS_CODE.SUCCESS) {
          setSuggestedData(res?.data);
          setIsAddressConfirmOpen(true);
        } else {
          onSave({
            ...getValues(),
            panelist_st: extractStateAbbreviation(
              getValues(addressProperties.STATE)
            )
          })
        }
      })
      .catch((err) => {
        openSnackBanner(err?.message);
      })
      .finally(() => setIsLoadingDetail(false));
  };

  const getAddressString = (addressDetail) => {
    return `${addressDetail?.[addressProperties.ADDRESS_LINE1]}, ${
      addressDetail?.[addressProperties.ADDRESS_LINE2] ?? ''
    } ${
      addressDetail?.[addressProperties.CITY] ?? ''
    } ${extractStateAbbreviation(addressDetail?.[addressProperties.STATE])} ${
      addressDetail?.[addressProperties.ZIP] ?? ''
    }`;
  };

  const handleModalClose = () => {
    setIsAddressConfirmOpen(false);
  };

  const getStateValue = (state) => {
    return findState(state) ? constructFullStateStr(findState(state)) : state;
  };

  const stateMenuItems = US_STATE?.map(constructFullStateStr);

  const displayZipErrorMessage =
    getValues(addressProperties.ZIP) &&
    !zipCodeValidityCheck(getValues(addressProperties.ZIP));

  const displayCityErrorMessage =
    getValues(addressProperties.CITY) &&
    !cityValidityCheck(getValues(addressProperties.CITY));

  const errorMessage = (text) => (
    <Grid item xs={12}>
      <Grid container>
        <div>
          <Error className={classes.errorIcon} />
        </div>
        <Grid item>
          <Typography className={classes.textFieldErrorMsg}>{text}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <>
      <SnackBanner
        isSnackbarOpen={snackBanner.displaySnackBanner}
        snackbarMessage={snackBanner.snackBannerMessage}
        onSnackBarClose={handleSnackBannerClose}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        {isAddressConfirmOpen && (
          <AddressConfirmModal
            addressDetail={getValues()}
            suggestedData={suggestedData}
            onSave={onSave}
            open={isAddressConfirmOpen}
            onClose={handleModalClose}
          />
        )}
        <Grid container>
          <Grid item xs={10}>
            <StreetAutoComplete
              id="address-line1"
              formRegister={register}
              value={getValues(addressProperties.ADDRESS_LINE1) ?? ''}
              valueOnChange={handleAddrLine1Change}
              setIsLoadingDetail={setIsLoadingDetail}
            />
            <InputTextField
              {...register(addressProperties.ADDRESS_LINE2)}
              id="address-line2"
              label={AddrLabels.LINE2}
              handleInputChange={handleAddrLine2Change}
              value={getValues(addressProperties.ADDRESS_LINE2) || ''}
            />
            <InputTextField
              {...register(addressProperties.CITY)}
              id="address-city"
              label={AddrLabels.CITY}
              handleInputChange={handleCityChange}
              value={getValues(addressProperties.CITY) || ''}
              errorStatus={displayCityErrorMessage}
              helperText={
                displayCityErrorMessage
                  ? errorMessage(userProfileFreeTexts.cityCodeErrorMsg)
                  : ''
              }
            />
            <FormSelect
              {...register(addressProperties.STATE)}
              label={AddrLabels.STATE}
              hasSearchBar={true}
              id="address-state"
              key="state"
              selectedValue={handleStateChange}
              menuItems={stateMenuItems}
              value={getStateValue(getValues(addressProperties.STATE)) || ''}
              className={classes.formControl}
            />
            <InputTextField
              {...register(addressProperties.ZIP)}
              id="address-zip"
              label={AddrLabels.ZIP}
              handleInputChange={handleZipChange}
              value={getValues(addressProperties.ZIP) || ''}
              errorStatus={displayZipErrorMessage}
              helperText={
                displayZipErrorMessage
                  ? errorMessage(userProfileFreeTexts.zipCodeErrorMsg)
                  : ''
              }
            />
          </Grid>
          <Grid item xs={5}>
            {isSaveLoading || isLoadingDetail ? (
              circularLoader
            ) : (
              <SaveButton
                testId={saveTestId}
                disabled={
                  !formState.isDirty ||
                  isLoadingDetail ||
                  displayZipErrorMessage ||
                  displayCityErrorMessage ||
                  isNotValid
                }
                type="submit"
              />
            )}
          </Grid>
        </Grid>
      </form>
    </>
  );
};
export default AddressForm;
