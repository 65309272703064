const styles = (theme) => ({
  activityGridContainer: {
    flexDirection: 'column',
    marginLeft: 30,
    marginRight: 30,
    columnGap: 12,
    rowGap: 18,
    justifyContent: 'start',
    alignItems: 'center'
  },
  activityCard: {},
  noActivitiesWrapper: {
    flexDirection: 'column',
    alignItems: 'center',
    width: 232,
    justifyContent: 'start',
    marginTop: 75
  },
  zeroMarginTop: {
    marginTop: '0!important'
  },
  noActivitiesTitle: {
    fontFamily: 'Bogle',
    fontSize: 24,
    fontWeight: 700,
    lineHeight: '32px',
    letterSpacing: 0,
    textAlign: 'center',
    marginTop: 30,
    marginBottom: 10
  },
  noActivitiesBody: {
    fontFamily: 'Bogle',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: 0,
    textAlign: 'center',
    marginBottom: 10,
    color: '#74767C'
  },
  okButtonWrapper: {
    marginTop: 20
  },
  trophyInListView: {
    flexDirection: 'column',
    alignItems: 'center',
    width: 328,
    justifyContent: 'start',
    marginTop: 30
  }
});

export default styles;
