import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  withStyles
} from '@material-ui/core';
import apiCall from '../../../utils/api';
import ApiPath from '../../../constants/api';
import API, { API_STATUS_CODE } from '../../../constants/common';
import { unsubContent } from '../../../constants/common';
import styles from './styles';
import { FormattedMessage } from 'react-intl';
import { removeLoginTokens } from '../../../utilities/utils';

const Unsubscribe = ({ classes, openDialog, setOpenDialog }) => {
  const title = 'unsubscribe-dialog';
  const desc = 'confirm-or-cancel-unsubscribe';
  const { push, replace } = useHistory();
  const [unsubscribe, setUnsubscribe] = useState(false);

  const handleClose = () => {
    setOpenDialog(false);
    push('/profile', 'unsubCancel');
  };

  const handleConfirm = () => {
    apiCall(ApiPath.unsubscribe, API.METHOD.POST, {}, false).then(
      (response) => {
        if (response?.error) {
          console.log(response?.error?.message);
        }

        if (response?.status === API_STATUS_CODE.SUCCESS) {
          setUnsubscribe(true);
        }
      }
    );
  };

  const handleLogout = () => {
    removeLoginTokens();
    replace('/home', '');
    push('/login', 'logOff');
  };

  const getUnsubscribeContent = () =>
    unsubContent.map((text, index) => {
      const content = (
        <DialogContentText id={desc} classes={{ root: classes.contentText }}>
          <FormattedMessage
            id={`UNSUBSCRIBE_CONTENT_${index}`}
            defaultMessage={text.TEXT}
          />
        </DialogContentText>
      );

      return content;
    });

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      aria-labelledby={title}
      aria-describedby={desc}
      disableBackdropClick
      classes={{
        paper: classes.dialog
      }}
    >
      {unsubscribe ? (
        <>
          <DialogTitle />
          <DialogContent classes={{ root: classes.dialogOkContent }}>
            <DialogContentText
              id={desc}
              classes={{ root: classes.contentText }}
            >
              <FormattedMessage
                id="UNSUBSCRIBE_CONFIRMATION"
                defaultMessage="We’re sorry to see you go! Please give us 72 hours to process your request to unsubscribe."
              />
            </DialogContentText>
          </DialogContent>
        </>
      ) : (
        <>
          <DialogTitle id={title} classes={{ root: classes.dialogTitle }}>
            <FormattedMessage id="UNSUBSCRIBE" defaultMessage="Unsubscribe" />
          </DialogTitle>
          <DialogContent classes={{ root: classes.dialogContent }}>
            {getUnsubscribeContent()}
          </DialogContent>
        </>
      )}
      {unsubscribe ? (
        <DialogActions classes={{ root: classes.dialogOkActions }}>
          <Button
            id="alert-ok"
            className={classes.ok}
            onClick={handleLogout}
            color="primary"
            variant="contained"
            autoFocus
          >
            <FormattedMessage id="OK" defaultMessage="OK" />
          </Button>
        </DialogActions>
      ) : (
        <DialogActions classes={{ root: classes.dialogActions }}>
          <Button
            id="alert-cancel"
            className={classes.confirm}
            onClick={handleClose}
            color="primary"
          >
            <FormattedMessage id="CANCEL" defaultMessage="Cancel" />
          </Button>
          <Button
            id="alert-confirm"
            className={classes.confirm}
            onClick={handleConfirm}
            color="primary"
            variant="contained"
          >
            <FormattedMessage id="CONFIRM" defaultMessage="Confirm" />
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

Unsubscribe.defaultProps = {
  classes: {}
};

Unsubscribe.propTypes = {
  classes: PropTypes.object,
  openDialog: PropTypes.bool.isRequired,
  setOpenDialog: PropTypes.func.isRequired
};

export default withStyles(styles)(Unsubscribe);
