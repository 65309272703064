import React from 'react';

import { Switch, Route } from 'react-router-dom';
import Activities from '../../../components/Activities';
import History from '../../../components/History';
import Redeem from '../../../components/Redeem';

const TabRoutes = () => {
  return (
    <Switch>
      <Route path={['/home', '/rewards']} component={Redeem} />
      <Route path={'/activities'} component={Activities} />
      <Route path={'/history'} component={History} />
    </Switch>
  );
};

export default TabRoutes;
