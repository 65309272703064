import React from 'react';
import {
  AssignmentIndOutlined,
  CakeOutlined,
  CardMembershipOutlined,
  EmojiObjectsOutlined,
  LocalPharmacyOutlined,
  PeopleOutlineOutlined,
  HouseOutlined,
  AlternateEmail,
  VpnKeyOutlined,
  PersonOutlineOutlined,
  SchoolOutlined,
  WorkOutlineOutlined,
  PublicOutlined,
  PetsOutlined,
  VideocamOutlined,
  PersonOutline,
  MapOutlined
} from '@material-ui/icons';

const ProfileIcon = (props) => {
  const { iconStyle, detailName } = props;

  const detailNameIconMap = {
    per_screen_name: <PersonOutlineOutlined className={iconStyle} />,
    per_dob: <CakeOutlined className={iconStyle} />,
    per_education: <SchoolOutlined className={iconStyle} />,
    per_employment: <WorkOutlineOutlined className={iconStyle} />,
    per_zip_code: <PublicOutlined className={iconStyle} />,
    per_address: <MapOutlined className={iconStyle} />,
    ho_decision_making: (
      <img
        src={'/assets/images/icons/decision-making.webp'}
        alt="decision-making"
        className={iconStyle}
      />
    ),
    ho_pet_ownership: <PetsOutlined className={iconStyle} />,
    ho_walmart_health_indicator: (
      <img
        src={'/assets/images/icons/health.webp'}
        alt="health"
        className={iconStyle}
      />
    ),
    ho_family_pharmacy: <LocalPharmacyOutlined className={iconStyle} />,
    sho_retail_membership: <CardMembershipOutlined className={iconStyle} />,
    actFrequency: <AssignmentIndOutlined className={iconStyle} />,
    ap_surveys_flag: (
      <img
        src={'/assets/images/icons/surveys.webp'}
        alt="surveys"
        className={iconStyle}
      />
    ),
    ap_video_flag: <VideocamOutlined className={iconStyle} />,
    ap_other_flag: <EmojiObjectsOutlined className={iconStyle} />,
    per_marital_status: <PeopleOutlineOutlined className={iconStyle} />,
    per_baby_expectancy: (
      <img
        src={'/assets/images/icons/baby-crib.png'}
        alt="baby_expectancy"
        className={iconStyle}
      />
    ),
    per_rent_or_own: <HouseOutlined className={iconStyle} />,
    ho_household_income: (
      <img
        src={'/assets/images/icons/outline_payments_black.png'}
        alt="household_income"
        className={iconStyle}
      />
    ),
    ho_customers_household_size: (
      <img
        src={'/assets/images/icons/outline_groups_black.png'}
        alt="customers_household_size"
        className={iconStyle}
      />
    ),
    per_number_of_children_under_18: (
      <img
        src={'/assets/images/icons/outline_escalator_warning.png'}
        alt="number_of_children_below_18"
        className={iconStyle}
      />
    ),
    ap_email: <AlternateEmail className={iconStyle} />,
    ap_password: <VpnKeyOutlined className={iconStyle} />
  };

  return (
    detailNameIconMap[detailName] || <PersonOutline className={iconStyle} />
  );
};

export default ProfileIcon;
