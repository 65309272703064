/* eslint-disable react/jsx-props-no-spreading */
import { withStyles } from '@material-ui/core';
import React from 'react';
import Dropzone from 'react-dropzone';
import styles from './styles';

const DropzoneComponent = ({
  children,
  supportTypes,
  classes,
  onDrop,
  config
}) => {
  const { container, dropZone } = classes;

  return (
    <Dropzone onDrop={onDrop} accept={supportTypes} {...config}>
      {({ getRootProps, getInputProps }) => (
        <section className={container}>
          <div
            {...getRootProps({
              className: dropZone,
              'data-testid': 'dropzone'
            })}
          >
            <input data-testid="drop-input" {...getInputProps()} />
            {children}
          </div>
        </section>
      )}
    </Dropzone>
  );
};

export default withStyles(styles)(DropzoneComponent);
