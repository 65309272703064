const styles = (theme) => ({
  videoContainer: {
    width: '100%',
    '& .video-js .vjs-dock-text': {
      display: 'contents'
    },
    '& .video-js .vjs-big-play-button:hover': {
      backgroundColor: '#0071CE'
    },
    '& .video-js:hover .vjs-big-play-button': {
      backgroundColor: '#0071CE'
    }
  },
  bgCalcite: {
    backgroundColor: '#ffffff',
    opacity: 0.5
  },
  replayButton: {
    color: '#0071CE',
    width: '2em',
    height: '2em',
  },
  pauseButton: {
    color: '#ccc',
    width: '2em',
    height: '2em',
    visibility: 'hidden'
  },
  container: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    '&:hover .MuiButtonBase-root .MuiSvgIcon-root': {
      visibility: 'visible'
    }
  },
  btnContainer: {
    width: '100%',
    position: 'absolute',
    top: '40%',
    left: '43%',
    [theme.breakpoints.up('sm')]: {
      left: '46%'
    },
    [theme.breakpoints.up('md')]: {
      left: '47%'
    },
    [theme.breakpoints.up('md')]: {
      left: '48%'
    }
  }
});

export default styles;
