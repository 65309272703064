export const rewardsConstants = {
  freeText: {
    myPointsEarned: 'My Points Earned',
    availableRewards: 'Available Rewards',
    chooseGiftToRedeem:
      'Choose an available Walmart eGift card option to redeem',
    points: 'points',
    dollarSign: '$',
    redeem: 'Redeem',
    giftCardText: {
      card1: {
        dollarValue: 5
      },
      card2: {
        dollarValue: 25
      },
      card3: {
        dollarValue: 50
      },
      card4: {
        dollarValue: 100
      }
    },
    giftCardValues: [5, 25, 50, 100]
  },
  sliderText: {
    minNumber: 0,
    maxNumber: 500,
    defaultPoints: 0
  }
};

export const dummyRewardsApiResponse = {
  points: 8000,
  currency: 'USD',
  eligibleGiftCards: [5]
};
