const styles = (theme) => ({
  contentText: {
    fontSize: 14,
    fontWeight: 300,
    lineHeight: 1.43,
    color: '#414042',
    margin: 0
  },
  mainTitle: {
    fontSize: 24,
    color: '#414042'
  },
  giftCardUrl: {
    fontSize: 14,
    cursor: 'pointer',
    fontWeight: 300,
    lineHeight: 1.43,
    color: theme?.palette?.primary?.main,
    textDecoration: 'underline',
    padding: '10px 0'
  },
  titleText: {
    fontSize: 14,
    lineHeight: 1.43,
    color: '#414042'
  },
  dialog: {
    minWidth: 328,
    padding: 30,
    borderRadius: 8,
    margin: 0,
    boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#ffffff',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 469
    },
    [theme.breakpoints.down('sm')]: {
      padding: 20,
      width: 300,
      minWidth: 'unset'
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 487
    },
    [theme.breakpoints.up('lg')]: {
      width: 444
    }
  },
  dialogContent: {
    padding: '0 16px',
    textAlign: 'center',
    WebkitUserSelect: 'text',
    [theme.breakpoints.up('sm')]: {
      padding: '0px 38px'
    },
    [theme.breakpoints.up('md')]: {
      padding: '0 45px 0 54px'
    },
    [theme.breakpoints.up('lg')]: {
      padding: '8px 24px 0 32px'
    }
  },
  closeIconHolder: {
    display: 'flex',
    flexDirection: 'row-reverse'
  },
  icon: {
    color: 'grey'
  },
  image: {
    width: 122,
    height: 80,
    padding: '20px 0'
  }
});

export default styles;
