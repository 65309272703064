const styles = (theme) => ({
  menuItem: {
    color: "#282828",
    width: "auto",
    height: 48
  },
  track: {
    backgroundColor: "#3c228a1a !important",
    opacity: "1 !important",
  },
  minPadding: {
    paddingLeft: 4,
  },
  switch: {
    "& .MuiTouchRipple-root span": {
      backgroundColor: "#B8B8B8 !important",
      opacity: 0.5,
    },
  },
});

export default styles;
