import React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import styles from './styles';
import PropTypes from 'prop-types';

const CloseIconButton = (props) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const { onClick } = props;

  return (
    <IconButton
      onClick={onClick}
      aria-label="close-button"
      classes={{ root: classes.iconButtonRoot }}
      data-testid="close-button"
    >
      <Close className={classes.icon} />
    </IconButton>
  );
};

CloseIconButton.propTypes = {
  onClick: PropTypes.func
};

export default CloseIconButton;
