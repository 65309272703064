const styles = (theme) => ({
  buttonRoot: {
    textTransform: 'none',
    textAlign: 'center',
    fontSize: 14,
    fontFamily: 'Bogle',
    fontWeight: 'bold',
    wordWrap: 'break-word'
  },
  roundedFilled: {
    color: 'white',
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 10,
    paddingBottom: 10,
    background: '#0071CE',
    borderRadius: 32,
    '&:disabled': {
      backgroundColor: '#6D6E71',
      color: '#FFFFFF'
    }
  },
  roundedUnfilled: {
    color: 'black',
    backgroundColor: 'white',
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 10,
    paddingBottom: 10,
    background: '#0071CE',
    borderRadius: 32,
    border: '1px #2E2F32 solid',
    '&:disabled': {
      backgroundColor: '#6D6E71',
      color: '#FFFFFF'
    }
  },
  roundFilledDiabled: {
    backgroundColor: '#6D6E71',
    color: '#FFFFFF'
  },
  unfilledSquare: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
    paddingBottom: 8,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
});

export default styles;
