import { Box, Grid, makeStyles } from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { useState } from 'react';
import { AddrConfirmation, addressProperties } from '../../constanst';
import styles from '../styles';
import clsx from 'clsx';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import { constructFullStateStr, decodeOctalUTF8, findState } from '../../utils';

const AddrConfirmContent = (props) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const { userData, suggestedData, setSelectedAddress } = props;
  const [userAddrSelected, setUserAddrSelected] = useState(true);

  const handleClickAddrEntered = () => {
    setUserAddrSelected(true);
    setSelectedAddress(userData);
  };

  const handleClickAddrSuggested = () => {
    setUserAddrSelected(false);
    setSelectedAddress(suggestedData);
  };

  const constructSuggestionContent = (data) => {
    let cityName;
    const content = Object.entries(addressProperties).map(([key, value]) => {
      if (value === addressProperties.CITY) {
        cityName = decodeOctalUTF8(data[value] ?? '')
      } else if (value === addressProperties.STATE) {
        return (
          <Grid item key={`suggestion-${key}`} xs={12}>
            {cityName},{' '}
            {findState(data?.[value])
              ? constructFullStateStr(findState(data?.[value]))
              : data?.[value]}
          </Grid>
        );
      } else if (
        value !== addressProperties.LATITUDE &&
        value !== addressProperties.LONGITUDE
      ) {
        return (
          <Grid item key={`suggestion-${key}`} xs={12}>
            {data[value]}
          </Grid>
        );
      }
    });

    return (
      <Grid container className={classes.addressContent}>
        {content}
      </Grid>
    );
  };

  return (
    <>
      <Box
        onClick={handleClickAddrEntered}
        className={clsx(classes.addressConfirmBox, {
          [classes.addressConfirmBoxSelected]: userAddrSelected
        })}
        data-testid="entered-address-content-testId"
      >
        <Grid
          container
          className={clsx(classes.suggestionTitle, {
            [classes.suggestionTitleSelected]: userAddrSelected
          })}
        >
          <Grid item container xs={1} className={classes.icon}>
            {userAddrSelected ? (
              <RadioButtonCheckedIcon />
            ) : (
              <RadioButtonUncheckedIcon />
            )}
          </Grid>
          <Grid item xs={11} className={classes.boxTitle}>
            {AddrConfirmation.addressEntered}
          </Grid>
        </Grid>
        {constructSuggestionContent(userData)}
      </Box>
      <Box
        onClick={handleClickAddrSuggested}
        className={clsx(classes.addressConfirmBox, {
          [classes.addressConfirmBoxSelected]: !userAddrSelected
        })}
        id="validated-address-content-testId"
      >
        <Grid
          container
          className={clsx(classes.suggestionTitle, {
            [classes.suggestionTitleSelected]: !userAddrSelected
          })}
        >
          <Grid item container xs={1} className={classes.icon}>
            {!userAddrSelected ? (
              <RadioButtonCheckedIcon />
            ) : (
              <RadioButtonUncheckedIcon />
            )}
          </Grid>
          <Grid item xs={11} className={classes.boxTitle}>
            {AddrConfirmation.suggestedAddress}
          </Grid>
        </Grid>
        {constructSuggestionContent(suggestedData)}
      </Box>
    </>
  );
};

export default AddrConfirmContent;
