import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  withStyles,
  Button,
  Grid
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import styles from './styles';

const Model = ({
  classes,
  handleClose,
  openDialog,
  handelSuccess,
  rewardData
}) => {
  const dialogContent = `You are about to redeem a $${rewardData} eGift card for ${
    rewardData * 100
  } points. 
  This will be deducted from your point balance. 
  Would you like to continue?`;

  return (
    <Dialog open={openDialog} onClose={handleClose}>
      <div className={classes.closeIconHolder}>
        <CloseIcon
          onClick={handleClose}
          data-testid="popup-close"
          className={classes.closeIcon}
        />
      </div>
      <DialogContent>
        <DialogContentText classes={{ root: classes.mainTitle }}>
          {dialogContent}
        </DialogContentText>
        <Grid container justifyContent="flex-end">
          <div className={classes.buttonView}>
            <Button
              color="primary"
              onClick={handleClose}
              data-testid="TEST-CANCEL-BTN"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonText}
              onClick={handelSuccess}
              data-testid="TEST-CONTINUE-BTN"
            >
              Continue
            </Button>
          </div>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

Model.defaultProps = {
  classes: {}
};

Model.propTypes = {
  classes: PropTypes.object,
  openDialog: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handelSuccess: PropTypes.func.isRequired,
  rewardData: PropTypes.number
};

export default withStyles(styles)(Model);
