const styles = (theme) => ({
  dialogRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  dialogPaperSm: { maxWidth: 444 },
  dialogTitleContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    width: '100%'
  },
  dialogContentContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%'
  },
  dialogContentText: {
    fontSize: 18
  },
  dialogActionsRoot: { padding: '24px 24px' },
  dialogFooterContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    width: '100%',
    columnGap: 40
  }
});

export default styles;
