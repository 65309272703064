import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  withStyles
} from '@material-ui/core';
import api from '../../../constants/api';
import apiCall from '../../../utils/api';
import API, {
  API_STATUS_CODE,
  monthNames,
  getDobYearsRange,
  monthMap,
  APIConstants
} from '../../../constants/common';
import styles from './styles';
import { updateDateOfBirth } from './constants';
import FormSelect from '../../FormSelect';

const UpdateDob = ({
  classes,
  newDateOfBirth,
  openDialog,
  setNewDateOfBirth,
  setOpenDialog,
  setIsSnackbarOpen,
  setSnackbarMessage
}) => {
  const title = 'dob-dialog';
  const desc = 'save-date-of-birth';
  const descTitle = 'title';

  const yearValues = getDobYearsRange();

  useEffect(() => {
    apiCall(api.profile, API.METHOD.GET, {}, false).then((response) => {
      if (response?.error) {
        setIsSnackbarOpen(true);
        setSnackbarMessage(`${response?.error?.message}`);
      }
      if (response?.status === API_STATUS_CODE.SUCCESS) {
        const apiResponse = response?.data?.profile;
        const apiPersonalDetails = apiResponse.find(
          (x) => x.type === APIConstants.dataTypes.PERSONAL
        ).profileTypeDetailList;
        const apiDobDetails = apiPersonalDetails.find(
          (x) => x.detailName === APIConstants.detailNames.DOB
        );
        const dob = apiDobDetails?.selectedList[0]
          ? apiDobDetails?.selectedList?.[0]?.split('-')
          : '';

        setNewDateOfBirth({
          year: dob?.[0],
          month: dob?.[1]
        });
      }
    });
  }, []);

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleSave = () => {
    //api call to save DOB
    const { year, month } = newDateOfBirth;
    const dobDetails = `${year}-${month}-01`;
    const payload = [
      {
        detailName: updateDateOfBirth.apiKeys.dob,
        selectedList: [dobDetails]
      }
    ];

    apiCall(api.profile, API.METHOD.PUT, payload, false).then((response) => {
      if (response?.error) {
        setIsSnackbarOpen(true);
        setSnackbarMessage(
          `${updateDateOfBirth.freeTexts.updateErrorMessage}: ${response?.error?.message}`
        );
      }
      if (response?.status === API_STATUS_CODE.SUCCESS) {
        setIsSnackbarOpen(true);
        setSnackbarMessage(
          `${updateDateOfBirth.freeTexts.updateSuccessMessage}`
        );
        handleClose();
      }
    });
  };

  const handleMonthDobChange = (value) => {
    const monthValueToSave = monthMap[value];
    setNewDateOfBirth({ ...newDateOfBirth, month: monthValueToSave });
  };

  const handleYearDobChange = (value) => {
    setNewDateOfBirth({ ...newDateOfBirth, year: value });
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      aria-labelledby={title}
      aria-describedby={desc}
      disableBackdropClick
      classes={{
        paper: classes.dialog
      }}
    >
      <DialogTitle id={title} classes={{ root: classes.dialogTitle }}>
        {updateDateOfBirth.freeTexts.welcomeMessage}
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <DialogContentText id={desc}>
          {updateDateOfBirth.freeTexts.selectBirthdayDirective}
        </DialogContentText>
        <DialogContentText id={descTitle}>Date of Birth</DialogContentText>
        <DialogContentText>
          <FormSelect
            label="Month"
            id="month"
            value={newDateOfBirth.month}
            selectedValue={handleMonthDobChange}
            menuItems={monthNames}
          />
          <FormSelect
            label="Year"
            id="year"
            value={newDateOfBirth.year}
            selectedValue={handleYearDobChange}
            menuItems={yearValues}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogOkActions }}>
        <Button
          id="save-dob"
          className={classes.save}
          onClick={handleSave}
          color="primary"
          variant="contained"
        >
          {updateDateOfBirth.labels.saveButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

UpdateDob.defaultProps = {
  classes: {}
};

UpdateDob.propTypes = {
  classes: PropTypes.object,
  openDialog: PropTypes.bool.isRequired,
  setOpenDialog: PropTypes.func.isRequired
};

export default withStyles(styles)(UpdateDob);
