import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Unsubscribe from '../../components/AlertDialog/Unsubscribe';
import { PAGES } from '../../constants/analytics';
import analytics from '../../utils/analytics';
import { getQueryStringValue, isEmptyString } from '../../utils/common';

const UnsubRoute = () => {
  const { push } = useHistory();
  const location = useLocation();
  const flow = getQueryStringValue(location, 'flow') || '';
  const [unsubscribe, setUnsubscribe] = useState(true);

  useEffect(() => {
    analytics.trackPageView(PAGES.UNSUBSCRIBE);

    if (!isEmptyString(flow) && flow === 'email') {
      push('/login', 'unsubEvent');
    }
  }, []);

  useEffect(() => {
    if (!isEmptyString(flow) && flow === 'bottomNav' && !unsubscribe) {
      push('/home', 'unsubCancel');
    }
  }, [unsubscribe]);

  return (
    <Unsubscribe openDialog={unsubscribe} setOpenDialog={setUnsubscribe} />
  );
};

export default UnsubRoute;
