import { Box, InputAdornment, TextField } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { SearchOutlined } from '@material-ui/icons';

const SelectionSearchBar = (props) => {
  const { options, setOptions } = props;

  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (options) {
      const filteredOptions = searchTerm
        ? options.filter((option) =>
            option.toLowerCase().includes(searchTerm.toLowerCase())
          )
        : options;
      setOptions(filteredOptions);
    } else {
      setOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchClick = (event) => {
    event.stopPropagation();
  };

  const handleSearchKeyDown = (event) => {
    event.stopPropagation();
  };

  return (
    <Box m={2} onClick={handleSearchClick}>
      <TextField
        id="select-search"
        placeholder="Search"
        fullWidth
        value={searchTerm}
        onChange={handleSearchChange}
        onKeyDown={handleSearchKeyDown}
        onClick={handleSearchClick}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchOutlined />
            </InputAdornment>
          )
        }}
      />
    </Box>
  );
};

export default SelectionSearchBar;
