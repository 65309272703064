import React, { useState } from 'react';
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  makeStyles,
  OutlinedInput
} from '@material-ui/core';
import { Visibility, VisibilityOff, CheckCircle } from '@material-ui/icons';
import styles from './styles';
import PropTypes from 'prop-types';

const PasswordFieldWithIcon = (props) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const {
    variant = 'outlined',
    label = 'Password',
    id = 'password',
    name,
    value,
    error,
    helperText = ' ',
    errorMessage = ' ',
    isConfirmedTrue = false,
    onValueChange
  } = props;

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <FormControl variant={variant} className={classes.textField}>
      <InputLabel aria-label={`LABEL-${id}`}>{label}</InputLabel>
      <OutlinedInput
        aria-describedby="input field"
        aria-label={`INPUT-${id}`}
        id={id}
        name={name}
        data-testid={`TEST-${id}`}
        type={showPassword ? 'text' : 'password'}
        endAdornment={
          <InputAdornment position="end">
            {isConfirmedTrue ? (
              <CheckCircle className={classes.checkCircle} />
            ) : (
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            )}
          </InputAdornment>
        }
        label={label}
        value={value}
        error={error}
        onChange={onValueChange}
      />
      <FormHelperText>{error ? errorMessage : helperText}</FormHelperText>
    </FormControl>
  );
};

PasswordFieldWithIcon.propTypes = {
  variant: PropTypes.oneOf(['outlined', 'filled', 'standard']),
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  helperText: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  isConfirmedTrue: PropTypes.bool,
  onValueChange: PropTypes.func
};

export default PasswordFieldWithIcon;
