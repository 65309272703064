import React from 'react';
import PropTypes from 'prop-types';
import { LinearProgress, withStyles } from '@material-ui/core';
import styles from './style';
import Transition from '../Transition';
import { FormattedMessage } from 'react-intl';
import { IMAGE_PATH } from '../../utilities/utils';

const LuminateLoader = ({
  display,
  displayLogo,
  classes: {
    barColorPrimary,
    center,
    colorPrimary,
    flexCenter,
    flexDir,
    image,
    loader,
    loadingText,
    root
  }
}) => (
  <div className={center}>
    <Transition in>
      <div className={flexDir}>
        <div className={loadingText}>{display}</div>
        <LinearProgress
          className={loader}
          classes={{
            barColorPrimary,
            colorPrimary,
            root
          }}
        />
        {displayLogo && (
          <div className={flexCenter}>
            <img
              alt="Walmart"
              className={image}
              src={`${IMAGE_PATH}/Walmart-logo.svg`}
            />
          </div>
        )}
      </div>
    </Transition>
  </div>
);

LuminateLoader.defaultProps = {
  display: <FormattedMessage id="LOADING" defaultMessage="loading..." />,
  displayLogo: false
};

LuminateLoader.propTypes = {
  display: PropTypes.element,
  displayLogo: PropTypes.bool
};

export default withStyles(styles)(LuminateLoader);
