const styles = (theme) => ({
  mainContainer: { display: 'flex', flexDirection: 'column' },
  circleBack: {
    fill: 'none',
    stroke: '#dadce0'
  },
  circlePro: {
    fill: 'none',
    stroke: '#0071ce',
    strokeLinecap: 'round',
    strokeLinejoin: 'round'
  },
  circleAmt: {
    width: 69,
    height: 83,
    fontSize: 60,
    fontWeight: 300,
    color: '#3a3a3a'
  },
  circlePoints: {
    width: 48,
    height: 20,
    fontSize: 14,
    fontWeight: 300,
    color: '#333333'
  },
  skeletonWrapper: {
    width: '20%'
  },
  svgContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-end',
    width: 250,
    height: 160,
    justifyContent: 'center'
  },
  svg: {
    position: 'absolute'
  },
  trackerPointsValue: {
    fontWeight: 400,
    fontSize: 26
  },

  svgEndPoints: {
    display: 'block',
    position: 'relative'
  },
  minMaxPointsContainer: {
    marginTop: 4,
    width: 260
  },
  pointsContainer: {
    position: 'absolute',
    height: '10%',
    width: '70%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItem: 'center',
    marginBottom: '1%'
  },

  imgContainer: {
    height: '100%',
    width: '10%',
    display: 'flex',
    justifyContent: 'center',
    alignItem: 'center'
  },
  logoImage: { height: 6, width: 6, marginTop: '20%' },
  pointsValue: {
    fontWeight: 400,
    fontSize: 12
  },

  trackerMinPoints: {
    marginLeft: 18
  },
  trackerMaxPoints: {
    marginLeft: 175
  }
});

export default styles;
