import {
  ALREADY_TAKEN,
  SAVE_ERROR,
  SURVEY_CLOSED,
  SURVEY_NOT_AUTHORIZED,
  SURVEY_NOT_EXIST,
  THANK_YOU
} from '../constants/keywords';
import { axiosIns } from '../utils/api';
import { logger } from '../utils/logger';

export const ASSETS_PATH = '/assets';

export const IMAGE_PATH = `${ASSETS_PATH}/images`;

export const objectToString = (currentObject) => {
  const TY = JSON.stringify(THANK_YOU);
  const SE = JSON.stringify(SAVE_ERROR);
  const AT = JSON.stringify(ALREADY_TAKEN);
  const SNE = JSON.stringify(SURVEY_NOT_EXIST);
  const SNA = JSON.stringify(SURVEY_NOT_AUTHORIZED);
  const SC = JSON.stringify(SURVEY_CLOSED);
  const CO = JSON.stringify(currentObject);

  switch (CO) {
    case TY:
      return 'THANK_YOU';
    case SE:
      return 'SAVE_ERROR';
    case AT:
      return 'ALREADY_TAKEN';
    case SNE:
      return 'SURVEY_NOT_EXIST';
    case SNA:
      return 'SURVEY_NOT_AUTHORIZED';
    case SC:
      return 'SURVEY_CLOSED';
    default:
      return '';
  }
};

export const removeLoginTokens = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('panelistId');
  localStorage.removeItem('emailId');
  logger.setUserInfo({
    userId: 'unset'
  });
};

// For setting the session
export const setSession = () => {
  const sessionId = Date.now();
  logger.setSessionInfo(`${sessionId}`);
  sessionStorage.setItem('cs_session', sessionId);
};

export const truncate = (lineCount) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: lineCount || 1,
  WebkitBoxOrient: 'vertical'
});

export const handleFileUpload = (url, files) => {
  const formData = new FormData();
  files?.forEach((file) => {
    formData.append('files', file);
  });
  return axiosIns.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const getSizeFromBytesToKBMB = (sizeInBytes) => {
  let sizeInMB = sizeInBytes / 1000 / 1000;

  if (sizeInMB < 1) {
    return `${sizeInMB * 1000} KB`;
  } else {
    return `${sizeInMB?.toFixed(2)} MB`;
  }
};
