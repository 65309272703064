import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  withStyles
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import styles from './styles';
import moment from 'moment';
import { DATE_DISPLAY_SLASH_FORMAT } from '../../constants/common';

const GiftCardDetail = ({
  classes,
  giftCardInfo: { rewardType, points, status, timestamp, challengeCode, url },
  handleClose,
  openDialog
}) => {
  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      classes={{
        paper: classes.dialog
      }}
    >
      <div className={classes.closeIconHolder}>
        <CloseIcon
          onClick={handleClose}
          data-testid="popup-close"
          className={classes.icon}
        />
      </div>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <DialogContentText classes={{ root: classes.mainTitle }}>
          {rewardType}
        </DialogContentText>
        <DialogContentText classes={{ root: classes.titleText }}>
          {points} points {status.toLowerCase()}
        </DialogContentText>
        <img
          src={'/assets/images/rewards.webp'}
          alt="rewards"
          className={classes.image}
        />
        <DialogContentText classes={{ root: classes.contentText }}>
          {status} Date:
        </DialogContentText>
        <DialogContentText classes={{ root: classes.titleText }}>
          {moment(timestamp).format(DATE_DISPLAY_SLASH_FORMAT)}
        </DialogContentText>
        {url && (
          <DialogContentText
            classes={{ root: classes.giftCardUrl }}
            onClick={() => window.open(url, '_blank')}
          >
            Go to eGift Card
          </DialogContentText>
        )}
        {challengeCode && (
          <>
            <DialogContentText classes={{ root: classes.contentText }}>
              Challenge Code:
            </DialogContentText>
            <DialogContentText classes={{ root: classes.titleText }}>
              {challengeCode}
            </DialogContentText>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

GiftCardDetail.defaultProps = {
  classes: {}
};

GiftCardDetail.propTypes = {
  classes: PropTypes.object,
  openDialog: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default withStyles(styles)(GiftCardDetail);
