const styles = (theme) => ({
  root: {
    flexGrow: 1,
    padding: 0,
    position: 'sticky',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginTop: 8,
    marginBottom: 80,
    minHeight: '23vw',
    [theme.breakpoints.up('md')]: {
      marginTop: 24,
      marginBottom: 20
    }
  },
  sectionsComponent: {
    width: '33%',
    marginTop: 16,
    [theme.breakpoints.up('xs')]: {
      width: '100%'
    },
    [theme.breakpoints.up('sm')]: {
      width: '60%'
    },
    [theme.breakpoints.up('md')]: {
      width: '33%'
    }
  },
  button: {
    width: 312,
    height: 40,
    fontSize: 14,
    textTransform: 'none',
    [theme.breakpoints.up('sm')]: {
      width: 119
    },
    '&.Mui-disabled': {
      backgroundColor: '#6d6e71',
      color: '#ffffff'
    }
  },
  buttonHolder: {
    display: 'flex',
    marginTop: 40,
    [theme.breakpoints.up('sm')]: {
      width: 344,
      justifyContent: 'space-around'
    },
    [theme.breakpoints.up('md')]: {
      width: 300
    },
    [theme.breakpoints.up('lg')]: {
      width: 412
    }
  },
  snackbarRoot: {
    maxWidth: 444,
    fontSize: 16,
    color: '#0089e2',
    backgroundColor: '#e5f0fa',
    fontWeight: '500',
    textAlign: 'center'
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    height: 90,
    '&:hover': {
      cursor: 'pointer'
    },
    width: '100%'
  },
  containerBox: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: 16
  }
});

export default styles;
