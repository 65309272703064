import { IconButton, Slide, Typography, withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import styles from './styles';
import { FormattedMessage } from 'react-intl';
import { handleLocalStorage, onAppDownloadNowClick } from '../../utils/common';
import useConfigContext from '../../utils/useConfigContext';
import { useLocation } from 'react-router-dom';

const LOCAL_STORAGE_KEY = 'mobileNudge';

function MobileNudge({ classes }) {
  const location = useLocation();
  const showMobileBanner = useConfigContext('showMobileBanner') === true;

  const [visible, setVisible] = useState(() => {
    if (!showMobileBanner) {
      return false;
    }
    const data = handleLocalStorage.get(LOCAL_STORAGE_KEY);
    if (data) {
      const { lastNudge } = JSON.parse(data);
      const now = new Date().getTime();
      const diff = now - lastNudge;
      const diffInHours = diff / (1000 * 60 * 60);
      if (diffInHours < 48) {
        return false;
      }
    }
    return true;
  });

  const onCloseClick = () => {
    setVisible(false);
    const data = { lastNudge: new Date().getTime() };
    handleLocalStorage.set(LOCAL_STORAGE_KEY, JSON.stringify(data));
  };
  const isPageWeeklyQuests = location.pathname.includes('weekly-quests');
  return (
    <Slide
      direction="left"
      in={visible}
      timeout={400}
      mountOnEnter
      unmountOnExit
    >
      <div className={classes.container}>
        <img
          src="/assets/images/mobile/app-icon.png"
          alt="customer spark app icon"
          className={classes.appIcon}
        />
        <div className={classes.textContainer}>
          <Typography className={classes.nudgeTextTitle}>
            <FormattedMessage
              id={
                isPageWeeklyQuests
                  ? 'WEEKLY_QUESTS_NUDGE_TITLE'
                  : 'HOME_MOBILE_NUDGE_TITLE'
              }
            />
          </Typography>
          <div>
            <button
              className={classes.downloadTextBtn}
              onClick={onAppDownloadNowClick}
            >
              <FormattedMessage id="HOME_MOBILE_NUDGE_DOWNLOAD" />
            </button>
            <FormattedMessage id="HOME_MOBILE_NUDGE_OUR_MOBILE_APP" />
          </div>
        </div>
        <div>
          <IconButton
            onClick={onCloseClick}
            data-testid="close-icon"
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
    </Slide>
  );
}

export default withStyles(styles)(MobileNudge);
