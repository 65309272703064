import React from 'react';
import MicroSurveyActivityCard from './MicroSurveyActivityCard';
import styles from './styles';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import Logo from '../../../Logo';
import { RoundedFilledButton } from '../../../StyledButtons';
import { weeklyQuestIcons } from '../../utils';
import { Link, useLocation } from 'react-router-dom';
import ShareFeedback from './ShareFeedback';

const MicroSurveyActivitiesGrid = ({ activities, feedbackSurvey }) => {
  const { state: locationState } = useLocation();
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const showList =
    locationState === 'previousScreenWasWeeklyQuest' ||
    activities.find((x) => x.status !== 'Completed');

  // has feedback survey and no open activities
  const showFeedback =
    Boolean(feedbackSurvey) &&
    activities?.filter((x) => x.status !== 'Completed')?.length === 0;

  if (showFeedback) {
    return <ShareFeedback feedbackSurvey={feedbackSurvey} />;
  }

  if (showList) {
    return (
      <Grid
        container
        className={classes.activityGridContainer}
        data-testid="MicroSurvey-Activities-Container"
      >
        {activities.map((activity, index) => (
          <Grid item key={activity.shortId} className={classes.activityCard}>
            <MicroSurveyActivityCard
              {...activity}
              imageType={weeklyQuestIcons[index % weeklyQuestIcons.length]}
              isCompleted={activity.status === 'Completed'}
            />
          </Grid>
        ))}
        {!activities.find((x) => x.status !== 'Completed') && (
          <Grid container className={classes.trophyInListView}>
            <Logo type="trophy" />
            <Typography className={`${classes.noActivitiesTitle}`}>
              Congratulations
            </Typography>
            <Typography
              className={`${classes.noActivitiesTitle} ${classes.zeroMarginTop}`}
            >
              you completed your Quest!
            </Typography>
          </Grid>
        )}
      </Grid>
    );
  } else {
    return (
      <Grid container className={classes.noActivitiesWrapper}>
        <Logo type="trophy" />
        <Typography className={classes.noActivitiesTitle}>
          Congratulations!
        </Typography>
        <Typography className={classes.noActivitiesBody}>
          You completed your Quests for this week! Come back on Monday for more
          fun activities!
        </Typography>
        <Grid className={classes.okButtonWrapper}>
          <RoundedFilledButton component={Link} label="Ok" to={'/activities'} />
        </Grid>
      </Grid>
    );
  }
};

export default MicroSurveyActivitiesGrid;
