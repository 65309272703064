import {
  Grid,
  IconButton,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Accordian from '../../components/Accordian';
import { EditButton, CancelButton } from '../../components/Buttons/index.jsx';
import PropTypes from 'prop-types';
import { InfoOutlined, LockOutlined } from '@material-ui/icons';
import ActivityFrequencyDetails from './ActivityFrequencyDetails.jsx';
import ActivityPreferenceSwitch from './ActivityPreferenceSwitch.jsx';
import ProfileDetailEditor from './ProfileDetailEditor.jsx';
import styles from './styles';
import {
  constructFullStateStr,
  convertToAddrObj,
  findState,
  generateUnlockIconMessage
} from './utils';
import {
  monthNames,
  APIConstants,
  addressProperties,
  EmptyAddress
} from './constanst';
import { useHistory } from 'react-router-dom';
import ProfileIcon from './ProfileIcons';

const ProfileAccordian = (props) => {
  const {
    profileDetailsData,
    detailTitle,
    displayValues,
    updateProfileState,
    profileType,
    activityFeqData,
    saveActivityFrequencyData,
    isProfileDataLoading,
    isActivityDataLoading,
    accordiansExpanded,
    onAccordianExpansionToggle
  } = props;

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const history = useHistory();

  const [currentlyEditing, setCurrentlyEditing] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);

  useEffect(() => {
    if (isProfileDataLoading === false) {
      setSaveLoading(false);
      setCurrentlyEditing([]);
    }
  }, [isProfileDataLoading]);

  const saveDetailData = (newValue, detailName) => {
    setSaveLoading(true);
    updateProfileState(newValue, detailName, profileType);
  };

  const displayEditButton = (detail, valuesToDisplay) => {
    const editHandler = () => {
      if (detail.detailName === 'ap_email') {
        history.push('/profile/change-email');
      } else if (detail.detailName === 'ap_password') {
        history.push('/profile/change-password');
      } else {
        setCurrentlyEditing([detail.detailName]);
      }
    };

    const editTestId = `edit-${detail.detailName}`;

    if (detail.selectionType === 'boolean') {
      return (
        <ActivityPreferenceSwitch
          checked={valuesToDisplay[detail.detailName]}
          detailObj={detail}
          label={detail.displayName}
          onSwitch={saveDetailData}
          values={valuesToDisplay}
        />
      );
    } else if (!detail.editable) {
      return (
        <Tooltip title={generateUnlockIconMessage(detail)} placement="top">
          <IconButton size="small" className={classes.toolTipButton}>
            <LockOutlined />
          </IconButton>
        </Tooltip>
      );
    } else {
      return <EditButton onClickHandler={editHandler} testId={editTestId} ariaLabel={`Edit ${detail.displayName}`}/>;
    }
  };

  const displayAddress = (addressDetails) => {
    const addressDetailObj = convertToAddrObj(addressDetails);
    const streetLine1 = addressDetailObj[addressProperties.ADDRESS_LINE1];
    const city = addressDetailObj[addressProperties.CITY] ?? '';
    const state =
      constructFullStateStr(
        findState(addressDetailObj[addressProperties.STATE])
      ) !== ' ()'
        ? constructFullStateStr(
            findState(addressDetailObj[addressProperties.STATE])
          )
        : '';

    let streetLine2 = addressDetailObj[addressProperties.ADDRESS_LINE2] ?? '';
    if (streetLine2 !== '') {
      streetLine2 = `, ${streetLine2}`;
    }
    if (streetLine1 === null) {
      return <Typography>{EmptyAddress}</Typography>;
    } else {
      return getAddressText([
        `${streetLine1}${streetLine2}`,
        `${city}${state !== '' && city !== '' ? ', ' + state : state}`,
        addressDetailObj[addressProperties.ZIP]
      ]);
    }
  };

  const getAddressText = (addrTextArr) => {
    return addrTextArr.map((line) => {
      return <Typography>{line}</Typography>;
    });
  };

  const onAccordianBtnClick = (event, expanded) => {
    onAccordianExpansionToggle({
      event: event,
      expanded: expanded,
      profileType: detailTitle
    });
  };

  const accordianDetailData = (
    <Grid container className={classes.featureContainer}>
      {profileType === 'activitypreference' && (
        <ActivityFrequencyDetails
          activityData={activityFeqData}
          saveActFreq={saveActivityFrequencyData}
          isActivityDataLoading={isActivityDataLoading}
        />
      )}
      {profileDetailsData?.map((detail) => {
        const cancelTestId = `cancel-${detail.detailName}`;
        const cancelEdit = () => {
          setCurrentlyEditing((prevState) =>
            prevState.filter((element) => element !== detail.detailName)
          );
        };

        let dobYear;
        let dobMonth;

        if (detail.detailName === APIConstants.detailNames.DOB) {
          const dob = new Date(displayValues[detail.detailName]);
          dobYear = dob.getFullYear();
          dobMonth = monthNames[dob.getUTCMonth()];
        }

        return (
          <Grid
            container
            key={detail.detailName}
            className={classes.detailWrapper}
          >
            <Grid container direction="row" alignItems="center">
              <Grid item xs={1}>
                <ProfileIcon
                  iconStyle={classes.icon}
                  detailName={detail.detailName}
                />
              </Grid>
              <Grid item className={classes.profileH3Name}>
                {detail.displayName}
                {detail?.info && (
                  <Tooltip title={detail?.info} placement="top">
                    <IconButton size="small" className={classes.toolTipButton}>
                      <InfoOutlined className={classes.toolTipIcon} />
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
              <Grid item container xs={4} direction="row-reverse">
                {currentlyEditing.includes(detail.detailName) ? (
                  <CancelButton
                    onButtonClick={cancelEdit}
                    testId={cancelTestId}
                  />
                ) : (
                  displayEditButton(detail, displayValues)
                )}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={1} />
              {!currentlyEditing.includes(detail.detailName) ? (
                <Grid item xs={10} className={classes.profileH3Value}>
                  {detail.detailName === APIConstants.detailNames.address
                    ? displayAddress(displayValues[detail.detailName][0] ?? '')
                    : Array.isArray(displayValues[detail.detailName])
                    ? displayValues[detail.detailName].join(', ')
                    : detail.detailName === APIConstants.detailNames.DOB
                    ? `${dobMonth} ${dobYear}`
                    : displayValues[detail.detailName]}
                </Grid>
              ) : (
                <Grid item xs={10}>
                  {
                    <ProfileDetailEditor
                      detailObj={detail}
                      onSave={saveDetailData}
                      displayValues={displayValues}
                      isSaveLoading={saveLoading}
                    />
                  }
                </Grid>
              )}
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );

  return (
    <Accordian
      accordianTitle={detailTitle}
      accordianDetails={accordianDetailData}
      defaultExpanded={accordiansExpanded}
      onAccordianBtnClick={onAccordianBtnClick}
    />
  );
};

ProfileAccordian.propTypes = {
  profileDetailsData: PropTypes.array,
  detailTitle: PropTypes.string,
  displayValues: PropTypes.object,
  profileType: PropTypes.string,
  updateProfileState: PropTypes.func,
  activityFeqData: PropTypes.object,
  saveActivityFrequencyData: PropTypes.func,
  isProfileDataLoading: PropTypes.bool,
  isActivityDataLoading: PropTypes.bool,
  accordiansExpanded: PropTypes.bool,
  onAccordianExpansionToggle: PropTypes.func
};

export default ProfileAccordian;
