import Cookies from 'js-cookie';
import {
  REGEXP_PASSWORD,
  REGEXP_OTP,
  REGEXP_EMAILID,
  APP_STORE_LINK
} from '../constants/common';

export const isEmptyString = (str) => {
  return !str || 0 === str.length;
};

export const isValidArray = (arr) => {
  return arr && Array.isArray(arr) && arr.length > 0;
};

export const isValidPassword = (pword) => {
  return REGEXP_PASSWORD.test(pword);
};

export const isValidOtp = (otpToken) => {
  return otpToken && REGEXP_OTP.test(otpToken);
};

export const isValidEmailId = (emailId) => {
  return emailId && !isEmptyString(emailId) && REGEXP_EMAILID.test(emailId);
};

export const maskEmailId = (emailId) => {
  if (isValidEmailId(emailId)) {
    const emailParts = emailId.split('@');
    return emailParts[0].substr(0, 3) + '•••@' + emailParts[1];
  }
  return '';
};

export const getQueryStringValue = (location, key) => {
  const params = new URLSearchParams(location.search);
  return params.get(key);
};

export const getDotLocation = (track) => {
  let x, y;
  x = 40;
  y = 235;

  switch (track) {
    case 15:
      x -= 35;
      y -= 120;
      break;
    case 30:
      x += 40;
      y -= 220;
      break;
    case 45:
      x += 160;
      y -= 220;
      break;
    case 60:
      x += 235;
      y -= 120;
      break;
    case 75:
      x += 198;
      break;
    default:
      return { dotX: x, dotY: y };
  }
  return { dotX: x, dotY: y };
};

export const decodeValue = (value) => {
  try {
    return atob(value);
  } catch {
    return '';
  }
};

export const setCookie = (name, value, days) => {
  var d = new Date();
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
  document.cookie = name + '=' + value + ';path=/;expires=' + d.toGMTString();
};

export const getCookie = (name) => {
  var b = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
  return b ? b.pop() : '';
};

export const deleteCookie = (name) => setCookie(name, '', -1);

export const handleSessionStorage = {
  get: (key) => sessionStorage.getItem(key),
  set: (key, value) => sessionStorage.setItem(key, value),
  remove: (key) => sessionStorage.removeItem(key)
};

export const handleLocalStorage = {
  get: (key) => localStorage.getItem(key),
  set: (key, value) => localStorage.setItem(key, value)
};

export const getJsCookie = (key) => {
  return Cookies.get(key);
};

export const setJsCookie = (key, value) => {
  return Cookies.set(key, value, { expires: 1 });
};

export const removeJsCookie = (key) => {
  return Cookies.remove(key);
};

/**
 * A function to return the OS name of the device
 * @returns {string} - returns the OS name - android, ios, other
 */
export const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent;
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'other';
  }
  if (/android/i.test(userAgent)) {
    return 'android';
  }
  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'ios';
  }
  return 'other';
};

export const onAppDownloadNowClick = () => {
  const { android: playStoreLink, ios: appStoreLink } = APP_STORE_LINK;
  const os = getMobileOperatingSystem();
  switch (os) {
    case 'ios':
      window.open(appStoreLink, '_blank');
      break;
    case 'android':
      window.open(playStoreLink, '_blank');
      break;
    // since we don't know the OS, we open the play store link
    default:
      window.open(playStoreLink, '_blank');
      break;
  }
};

// Function to append the Required Question Asterisk to end of the question - Copied from survey-taker-ui
export const modifyRequiredQuestionHTMLString = (
  prefixString,
  question_title_str,
  requiredCondition,
  requiredQuestionAsterisk,
  suffixString
) => {
  // Regex to find closing tags in the html string of the question
  const closing_html_tag = question_title_str.match(/<\/.+?>/g);
  // Last closing HTML Tag in the question string
  const last_html_tag = closing_html_tag?.[closing_html_tag.length - 1];

  // Varaibles to hold the modified string, closing tag to match, modified tag with the required question asterisk
  let final_modified_str, pos_str, modified;

  // String to replace the final closing tag
  const reqStar = requiredCondition
    ? `<sup class=${requiredQuestionAsterisk}> *</sup>`
    : ``;

  // Switch case - specific for the tags
  switch (last_html_tag) {
    case '</ol>':
    case '</ul>':
      // Last Index for the List Item
      pos_str = question_title_str.lastIndexOf('</li>');
      // String to replace for the required question scenario
      modified = reqStar + '</li>';
      // Replace the value to include the required question star - second part index for substring is 5 since there are 5 characters in </li> tag
      final_modified_str =
        question_title_str.substring(0, pos_str) +
        modified +
        question_title_str.substring(pos_str + 5);
      break;
    case '</p>':
      // Last Index for the Paragraph Item
      pos_str = question_title_str.lastIndexOf('</p>');
      // String to replace for the required question scenario
      modified = reqStar + suffixString + '</p>';
      // Replace the value to include the required question star - second part index for substring is 4 since there are 4 characters in </p> tag
      final_modified_str =
        '<p>' +
        prefixString +
        question_title_str.substring(3, pos_str) +
        modified +
        question_title_str.substring(pos_str + 4);
      break;
    default:
      //this will be used by media type question
      // its title is not richtext and therfore doesn't have any html tag
      final_modified_str = question_title_str;
      break;
  }

  // Returning the Final Modified HTML String
  return final_modified_str;
};
