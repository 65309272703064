import React, { useState } from 'react';
import api from '../../../constants/api';
import API, { API_STATUS_CODE } from '../../../constants/common';
import apiCall from '../../../utils/api';
import ChangePasswordForm from './ChangePasswordForm';
import SnackBanner from '../../../components/SnackBanner';
import { useHistory } from 'react-router-dom';

const ChangeProfilePassword = () => {
  const [formValues, setFormValues] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  const [snackBanner, setSnackBanner] = useState({
    displaySnackBanner: false,
    snackBannerMessage: '',
    isErrorSnack: false
  });

  const history = useHistory();

  const openErrorSnackBanner = (message) => {
    setSnackBanner({
      displaySnackBanner: true,
      snackBannerMessage: message,
      isErrorSnack: true
    });
  };

  const handleSnackBannerClose = () => {
    setSnackBanner({
      displaySnackBanner: false,
      snackBannerMessage: ''
    });
  };

  const onFormValueChange = (event) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  };

  const onFormSubmit = () => {
    const submitPayload = {
      oldPassword: formValues.currentPassword,
      newPassword: formValues.newPassword
    };
    apiCall(
      `${api.profileUpdatePassword}`,
      API.METHOD.POST,
      submitPayload,
      false
    ).then((response) => {
      if (response?.error) {
        openErrorSnackBanner(
          response?.error?.message || response?.error?.error
        );
      }

      if (response.status === API_STATUS_CODE.SUCCESS) {
        history.push('/login', { prevPath: 'passwordUpdate' });
      }
    });
  };

  return (
    <>
      <SnackBanner
        isSnackbarOpen={snackBanner.displaySnackBanner}
        snackbarMessage={snackBanner.snackBannerMessage}
        onSnackBarClose={handleSnackBannerClose}
        errorMessage={snackBanner.isErrorSnack}
      />
      <ChangePasswordForm
        formValues={formValues}
        onFormChange={onFormValueChange}
        onFormSubmit={onFormSubmit}
      />
    </>
  );
};

export default ChangeProfilePassword;
