import React from 'react';
import PropTypes from 'prop-types';
import { Fade, withStyles } from '@material-ui/core';
import Logo from '../Logo';

import styles from './styles';
import { FormattedMessage } from 'react-intl';

const ListNoResult = ({ classes }) => (
  <Fade in timeout={1000}>
    <div className={classes.noResultWrapper}>
      <Logo type="resultsNotFound" />
      <p className={classes.title} data-testid="no-results">
        <FormattedMessage id="NO_RESULTS" defaultMessage="No results found" />
      </p>
    </div>
  </Fade>
);

ListNoResult.defaultProps = {
  classes: {}
};

ListNoResult.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(ListNoResult);
