import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import OnboardHeader from '../../components/OnboardHeader';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import analytics from '../../utils/analytics';
import api from '../../constants/api';
import apiCall from '../../utils/api';
import styles from './styles';
import { FormattedMessage } from 'react-intl';
import { PAGES } from '../../constants/analytics';
import { RemoveRedEye } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Grid,
  InputAdornment,
  LinearProgress,
  Link,
  TextField,
  Snackbar,
  withStyles,
  IconButton
} from '@material-ui/core';
import API, {
  API_STATUS_CODE,
  AUTO_HIDE_DURATION,
  EMAIL_FIELD_LENGTH,
  ERR_MESSAGES,
  TOAST_MSGS,
  EMAIL_UPDATE_SNACK_MSG,
  PASSWORD_UPDATE_SNACK_MSG,
  PASSWORD_UPDATED_MESSAGE,
  PROFILE_REVIEW_LOGIN_SNACK
} from '../../constants/common';
import {
  decodeValue,
  getQueryStringValue,
  handleSessionStorage,
  isValidEmailId,
  isValidPassword
} from '../../utils/common';
import HomeFooter from '../../components/Footer/HomeFooter';
import MobileDownloadBanner from './MobileDownloadBanner';
import { logger } from '../../utils/logger';

const SignIn = ({ classes }) => {
  const history = useHistory();
  const [emailId, setEmailId] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [isError, setIsError] = useState({
    apiError: false,
    email: false,
    pword: false
  });
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [pword, setPword] = useState('');
  const [pwdIsMasked, setPwdIsMasked] = useState(true);
  const [progress, setProgress] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    const extractedEmailId =
      getQueryStringValue(history.location, 'invite') || '';
    const decodedEmailId = decodeValue(extractedEmailId);
    setEmailId(decodedEmailId);
  }, [history.location]);

  useEffect(() => {
    analytics.trackPageView(PAGES.SIGN_IN);
  }, []);

  useEffect(() => {
    if (history.location?.state?.prevPath === 'emailUpdate') {
      setSnackbarMessage(EMAIL_UPDATE_SNACK_MSG);
      setIsSnackbarOpen(true);
    }
    if (history.location?.state?.prevPath === 'passwordUpdate') {
      setSnackbarMessage(PASSWORD_UPDATE_SNACK_MSG);
      setIsSnackbarOpen(true);
    }

    let alternateActivityData;

    try {
      alternateActivityData = JSON.parse(
        handleSessionStorage.get('alternateActivity')
      );
    } catch (error) {
      alternateActivityData = { taskId: -1 };
      logger.error('Failed to parse alternateActivity', {
        error,
        alternateActivityData: handleSessionStorage.get('alternateActivity'),
        page: 'signIn'
      });
    }

    const taskId = alternateActivityData?.taskId;

    const acceptedProfileReview = handleSessionStorage.get(
      'acceptedProfileReview'
    );

    const userNotLoggedInOnce = !localStorage.getItem('token');

    if (
      taskId !== -1 &&
      acceptedProfileReview === 'false' &&
      userNotLoggedInOnce
    ) {
      setSnackbarMessage(PROFILE_REVIEW_LOGIN_SNACK);
      setIsSnackbarOpen(true);
    }
  }, []);

  const signInCall = (event) => {
    event.preventDefault();

    analytics.trackClick(PAGES.SIGN_IN, 'signIn');

    setProgress(true);
    const payload = {
      userId: emailId,
      password: pword
    };

    apiCall(api.signIn, API.METHOD.POST, payload, true).then((response) => {
      setProgress(false);
      if (response?.error) {
        logger.error('login failed', { email: payload.userId });
        setIsError({ ...isError, apiError: true });
        if (response.error.message) {
          setErrorMsg(response.error.message);
        } else {
          if (response.error.status === API_STATUS_CODE.UNAUTHORIZED) {
            setErrorMsg(
              <FormattedMessage
                defaultMessage={ERR_MESSAGES.AUTH_DEFAULT}
                id="AUTH_DEFAULT"
              />
            );
          } else {
            setErrorMsg(
              <FormattedMessage
                defaultMessage={TOAST_MSGS.generic_error}
                id="GENERIC_ERROR"
              />
            );
          }
        }
      }

      let alternateActivityData;

      try {
        alternateActivityData = JSON.parse(
          handleSessionStorage.get('alternateActivity')
        );
      } catch (error) {
        alternateActivityData = { taskId: -1 };
        logger.error('Failed to parse alternateActivity', {
          signIn: true,
          error,
          alternateActivityData: handleSessionStorage.get('alternateActivity'),
          page: 'signIn'
        });
      }
      const acceptedProfileReview = handleSessionStorage.get(
        'acceptedProfileReview'
      );

      const taskId = alternateActivityData?.taskId;

      if (response?.status === API_STATUS_CODE.SUCCESS) {
        logger.info('login success', { email: payload.userId });
        localStorage.setItem('platform', 'web');
        localStorage.setItem('token', response.data?.authToken);
        localStorage.setItem('refreshToken', response.data?.refreshToken);
        localStorage.setItem('panelistId', response.data?.panelistId);
        localStorage.setItem('emailId', emailId);
        logger.setUserInfo({
          userId: response.data?.panelistId ?? 'unset'
        });

        if (history.location.state === 'termsUseEvent') {
          history.push('/terms-of-use', 'postSignIn');
        } else if (history.location.state === 'unsubEvent') {
          history.push('/unsubscribe', 'postSignIn');
        } else if (history.location.state === 'privacyPolicy') {
          history.push('/privacy-policy', 'postSignIn');
        } else if (history.location.state === 'faqEvent') {
          history.push('/faq', 'postSignIn');
        } else if (history.location?.state?.prevPath === 'passwordUpdate') {
          history.push('/profile', {
            prevPath: 'signIn',
            action: 'openSnack',
            snackMessage: PASSWORD_UPDATED_MESSAGE
          });
        } else if (taskId !== -1 && acceptedProfileReview === 'false') {
          history.push('/profile');
        } else {
          history.push('/home', 'signIn');
        }
      }
    });
  };

  const forgotPassword = () => {
    analytics.trackClick(PAGES.SIGN_IN, 'forgotPassword');
    history.push(
      `/forgot-password${
        isValidEmailId(emailId)
          ? `?invite=${btoa(emailId)}&isforgotpasswordflow=true`
          : '?isforgotpasswordflow=true'
      }`
    );
  };

  const togglePasswordMask = () => {
    setPwdIsMasked(!pwdIsMasked);
  };

  const handleEmailChange = (e) => {
    if (e.currentTarget.value.includes(' ')) {
      e.currentTarget.value = e.currentTarget.value.replace(/\s/g, '');
    }
    setEmailId(e.target.value?.toLowerCase());
    setIsError({ ...isError, email: false });
  };

  const handleErrorMessage = (isErrorContent, validationFunc, value) => {
    if (!validationFunc(value)) {
      setIsError(isErrorContent);
      setErrorMsg(
        <FormattedMessage
          id="VALID_EMAIL_PASSWORD"
          defaultMessage={TOAST_MSGS.valid_email_password}
        />
      );
    }
  };

  const handlePwordChange = (e) => {
    setPword(e.target.value);
    setIsError({ ...isError, pword: false });
  };

  const handleKeyDown = (e) => {
    e.key === ' ' && e.preventDefault();
  };

  return (
    <Grid className={classes.container}>
      <title>
        <FormattedMessage
          id="SIGNIN_TITLE"
          defaultMessage="Walmart - Customer Spark: Sign In"
        />
      </title>
      <Grid className={classes.formHolder}>
        {progress && (
          <div className={classes.progress}>
            <LinearProgress />
          </div>
        )}
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={isSnackbarOpen}
          message={snackbarMessage}
          autoHideDuration={AUTO_HIDE_DURATION}
          onClose={() => setIsSnackbarOpen(false)}
          ContentProps={{
            classes: {
              root: classes.snackbarRoot
            }
          }}
        />
        <OnboardHeader
          headerText={
            <FormattedMessage
              id="SIGNIN_WELCOME_1"
              defaultMessage="Welcome to the Walmart Customer Spark Community!"
            />
          }
          headerContent={
            <FormattedMessage
              id="SIGNIN_WELCOME_2"
              defaultMessage="We offer an engaging experience and an opportunity to help define the future of Walmart."
            />
          }
        />
        <form
          className={classes.form}
          noValidate
          autoComplete="off"
          onSubmit={(event) => signInCall(event)}
        >
          <TextField
            id="email"
            className={classes.textField}
            value={emailId}
            error={isError.email}
            variant="outlined"
            label={<FormattedMessage id="EMAIL" defaultMessage="Email" />}
            onChange={handleEmailChange}
            onBlur={() => {
              if (emailId || (!emailId && pword)) {
                handleErrorMessage(
                  { ...isError, email: true },
                  isValidEmailId,
                  emailId.toLowerCase()
                );
              }
            }}
            onKeyDown={handleKeyDown}
            inputProps={{
              maxLength: EMAIL_FIELD_LENGTH
            }}
            data-testid="email"
          />
          <div className={classes.pwdWrapper}>
            <TextField
              id="pword"
              type={pwdIsMasked ? 'password' : 'input'}
              error={isError.pword}
              value={pword}
              className={classes.textField}
              variant="outlined"
              label={
                <FormattedMessage id="PASSWORD" defaultMessage="Password" />
              }
              autoComplete="new-password"
              onChange={handlePwordChange}
              onBlur={() => {
                if ((emailId && !pword) || pword) {
                  handleErrorMessage(
                    { ...isError, pword: true },
                    isValidPassword,
                    pword
                  );
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {pwdIsMasked ? (
                      <IconButton
                        onClick={togglePasswordMask}
                        size="small"
                        data-testid="show-password"
                        aria-label="show password"
                      >
                        <RemoveRedEye className={classes.eye} />
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={togglePasswordMask}
                        size="small"
                        data-testid="hide-password"
                        className={classes.eye}
                        aria-label="hide password"
                      >
                        <VisibilityOffIcon className={classes.eye} />
                      </IconButton>
                    )}
                  </InputAdornment>
                )
              }}
              FormHelperTextProps={{
                classes: { root: classes.helperText },
                error: isError.email || isError.pword
              }}
              data-testid="pword"
            />
            {(isError.apiError || isError.email || isError.pword) && (
              <p className={classes.helperText} role="alert">
                {errorMsg}
              </p>
            )}
          </div>
          <div className={classes.forgotPwdWrapper}>
            <Link
              id="forgot-password"
              tabIndex="0"
              className={classes.forgotPassword}
              onClick={forgotPassword}
              onKeyPress={forgotPassword}
              data-testid="forgotPwd"
            >
              <FormattedMessage
                id="FORGOT_PASSWORD"
                defaultMessage="Forgot Password?"
              />
            </Link>
          </div>
          <div className={classes.buttonHolder}>
            <Button
              id="login"
              variant="contained"
              className={classes.button}
              color="primary"
              type="submit"
              value="Post"
              disabled={
                !isValidEmailId(emailId) ||
                !isValidPassword(pword) ||
                progress ||
                isError.email ||
                isError.pword
              }
            >
              <FormattedMessage id="LOGIN" defaultMessage="Login" />
            </Button>
          </div>
        </form>
      </Grid>
      <MobileDownloadBanner />
      <Grid item>
        <HomeFooter type="landing" />
      </Grid>
    </Grid>
  );
};

SignIn.defaultProps = {
  classes: {}
};
SignIn.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(SignIn);
