import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';

const CircularLoader = (props) => {
  const { size } = props;

  const sizeMapping = {
    small: { size: 20, thickness: 5 },
    medium: { size: 50, thickness: 5 },
    large: { size: 100, thickness: 5 }
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <CircularProgress {...sizeMapping[size]} />
    </Box>
  );
};

CircularLoader.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']).isRequired
};

export default CircularLoader;
