const getCurrentEnv = (isApiProxyCall = false) => {
  const env = 'prod';
  const isServer = !(typeof window !== 'undefined' && window.document);

  if (!isServer) {
    const { host } = window.location;

    if (
      host.includes('dev.') ||
      host.includes('localhost') ||
      host.includes('.k8s.us.walmart.net')
    ) {
      return 'dev';
    }

    if (host.includes('stage.') && isApiProxyCall) {
      return 'stg';
    } else if (host.includes('stage.')) {
      return 'stage';
    }
  }
  return env;
};

export default getCurrentEnv;
