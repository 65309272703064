const styles = (theme) => ({
  rootContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  mainContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '70%',
    [theme.breakpoints.down('md')]: {
      width: '80%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '60%'
    },
    [theme.breakpoints.down('xs')]: {
      width: '90%'
    }
  },
  formContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  passwordCheckContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    width: '80%',
    height: '100%',
    alignItems: 'flex-end',
    marginLeft: '10%'
  },
  passwordCheckItem: {
    marginTop: 'auto',
    marginBottom: '20%'
  },
  header: {
    marginTop: 20,
    marginBottom: 20
  },
  headerText: {
    fontSize: 32,
    fontWeight: 400,
    letterSpacing: 0,
    textAlign: 'center'
  },
  subheader: {
    marginTop: 20,
    marginBottom: 20
  },
  subHeaderText: {
    fontSize: 20,
    fontWeight: 400,
    letterSpacing: 0,
    textAlign: 'center'
  },
  textBoxItem: {
    marginBottom: 16,
    marginTop: 16
  },
  textFieldError: {
    marginBottom: 17
  },
  textFieldErrorMsg: {
    color: 'rgba(213, 34, 28, 1)',
    fontSize: 12,
    fontWeight: 400,
    textAlign: 'left'
  },
  errorIcon: {
    color: 'rgba(213, 34, 28, 1)',
    height: 20,
    width: 20,
    marginRight: 5
  },
  submitButtonContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  submitBtnInnerContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%'
  },
  submitButtonItem: {
    marginBottom: 24,
    marginTop: 24
  },
  submitButton: {
    height: 40,
    width: '50%',
    borderRadius: 4
  },
  submitButtonText: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0,
    textAlign: 'center'
  },
  passwordValidationItem: {},
  passwordInstructionsHeadingText: {
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: 0,
    textAlign: 'left',
    marginBottom: 8
  }
});

export default styles;
