const styles = (theme) => ({
  submitButton: {
    textTransform: 'none'
  },
  disabledSubmitButton: {
    color: '#FFFFFF',
    backgroundColor: '#BABBBE'
  }
});

export default styles;
