import React from 'react';
import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
  makeStyles
} from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(({ breakpoints }) => ({
  squareCard: {
    width: 156,
    height: 128,
    boxShadow: '0px 1px 2px 1px #00000026'
  },
  actionArea: {
    height: '100%',
    width: '100%'
  },
  selected: {
    backgroundColor: '#E6F1FC',
    color: '#004F9A'
  },
  wideRectangularCard: {
    width: 328,
    height: 96,
    boxShadow: '0px 1px 2px 1px #00000026',
    [breakpoints.up('sm')]: {
      width: 444
    }
  },
  cardContent: {
    height: '100%',
    width: '100%'
  }
}));

export const SquareCard = (props) => {
  const classes = useStyles();

  const { cardText, textStyling, selected, onSelect } = props;

  return (
    <Card
      className={clsx(classes.squareCard, selected && classes.selected)}
      onClick={onSelect}
    >
      <CardActionArea className={classes.actionArea}>
        <CardContent>
          <Typography className={textStyling}>{cardText}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export const WideRectangularCard = (props) => {
  const classes = useStyles();

  const { cardText, textStyling, selected, onSelect } = props;
  return (
    <Card
      className={clsx(
        classes.wideRectangularCard,
        selected && classes.selected
      )}
      onClick={onSelect}
    >
      <CardActionArea className={classes.actionArea}>
        <CardContent>
          <Typography className={textStyling}>{cardText}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
