import React from 'react';
import Logo from '../Logo';
import { FormattedMessage } from 'react-intl';
import { makeStyles, Grid } from '@material-ui/core';
import styles from './styles';

const EmptyCart = props => {
    const { id="EMPTY_CART", message="No items to display", logoType } = props;

    const useStyles = makeStyles(styles);
    const classes = useStyles();


    return (
        <Grid container className={classes.emptyCartWrapper}>
          <p>
            <FormattedMessage
              id= {id}
              defaultMessage={message}
            />
          </p>
          { logoType && <Logo type={logoType} />}
        </Grid>
    )
}

export default EmptyCart;