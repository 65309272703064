import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Link,
  Radio,
  RadioGroup,
  Typography,
  makeStyles
} from '@material-ui/core';
import React, { useState } from 'react';
import styles from './styles';
import Logo from '../../Logo';
import PropTypes from 'prop-types';
import SubmitButton from '../../SubmitButton';
import {
  HEADER_LOGO_TYPE,
  TITLE,
  MAIN_MESSAGE,
  MESSAGE_SIGN,
  FORM_LABEL,
  LINK_HEADERS
} from './constants';

const CCPADialog = (props) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const { fullScreen, open, handleClose, handleCCPASubmit } = props;

  const [agreeTOU, setAgreeTOU] = useState(true);

  const width = 'lg';

  const TERMS_OF_USE_LINK = props?.termsOfUseURL;
  const PRIVACY_NOTICE_LINK = props?.privacyNoticeURL;
  const FINANCIAL_INCENTIVE_NOTICE_SUFFIX = 'Notice';
  const NOTICE_OF_FINANCIAL_INCENTIVE_LINK = `${PRIVACY_NOTICE_LINK}#${FINANCIAL_INCENTIVE_NOTICE_SUFFIX}`;

  const TEXT1_LINK1 = (
    <Link underline="always" href={TERMS_OF_USE_LINK} target="_blank">
      {LINK_HEADERS.TERMS_CONDITIONS}
    </Link>
  );
  const TEXT1_LINK2 = (
    <Link underline="always" href={PRIVACY_NOTICE_LINK} target="_blank">
      {LINK_HEADERS.PRIVACY_NOTICE}
    </Link>
  );
  const TEXT1_LINK3 = (
    <Link
      underline="always"
      href={NOTICE_OF_FINANCIAL_INCENTIVE_LINK}
      target="_blank"
    >
      {LINK_HEADERS.NOTICE_FINANCIAL_INCENTIVE}
    </Link>
  );

  const TEXT2_LINK1 = (
    <Link underline="always" href={TERMS_OF_USE_LINK} target="_blank">
      {LINK_HEADERS.TERMS_CONDITIONS}
    </Link>
  );

  const TEXT2_LINK2 = (
    <Link underline="always" href={TERMS_OF_USE_LINK} target="_blank">
      {LINK_HEADERS.CS_REWARD_PROGRAM}
    </Link>
  );

  const TEXT_1 = (
    <Typography className={classes.radioLabelText}>
      I have read and agree to the {TEXT1_LINK1} of the Walmart Customer Spark
      Community Rewards Program, the updated Walmart Customer Spark Community{' '}
      {TEXT1_LINK2}, and the {TEXT1_LINK3}{' '}
    </Typography>
  );

  const TEXT_2 = (
    <Typography className={classes.radioLabelText}>
      I do not agree, and understand that I will be unsubscribed and removed
      from, and will no longer be able to participate in or earn additional
      points from, the {TEXT2_LINK1}. However, you will receive a credit for any
      unused points in your account pursuant to the {TEXT2_LINK2} of the Walmart
      Customer Spark Community Rewards Program.
    </Typography>
  );

  const FORM_DATA = {
    RADIO_OPTION_1: {
      TEXT: TEXT_1,
      VALUE: 'option1'
    },
    RADIO_OPTION_2: {
      TEXT: TEXT_2,
      VALUE: 'option2'
    }
  };
  const CONTINUE_BTN_LABEL = 'Continue';

  const onRadioOptionChange = (event, value) => {
    setAgreeTOU((prevState) => !prevState);
  };

  const onSubmitHandler = () => {
    handleCCPASubmit(agreeTOU);
  };

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      maxWidth={width}
      onClose={handleClose}
      fullWidth
    >
      <DialogTitle>
        <Grid container className={classes.headerContainer}>
          <Grid container className={classes.headerInnerContainer}>
            <Grid container className={classes.headerContentContainer}>
              <Logo type={HEADER_LOGO_TYPE} />
            </Grid>
            <Grid container className={classes.headerContentContainer}>
              <Typography className={classes.dialogHeaderText}>
                {TITLE}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container className={classes.bodyContainer}>
          <Grid container className={classes.bodyInnerContainer}>
            <Typography className={classes.bodyContentText}>
              {MAIN_MESSAGE}
            </Typography>
            <Grid container className={classes.contentSignatureContainer}>
              <Typography className={classes.signatureText}>
                {MESSAGE_SIGN}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.agreementFormContainer}>
            <FormControl>
              <FormLabel className={classes.radioGroupLabel}>
                {FORM_LABEL}
              </FormLabel>
              <RadioGroup
                onChange={onRadioOptionChange}
                value={
                  agreeTOU
                    ? FORM_DATA.RADIO_OPTION_1.VALUE
                    : FORM_DATA.RADIO_OPTION_2.VALUE
                }
              >
                <FormControlLabel
                  value={FORM_DATA.RADIO_OPTION_1.VALUE}
                  control={
                    <Radio
                      color="primary"
                      className={classes.radioLabelRoot}
                      name={FORM_DATA.RADIO_OPTION_1.VALUE}
                      data-testid={`TEST_${FORM_DATA.RADIO_OPTION_1.VALUE}`}
                    />
                  }
                  label={FORM_DATA.RADIO_OPTION_1.TEXT}
                  className={classes.radioOption}
                  classes={{
                    label: classes.radioLabelText
                  }}
                />
                <FormControlLabel
                  value={FORM_DATA.RADIO_OPTION_2.VALUE}
                  control={
                    <Radio
                      color="primary"
                      className={classes.radioLabelRoot}
                      name={FORM_DATA.RADIO_OPTION_2.VALUE}
                      data-testid={`TEST_${FORM_DATA.RADIO_OPTION_2.VALUE}`}
                    />
                  }
                  label={FORM_DATA.RADIO_OPTION_2.TEXT}
                  className={classes.radioOption}
                  classes={{ label: classes.radioLabelText }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container className={classes.actionContainer}>
          <Grid container className={classes.actionInnerContainer}>
            <Grid container className={classes.buttonContainer}>
              <SubmitButton
                label={CONTINUE_BTN_LABEL}
                variant="contained"
                labelClass={classes.buttonLabel}
                onSubmit={onSubmitHandler}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

CCPADialog.propTypes = {
  fullScreen: PropTypes.bool,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleCCPASubmit: PropTypes.func
};

export default CCPADialog;
