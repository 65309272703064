import { useState } from 'react';
import styles from '../styles';
import {
  DialogTitle,
  IconButton,
  Typography,
  makeStyles,
  Dialog,
  DialogContent,
  DialogActions
} from '@material-ui/core';
import { AddrConfirmation } from '../../constanst';
import AddrConfirmContent from './AddrConfirmContent';
import { CancelButton, SaveButton } from '../../../../components/Buttons';
import CloseIcon from '@material-ui/icons/Close';
import { extractStateAbbreviation } from '../../utils';

const AddressConfirmModal = (props) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const { addressDetail, onSave, open, onClose, suggestedData } = props;
  const [selectedAddress, setSelectedAddress] = useState(addressDetail);

  const onContinueClick = () => {
    onSave({
      ...selectedAddress,
      panelist_st: extractStateAbbreviation(selectedAddress.panelist_st)
    });
    onClose();
  };

  return (
    <>
      <Dialog
        PaperProps={{ className: classes.modalPaper }}
        onClose={onClose}
        open={open}
      >
        <DialogTitle>{AddrConfirmation.title}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          className={classes.closeButton}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Typography className={classes.description}>
            {AddrConfirmation.description}
          </Typography>
          {
            <AddrConfirmContent
              userData={addressDetail}
              suggestedData={suggestedData}
              setSelectedAddress={setSelectedAddress}
            />
          }
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <CancelButton onButtonClick={onClose} />
          <SaveButton
            onButtonClick={onContinueClick}
            buttonLabel="Continue"
            variant="contained"
            testId="save-confirm-address"
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddressConfirmModal;
