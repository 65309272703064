import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import API, { API_STATUS_CODE } from '../../constants/common';
import ApiPath from '../../constants/api';
import BottomNav from '../../components/BottomNav';
import ConfAgree from '../../components/AlertDialog/ConfAgree';
import Header from '../../components/Header';
import HomeFooter from '../../components/Footer/HomeFooter';
import Logo from '../../components/Logo';
import SuperTab from '../../components/SuperTab';
import UpdateDob from '../../components/AlertDialog/UpdateDob';
import apiCall from '../../utils/api';
import styles from './styles';
import { FormattedMessage } from 'react-intl';
import { Grid, Snackbar, withStyles } from '@material-ui/core';
import { isEmptyString } from '../../utils/common';
import { useWindowSize } from '../../hooks';
import { AUTO_HIDE_DURATION, HOME_TAB_ITEMS } from '../../constants/common';
import { removeLoginTokens } from '../../utilities/utils';
import MobileNudge from '../../components/MobileNudge';
import TabRoutes from './TabRoutes';

const Home = ({ classes }) => {
  const history = useHistory();
  const location = useLocation();
  const [confAgree, setConfAgree] = useState(false);
  const [openDobUpdate, setOpenDobUpdate] = useState(false);
  const [userName, setUserName] = useState('');
  const [newDateOfBirth, setNewDateOfBirth] = useState({
    screenName: '',
    day: '',
    month: '',
    year: ''
  });
  const [isDobUpdated, setIsDobUpdated] = useState(true);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [radioOption, setRadioOption] = useState('');
  const { width } = useWindowSize();

  useEffect(() => {
    apiCall(`${ApiPath.status}`, API.METHOD.GET, {}, false).then((response) => {
      if (response?.error) {
        setIsSnackbarOpen(true);
        setSnackbarMessage(
          `Confidentiality Agreement Error: ${response?.error?.message}`
        );
      }
      if (response?.status === API_STATUS_CODE.SUCCESS) {
        const { confAgree, dobUpdated } = response?.data;
        setIsDobUpdated(dobUpdated);
        if (confAgree === 'UNSET' || confAgree === 'NO') {
          setConfAgree(true);
        } else {
          setOpenDobUpdate(!dobUpdated);
        }
      }
    });
  }, [isDobUpdated]);

  useEffect(() => {
    apiCall(`${ApiPath.authValidation}`, API.METHOD.GET, {}, false).then(
      (response) => {
        if (response?.error) {
          setIsSnackbarOpen(true);
          setSnackbarMessage(`Auth Error: ${response?.error?.message}`);
          history.push('/login', 'browserEvent');
        }
        if (response?.status === API_STATUS_CODE.SUCCESS) {
          setUserName(response.data?.message?.split(/(?:[ ])+/)[1]);
        }
      }
    );
  }, []);

  useEffect(() => {
    if (!isEmptyString(radioOption)) {
      const payload = {
        confAgree: radioOption
      };
      apiCall(`${ApiPath.termsAgreement}`, API.METHOD.PUT, payload, false).then(
        (response) => {
          if (response?.error) {
            setIsSnackbarOpen(true);
            setSnackbarMessage(
              `Confidentiality Agreement Error: ${response?.error?.message}`
            );
          }
          if (radioOption === 'NO') {
            removeLoginTokens();

            history.replace('/home', '');
            history.push('/login', 'logOff');
          } else {
            setOpenDobUpdate(!isDobUpdated);
          }
        }
      );
    }
  }, [confAgree]);

  return (
    <>
      <title>
        <FormattedMessage
          id="HOME_TITLE"
          defaultMessage="Walmart - Customer Spark - Home"
        />
      </title>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isSnackbarOpen}
        message={snackbarMessage}
        autoHideDuration={AUTO_HIDE_DURATION}
        onClose={() => setIsSnackbarOpen(false)}
        ContentProps={{
          classes: {
            root: classes.snackbarRoot
          }
        }}
      />
      <ConfAgree
        openDialog={confAgree}
        setOpenDialog={setConfAgree}
        radioOption={radioOption}
        setRadioOption={setRadioOption}
      />
      <UpdateDob
        newDateOfBirth={newDateOfBirth}
        openDialog={openDobUpdate}
        setNewDateOfBirth={setNewDateOfBirth}
        setOpenDialog={setOpenDobUpdate}
        setIsSnackbarOpen={setIsSnackbarOpen}
        setSnackbarMessage={setSnackbarMessage}
      />
      {width <= 600 ? (
        <>
          {!location.pathname.includes('weekly-quests') && <Header />}
          <div>
            {!location.pathname.includes('weekly-quests') && (
              <Logo type={'hero'} flag={true} />
            )}
            <TabRoutes />
            <MobileNudge />
            <BottomNav user={userName} />
          </div>
        </>
      ) : (
        <>
          <Header user={userName} />
          <Grid container className={classes.gridContainer}>
            <Logo type={'hero'} flag={true} />
            <MobileNudge />
            <Grid className={classes.actionRow}>
              <SuperTab tabItems={HOME_TAB_ITEMS} />
            </Grid>
            <div role="tabpanel">
              <TabRoutes />
            </div>
          </Grid>
          <HomeFooter />
        </>
      )}
    </>
  );
};

Home.defaultProps = {
  classes: {}
};

Home.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(Home);
