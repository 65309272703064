import React, { useEffect, useState } from 'react';
import API from '../../constants/common';
import InfoScreen from '../InfoScreen';
import LuminateLoader from '../../components/LuminateLoader';
import NewRegistrationForm from './NewRegistrationForm';
import analytics from '../../utils/analytics';
import api from '../../constants/api';
import apiCall from '../../utils/api';
import { PAGES } from '../../constants/analytics';
import { useHistory, useLocation } from 'react-router-dom';

const NewRegistration = () => {
  const history = useHistory();
  const location = useLocation();

  const query = new URLSearchParams(location?.search);

  const panelistHashEncoded = query.get('panelist_hash');

  const panelistHash = atob(panelistHashEncoded);

  const [newUserData, setNewUserData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  const [displayForm, setDisplayForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const errorScreenText = {
    title: 'Thank You',
    description: `Thanks for your interest but you're unable to join the Walmart Customer Spark Community at this time.`
  };

  useEffect(() => {
    setIsLoading(true);

    apiCall(
      `${api.newUserRegistrationAuth}?panelistHash=${panelistHash}`,
      API.METHOD.GET,
      {},
      true
    ).then((response) => {
      setIsLoading(false);
      if (!response?.data?.valid) {
        setDisplayForm(false);
      }
      if (response?.data?.valid) {
        setNewUserData({
          ...newUserData,
          email: response?.data?.email
        });
        setDisplayForm(true);
      }
    });
  }, []);

  const formUpdateHandler = (event) => {
    setNewUserData({ ...newUserData, [event.target.name]: event.target.value });
  };

  const continueClickHandler = () => {
    history.push({
      pathname: '/new-registration-form/register',
      state: { emailId: newUserData.email, pDetail: newUserData.password }
    });
  };

  useEffect(() => {
    analytics.trackPageView(PAGES.REGISTRATION_FORM);
  }, []);

  return (
    <>
      {isLoading && <LuminateLoader />}
      {!isLoading && !displayForm && (
        <InfoScreen displayContent={errorScreenText} />
      )}
      {!isLoading && displayForm && (
        <NewRegistrationForm
          formData={newUserData}
          formUpdateHandler={formUpdateHandler}
          onClickContinue={continueClickHandler}
        />
      )}
    </>
  );
};

export default NewRegistration;
