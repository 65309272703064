import React from 'react';
import { withStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { useWindowSize } from '../../hooks';
import styles from './styles';
import { FormattedMessage } from "react-intl";

const PasswordRulesCheck = ({ classes, pword }) => {
  const { width } = useWindowSize();
  const PASSWORD_RULES = [
    {
      title: 'Include 8 to 30 characters',
      rule: () => new RegExp('^(?=.{8,30}$)').test(pword)
    },

    {
      title: 'Include at least one number',
      rule: () => new RegExp('^(?=.*[0-9])').test(pword)
    },
    {
      title: 'Include at least one upper case letter',
      rule: () => new RegExp('^(?=.*[A-Z])').test(pword)
    },
    {
      title: 'Include at least one lower case letter',
      rule: () => new RegExp('^(?=.*[a-z])').test(pword)
    },
    {
      title: 'Include at least one symbol (ie: #, &, $, %)',
      rule: () => new RegExp('^(?=.*[!@#$%^&*])').test(pword)
    },
    {
      title: 'Cannot contain more than 3 consecutive identical characters',
      rule: () => !new RegExp('^(?=(.)+\\1\\1\\1)').test(pword)
    }
  ];

  return (
    <>
      {width > 960 && (
        <div className={classes.passwordRuleTitle}>
          <FormattedMessage id="STRONG_PASSWORD" defaultMessage="Strong password required:"/>
        </div>
      )}
      {PASSWORD_RULES.map((item, index) => (
        <div key={index} className={classes.passwordRulesItem}>
          {item.rule(pword) ? (
            <CheckIcon className={`${classes.checkIcon} black`} />
          ) : (
            <CloseIcon className={classes.checkIcon} color="secondary" />
          )}
          <p className={classes.cushion}><FormattedMessage id={`PASSWORD_RULES_${index}`} defaultMessage={item.title}/></p>
        </div>
      ))}
    </>
  );
};

export default withStyles(styles)(PasswordRulesCheck);
