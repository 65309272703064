import { Box, IconButton, Typography, withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import styles from './styles';
import { FormattedMessage } from 'react-intl';
import { APP_STORE_LINK } from '../../constants/common';
import { onAppDownloadNowClick } from '../../utils/common';
import { Close } from '@material-ui/icons';
import useConfigContext from '../../utils/useConfigContext';

const { android: playStoreLink, ios: appStoreLink } = APP_STORE_LINK;

function MobileBanner({ classes }) {
  const [hideBanner, setHideBanner] = useState(false);

  const showMobileBanner = useConfigContext('showMobileBanner') === true;

  if (hideBanner || !showMobileBanner) {
    return null;
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.storeButtonWrapper}>
        <a href={playStoreLink} target="_blank" rel="noopener noreferrer">
          <img
            src="/assets/images/mobile/playstore-white.png"
            alt="Get it on Google Play"
            className={classes.storeButton}
          />
        </a>
        <img
          src="/assets/images/mobile/playstore-qr.png"
          alt="playstore qr code"
          className={classes.qrCode}
        />
      </Box>

      <Box className={classes.appIconWrapper}>
        <img
          src="/assets/images/mobile/app-icon.png"
          alt="customer spark app icon"
          className={classes.appIcon}
        />
      </Box>

      <Box className={classes.mobileBannerImageWrapper}>
        <Box>
          <Typography className={classes.mobileBannerTitle}>
            <FormattedMessage id="LANDING_PAGE_MOBILE_BANNER_TITLE" />
          </Typography>
          <Typography className={classes.mobileBannerSubtitle}>
            <FormattedMessage id="LANDING_PAGE_MOBILE_BANNER_SUBTITLE" />
          </Typography>
        </Box>
        <div>
          <button
            className={classes.downloadTextBtn}
            onClick={onAppDownloadNowClick}
          >
            <FormattedMessage id="LANDING_PAGE_MOBILE_BANNER_DOWNLOAD_BTN" />
          </button>
        </div>
        <img
          src="/assets/images/mobile/mobile-marketing-banner.png"
          alt="customer spark app banner"
          className={classes.mobileBannerImage}
        />
      </Box>

      <Box className={classes.storeButtonWrapper}>
        <a href={appStoreLink} target="_blank" rel="noopener noreferrer">
          <img
            src="/assets/images/mobile/appstore-white.png"
            alt="Download on the App Store"
            className={classes.storeButton}
          />
        </a>
        <img
          src="/assets/images/mobile/appstore-qr.png"
          alt="appstore qr code"
          className={classes.qrCode}
        />
      </Box>
      <IconButton
        aria-label="close"
        data-testid="close-button"
        className={classes.closeContainer}
        onClick={() => setHideBanner(true)}
      >
        <Close className={classes.closeIcon} />
      </IconButton>
    </Box>
  );
}

export default withStyles(styles)(MobileBanner);
