const styles = (theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
    cursor: 'pointer'
  },
  content: {
    fontSize: 14,
    [theme.breakpoints.up('md')]: {
      fontSize: 22
    }
  }
});

export default styles;
