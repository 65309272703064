import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import NotificationsRoundedIcon from '@material-ui/icons/NotificationsRounded';
import {
  Card,
  IconButton,
  Popover,
  Typography,
  withStyles,
  Button
} from '@material-ui/core';
import Logo from '../Logo';
import { getOrigin } from '../../constants/styles';
import styles from './styles';
import { FormattedMessage } from 'react-intl';
import { removeLoginTokens } from '../../utilities/utils';

const Header = ({ classes, type, user }) => {
  const { push, replace } = useHistory();
  const [notification, setNotification] = useState(null);
  const [userProfile, setUserProfile] = useState(null);

  const notify = (event) => {
    setNotification(event.currentTarget);
  };

  const closeNotify = () => {
    setNotification(null);
  };

  const notifyOpen = Boolean(notification);
  const notifyId = notifyOpen ? 'notification' : undefined;

  const openUserProfile = (event) => {
    setUserProfile(event.currentTarget);
  };

  const closeUserProfile = () => {
    setUserProfile(null);
  };

  const userOpen = Boolean(userProfile);
  const userId = userOpen ? 'user-detail' : undefined;

  const logout = () => {
    removeLoginTokens();

    replace('/home', '');
    push('/login', 'logOff');
  };

  const handleProfile = () => {
    push('/profile');
  };

  const handleHomeClick = () => {
    push('/home', 'homeLogoEvent');
  };

  return (
    <header>
      <nav className={classes.nav}>
        <Logo callBack={handleHomeClick} />
        {user && (
          <div className={classes.optionHolder}>
            <IconButton
              onClick={notify}
              id={notifyId}
              aria-label="notify"
              data-testid={'notify'}
            >
              <NotificationsRoundedIcon className={classes.notifyIcon} />
            </IconButton>
            <IconButton
              onClick={openUserProfile}
              id={userId}
              aria-label="profile"
              data-testid={'profile'}
            >
              <div className={classes.userName}>
                {user && user?.charAt(0).toUpperCase()}
              </div>
            </IconButton>
            <Popover
              id={notifyId}
              open={notifyOpen}
              anchorEl={notification}
              onClose={closeNotify}
              anchorOrigin={getOrigin('bottom', 'left')}
              transformOrigin={getOrigin('top', 'center')}
              data-testid={'notify-pop'}
            >
              <Typography className={classes.typography}>
                <FormattedMessage
                  id="NO_NOTIFICATIONS"
                  defaultMessage="No notifications"
                />
              </Typography>
            </Popover>
            <Popover
              id={userId}
              open={userOpen}
              anchorEl={userProfile}
              onClose={closeUserProfile}
              anchorOrigin={getOrigin('bottom', 'left')}
              transformOrigin={getOrigin('top', 'center')}
              data-testid={'profile-pop'}
            >
              <Card
                className={`${classes.typography} ${classes.center} ${classes.card}`}
                role="dialog"
              >
                <Button
                  id="profile"
                  className={classes.btnStyle}
                  onClick={handleProfile}
                  data-testid={'profile'}
                >
                  <FormattedMessage
                    id="VIEW_PROFILE"
                    defaultMessage="View Profile"
                  />
                </Button>
                <Button
                  id="logout"
                  className={classes.btnStyle}
                  onClick={logout}
                  data-testid={'logout'}
                >
                  <FormattedMessage id="LOG_OUT" defaultMessage="Log Out" />
                </Button>
              </Card>
            </Popover>
          </div>
        )}
      </nav>
    </header>
  );
};

Header.defaultProps = {
  classes: {},
  type: ''
};

Header.propTypes = {
  classes: PropTypes.object,
  type: PropTypes.string,
  user: PropTypes.string
};

export default withStyles(styles)(Header);
