import React from 'react';
import useConfigContext from '../../utils/useConfigContext';

const CCM = () => {
  const name = useConfigContext('name');

  return <div>{name}</div>;
};

export default CCM;
