const styles = (theme) => ({
  confirm: {
    width: 312,
    fontSize: 14,
    textTransform: 'none',
    [theme.breakpoints.up('sm')]: {
      width: 83,
      height: 36
    },
    [theme.breakpoints.up('md')]: {
      marginRight: 28
    },
    [theme.breakpoints.up('lg')]: {
      width: 92,
      height: 40,
      marginRight: 32
    }
  },
  contentText: {
    fontSize: 14,
    fontWeight: 300,
    lineHeight: 1.43,
    paddingTop: 7,
    color: '#333333',
    [theme.breakpoints.up('sm')]: {
      fontSize: 16
    }
  },
  confDialog: {
    maxWidth: 328,
    borderRadius: 8,
    margin: 0,
    boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#ffffff',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 528
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 827
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 1146
    }
  },
  dialogActions: {
    padding: '0 7px 48px 7px',
    boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
    backgroundColor: '#ffffff',
    flexDirection: 'column',
    alignItems: 'flex-end',
    [theme.breakpoints.up('sm')]: {
      padding: '0 0 24px 7px'
    },
    [theme.breakpoints.up('md')]: {
      padding: '0 20px 24px 27px'
    }
  },
  dialogActions2: {
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end'
    }
  },
  dialogContent: {
    margin: '0px 16px',
    padding: 0,
    [theme.breakpoints.up('md')]: {
      margin: '0px 36px'
    },
    [theme.breakpoints.up('lg')]: {
      margin: '0px 40px'
    }
  },
  dialogTitle1: {
    padding: '18px 0px',
    textAlign: 'center'
  },
  dialogTitle2: {
    padding: '20px 0px',
    '& h2': {
      textAlign: 'center',
      height: 24,
      fontSize: 16,
      fontWeight: 'normal',
      letterSpacing: 0.19,
      color: '#333333',
      [theme.breakpoints.up('lg')]: {
        fontSize: 20
      }
    },
    [theme.breakpoints.up('sm')]: {
      '& h2': {
        fontSize: 20
      }
    }
  },
  radioLabelRoot: {
    marginTop: 18,
    marginLeft: 0,
    alignItems: 'start'
  }
});

export default styles;
