import React from 'react';
import { Button, CircularProgress, withStyles } from '@material-ui/core';
import styles from './styles';

const ButtonWithLoader = ({ btnText, isLoading, classes, btnProps }) => (
  <Button {...btnProps}>
    <span className={isLoading ? classes.zeroOpacity : ''}>{btnText}</span>
    {isLoading && (
      <CircularProgress
        size={20}
        thickness={5}
        className={classes.buttonProgress}
      />
    )}
  </Button>
);

export default withStyles(styles)(ButtonWithLoader);
