import { CircularProgress, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { SurveySelectors } from '@walmart/survey-taker-api-js-sdk';
import {
  Button,
  ProgressIndicator
} from '@walmart-web/livingdesign-components';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    display: 'flex',
    wrap: 'nowrap',
    backgroundColor: 'white'
  },
  buttonsConatiner: {
    width: '100%',
    display: 'flex',
    padding: '16px',
    justifyContent: 'space-between'
  }
}));

const Footer = (props) => {
  const classes = useStyles();

  const { onNextClick, onBackClick } = props;

  const currentQuestionNumber = useSelector(
    SurveySelectors.selectCurrentQuestionNumber
  );
  const totalQuestions = useSelector(SurveySelectors.selectTotalQuestions);
  const isNextBtnLoading = useSelector(SurveySelectors.selectNextBtnLoading);
  const nextBtnEnabled = useSelector(SurveySelectors.selectEnableNextBtn);

  const nextBtnLabel =
    currentQuestionNumber === totalQuestions ? 'Submit' : 'Next';

  return (
    <footer className={classes.container}>
      <Grid container>
        <ProgressIndicator
          min={0}
          max={totalQuestions}
          value={currentQuestionNumber}
          a11yLabelledBy="survey progress"
        />
        <Grid className={classes.buttonsConatiner}>
          <Button
            size="medium"
            variant="secondary"
            disabled={currentQuestionNumber === 1}
            onClick={onBackClick}
          >
            Back
          </Button>
          <Button
            size="medium"
            variant="primary"
            disabled={!nextBtnEnabled || isNextBtnLoading}
            onClick={onNextClick}
          >
            {isNextBtnLoading ? (
              <CircularProgress style={{ height: 20, width: 20 }} />
            ) : (
              nextBtnLabel
            )}
          </Button>
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;
