import React from 'react';
import { ERROR_FALLBACK } from '../../constants/common';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import styles from './styles';
import HomeFooter from '../Footer/HomeFooter';
import { IMAGE_PATH } from '../../utilities/utils';
import { RoundedFilledButton } from '../Buttons';
import { useWindowSize } from '../../hooks';

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const { width } = useWindowSize();

  const retry = () => {
    resetErrorBoundary();
  };

  return (
    <Grid container className={classes.container}>
      <Grid container className={classes.errorContainer}>
        <Grid className={classes.imageItem}>
          <img
            src={`${IMAGE_PATH}/application_error_image.png`}
            alt="Application Error"
          />
        </Grid>
        <Grid item className={classes.messageItem}>
          <Typography className={classes.headerText}>
            {ERROR_FALLBACK.ERROR_HEADER}
          </Typography>
          {ERROR_FALLBACK.ERROR_MSG.map((x) => (
            <Typography className={classes.messageText}>{x}</Typography>
          ))}
        </Grid>
        <Grid>
          <RoundedFilledButton label="Try Again" onClick={retry} />
        </Grid>
      </Grid>

      {width > 600 && <HomeFooter />}
    </Grid>
  );
};

export default ErrorFallback;
