import React from 'react';
import { Fade, Button, withStyles } from '@material-ui/core';
import { useWindowSize } from '../../hooks';
import styles from './styles';

const Logo = ({ classes, type, flag, callBack }) => {
  const {
    appLogo,
    emptyCart,
    groceryBag,
    trailer,
    shopCart,
    site,
    boxes,
    tech,
    sports,
    receipt,
    mobileSite,
    headerImage,
    heroImage,
    pageNotFound,
    resultsNotFound,
    avatar,
    confAgree,
    landing,
    carouselAvatar,
    walmartIcon,
    walmartLogo,
    trophy,
    microSurveyCardImage
  } = classes;

  const activities = [
    { name: 'grocery-bag-activities', style: groceryBag },
    { name: 'trailer-activities', style: trailer },
    { name: 'shopping-cart-activities', style: shopCart },
    { name: 'site-activities', style: site },
    { name: 'boxes-activities', style: boxes },
    { name: 'tech-activities', style: tech },
    { name: 'sports-activities', style: sports },
    { name: 'receipt-activities', style: receipt },
    { name: 'mobile-site-activities', style: mobileSite }
  ];

  const { width } = useWindowSize();

  const getImageTag = (name, source, alt) => {
    return <img className={name} src={source} alt={alt} />;
  };

  const getImageSize = (width) =>
    width && width > 1280 ? 1440 : width > 960 ? 1024 : width > 600 ? 768 : 360;

  switch (type) {
    case 'activity':
      return getImageTag(
        activities[flag % 9].style,
        `/assets/images/activities/${activities[flag % 9].name}.jpg`,
        ''
      );
    case 'emptyCart':
      return getImageTag(
        emptyCart,
        '/assets/images/activities/empty-cart-no-activities.jpg',
        'walmart-empty-cart-no-activities'
      );
    case 'hero':
      return (
        <Fade in={flag}>
          <div>
            {getImageTag(
              heroImage,
              `/assets/images/customer-spark-hero-${getImageSize(width)}.jpg`,
              'customer-spark-hero'
            )}
          </div>
        </Fade>
      );
    case 'onboard':
      return getImageTag(
        headerImage,
        '/assets/images/walmart-customer-spark-logo-1440.png',
        'walmart-customer-spark-logo'
      );
    case 'pageNotFound':
      return getImageTag(
        pageNotFound,
        `/assets/images/page-not-found/page-not-found-${getImageSize(
          width
        )}.jpg`,
        'page-not-found'
      );
    case 'resultsNotFound':
      return getImageTag(
        resultsNotFound,
        `/assets/images/list-no-results/no-results-found-${getImageSize(
          width
        )}.jpg`
      );
    case 'avatar':
      return getImageTag(
        avatar,
        '/assets/images/customer-spark-avatar.jpg',
        'results-not-found'
      );
    case 'confAgree':
      return getImageTag(
        confAgree,
        '/assets/images/walmart-customer-spark-dialog.jpg',
        'conf-agree'
      );
    case 'landing':
      return getImageTag(
        landing,
        '/assets/images/spark-landing-header-logo.webp',
        'spark-landing-header-logo'
      );
    case 'carousel-avatar':
      return getImageTag(
        carouselAvatar,
        `/assets/images/landing-page-avatars/${flag}.webp`,
        'spark-landing-carousel-avatar-logo'
      );
    case 'walmart-icon':
      return getImageTag(
        walmartIcon,
        '/assets/images/walmart-icon.png',
        'no-logo-found'
      );
    case 'walmart-logo':
      return getImageTag(
        walmartLogo,
        '/assets/images/yellow-spark.png',
        'no-logo-found'
      );
    case 'trophy':
      return getImageTag(
        trophy,
        '/assets/images/activities/micro-surveys/trophy.svg',
        'no-logo-found'
      );
    case 'bag-post-shopping-1':
    case 'bag-post-shopping-2':
    case 'box':
    case 'car-trunk':
    case 'cart-post-shopping':
    case 'cart-while-shopping':
    case 'celebration':
    case 'empty-basket':
    case 'receipt-curled':
    case 'truck':
      return getImageTag(
        microSurveyCardImage,
        `/assets/images/activities/micro-surveys/${type}.svg`,
        'no-logo-found'
      );
    default:
      return (
        <Button onClick={callBack}>
          {getImageTag(
            appLogo,
            '/assets/images/walmart-customer-spark-logo-1440.png',
            'walmart-customer-spark-logo'
          )}
        </Button>
      );
  }
};

export default withStyles(styles)(Logo);
