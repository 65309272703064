const styles = () => ({
  container: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative'
  },
  dropZone: {
    width: '100%'
  }
});

export default styles;
