import {
  getCharacterCountLimitMessage,
  HTML_CONTAINS_FIELD_ERROR_MESSAGE,
  URL_VALIDATION_MESSAGE,
  FORM_FIELD_NAME_ATTRIBUTES,
  FORM_FIELD_CHAR_LIMIT,
  URL_ACTIVITYID_VALIDATION_MESSAGE
} from '../constants/common';

export const getIsContainHtmlTags = ({ string }) => {
  const regexForHTML = new RegExp(
    /<[a-z]+\d?(\s+[\w-]+=("[^"]*"|'[^']*'))*\s*\/?>|&#?\w+;/,
    'i'
  );
  return string ? string?.match(regexForHTML) : false;
};

export const getUrlValidation = ({ string }) => {
  const regexForHTML = 
   /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;
  return regexForHTML.test(string);
};

export const charCountValidation =  ({ string, name }) => {
  return string ? string?.length > FORM_FIELD_CHAR_LIMIT[name] : false;
};

export const getActivityIdValidation =  ({ string }) => {
  const regexForHTML =  /^[a-zA-Z0-9]{6}$/;
  return regexForHTML.test(string)
};

export const getContactUsFieldValidation = ({name, value}) => {
  let errorMessage = '';
  let isValid = true;
  const isValidUrl = getUrlValidation({string: value});
  const isValidActivityId = getActivityIdValidation({string: value});
  const charCountInValid = charCountValidation({string: value, name});
  const isContainHtmlTags = getIsContainHtmlTags({ string: value });
  switch (name) {
    case FORM_FIELD_NAME_ATTRIBUTES.ACTIVITY_TYPE:
      if(!isValidActivityId && !isValidUrl) {
        errorMessage = URL_ACTIVITYID_VALIDATION_MESSAGE;
        isValid = false;
      }
      break;
    case FORM_FIELD_NAME_ATTRIBUTES.EGIFT_CARD_URL:
      if(!isValidUrl) {
        errorMessage = URL_VALIDATION_MESSAGE;
        isValid = false;
      }
      break;
    default:
      if(charCountInValid) {
        errorMessage = getCharacterCountLimitMessage({ count: FORM_FIELD_CHAR_LIMIT[name] });
        isValid = false;
      } else {
        if(isContainHtmlTags) {
          errorMessage = HTML_CONTAINS_FIELD_ERROR_MESSAGE;
          isValid = false;
        }
      }
  }
  return {
    isInValid: !isValid,
    message: errorMessage
  };
}
