//content which needs to be copied to /etc/config/basicConfig.json
// keep updating with changes you make to ccm.yml to commented part and config

// {
//     "configResolution": {
//       "resolved": {
//         "basicConfig": {
//           "name": "Hello World from config Walmart"
//         }
//       }
//     }
//   }

const config = {
  name: 'Hello World from config Walmart',
  showMobileBanner: true,
  enableMicroSurveys: true
};

export default config;
n