const themeStyles = {
  overrides: {
    MuiFormHelperText: {
      contained: { marginLeft: 0 }
    },
    MuiPickersToolbar: {
      toolbar: {
        height: 68
      }
    },
    MuiOutlinedInput: {
      adornedStart: {
        paddingLeft: '3px'
      }
    }
  },
  palette: {
    primary: {
      main: '#0071ce'
    },
    text: {
      primary: '#333333',
      secondary: '#6d6e71'
    }
  },
  typography: {
    fontFamily: 'Bogle',
    h3: {
      fontFamily: 'Bogle-Regular',
      fontSize: 24,
      fontWeight: 400
    },
    h4: {
      fontFamily: 'Bogle-Regular',
      fontSize: 18,
      fontWeight: 300
    },
    subtitle1: {
      fontFamily: 'Bogle-Regular',
      fontSize: 12,
      fontWeight: 400
    }
  },
  font: {
    regular: 'Bogle-Regular',
    light: 'Bogle-Light',
    medium: 'Bogle-Medium'
  }
};

export default themeStyles;

export const getOrigin = (vertical, horizontal) => ({ vertical, horizontal });

export const TAB_INDICATOR_STYLE = {
  style: {
    borderRadius: '100px 100px 0px 0px',
    height: 3
  }
};

export const SELECT_MENU_POPOVER_STYLE = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left'
  },
  getContentAnchorEl: null
};

export const CONFETTI_STYLE = {
  angle: 90,
  spread: 100,
  startVelocity: 30,
  elementCount: 200,
  dragFriction: 0.12,
  duration: 3000,
  stagger: 2,
  width: '10px',
  height: '10px',
  colors: [
    '#a864fd',
    '#29cdff',
    '#78ff44',
    '#ff718d',
    '#fdff6a',
    '#f44336',
    '#e91e63',
    '#9c27b0',
    '#673ab7',
    '#3f51b5',
    '#2196f3',
    '#03a9f4',
    '#00bcd4',
    '#009688',
    '#4CAF50',
    '#8BC34A',
    '#CDDC39',
    '#FFEB3B',
    '#FFC107',
    '#FF9800',
    '#FF5722',
    '#795548'
  ]
};

const smallFieldStyles = {
  textField: {
    margin: '12px 0 !important',
    '&>label': {
      fontSize: 14,
      transform: 'translate(14px, 17px) scale(1) !important'
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75) !important'
    }
  },
  input: {
    paddingTop: '14.5px !important',
    paddingBottom: '14.5px !important'
  },
  selectField: {
    margin: '16px 0 !important',
    '&>label': {
      fontSize: 14,
      transform: 'translate(14px, 17px) scale(1) !important'
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75) !important'
    }
  },
  selectRoot: {
    padding: '14.5px 14px !important'
  }
};
// fieldsStlyes is available in submodules as well
// changes need to be incorporated in both the places
export const fieldsStyles = (theme) => ({
  smTextField: smallFieldStyles.textField,
  smInput: smallFieldStyles.input,
  smSelectField: smallFieldStyles.selectField,
  smSelectRoot: smallFieldStyles.selectRoot,
  textFields: {
    margin: '12px 0',
    [theme.breakpoints.down('md')]: {
      ...smallFieldStyles.textField
    }
  },
  selectField: {
    margin: '0px 24px 16px 0 !important',
    [theme.breakpoints.down('md')]: {
      ...smallFieldStyles.selectField
    }
  },
  input: {
    color: theme?.palette?.text?.main,
    [theme.breakpoints.down('md')]: {
      ...smallFieldStyles.input
    }
  },
  selectRoot: {
    [theme.breakpoints.down('md')]: {
      ...smallFieldStyles.selectRoot
    }
  },
  selectMenuHeight: {
    height: 290,
    minWidth: 295
  },
  selectMenu: {
    color: theme?.palette?.text?.main
  },
  btnGroup: {
    border: '1px solid #0000003b',
    margin: '12px 0',
    '& .MuiFormControl-root': {
      margin: '10px 0'
    },
    '& .MuiIconButton-root': {
      [theme.breakpoints.down('sm')]: {
        padding: 8
      }
    },
    '& .MuiInputAdornment-positionStart': {
      [theme.breakpoints.down('sm')]: {
        marginRight: 0
      }
    }
  },
  icon: {
    fill: '#000'
  },
  iconOpen: {
    fill: theme?.palette?.primary?.main
  }
});
