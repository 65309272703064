const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '24px 0'
  },
  avatar: {
    width: 140,
    height: 140,
    background: '#fff',
    border: '0.5px solid #DADCE0',
    color: '#DADCE0'
  },
  avatarEdit: {
    display: 'block',
    color: '#0071ce',
    fontSize: 14,
    marginTop: 16,
    textTransform: 'capitalize'
  },
  snackbarRoot: {
    maxWidth: 444,
    fontSize: 16,
    color: '#0089e2',
    backgroundColor: '#e5f0fa',
    fontWeight: '500',
    textAlign: 'center'
  }
});

export default styles;
