import React, { useEffect } from 'react';
import Iframe from '../../components/Iframe';
import { PAGES } from '../../constants/analytics';
import { FOOTER_LINKS, FOOTER_LINK_NAMES } from '../../constants/common';
import analytics from '../../utils/analytics';

const Faq = () => {
  useEffect(() => {
    analytics.trackPageView(PAGES.FAQ);
  }, []);

  return <Iframe url={
    FOOTER_LINKS.find(
    footerLinks => footerLinks.name === FOOTER_LINK_NAMES.FAQ
    )?.url
    } title="FAQ" type="faqEvent" />;
};

export default Faq;
