const styles = (theme) => ({
  paper: {
    display: 'flex',
    alignItems: 'center',
    height: 90,
    '&:hover': {
      cursor: 'pointer'
    },
    width: '100%'
  },
  sectionText: {
    width: '100%',
    textAlign: 'left'
  },
  sectionTitle: {
    marginLeft: 24,
    marginTop: 17.5,
    display: 'block'
  },
  sectionTitleText: {
    fontFamily: 'Bogle',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 18,
    textTransform: 'none'
  },
  sectionDescription: {
    marginLeft: 24
  },
  sectionDescriptionText: {
    fontFamily: 'Bogle',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: 14,
    textTransform: 'none'
  },
  sectionInnerContainer: {
    justifyContent: 'center',
    alignItems: 'flex-end'
  },
  sectionPointsContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  sectionPoints: {
    alignItems: 'center',
    marginRight: '8px'
  },
  sectionPointsText: {
    fontFamily: 'Bogle',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    letterSpacing: 0.075,
    textTransform: 'none'
  },
  sectionPointsNumber: {
    fontFamily: 'Bogle',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 16
  },
  shareFeedbackLabel: {
    color: '#333',
    textAlign: 'center',
    fontFamily: 'Bogle',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal',
    letterSpacing: '0.075px',
    textTransform: 'none'
  },
  optOutText: {
    alignItems: 'center',
    marginTop: 30,
    marginBottom: 2
  },
  activityContainer: {
    position: 'relative',
    height: 90
  },
  disabledContainer: {
    backgroundColor: '#E5F0FA'
  },
  disabledText: {
    color: '#999999'
  },
  contentBox: {
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column',
    padding: 0
  },
  shareFeedbackContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: 0
  }
});

export default styles;
