const styles = (theme) => ({
  container: {
    position: 'absolute',
    display: 'flex',
    padding: 16,
    borderRadius: 8,
    backgroundColor: '#E6F1FC',
    top: 64 + 40,
    right: 32,
    boxShadow: '0px 0px 10px 0px #0000001A',
    columnGap: 16,
    [theme.breakpoints.down('600')]: {
      right: 'unset',
      top: 'unset',
      bottom: 104,
      margin: '0px 16px',
      position: 'fixed'
    },
    width: 393
  },
  appIcon: {
    height: 50,
    width: 50
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 8
  },
  nudgeTextTitle: {
    fontSize: 18,
    fontWeight: 700,
    color: '#2E2F32'
  },
  downloadTextBtn: {
    background: 'none',
    border: 'none',
    padding: 0,
    fontSize: 16,
    fontWeight: 400,
    color: '#2E2F32',
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  closeIcon: {
    marginTop: -10
  }
});

export default styles;
