import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import styles from './styles';
const useStyles = makeStyles((theme) => styles(theme));

const ArrowTooltip = ({ title, subTitle , alignment }) => {
  const classes = useStyles();

  const getAlignment = {
    right: {
      arrow: classes.arrowRight,
      text: classes.textRight
    },
    left: {
      arrow: classes.arrowLeft,
      text: classes.textLeft
    },
    center: {
      arrow: classes.arrowCenter,
      text: classes.textCenter
    }
  };

  return (
    <Box
      className={`${classes.tooltip} ${
        alignment && getAlignment[alignment].text
      }`}
    >
      <div>{title}</div>
      {subTitle && <div>{subTitle}</div>}
      <div
        className={`${classes.arrow}  ${
          alignment && getAlignment[alignment].arrow
        }`}
      ></div>
    </Box>
  );
};

export default ArrowTooltip;
