const styles = (theme) => ({
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
    backgroundColor: '#fff',
    height: '100%'
  },
  flexDir: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  root: {
    height: 8,
    borderRadius: 4
  },
  colorPrimary: {
    backgroundColor: '#dadce0'
  },
  barColorPrimary: {
    backgroundColor: '#0370cd'
  },
  loader: {
    margin: '24px 0',
    width: 210
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center'
  },
  image: {
    width: 36,
    height: 40
  },
  loadingText: {
    fontFamily: theme?.font?.light || 'Bogle-Light'
  }
});

export default styles;
