const styles = (theme) => ({
  selected: {
    fontWeight: '700 !important'
  },
  tab: {
    fontSize: 16,
    minWidth: 110,
    textTransform: 'none',
    paddingBottom: 0,
    fontWeight: 300,
    [theme.breakpoints.up('sm')]: {
      fontSize: 18,
      minWidth: 183
    },
    [theme.breakpoints.up('md')]: {
      minWidth: 162
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: 147
    }
  },
  tabContainer: {
    display: 'flex'
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center'
  }
});

export default styles;
