const styles = (theme) => ({
  activityCardsContainerMuliple2: {
    justifyContent: 'center',
    alignItems: 'start',
    width: 1380,
    columnGap: 50,
    rowGap: 18,
    [theme.breakpoints.down(1380)]: {
      width: '100%',
      columnGap: 50
    },
    [theme.breakpoints.down(768)]: {
      ...smallerActivityCards
    },
    [theme.breakpoints.down(600)]: {
      ...mobileActivityCards
    }
  },
  activityCardsDefault: {
    marginLeft: 30,
    marginRight: 30,
    columnGap: 12,
    rowGap: 18,
    maxWidth: 1380,
    justifyContent: 'start',
    alignItems: 'start',
    [theme.breakpoints.down(768)]: {
      ...smallerActivityCards
    },
    [theme.breakpoints.down(600)]: {
      ...mobileActivityCards
    }
  },
  activityCard: {
    [theme.breakpoints.down(1380)]: {
      width: '31%'
    },
    [theme.breakpoints.down(768)]: {
      width: '48%',
      '&:only-child': {
        flex: '0 0 60%'
      }
    }
  }
});

const smallerActivityCards = {
  maxWidth: 'calc((100% - 10px) * 2)',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'flex-start',
  columnGap: 22,
  '&::after': {
    content: '""',
    width: '48%',
    visibility: 'hidden'
  }
};

const mobileActivityCards = {
  marginLeft: '5%',
  marginRight: '5%',
  columnGap: 5
};

export default styles;
