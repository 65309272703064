import React, { useState } from 'react';
import { makeStyles, TextField, InputAdornment } from '@material-ui/core';
import styles from '../styles';
import { RemoveRedEye } from '@material-ui/icons';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';


const NRTextField = (props) => {
  const {
    id,
    label,
    name,
    disabled = false,
    defaultValue,
    type,
    onTextChange,
    onTextFieldFocus,
    onTextFieldBlur,
    isError,
    helperText
  } = props;

  const useStyles = makeStyles(styles);
  const classes = useStyles();
  
  const [pwdIsMasked, setPwdIsMasked] = useState(true);

  const togglePasswordMask = () => {
    setPwdIsMasked(!pwdIsMasked);
  };

  return (
    <TextField
      variant="outlined"
      id={id}
      label={label}
      name={name}
      disabled={disabled}
      defaultValue={defaultValue}
      type={pwdIsMasked?type:'input'}
      className={classes.textField}
      data-testid={`test-${id}`}
      onChange={onTextChange}
      onFocus={onTextFieldFocus}
      onBlur={onTextFieldBlur}
      error={isError}
      helperText={helperText}
      InputProps={
        type === "password" ? {
          endAdornment: (
            <InputAdornment position="end">
            {pwdIsMasked ? (
              <RemoveRedEye
                className={classes.eye}
                onClick={togglePasswordMask}
                data-testid="show-password"
              />
            ) : (
              <VisibilityOffIcon
                className={classes.eye}
                onClick={togglePasswordMask}
                data-testid="hide-password"
              />
            )}
            </InputAdornment>
          )
        }
        :null
      }
    />
  );
};

export default NRTextField;
