import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import API, { API_STATUS_CODE, TOAST_MSGS } from '../../constants/common';
import ApiPath from '../../constants/api';
import Confetti from 'react-dom-confetti';
import Model from './model';
import RedeemCard from './RedeemCard';
import SnackBanner from '../SnackBanner';
import Tracker from '../Tracker';
import analytics from '../../utils/analytics';
import apiCall from '../../utils/api';
import styles from './styles';
import { CONFETTI_STYLE } from '../../constants/styles';
import { FormattedMessage } from 'react-intl';
import { Fade, Grid, Typography, withStyles } from '@material-ui/core';
import { PAGES } from '../../constants/analytics';
import { enableCard } from './utils';
import { rewardsConstants } from './constants';

const Redeem = ({ classes }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [redeemSuccess, setRedeemSuccess] = useState(false);
  const [reward, setReward] = useState({
    currency: '',
    eligibleGiftCards: [],
    giftCard: 5,
    points: 0
  });
  const [snackBanner, setSnackBanner] = useState({
    displaySnackBanner: false,
    snackBannerMessage: ''
  });
  const [cardToRedeem, setCardToRedeem] = useState();

  const [isModelOpen, setIsModelOpen] = useState(false);

  const openSnackBanner = (message) => {
    setSnackBanner({
      displaySnackBanner: true,
      snackBannerMessage: message
    });
  };

  const handleSnackBannerClose = () => {
    setSnackBanner({
      displaySnackBanner: false,
      snackBannerMessage: ''
    });
  };

  const closeModel = () => {
    setIsModelOpen(false);
  };

  useEffect(() => {
    apiCall(ApiPath.rewardStatus, API.METHOD.GET, {}, false).then(
      (response) => {
        if (response?.error) {
          openSnackBanner(response?.error?.message || TOAST_MSGS.generic_error);
        }
        if (response?.status === API_STATUS_CODE.SUCCESS) {
          setIsLoading(false);
          const { currency, eligibleGiftCards, points } = response.data;
          setReward((reward) => {
            return { ...reward, currency, eligibleGiftCards, points };
          });
        }
      }
    );

    analytics.trackPageView(PAGES.REWARDS);
  }, []);

  const handleRedeemRewardClick = (cardDollarValue) => {
    setIsModelOpen(true);
    setCardToRedeem(cardDollarValue);
  };

  const handleSucessRedeemRewardClick = () => {
    closeModel();

    const payload = {
      giftCard: cardToRedeem
    };

    apiCall(`${ApiPath.redeem}`, API.METHOD.POST, payload, false).then(
      (response) => {
        if (response?.error) {
          openSnackBanner(response?.error?.message || TOAST_MSGS.generic_error);
        }
        if (response?.status === API_STATUS_CODE.SUCCESS) {
          const {
            currency,
            eligibleGiftCards,
            remainingPoints,
            pointsRedeemed
          } = response.data;
          setRedeemSuccess(true);
          setTimeout(() => {
            setRedeemSuccess(false);
          }, 5000);
          setReward({
            ...reward,
            giftCard: pointsRedeemed / 100,
            currency,
            eligibleGiftCards,
            points: remainingPoints
          });
        }
      }
    );

    setCardToRedeem('');
  };

  const successBanner = (
    <Grid item className={classes.successBanner}>
      <div
        className={classes.successText}
        role="alert"
        data-testid="redeemSuccess"
      >
        {
          <FormattedMessage
            id="REDEEM_CONGRATULATIONS"
            defaultMessage={`Congratulations! We sent your “Walmart $${reward.giftCard} Gift Card” to your provided email.`}
            values={{ GC: `${reward.giftCard}` }}
          />
        }
      </div>
    </Grid>
  );

  const renderGiftCard = rewardsConstants.freeText.giftCardValues.map((x) => (
    <RedeemCard
      key={`${x}`}
      enabled={enableCard(reward.points, x * 100)}
      cardDollarValue={x}
      onClickRedeemReward={handleRedeemRewardClick}
    />
  ));

  return (
    <Fade in timeout={1000}>
      <Grid container className={classes.redeemContainer}>
        <Model
          handleClose={closeModel}
          openDialog={isModelOpen}
          handelSuccess={handleSucessRedeemRewardClick}
          rewardData={cardToRedeem}
        />
        <SnackBanner
          isSnackbarOpen={snackBanner.displaySnackBanner}
          snackbarMessage={snackBanner.snackBannerMessage}
          onSnackBarClose={handleSnackBannerClose}
        />
        <div className={classes.rectangle}>
          {redeemSuccess && successBanner}
          <Confetti active={redeemSuccess} config={CONFETTI_STYLE} />
          <Grid item xs={12} className={classes.pointsEarnedText}>
            <Typography>{rewardsConstants.freeText.myPointsEarned}</Typography>
          </Grid>
          <Grid item xs={12} className={classes.trackGrid}>
            <Tracker
              // disabled={redeemDisabled}
              loading={isLoading}
              points={reward.points}
              strokeWidth={3}
              sqSize={280}
            />
          </Grid>
          <Grid container className={classes.pointsEarnedText}>
            <Typography>
              {rewardsConstants.freeText.availableRewards}
            </Typography>
            <Typography className={classes.chooseRewardsText}>
              {rewardsConstants.freeText.chooseGiftToRedeem}
            </Typography>
          </Grid>
          <Grid container className={classes.rewardsContainer}>
            {renderGiftCard}
          </Grid>
        </div>
      </Grid>
    </Fade>
  );
};

Redeem.defaultProps = {
  classes: {}
};

Redeem.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(Redeem);
