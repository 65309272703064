/* eslint-disable import/no-anonymous-default-export */
import { APP_NAME } from '../constants/analytics';
import { ADOBE_URL } from '../constants/common';
import getCurrentEnv from './env';

const setBase = (callback = () => {}) => {
  if (!window.__CUSTOMER_SPARK__)
    window.__CUSTOMER_SPARK__ = {
      dataLayer: { analyticsEvent: [] }
    };

  callback();
};

const loadAdobeScript = () => {
  const adobeScriptURL = ADOBE_URL[getCurrentEnv()];

  loadScriptFile(
    adobeScriptURL,
    document.getElementsByTagName('head')[0],
    true,
    true
  );
};

const loadScriptFile = (file, appendTo, async, defer) => {
  const script = document.createElement('script');
  script.src = file;
  if (async) {
    script.async = true;
  }
  if (defer) {
    script.defer = true;
  }
  appendTo.appendChild(script);
};

const loadContent = (appendTo) => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  appendTo.appendChild(script);
};

const log = (...args) => {
  if (getCurrentEnv() !== 'prod') console.log('CS - Debug:', args);
};

const trackPageView = (page) => {
  const trackView = () => {
    const payload = {
      appName: APP_NAME,
      page,
      url: window?.location?.href,
      userDetails: {}
    };

    log('Page View:', payload);
    window.__CUSTOMER_SPARK__.dataLayer = Object.assign(
      {},
      window.__CUSTOMER_SPARK__.dataLayer,
      payload
    );
    triggerEvent('pageNavigation');
  };
  if (window.__CUSTOMER_SPARK__?.dataLayer) {
    trackView();
  } else {
    setBase(trackView);
  }
};

const trackClick = (page, click, data = {}) => {
  if (window.__CUSTOMER_SPARK__?.dataLayer) {
    const payload = {
      appName: APP_NAME,
      click,
      page,
      data,
      url: window?.location?.href,
      userDetails: {}
    };
    log('Click', payload, 'clickEvent');
    window.__CUSTOMER_SPARK__.dataLayer = Object.assign(
      {},
      window.__CUSTOMER_SPARK__.dataLayer,
      payload
    );
    triggerEvent('clickEvent');
  }
};

const trackEvents = (eventName, eventDetail, page) => {
  if (window?.__CUSTOMER_SPARK__) {
    const payload = {
      event: eventName,
      eventDetail,
      page,
      appName: APP_NAME,
      url: window?.location?.href,
      userDetails: {}
    };
    log('Events', payload, 'clickEvent');

    window.__CUSTOMER_SPARK__.dataLayer = Object.assign(
      {},
      window?.__CUSTOMER_SPARK__?.dataLayer,
      payload
    );
    triggerEvent('Event');
  }
};

const trackAPIEvents = (eventName, eventDetail, apiPath) => {
  if (window?.__CUSTOMER_SPARK__) {
    const payload = {
      event: eventName,
      eventDetail,
      apiPath,
      appName: APP_NAME,
      url: window?.location?.href,
      userDetails: {}
    };
    log('API Events', payload, 'ApiEvent');
    window.__CUSTOMER_SPARK__.dataLayer = Object.assign(
      {},
      window?.__CUSTOMER_SPARK__?.dataLayer,
      payload
    );
    triggerEvent('ApiEvent');
  }
};

// const flushTrackingInfo = (info, flushWith = {}) => {
//   window.__CUSTOMER_SPARK__.dataLayer[info] = flushWith;
//   triggerEvent(`flush${info}`);
// };

const triggerEvent = (type) => {
  if (window.__CUSTOMER_SPARK__?.dataLayer?.analyticsEvent?.push) {
    window.__CUSTOMER_SPARK__.dataLayer.analyticsEvent.push({ type: type });
  }
};

export default {
  loadAdobeScript,
  loadContent,
  setBase,
  trackClick,
  trackEvents,
  trackPageView,
  trackAPIEvents
};
