const styles = (theme) => ({
  emptyCartWrapper: {
    minHeight: '80%',
    //position: 'absolute',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: '#333333',
    position: 'relative',
    //marginTop: 40,
    fontSize: 14,
    '& p': {
      '&:nth-child(1)': {
        width: 178,
        marginBottom: 32,
        [theme.breakpoints.up('sm')]: {
          width: 432
        }
      },
      '&:nth-child(3)': {
        width: 260,
        marginTop: 32,
        [theme.breakpoints.up('sm')]: {
          width: 639
        }
      }
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: '50vw',
      fontSize: 18,
      //position: 'relative'
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '40vw'
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: '30vw',
      //marginTop: 80
    }
  }
});

export default styles;
