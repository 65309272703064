import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import CSLayout from '../../../../components/CSLayout';
import styles from '../styles';
import { CHANGE_PASSWORD } from '../../constanst';
import SubmitButton from '../../../../components/SubmitButton';
import PasswordFieldWithIcon from '../../../../components/PasswordFieldWithIcon';
import PasswordValidation from '../../../../components/PasswordValidation';
import { REGEXP_PASSWORD } from '../../../../constants/common';
import { Error } from '@material-ui/icons';

const ChangePasswordForm = (props) => {
  const { FORM_CONSTANTS } = CHANGE_PASSWORD;
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const { formValues, onFormChange, onFormSubmit } = props;
  const { currentPassword, newPassword, confirmPassword } = formValues;

  const isPasswordInValid = (passwordString) => {
    return passwordString !== '' && !REGEXP_PASSWORD.test(passwordString);
  };

  const isConfirmPasswordInValid = () => {
    return confirmPassword !== '' && confirmPassword !== newPassword;
  };

  const isSubmitDisabled = () => {
    return (
      !currentPassword ||
      !newPassword ||
      !confirmPassword ||
      isPasswordInValid(currentPassword) ||
      isPasswordInValid(newPassword) ||
      isConfirmPasswordInValid()
    );
  };

  const errorMessage = (errorText) => {
    return (
      <Grid item xs={12} className={classes.textFieldError}>
        <Grid container>
          <div>
            <Error className={classes.errorIcon} />
          </div>
          <Grid item>
            <Typography className={classes.textFieldErrorMsg}>
              {errorText}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <CSLayout>
      <Grid container className={classes.rootContainer}>
        <Grid container className={classes.mainContainer}>
          <Grid item xs={12} sm={12} md={4} />
          <Grid item xs={12} sm={12} md={4}>
            <Grid container className={classes.formContainer}>
              <Grid item xs={12} className={classes.header}>
                <Typography className={classes.headerText}>
                  {FORM_CONSTANTS.HEADER}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.subheader}>
                <Typography className={classes.subHeaderText}>
                  {FORM_CONSTANTS.SUB_HEADER}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.textBoxItem}>
                <PasswordFieldWithIcon
                  {...FORM_CONSTANTS.TEXT_FIELDS.CURRENT_PASSWORD}
                  value={currentPassword}
                  onValueChange={onFormChange}
                  error={isPasswordInValid(currentPassword)}
                  errorMessage={errorMessage(
                    FORM_CONSTANTS.ERROR_MSG_TEXTS.INVALID_PASSWORD
                  )}
                />
              </Grid>
              <Grid container>
                <Grid item xs={12} className={classes.textBoxItem}>
                  <PasswordFieldWithIcon
                    {...FORM_CONSTANTS.TEXT_FIELDS.NEW_PASSWORD}
                    value={newPassword}
                    onValueChange={onFormChange}
                    error={isPasswordInValid(newPassword)}
                    errorMessage={errorMessage(
                      FORM_CONSTANTS.ERROR_MSG_TEXTS.STRENGTH
                    )}
                  />
                </Grid>
                <Grid item xs={12} className={classes.textBoxItem}>
                  <PasswordFieldWithIcon
                    isConfirmedTrue={
                      !!confirmPassword && confirmPassword === newPassword
                    }
                    {...FORM_CONSTANTS.TEXT_FIELDS.CONFIRM_PASSWORD}
                    value={confirmPassword}
                    onValueChange={onFormChange}
                    error={isConfirmPasswordInValid()}
                    errorMessage={errorMessage(
                      FORM_CONSTANTS.ERROR_MSG_TEXTS.DONT_MATCH
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid item className={classes.passwordValidationItem} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            {newPassword !== '' && (
              <Grid container className={classes.passwordCheckContainer}>
                <Grid item className={classes.passwordCheckItem}>
                  <Typography
                    className={classes.passwordInstructionsHeadingText}
                  >
                    {FORM_CONSTANTS.PASS_VALIDATION_HEADER}
                  </Typography>
                  <PasswordValidation password={newPassword} />
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid container className={classes.submitButtonContainer}>
            <Grid item xs={12} sm={12} md={4} />
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              className={classes.submitButtonItem}
            >
              <Grid container className={classes.submitBtnInnerContainer}>
                <SubmitButton
                  {...FORM_CONSTANTS.SUBMIT_BUTTON}
                  labelClass={classes.submitButtonText}
                  buttonClass={classes.submitButton}
                  onSubmit={onFormSubmit}
                  disabled={isSubmitDisabled()}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={4} />
          </Grid>
        </Grid>
      </Grid>
    </CSLayout>
  );
};

export default ChangePasswordForm;
