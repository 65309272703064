export const APP_NAME = 'Customer Spark - Web';

export const TRACKER = {
  EVENTS: {
    CLICK: 'click',
    ERROR: 'click',
    EVENT: 'click'
  },
  PREFIXES: {
    TAB: 'cs|tab|',
    BUTTON: 'cs|btn|',
    ICON: 'cs|icon|',
    CARD: 'cs|card|',
    ERROR: 'cs|err|',
    EVENT: 'cs|evt|'
  }
};

export const PAGES = {
  ACTIVITIES: 'ACTIVITIES',
  BOTTOM_NAV: 'BOTTOM_NAV',
  CONTACT_US: 'CONTACT_US',
  FAQ: 'FAQ',
  FORGET_PASSWORD: 'FORGET_PASSWORD',
  HISTORY: 'HISTORY',
  HOME: 'HOME',
  LANDING: 'LANDING',
  PAGE_NOT_FOUND: 'PAGE_NOT_FOUND',
  PRIVACY_POLICY: 'PRIVACY_POLICY',
  PROFILE: 'PROFILE',
  REGISTRATION: 'REGISTRATION',
  REGISTRATION_FORM: 'REGISTRATION_FORM',
  RESET_PASSWORD: 'RESET_PASSWORD',
  REWARDS: 'REWARDS',
  SIGN_IN: 'SIGN_IN',
  TERMS_OF_USE: 'TERMS_OF_USE',
  UNSUBSCRIBE: 'UNSUBSCRIBE',
  USER_PROFILE: 'USER_PROFILE'
};

export const CLICK_EVENTS = {
  BACK_TO_HOME_PAGE: 'Back to home page',
  RESEND_OTP: 'Resend OTP',
  BACK_PRESS: 'Back press',
  UNSUBSCRIBE: 'Unsubscribe',
  SAVE_AVATAR: 'Save avatar',
  CANCEL: 'Cancel'
};

export const CONTEXT = {
  CUSTOMER_SPARK_WEB: 'customerSparkWeb'
};

export const ACTION = {
  LOG: 'log',
  OVERLAY: 'overlayView',
  API_CALL: 'apiCall'
};

export const NAME = {
  PAGE_VIEW: 'pageView',
  API_CALL: 'apiCall',
  EXCEPTION: 'exception'
};

export const LOG_LEVEL = {
  INFO: 'info',
  ERROR: 'error',
  DEBUG: 'debug',
  FATAL: 'fatal'
};

export const EVENT = {
  PAGE_VIEW: 'pageView',
  API_CALL: 'apiCall',
  EXCEPTION: 'exception',
  OTHER: 'other'
};

export const API_DATA_TYPE = {
  REQUEST: 'request',
  RESPONSE: 'response'
};

export const PROFILE_ACTIVITY_METRICS = {
  PAGE: 'profile',
  METRIC: 'profileInteraction',
  PROFILE_REVIEW_POPUP: 'profileReviewScreenPopup',
  REVIEW_PROFILE_BTN_CLICK: 'reviewProfileClick',
  PROFILE_HEADER_EXPANDED: 'profileHeaderExpanded'
};
