import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
  Fade,
  IconButton,
  Typography,
  withStyles
} from '@material-ui/core';
import styles from './styles';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import clsx from 'clsx';
import { IMAGE_FILE_TYPE, PDF_FILE_TYPE } from '../../constants/keywords';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import { getSizeFromBytesToKBMB } from '../../utilities/utils';
import { InfoRounded } from '@material-ui/icons';

const DropzoneContent = ({
  classes: {
    blockContainer,
    browse,
    clear,
    clearBtn,
    containPadding,
    container,
    contentPlaceHolder,
    dashedContainer,
    errorText,
    filterAdd,
    flexDisplay,
    fullWidth,
    greyout,
    iconAlign,
    innerBlock,
    loader,
    mainText,
    redDashed,
    relativeContainer,
    singleLineText,
    subFileContain,
    subText,
  },
  data,
  onClear,
  config
}) => {
  const getContentData = (item, key) => {
    if (item?.file?.type?.includes(IMAGE_FILE_TYPE)) {
      return (
        <img
          alt=""
          className={clsx(contentPlaceHolder, item?.loading && filterAdd)}
          src={URL.createObjectURL(item?.file)}
          key={key}
        />
      );
    } else if (item?.file?.type?.includes(PDF_FILE_TYPE))
      return (
        <embed
          src={URL.createObjectURL(item?.file)}
          className={clsx(contentPlaceHolder, item?.loading && filterAdd)}
          key={key}
        />
      );
    else {
      return null;
    }
  };

  return (
    <div className={container}>
      <div
        className={clsx(
          dashedContainer,
          data?.length ? subFileContain : containPadding,
          config?.error && redDashed
        )}
      >
        <div className={innerBlock}>
          <Typography
            classes={{ root: `${mainText} ${config?.disabled ? greyout : ''}` }}
          >
            <CloudUploadOutlinedIcon fontSize="small" color="primary" />
            Drop files to attach or <span className={browse}>Browse.</span>
          </Typography>
          {config?.subText?.map((subTxt, idx) => (
            <span className={subText} key={idx}>
              {subTxt}
            </span>
          ))}
        </div>
        {data?.length
          ? data?.map((item, idx) => (
              <Fade in={item?.file} timeout={1000} unmountOnExit>
                <div className={blockContainer}>
                  <div className={relativeContainer}>
                    {getContentData(item, idx)}
                    <Fade in={item?.loading} timeout={500}>
                      <CircularProgress
                        className={loader}
                        size={30}
                        thickness={5}
                      />
                    </Fade>
                    <IconButton
                      className={clear}
                      data-testid="clearStateButton"
                      disabled={item?.loading}
                      onClick={(evt) => {
                        evt.stopPropagation();
                        onClear(item, idx);
                      }}
                      size="small"
                    >
                      <HighlightOffRoundedIcon fontSize="small" />
                    </IconButton>
                  </div>
                  <Typography variant="subtitle1" className={singleLineText}>
                    {item?.file?.name}
                  </Typography>
                  <Typography variant="subtitle1" className={singleLineText}>
                    {getSizeFromBytesToKBMB(item?.file?.size)}
                  </Typography>
                  {item?.error ? (
                    <Typography
                      variant="subtitle1"
                      className={`${singleLineText} ${errorText}`}
                    >
                      {item?.error}
                    </Typography>
                  ) : null}
                </div>
              </Fade>
            ))
          : null}
        <Fade in={data?.length} timeout={500} unmountOnExit>
          <div className={fullWidth}>
            <Button
              variant="text"
              color="primary"
              className={clearBtn}
              onClick={(evt) => {
                onClear('reset');
                evt?.stopPropagation();
              }}
            >
              Clear all
            </Button>
          </div>
        </Fade>
      </div>
      <Fade in={!!config?.error} timeout={500}>
        <Typography
          variant="subtitle1"
          className={clsx(flexDisplay, errorText)}
        >
          <InfoRounded fontSize="small" className={iconAlign} />
          {config?.error}
        </Typography>
      </Fade>
    </div>
  );
};

DropzoneContent.propTypes = {
  className: PropTypes.shape({})
};

export default withStyles(styles)(DropzoneContent);
