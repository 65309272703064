import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  InputAdornment,
  LinearProgress,
  Link,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
  withStyles,
  IconButton
} from '@material-ui/core';
import { RemoveRedEye } from '@material-ui/icons';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import OkCancel from '../../components/AlertDialog/OkCancel';
import OnboardHeader from '../../components/OnboardHeader';
import PasswordRulesCheck from '../../components/PasswordRulesCheck';
import api from '../../constants/api';
import API, {
  API_STATUS_CODE,
  AUTO_HIDE_DURATION,
  TOAST_MSGS,
  errorMsg,
  ERR_MESSAGES
} from '../../constants/common';
import apiCall from '../../utils/api';
import {
  decodeValue,
  handleSessionStorage,
  isEmptyString,
  isValidEmailId,
  isValidOtp,
  isValidPassword,
  maskEmailId,
  getQueryStringValue
} from '../../utils/common';
import styles from './styles';
import { FormattedMessage } from 'react-intl';
import analytics from '../../utils/analytics';
import { CLICK_EVENTS, PAGES } from '../../constants/analytics';
import { logger } from '../../utils/logger';

const ResetPassword = ({ classes }) => {
  const { push } = useHistory();
  const location = useLocation();
  const initAlertBox = {
    open: false,
    primaryBtn: '',
    handleBtnClick: () => {},
    title: '',
    content: '',
    code: ''
  };

  const [alertBox, setAlertBox] = useState(initAlertBox);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [disableOtp, setDisableOtp] = useState(false);
  const [emailId, setEmailId] = useState('');
  const [isSnackbarOpen, setIsSnackbarOpen] = useState('');
  const [otpToken, setOtpToken] = useState('');
  const [pword, setPword] = useState('');
  const [pwdIsMasked, setPwdIsMasked] = useState(true);
  const [progress, setProgress] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    const extractedEmailId = getQueryStringValue(location, 'invite') || '';
    const decodedEmailId = decodeValue(extractedEmailId);
    setEmailId(decodedEmailId);
    if (!isValidEmailId(decodedEmailId)) {
      setIsSnackbarOpen(true);
      setSnackbarMessage(
        <FormattedMessage
          id="RESET_PASSWORD_URL_ERROR"
          defaultMessage={TOAST_MSGS.reset_password_url_error}
        />
      );
    }
  }, [location]);

  useEffect(() => {
    const forgotPassFlag = getQueryStringValue(
      location,
      'isforgotpasswordflow'
    );
    const isForgotPwdFlow = forgotPassFlag === null ? 'false' : forgotPassFlag;

    if (isValidEmailId(emailId)) {
      if (
        !handleSessionStorage.get('refreshLoad') &&
        isForgotPwdFlow !== 'true'
      ) {
        setAlertBox({
          open: true,
          primaryBtn: 'OK',
          handleBtnClick: sendOtpCall,
          content: TOAST_MSGS.otp_success_message(maskEmailId(emailId))
        });
      }
      handleSessionStorage.set('refreshLoad', true);
    }
  }, [emailId, location]);

  useEffect(() => {
    analytics.trackPageView(PAGES.RESET_PASSWORD);
  }, []);

  const sendOtpCall = () => {
    analytics.trackClick(PAGES.RESET_PASSWORD, CLICK_EVENTS.RESEND_OTP);
    setAlertBox(initAlertBox);
    setDisableOtp(true);
    const isforgotpasswordflow = getQueryStringValue(
      location,
      'isforgotpasswordflow'
    );

    const payload = {
      userId: emailId,
      registration: isforgotpasswordflow === 'false'
    };

    setProgress(true);
    apiCall(api.sendOtp, API.METHOD.POST, payload, true).then((response) => {
      setProgress(false);
      setTimeout(() => setDisableOtp(false), 60000);
      if (response?.error) {
        logger.error('failed to send otp', { emailId });
        setIsSnackbarOpen(true);
        setSnackbarMessage(response?.error?.message);
      }

      if (response?.status === API_STATUS_CODE.SUCCESS) {
        push(
          `/reset-password?invite=${btoa(
            emailId
          )}&isforgotpasswordflow=${isforgotpasswordflow}`
        );
      }
    });
  };

  const cancel = () => {
    analytics.trackClick(PAGES.RESET_PASSWORD, CLICK_EVENTS.CANCEL);
    push('/login');
  };

  const transformError = (errorMsg) => {
    if (errorMsg.includes('password code is incorrect.')) {
      return ERR_MESSAGES.AUTH_CODE_INCORRECT.replace(
        '{%type%}',
        getQueryStringValue(location, 'isforgotpasswordflow') === 'true'
          ? 'reset password'
          : 'registration'
      );
    } else if (errorMsg.includes('password code has expired.')) {
      return ERR_MESSAGES.AUTH_CODE_EXPIRED.replace(
        '{%mail%}',
        maskEmailId(emailId)
      ).replace(
        '{%type%}',
        getQueryStringValue(location, 'isforgotpasswordflow') === 'true'
          ? 'reset password'
          : 'registration'
      );
    }
    return errorMsg;
  };

  const resetPasswordCall = (event) => {
    event.preventDefault();

    if (pword && isValidPassword(pword) && pword === confirmPassword) {
      setProgress(true);
      const payload = {
        userId: emailId,
        password: pword,
        otp: otpToken
      };

      apiCall(`${api.resetPassword}`, API.METHOD.POST, payload, true).then(
        (response) => {
          setProgress(false);
          if (response?.error) {
            logger.error('failed to change password', { emailId });
            setAlertBox({
              code: 1,
              title: 'Action cannot be completed',
              open: true,
              primaryBtn: 'Continue',
              content: transformError(response?.error?.message)
            });
          }

          if (response?.status === API_STATUS_CODE.SUCCESS) {
            setAlertBox({
              code: 0,
              title: 'Success!',
              open: true,
              primaryBtn: 'OK',
              content: (
                <FormattedMessage
                  id="PASSWORD_UPDATED"
                  defaultMessage="Your password has been updated. Please use your new password to log in to Customer Spark."
                />
              )
            });
          }
        }
      );
    }
  };

  const isPasswordValid = () =>
    pword && isValidPassword(pword) && pword === confirmPassword;

  const togglePasswordMask = () => {
    setPwdIsMasked(!pwdIsMasked);
  };

  const handleConfirmModalClose = () => {
    if (alertBox.code) {
      setAlertBox(initAlertBox);
      if (alertBox.content.includes('one-time authorization code')) {
        setOtpToken('');
      } else {
        setPword('');
        setConfirmPassword('');
      }
    } else {
      const param = isValidEmailId(emailId) ? `?invite=${btoa(emailId)}` : '';
      push(`/login${param}`);
    }
  };

  return (
    <Grid className={classes.container}>
      <title>
        <FormattedMessage
          id="RESET_PASSWORD_TITLE"
          defaultMessage="Walmart - Customer Spark: Reset Password"
        />
      </title>
      <Grid className={classes.formHolder}>
        {progress && (
          <div className={classes.progress}>
            <LinearProgress />
          </div>
        )}
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={AUTO_HIDE_DURATION}
          message={snackbarMessage}
          onClose={() => setIsSnackbarOpen(false)}
          open={isSnackbarOpen}
          ContentProps={{
            classes: {
              root: classes.snackbar
            }
          }}
        />
        <OnboardHeader
          headerText={
            getQueryStringValue(location, 'isforgotpasswordflow') === 'true' ? (
              <FormattedMessage
                id="RESET_PASSWORD"
                defaultMessage="Reset Password"
              />
            ) : (
              <FormattedMessage
                id="COMPLETE_REGISTRATION"
                defaultMessage="Complete your registration"
              />
            )
          }
          headerContent={
            <FormattedMessage
              id="INFORMATION"
              defaultMessage="Please provide the information below"
            />
          }
        />
        <form
          autoComplete="off"
          className={classes.form}
          noValidate
          onSubmit={resetPasswordCall}
        >
          <div className={classes.pwdWrapper}>
            <TextField
              className={classes.textField}
              data-testid="enter-code"
              error={otpToken && !isValidOtp(otpToken)}
              id="otpCode"
              onChange={(event) => setOtpToken(event.target.value)}
              value={otpToken}
              variant="outlined"
              label={
                <FormattedMessage
                  id="ENTER_CODE"
                  defaultMessage="Enter the code we sent you"
                />
              }
            />
            {!isEmptyString(otpToken) && !isValidOtp(otpToken) && (
              <p className={classes.helperText} role="alert">
                <FormattedMessage
                  id="INVALID_CODE"
                  defaultMessage={errorMsg.INVALID_CODE}
                />
              </p>
            )}
          </div>
          <div className={`${classes.resendCodeWrapper} ${classes.marginWrap}`}>
            <Typography className={classes.mainBold}>
              <FormattedMessage
                id="DIDNOT_GET_CODE"
                defaultMessage="Didn’t get a code?"
              />
              &nbsp;
              <Tooltip
                title={
                  <FormattedMessage
                    id="RESEND_AFTER_1_MIN"
                    defaultMessage="You may resend the code after 1 minute has passed"
                  />
                }
                placement="top"
              >
                <Link
                  component="button"
                  onClick={sendOtpCall}
                  className={`${classes.resendCode} ${
                    disableOtp ? 'blockOtp' : ''
                  }`}
                  underline="hover"
                  disabled={disableOtp}
                  data-testid="resend"
                >
                  <FormattedMessage id="RESEND" defaultMessage="Resend" />
                </Link>
              </Tooltip>
            </Typography>
          </div>
          <Typography className={classes.passInfo}>
            <FormattedMessage
              id="PASSWORD_INFO"
              defaultMessage={TOAST_MSGS.password_info}
            />
          </Typography>
          <TextField
            autoComplete="new-password"
            className={classes.textField}
            error={pword && !isValidPassword(pword)}
            id="pword"
            type={pwdIsMasked ? 'password' : 'input'}
            variant="outlined"
            label={
              <FormattedMessage
                id="NEW_PASSWORD"
                defaultMessage="New password"
              />
            }
            onChange={(event) => setPword(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {pwdIsMasked ? (
                    <IconButton
                      onClick={togglePasswordMask}
                      size="small"
                      data-testid="show-eye"
                      aria-label="show password"
                    >
                      <RemoveRedEye className={classes.eye} />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={togglePasswordMask}
                      size="small"
                      data-testid="hide-eye"
                      aria-label="hide password"
                    >
                      <VisibilityOffIcon className={classes.eye} />
                    </IconButton>
                  )}
                </InputAdornment>
              )
            }}
            value={pword}
            data-testid="new-password"
          />
          <div className={classes.pwdWrapper}>
            <TextField
              id="confirmPwd"
              error={confirmPassword && pword !== confirmPassword}
              type="password"
              className={`${classes.textField} ${
                confirmPassword && pword !== confirmPassword
                  ? classes.helperText
                  : ''
              }`}
              variant="outlined"
              label={
                <FormattedMessage
                  id="CONFIRM_NEW_PASSWORD"
                  defaultMessage="Confirm new password"
                />
              }
              onChange={(event) => setConfirmPassword(event.target.value)}
              value={confirmPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {pword && pword === confirmPassword ? (
                      <CheckCircleIcon color="primary" />
                    ) : (
                      ''
                    )}
                  </InputAdornment>
                )
              }}
              data-testid="confirm-password"
            />
            {!isEmptyString(pword) &&
              !isValidPassword(pword) &&
              !confirmPassword && (
                <p className={classes.helperText} role="alert">
                  <FormattedMessage
                    id="INVALID_PASS"
                    defaultMessage={errorMsg.INVALID_PASS}
                  />
                </p>
              )}
            {confirmPassword && pword !== confirmPassword && (
              <p className={classes.helperText} role="alert">
                <FormattedMessage
                  id="PASS_NO_MATCH"
                  defaultMessage={errorMsg.PASS_NO_MATCH}
                />
              </p>
            )}
          </div>
          <div className={classes.buttonHolder}>
            <Button
              className={classes.cancelButton}
              color="primary"
              id="rp-cancel"
              onClick={cancel}
              value="cancel"
              variant="text"
            >
              <FormattedMessage id="CANCEL" defaultMessage="Cancel" />
            </Button>
            <Button
              className={classes.resetButton}
              color="primary"
              disabled={progress || !isPasswordValid() || !isValidOtp(otpToken)}
              id="reset-pwd"
              type="submit"
              value="Post"
              variant="contained"
            >
              {getQueryStringValue(location, 'isforgotpasswordflow') ===
              'true' ? (
                <FormattedMessage
                  id="RESET_PASSWORD"
                  defaultMessage="Reset Password"
                />
              ) : (
                <FormattedMessage id="REGISTER" defaultMessage="Register" />
              )}
            </Button>
          </div>
        </form>
      </Grid>
      <OkCancel
        content={alertBox.content || ''}
        isDisabled={progress}
        open={alertBox.open}
        primaryBtnClick={alertBox.handleBtnClick || handleConfirmModalClose}
        primaryBtnText={alertBox.primaryBtn}
        title={alertBox.title}
      />
      {pword && (
        <Grid className={classes.passwordRulesHolder}>
          <PasswordRulesCheck pword={pword} />
        </Grid>
      )}
    </Grid>
  );
};

ResetPassword.defaultProps = {
  classes: {}
};

ResetPassword.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(ResetPassword);
