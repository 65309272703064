import { configureStore } from '@reduxjs/toolkit';
import {
  SurveyTakerApi,
  SurveyTakerReducer
} from '@walmart/survey-taker-api-js-sdk';
import getCurrentEnv from '../../utils/env';

const middleware = [];

export const setupStore = (preloadedState) =>
  configureStore({
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: { surveyTakerApi: SurveyTakerApi }
        }
      }).concat(middleware),
    reducer: {
      SurveyTaker: SurveyTakerReducer
    },
    preloadedState
  });
const store = setupStore({
  SurveyTaker: {
    user: {
      environment: getCurrentEnv().toUpperCase()
    }
  }
});

export default store;
