const styles = (theme) => ({
  root: { justifyContent: 'center' },
  rootContainer: {
    justifyContent: 'center',
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '70%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '85%'
    },
    [theme.breakpoints.down('xs')]: {
      width: '95%'
    }
  },
  formContainer: {
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginBottom: '10vh'
  },
  headingContainer: {
    flexWrap: 'wrap',
    justifyContent: 'center',
    justify: 'center',
    marginTop: 5,
    marginBottom: 32
  },
  headingTypography: {
    fontSize: 32,
    fontWeight: 400,
    letterSpacing: 0,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: 24
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 20
    }
  },
  radioOptionsContainer: {
    justifyContent: 'center'
  },
  radioButtonLabel: {
    fontSize: '24px',
    fontWeight: '400',
    letterSpacing: '0px',
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
      fontSize: 20
    }
  },
  radioBtnRoot: {
    '&.checked': {
      color: 'blue'
    }
  },
  conditionalContainer: {
    justifyContent: 'center',
    alignContent: 'space-around'
  },
  otpMessageContainer: {
    justifyContent: 'center',
    marginBottom: '20px',
    marginTop: '20px'
  },
  otpMessageItem: {},
  otpMessageTypography: {
    fontSize: '24px',
    fontWeight: '400',
    letterSpacing: '0px',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      fontSize: 20
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 18
    }
  },
  authCodeContainer: {
    justifyContent: 'center'
  },
  authCodeItem: {
    marginBottom: 16,
    marginTop: 16,
    paddingLeft: 16,
    paddingRight: 16
  },
  resendOtpContainer: {
    justifyContent: 'flex-end',
    alignItems: 'baseline'
  },
  resendOtpTypography: {
    fontSize: '20px',
    fontWeight: '400',
    letterSpacing: '0px',
    textAlign: 'left'
  },
  resendButton: {
    color: '#0071CE',
    textTransform: 'none',
    fontSize: '20px',
    fontWeight: '500',
    letterSpacing: '0px',
    textAlign: 'left'
  },
  resendButtonLabel: {
    paddingBottom: 7
  }
});

export default styles;
