import { truncate } from '../../utilities/utils';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '4px'
  },
  dashedContainer: {
    width: '100%',
    textAlign: 'center',
    borderRadius: 8,
    border: 'dashed 2px #dadce0',
    gap: '8px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'center',
    [theme?.breakpoints?.down?.('md')]: {}
  },
  redDashed: {
    border: 'dashed 2px #CB2022'
  },
  containPadding: {
    minHeight: 93,
    padding: '22.5px 0',
    [theme?.breakpoints?.down?.('sm')]: {
      minHeight: 84,
      padding: '34px 0 32px 0'
    }
  },
  subFileContain: {
    padding: '16px 0',
    minHeight: 168,
    [theme?.breakpoints?.down?.('sm')]: {
      padding: '16px 0',
      minHeight: 84
    }
  },
  midBold16Medium: {
    fontFamily: theme?.font?.medium,
    fontSize: 16,
    fontWeight: 500
  },
  darkFont: {
    color: theme?.palette?.text?.main
  },
  primaryFont: {
    color: theme?.palette?.primary?.main
  },
  innerBlock: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  contentPlaceHolder: {
    objectFit: 'fill',
    backgroundColor: '#e6dede',
    height: 80,
    maxWidth: '100%'
  },
  clear: {
    marginTop: 0,
    cursor: 'pointer',
    fontSize: 14,
    textTransform: 'none',
    width: 'fit-content',
    padding: 0,
    position: 'absolute',
    right: 8,
    color: '#000',
    backgroundColor: '#fff'
  },
  relativeContainer: {
    alignItems: 'center',
    border: '1px dashed #DADCE0',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    height: 78,
    minWidth: 118,
    padding: 10,
    position: 'relative'
  },
  blockContainer: {
    width: 140,
    padding: 10,
    position: 'relative'
  },
  loader: {
    position: 'absolute',
    left: '42%',
    top: '32%'
  },
  filterAdd: {
    filter: 'opacity(0.5)'
  },
  errorText: {
    color: theme?.palette?.error?.main,
    textAlign: 'center',
    width: '100%'
  },
  subText: {
    color: '#838588',
    textAlign: 'center',
    fontSize: 12,
    fontFamily: 'Bogle',
    fontWeight: 400,
    lineHeight: 'normal'
  },
  mainText: {
    color: '#333',
    fontSize: 16,
    fontStyle: 'normal',
    fontFamily: 'Bogle',
    fontWeight: 400,
    lineHeight: 'normal',
    display: 'flex',
    marginBottom: 4,
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8
  },
  browse: {
    color: theme?.palette?.primary?.main
  },
  singleLineText: {
    ...truncate(1)
  },
  flexDisplay: {
    display: 'flex',
    alignItems: 'center',
    gap: 4
  },
  iconAlign: {
    display: 'flex',
    alignSelf: 'baseline'
  },
  greyout: {
    filter: 'grayscale(1)'
  },
  fullWidth: {
    display: 'block',
    width: '100%'
  },
  clearBtn: {
    textTransform: 'none',
    padding: '0px 8px',
    textDecoration: 'underline'
  }
});

export default styles;
