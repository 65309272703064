const styles = (theme) => ({
  iframe: {
    border: 'none',
    width: '100%',
    height: '80vh'
  },
  marginTop: {
    marginTop: 72,
    marginLeft: 2,
    [theme.breakpoints.up('lg')]: {
      marginLeft: 18
    }
  }
});

export default styles;
