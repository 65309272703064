import { FOOTER_HEADER } from './keywords';

const API = {
  DEFAULT_HEADER: {
    Accept: 'application/json',
    // 'Content-Type': 'application/json; charset=UTF-8',
    'Cache-Control': 'no-store'
  },
  METHOD: {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE'
  }
};

export default API;

export const API_STATUS_CODE = {
  ACCEPTED: 202,
  CREATED: 201,
  EXISTS: 409,
  RATE_LIMIT: 429,
  SUCCESS: 200,
  UNAUTHORIZED: 401
};
export const AUTO_HIDE_DURATION = 10_000;
export const DATE_DISPLAY_SLASH_FORMAT = 'MM/DD/YY';
export const FOOTER_LINK_NAMES = {
  CONTACT_US: 'Contact Us',
  PRIVACY_NOTICE: 'Privacy Notice',
  TERMS_OF_USE: 'Terms of Use',
  FAQ: 'Frequently Asked Questions',
  UNSUBSCRIBE: 'Unsubscribe',
  YOUR_PRIVACY_CHOICES: 'Your Privacy Choices',
  REQ_PERSONAL_INFO: 'Request My Personal Information',
  NOTICE_COLLECTION: 'Notice at Collection'
};

export const FOOTER_LINKS = [
  {
    name: FOOTER_LINK_NAMES.CONTACT_US,
    path: '/contact-us?flow=bottomNav',
    type: FOOTER_HEADER.CUSTOMER_SPARK
  },
  {
    name: FOOTER_LINK_NAMES.PRIVACY_NOTICE,
    url: 'https://one.walmart.com/content/walmart_customer_spark_community/Privacy_Policy.html',
    path: '/privacy-policy?flow=bottomNav',
    type: FOOTER_HEADER.CUSTOMER_SPARK
  },
  {
    name: FOOTER_LINK_NAMES.TERMS_OF_USE,
    url: 'https://one.walmart.com/content/walmart_customer_spark_community/Terms_of_Use.html',
    path: '/terms-of-use?flow=bottomNav',
    type: FOOTER_HEADER.CUSTOMER_SPARK
  },
  {
    name: FOOTER_LINK_NAMES.FAQ,
    url: 'https://one.walmart.com/content/walmart_customer_spark_community/FAQ',
    path: '/faq?flow=bottomNav',
    type: FOOTER_HEADER.CUSTOMER_SPARK
  },
  {
    name: FOOTER_LINK_NAMES.UNSUBSCRIBE,
    path: '/unsubscribe?flow=bottomNav',
    type: FOOTER_HEADER.CUSTOMER_SPARK
  },
  {
    name: FOOTER_LINK_NAMES.YOUR_PRIVACY_CHOICES,
    path: 'https://cpa-ui.walmart.com/affirmation?native=false&app=gm&brandCode=WMT&requestType=optout',
    type: FOOTER_HEADER.WALMART
  },
  {
    name: FOOTER_LINK_NAMES.REQ_PERSONAL_INFO,
    path: 'https://cpa-ui.walmart.com/affirmation?native=false&app=gm&brandCode=WMT&requestType=access',
    type: FOOTER_HEADER.WALMART
  },
  {
    name: FOOTER_LINK_NAMES.PRIVACY_NOTICE,
    path: 'https://corporate.walmart.com/privacy-security/walmart-privacy-notice',
    type: FOOTER_HEADER.WALMART
  },
  {
    name: FOOTER_LINK_NAMES.NOTICE_COLLECTION,
    path: 'https://corporate.walmart.com/privacy-security/california-privacy-rights',
    type: FOOTER_HEADER.WALMART
  }
];
export const GIFT_CARD_OPTIONS = [
  {
    id: 5,
    label: '$5 Gift Card - 500 pts',
    value: '$5 Gift Card',
    disabled: true
  },
  {
    id: 25,
    label: '$25 Gift Card - 2500 pts',
    value: '$25 Gift Card',
    disabled: true
  },
  {
    id: 50,
    label: '$50 Gift Card - 5000 pts',
    value: '$50 Gift Card',
    disabled: true
  },
  {
    id: 100,
    label: '$100 Gift Card - 10000 pts',
    value: '$100 Gift Card',
    disabled: true
  }
];
export const HISTORY_TAB_ITEMS = [
  { display: 'Rewards History', value: 'rewards history' },
  { display: 'Activities History', value: 'activities history' }
];
export const HOME_TAB_ITEMS = [
  { display: 'Rewards', value: 'rewards' },
  { display: 'Activities', value: 'activities' },
  { display: 'History', value: 'history' }
];
export const LIST_SIZE = 10;
export const LIST_TABLE_MAPPING = {
  rewardsHistory: [
    { display: 'Reward Type', field: 'rewardType', type: 'name', width: '20%' },
    { display: 'Points', field: 'points', type: 'points', width: '20%' },
    { display: 'Status', field: 'status', type: 'status', width: '20%' },
    {
      display: 'Redeemed Date',
      field: 'timestamp',
      sort: true,
      type: 'date',
      width: '20%'
    },
    {
      display: 'Reward Details',
      field: 'linkTitle',
      type: 'clickable',
      width: '20%',
      enable: 'showPopup',
      clickableField: 'id'
    }
  ],
  activityHistory: [
    { display: 'Activity Name', field: 'name', type: 'name' },
    { display: 'Activity ID', field: 'shortId', type: 'shortId' },
    { display: 'Points', field: 'points', type: 'points' },
    { display: 'Status', field: 'status', type: 'status' },
    { display: 'Date', field: 'timestamp', sort: true, type: 'date' }
  ]
};
export const REGEXP_OTP = /^(?=.*[0-9])(?=.{6,8}$)/;
export const REGEXP_PASSWORD =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,30}$)/;

//export const REGEXP_EMAILID = /^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
export const REGEXP_EMAILID =
  /^[a-z0-9!'#$%&*+\/=?^_`{|}~-]+(?:\.[a-z0-9!'#$%&*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-zA-Z]{2,}$/;

// Acceptable examples: Los Angles, El Cajón, New New City, Bentonville (No heading or trailing spaces)
// Unacceptable examples: (String's length exceeds 20), $Los Angles, Los123
export const REGEXP_CITY =
  /^(?=.{1,20}$)[a-zA-Z\u00C0-\u02AB]+( [a-zA-Z\u00C0-\u02AB]+)*$/u;

export const SERVICE_NAMES = [
  { key: 'customer-spark-service', name: 'customer-spark-service' }
];
export const SORT_VALUE = ['desc', 'asc'];
export const TOAST_MSGS = {
  activity_pref_update: 'Your Activity Frequency Preferences were updated.',
  forgot_password_url_error:
    'Please copy and paste the forgot password link correctly.',
  generic_error:
    'Something went wrong. Please try after sometime or contact us.',
  profile_info_update: 'Your Profile Information was updated.',
  address_info_update: 'Your Address was updated.',
  reset_password_url_error:
    'Please enter a valid URL or press cancel and use Forgot Password link.',
  reset_password_click: 'We just sent you a one-time authorization code to ',
  resend_code_time_limit:
    'If you do not receive our code within a few minutes, please click on the “Resend” to send the code to your email again.',
  valid_email_address: 'Please enter a valid email address.',
  valid_email_password: 'Please enter a valid email and password.',
  otp_success_message: (email) =>
    `We will send you a one-time authorization code to (${email}). If you do not receive the code within a few minutes, please click “Resend” to send the code to your email again.`,
  password_info: `Strong password required: 8 to 30 characters. You should use a mix of numbers, letters (upper and lower case) and symbols (ie: #, &, $, %). Consecutive
  identical characters cannot be more than 3.`,
  profile_review_activity_log_err: `Sorry something went wrong. You won't be able to collect points for this activity`
};

export const EMAIL_UPDATE_SNACK_MSG = `You successfully changed your email. Please login to access the community!`;

export const PASSWORD_UPDATE_SNACK_MSG = `You successfully changed your password. Please login to access the community!`;

export const PASSWORD_UPDATED_MESSAGE = `Your Password was successfully updated.`;

export const PROFILE_REVIEW_LOGIN_SNACK = 'Please login to review your profile';

export const confAgreeContent = {
  CONFIRM_DIALOG: `I understand that by proceeding I will not remain a member of the Walmart Customer Spark Community.`,
  CUST_PORT_URL: `https://customersparkcommunity.walmart.com/`,
  TERMS_URL: `https://one.walmart.com/content/walmart_customer_spark_community/Terms_of_Use.html`,
  PRIV_POLICY_URL: `https://one.walmart.com/content/walmart_customer_spark_community/Privacy_Policy.html`,
  LABEL_YES: `I agree that I have read and hereby consent to the Spark Community Terms of Use.`,
  LABEL_NO: `I have not read, do not consent, and/or do not understand the Spark Community Terms of Use. I understand that by not consenting to the terms that I will not remain a member of the Walmart Customer Spark Community.`
};

export const unsubContent = [
  {
    TEXT: `Are you sure you want to unsubscribe from the Walmart Customer Spark Community?`
  },
  {
    TEXT: `Note: If you unsubscribe, you will lose any unredeemed points within your account, you will no longer be a member of the Walmart Customer Spark Community and you will lose access to the customer portal.`
  }
  // The message is being because backend setup for auto redeeming points is not active as of now
  // ,
  // {
  //   TEXT: `Once you unsubscribe, all eligible points (500 or more) will be auto-redeemed and any eligible eGift card(s) will be sent to your email on file.`
  // }
];

export const errorMsg = {
  INVALID_CODE: `Please enter a valid code.`,
  INVALID_PASS: `Please enter a valid password.`,
  PASS_NO_MATCH: `Passwords do not match.`
};

export const EMAIL_FIELD_LENGTH = 320;

export const ERRORS = {
  IAM: {
    AUTH_CODE: {
      INCORRECT: 'IAM-1033',
      EXPIRED: 'IAM-1034'
    },
    PASSWORD: {
      NEW_OLD_SAME: 'IAM-1015',
      FORMAT_MISMATCH: 'IAM-1000',
      INVALID: 'IAM-1052'
    }
  }
};

export const ERR_MESSAGES = {
  AUTH_CODE_INCORRECT:
    'Your one-time authorization code is incorrect. In order to proceed, please enter the code again and continue the {%type%} process.',
  AUTH_CODE_EXPIRED:
    'Your one-time authorization code has expired. In order to proceed, please resend the code to {%mail%} and continue the {%type%} process.',
  AUTH_DEFAULT: 'Invalid email and/or password or not a valid account.'
};

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const monthMap = {
  January: '01',
  February: '02',
  March: '03',
  April: '04',
  May: '05',
  June: '06',
  July: '07',
  August: '08',
  September: '09',
  October: '10',
  November: '11',
  December: '12'
};

export const getDobYearsRange = () => {
  let yearValues = [];
  const date = new Date();
  const currYear = date.getFullYear();

  for (let i = currYear; i > currYear - 100; i--) {
    yearValues.push(i);
  }

  return yearValues;
};

export const APIConstants = {
  dataTypes: {
    PERSONAL: 'personal'
  },
  detailNames: {
    DOB: 'per_dob'
  },
  selectionTypes: {
    DATE: 'date_type',
    TEXT: 'open_text',
    SINGLE: 'single',
    MULTIPLE: 'multiple',
    JSON: 'json'
  }
};

export const DOB_MONTH_OPTIONS = [
  {
    id: '01',
    label: 'January',
    value: 'jan'
  },
  {
    id: '02',
    label: 'February',
    value: 'feb'
  },
  {
    id: '03',
    label: 'March',
    value: 'mar'
  },
  {
    id: '04',
    label: 'April',
    value: 'apr'
  },
  {
    id: '05',
    label: 'May',
    value: 'may'
  },
  {
    id: '06',
    label: 'June',
    value: 'jun'
  },
  {
    id: '07',
    label: 'July',
    value: 'jul'
  },
  {
    id: '08',
    label: 'August',
    value: 'aug'
  },
  {
    id: '09',
    label: 'September',
    value: 'sep'
  },
  {
    id: '10',
    label: 'October',
    value: 'oct'
  },
  {
    id: '11',
    label: 'November',
    value: 'nov'
  },
  {
    id: '12',
    label: 'December',
    value: 'dec'
  }
];

export const LANDING_PAGE_CONTENT = {
  Q1: `What is the Walmart Customer Spark Community?`,
  A1: `A community of Walmart customers, just like you, from across the country. The Walmart Customer Spark Community offers members a variety of activities to provide feedback on products, services and more. Our goal is to continue to make you, the customer, #1 by listening to your opinions.`,
  Q2: `How does the Walmart Customer Spark Community work?`,
  A2: `The Walmart Customer Spark Community is invitation only. When customers are invited, they join and can participate in our activities. It's that easy! Each activity has a point value. The more activities completed, the more points earned. Points can be redeemed for Walmart eGift cards.`
};

export const LANDING_FAQ_CONTENT = [
  {
    question: `What is the Walmart Customer Spark Community?`,
    answer: `Walmart Customer Spark Community is an invitation only community where members have the opportunity to participate in surveys, customer interviews, other activities and more.`
  },
  {
    question: `How does the Walmart Customer Spark Community work?`,
    answer: `When customers complete activities, they earn points redeemable for Walmart eGift cards.`
  },
  {
    question: `Can I join the Spark Community?`,
    answer: `At this time, the Spark Community is an invitation only community. If you are selected to participate, you will receive an email from us to learn more.`
  },
  {
    question: `Is this the Spark Reviewer program?`,
    answer: `Though you may be asked to provide product reviews, we are not the Spark Reviewer Program. The Walmart Customer Spark Community provides you with activities beyond only reviews to engage with us and earn points.`
  },
  {
    question: `How do I unsubscribe?`,
    answer: `You can unsubscribe from Spark Community via the 'Unsubscribe' link on the Spark Community customer portal or from any Spark Community email. Please note it will take 72 hours to process this request.`
  },
  {
    question: `I have a question or comment about a recent experience at or order from Walmart. Can you help?`,
    answer: `To ensure that the appropriate team can help as quickly as possible, please visit the Walmart Help Center page {%helpCenter%}`
  },
  {
    question: `What is Walmart's Privacy Notice?`,
    answer: `Walmart has detailed information on their Privacy Notice page {%privacy%}`
  },
  {
    question: `I have questions about the Walmart Customer Spark Community that are not addressed here. Can you help?`,
    answer: `Yes! You can reach us directly at sparkcommunity@walmart.com.`,
    note: `Note: If you are already a member, please login first to review additional FAQs that might answer your question.`
  }
];

export const LANDING_FAQ_LINKS = {
  HELP: 'https://www.walmart.com/help',
  PRIVACY:
    'https://corporate.walmart.com/privacy-security/walmart-privacy-policy'
};

export const LANDING_REVIEW_CONTENT = [
  {
    avatar: `customer-spark-external-male-person-1`,
    heading: `From Texas`,
    review: `“I feel appreciated as an important customer. And, I am rewarded for my participation!”`
  },
  {
    avatar: `customer-spark-external-male-person-4`,
    heading: `From Massachusetts`,
    review: `“Walmart cares about its customer experience and gives the opportunity to Spark members to give opinions.”`
  },
  {
    avatar: `customer-spark-external-female-person-2`,
    heading: `From Ohio`,
    review: `“It is fun, interesting and most of all you get voice your honest opinions and feedback!!”`
  },
  {
    avatar: `customer-spark-external-female-person-3`,
    heading: `From Washington`,
    review: `“Surveys are relatively easy to complete and the rewards are fair considering the amount of time involved.” `
  },
  {
    avatar: `customer-spark-external-female-person-5`,
    heading: `From California`,
    review: `“I enjoy giving my opinion about products and things going on in the community. I don't feel it takes too much of my time at all.”`
  },
  {
    avatar: `customer-spark-external-male-person-6`,
    heading: `From Ohio`,
    review: `“Getting rewarded with points that earn money at for weekly shopping trip is fantastic!”`
  },
  {
    avatar: `customer-spark-external-female-person-7`,
    heading: `From North Carolina`,
    review: `“I feel that my opinion is valued,and I get to make recommendations for items,or services that I would like to have available at my local Walmart.”`
  },
  {
    avatar: `customer-spark-external-female-person-9`,
    heading: `From Oklahoma`,
    review: `“The Spark Community is awesome!!”`
  },
  {
    avatar: `customer-spark-external-female-person-10`,
    heading: `From New York`,
    review: `“Surveys are easy and time efficient.”`
  },
  {
    avatar: `customer-spark-external-male-person-8`,
    heading: `From Virginia`,
    review: `“It gives me an opportunity to contribute to future decisions made to stock and functions of a store I shop often.”`
  }
];

export const LANDING_VIDEO_ID = {
  hero: '725844228/5b368e1a90',
  whatCS: '725843025/2646312de0',
  howCS: '725842728/92b2dc3ee4'
};

export const PROFILE_ACCORDION_DATA = [
  {
    type: 'personInfo',
    summary: 'Personal Information',
    details: [
      { name: 'Screen name', key: 'screenName', apiKey: 'per_screen_name' },
      { name: 'Birth month & year', key: 'dateOfBirth', apiKey: 'per_dob' },
      { name: 'Education', key: 'education', apiKey: 'per_education' },
      { name: 'Employment', key: 'employment', apiKey: 'per_employment' },
      { name: 'Zip Code', key: 'zipCode', apiKey: 'per_zip_code' }
    ]
  },
  {
    type: 'householdInfo',
    summary: 'Household Information',
    details: [
      {
        name: 'Decision making',
        key: 'decisionMaking',
        apiKey: 'ho_decision_making',
        info: ''
      },
      {
        name: 'Pet ownership',
        key: 'petOwned',
        apiKey: 'ho_pet_ownership',
        info: ''
      },
      {
        name: 'Walmart Health',
        key: 'wmHealth',
        apiKey: 'ho_walmart_health_indicator',
        info: 'Have you ever visited a Walmart Health Center for your healthcare services (e.g. primary care, dental, hearing, labs, x-ray, or counseling)?'
      },
      {
        name: 'Family pharmacy',
        key: 'familyPharmacy',
        apiKey: 'ho_family_pharmacy',
        info: 'Where do you regularly fill your or your family’s prescriptions?'
      }
    ]
  },
  {
    type: 'shopping',
    summary: 'Shopping',
    details: [
      {
        name: 'Retail memberships',
        key: 'retailMembership',
        apiKey: 'sho_retail_membership'
      }
    ]
  },
  {
    type: 'activityPref',
    summary: 'Activity Preferences',
    details: [
      {
        name: 'Activity frequency',
        key: 'actFrequency',
        apiKey: 'ap_act_frequency'
      },
      { name: 'Surveys', key: 'surveys', apiKey: 'ap_surveys_flag' },
      { name: 'Video activities', key: 'videoActs', apiKey: 'ap_video_flag' },
      { name: 'Other', key: 'other', apiKey: 'ap_other_flag' }
    ]
  }
];

export const PROFILE_OPTIONS_LIST = [
  {
    type: 'decisionMaking',
    selectionType: 'single',
    options: [
      {
        id: 1,
        label: 'I do all or most of the decision making',
        value: 1
      },
      {
        id: 2,
        label: 'I equally share the decision making with someone else',
        value: 2
      },
      {
        id: 3,
        label: 'Someone else makes all or most of the decisions',
        value: 3
      }
    ]
  },
  {
    type: 'education',
    selectionType: 'single',
    options: [
      {
        id: 1,
        label: 'Some high school or less',
        value: 1
      },
      {
        id: 2,
        label: 'Graduated high school',
        value: 2
      },
      {
        id: 3,
        label: 'Some college or technical school',
        value: 3
      },
      {
        id: 4,
        label: 'Graduated college or technical school',
        value: 4
      },
      {
        id: 5,
        label: 'Post graduate degree',
        value: 5
      },
      {
        id: 6,
        label: 'Prefer not to answer',
        value: 6
      }
    ]
  },
  {
    type: 'employment',
    selectionType: 'single',
    options: [
      {
        id: 1,
        label: 'Employed full time',
        value: 1
      },
      {
        id: 2,
        label: 'Employed part time',
        value: 2
      },
      {
        id: 3,
        label: 'Full time homemaker',
        value: 3
      },
      {
        id: 4,
        label: 'Full time student',
        value: 4
      },
      {
        id: 5,
        label: 'Part time student',
        value: 5
      },
      {
        id: 6,
        label: 'Retired',
        value: 6
      },
      {
        id: 7,
        label: 'Not currently working',
        value: 7
      },
      {
        id: 8,
        label: 'Prefer not to answer',
        value: 8
      }
    ]
  },
  {
    type: 'familyPharmacy',
    selectionType: 'multiple',
    options: [
      {
        id: 1,
        label: 'Walmart',
        value: 1
      },
      {
        id: 2,
        label: 'Walgreens',
        value: 2
      },
      {
        id: 3,
        label: 'CVS',
        value: 3
      },
      {
        id: 4,
        label: `Warehouse Sams or Costco Pharmacy`,
        value: 4
      },
      {
        id: 5,
        label: 'Local Pharmacy',
        value: 5
      },
      {
        id: 6,
        label: 'Other Pharmacy',
        value: 6
      },
      {
        id: 7,
        label: 'No Pharmacy',
        value: 7
      }
    ]
  },
  {
    type: 'petOwned',
    selectionType: 'multiple',
    options: [
      {
        id: 1,
        label: 'Dog',
        value: 1
      },
      {
        id: 2,
        label: 'Cat',
        value: 2
      },
      {
        id: 3,
        label: 'Reptile',
        value: 3
      },
      {
        id: 4,
        label: 'Rodent',
        value: 4
      },
      {
        id: 5,
        label: 'Fish',
        value: 5
      },
      {
        id: 6,
        label: 'Other',
        value: 6
      }
    ]
  },
  {
    type: 'retailMembership',
    selectionType: 'multiple',
    options: [
      {
        id: 1,
        label: 'Amazon Fresh Online Grocery',
        value: 1
      },
      {
        id: 2,
        label: 'Target Online Grocery',
        value: 2
      },
      {
        id: 3,
        label: 'Walmart Online Grocery Pickup',
        value: 2
      }
    ]
  },
  {
    type: 'wmHealth',
    options: [
      {
        id: 1,
        label: 'Yes',
        value: 1
      },
      {
        id: 2,
        label: 'No',
        value: 2
      }
    ]
  }
];

export const PROFILE_SELECT_FIELDS = [
  'decisionMaking',
  'education',
  'employment',
  'familyPharmacy',
  'petOwned',
  'retailMembership',
  'wmHealth'
];

export const ENGLISH_LANGUAGES = ['en-GB', 'en-US'];

export const PROFILE_SWITCH_FIELDS = ['surveys', 'videoActs', 'other'];
export const SOURCE = '&source=cs-web';

// Need to configure URL's
export const ADOBE_URL = {
  prod: 'https://assets.adobedtm.com/a49994c1c918/1a5e8ee9a56d/launch-933ac1a5b828.min.js',
  dev: 'https://assets.adobedtm.com/a49994c1c918/1a5e8ee9a56d/launch-9c195f3ab91b-development.min.js',
  stage:
    'https://assets.adobedtm.com/a49994c1c918/1a5e8ee9a56d/launch-53b6827c154f-staging.min.js'
};

export const PULSE_DETAILS = {
  prod: {
    url: 'https://beacon.walmart.com/beacon.js?bd=beacon.walmart.com&bh=beacon.customerspark.walmart.com',
    bd: 'beacon.walmart.com',
    bh: 'beacon.customerspark.walmart.com'
  },
  stage: {
    url: 'https://beacon.qa.walmart.com/beacon.js?bd=beacon.qa.walmart.com&bh=beacon.qa.customerspark.walmart.com',
    bd: 'beacon.qa.walmart.com',
    bh: 'beacon.qa.customerspark.walmart.com'
  },
  dev: {
    url: 'https://beacon.qa.walmart.com/beacon.js?bd=beacon.qa.walmart.com&bh=beacon.qa.glassDeveloper.walmart.com',
    bd: 'beacon.qa.walmart.com',
    bh: 'beacon.qa.glassDeveloper.walmart.com'
  }
};

export const onLoadSuccess = [
  'homeLogoEvent',
  'signIn',
  'termsUseClose',
  'unsubCancel',
  'backFromProfile',
  'newRecruitment',
  'routedFromReviewProfileMaybeLater'
];

export const ERROR_FALLBACK = {
  ERROR_HEADER: 'Oops!',
  ERROR_MSG: ['We’re having technical issues.', 'Give it another try, please'],
  BUTTON_LABEL: 'Try Again'
};

export const ONE_MB_IN_BYTES = 1000000;
export const SIZE_IN_MB = 10;

export const FILE_SIZE_SUPPORTED = SIZE_IN_MB * ONE_MB_IN_BYTES;

export const FILE_SIZE_EXCEEDED_WARNING = `Action cannot be completed.
The file is too large, please upload a file no larger than ${SIZE_IN_MB} MB / ${
  SIZE_IN_MB * 1000
} KB.`;
export const FILE_FORMAT_UNSUPPORTED =
  'Action cannot be completed. The file type is not supported, please upload (.jpg/jpeg, .png, .pdf files only) files only.';
export const ASSETS_COUNT = 5;
export const FILE_COUNT_EXCEED_WARNING = `You reached the maximum number of images. Maximum ${ASSETS_COUNT} images.`;

export const ISSUES_SUPPORT = [
  {
    name: 'I have an issue with participating in an activity',
    value: 'ACTIVITY'
  },
  {
    name: 'I did not receive the amount of points that I expected',
    value: 'POINTS'
  },
  {
    name: 'I am having an issue with an eGift Card',
    value: 'EGIFT'
  },
  {
    name: 'Other',
    value: 'OTHER'
  }
];

export const ERROR_RAISING_TICKET =
  'Facing issue while raising your ticket, kindly try again';
export const DUPLICATE_FILE = 'Duplicate file';
export const LIMIT_EXCEEDED_TICKET =
  'You have reached the maximum number of tickets per day. Please try again tomorrow. Thank you.';
export const VALIDATION_ERROR = {
  EMAIL: 'Please enter a valid email.',
  INVALID_ACCOUNT:
    'We cannot find an account with this email address. Please check the entered email address and try again.',
  EMAIL_MISSING: 'Please enter a email id'
};
export const CONTACT_US = {
  TITLE: 'Welcome to the Walmart Customer Spark Community Support Page',
  INFO: 'Please provide the following information:',
  REQUIRED: '*Required'
};
export const HTML_CONTAINS_FIELD_ERROR_MESSAGE =
  'Your submission contains text such as HTML tags that are not allowed as part of your submission. Please remove these portion(s) of your submission and attempt again.';
export const getCharacterCountLimitMessage = ({ count }) =>
  `Your submission must be less than ${count} characters long, including spaces. Please reduce the length and attempt again.`;
export const URL_ACTIVITYID_VALIDATION_MESSAGE =
  'Your submission contains text which is not a valid url or activity id';
export const URL_VALIDATION_MESSAGE =
  'Your submission contains text which is not a valid url';

export const FIELDS_PLACEHOLDER = {
  EMAIL: 'Email address for your Customer Spark account',
  ACTIVITY: 'Enter Activity URL or Activity ID',
  DESCRIPTION: 'Describe your situation',
  GIFTCARD: 'Enter Walmart eGift Card Link',
  DETAILS: 'Please provide the following information',
  MORE_DETAILS: `Please provide the following information *(Required)
  - Expected amount of points
  - Amount points received
  `
};

export const SUCCESS_DIALOG = {
  title: 'Your request was successfully submitted!',
  description:
    'We are working on your request, one of our support team members will be reaching out soon. Thank you.'
};

export const ISSUE_CATEGORY = {
  OTHER: 'OTHER',
  ACTIVITY: 'ACTIVITY',
  EGIFT: 'EGIFT',
  POINTS: 'POINTS',
  CATEGORY: 'issueCategory',
  EMAIL: 'emailId'
};

export const TEXTFIELD_CHARACTER_LIMIT_VALUE = {
  DESCRIPTION_OR_DETAILS_LIMIT: 5000,
  EMAIL_ID_LIMIT: 255,
  ACTIVITY_TYPE_LIMIT: 255,
  EGIFT_CARD_URL_LIMIT: 1000
};

export const FORM_FIELD_NAME_ATTRIBUTES = {
  DESCRIPTION: 'description',
  EMAIL_ID: 'emailId',
  ACTIVITY_TYPE: 'activityType',
  EGIFT_CARD_URL: 'egiftcardUrl'
};

export const FORM_FIELD_CHAR_LIMIT = {
  [FORM_FIELD_NAME_ATTRIBUTES.DESCRIPTION]:
    TEXTFIELD_CHARACTER_LIMIT_VALUE?.DESCRIPTION_OR_DETAILS_LIMIT,
  [FORM_FIELD_NAME_ATTRIBUTES.EMAIL_ID]:
    TEXTFIELD_CHARACTER_LIMIT_VALUE?.EMAIL_ID_LIMIT,
  [FORM_FIELD_NAME_ATTRIBUTES.ACTIVITY_TYPE]:
    TEXTFIELD_CHARACTER_LIMIT_VALUE?.ACTIVITY_TYPE_LIMIT,
  [FORM_FIELD_NAME_ATTRIBUTES.EGIFT_CARD_URL]:
    TEXTFIELD_CHARACTER_LIMIT_VALUE?.EGIFT_CARD_URL_LIMIT
};

export const APP_STORE_LINK = {
  android:
    'https://play.google.com/store/apps/details?id=com.walmart.customerspark&referrer=utm_source%3Dweblink',
  ios: 'https://apps.apple.com/app/apple-store/id1638777395?pt=306423&ct=weblink&mt=8'
};
