export const updateDateOfBirth = {
    freeTexts: {
        updateErrorMessage: 'Date of birth update error',
        updateSuccessMessage: 'Your information was successfully saved.',
        welcomeMessage: 'Welcome to your new Customer Spark Experience!',
        selectBirthdayDirective: 'Please select your birthday and save to continue.'
    },
    apiKeys: {
        dob: 'per_dob'
    },
    labels: {
        saveButtonLabel: 'Save'
    }
}