/* eslint-disable react/prop-types */
import React from 'react';
import { Link, makeStyles, Button } from '@material-ui/core';
import styles from './styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

export const EditButton = (props) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const {
    onClickHandler,
    testId = 'edit-button',
    buttonLabel = 'Edit',
    ariaLabel
  } = props;

  return (
    <>
      <Link
        component="button"
        aria-label={ariaLabel}
        className={classes.editButton}
        onClick={onClickHandler}
        underline="none"
        data-testid={testId}
      >
        {buttonLabel}
      </Link>
    </>
  );
};

export const CancelButton = (props) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const {
    onButtonClick,
    testId = 'cancel-button',
    buttonLabel = 'Cancel'
  } = props;

  return (
    <Link
      component="button"
      className={classes.editButton}
      onClick={onButtonClick}
      underline="none"
      data-testid={testId}
    >
      {buttonLabel}
    </Link>
  );
};

export const SaveButton = (props) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const {
    onButtonClick,
    testId = 'save-button',
    buttonLabel = 'Save',
    disabled = false,
    type,
    variant = 'outlined'
  } = props;

  return (
    <Button
      id="save"
      className={classes.saveButton}
      variant={variant}
      color="primary"
      onClick={onButtonClick}
      data-testid={testId}
      disabled={disabled}
      type={type}
    >
      {buttonLabel}
    </Button>
  );
};

export const RoundedFilledButton = (props) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const { label = 'Button', onClick, disabled } = props;
  return (
    <Button
      variant="contained"
      color="primary"
      onClick={onClick}
      disabled={disabled}
      className={clsx(classes.buttonRoot, classes.roundedFilled)}
      data-testid={`${label}-button`}
      aria-labelledby={`${label}-button`}
    >
      {label}
    </Button>
  );
};

RoundedFilledButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};
