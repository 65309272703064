import { TextField, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Fragment, useState } from 'react';
import textFieldStyle from '../TextField/styles';
import CircularLoader from '../CircularLoader';

const MuiAutoComplete = (props) => {
  const useStyles = makeStyles(textFieldStyle);
  const textFieldClasses = useStyles();

  const {
    id,
    options,
    textInputLabel,
    loading = false,
    setLoading,
    loadingText = 'Loading...',
    freeSolo = true,
    value,
    onValueChange,
    handleInputChange,
    getOptionLabel = (element) => {
      return element;
    }
  } = props;

  const [open, setOpen] = useState(false);

  const handleValueChange = (event, newValue) => {
    if (newValue !== null) {
      onValueChange(event, newValue);
    }
  };

  const inputChange = (event, newValue, reason) => {
    if (event && (event.type !== 'click' || reason === 'clear')) {
      handleInputChange(event, newValue);
    }
  };

  return (
    <>
      <Autocomplete
        {...props}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => {
          setOpen(false);
          setLoading(false);
        }}
        loading={loading}
        loadingText={loadingText}
        freeSolo={freeSolo}
        value={value.boxValue}
        onChange={(event, newValue) => {
          handleValueChange(event, newValue);
        }}
        inputValue={value.inputValue}
        onInputChange={inputChange}
        options={options}
        getOptionLabel={getOptionLabel}
        renderInput={(params) => (
          <TextField
            {...params}
            id={id}
            data-testid={`${id}-testId`}
            label={textInputLabel}
            variant="outlined"
            className={textFieldClasses.textField}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {loading ? <CircularLoader size="small" /> : null}
                  {params.InputProps.endAdornment}
                </Fragment>
              )
            }}
          />
        )}
      />
    </>
  );
};

export default MuiAutoComplete;
