const styles = (theme) => ({
  arrow: {
    width: 0,
    height: 0,
    borderLeft: '12px solid transparent',
    borderRight: '12px solid transparent',
    borderTop: '12px solid #fff',
    position: 'absolute',
    top: '98%'
  },
  arrowRight: {
    right: '5%'
  },
  arrowLeft: {
    left: '5%'
  },
  arrowCenter: {
    left: '45%'
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "#333333",
    position: 'absolute',
    background: 'white',
    borderRadius: '12px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    padding: '8px 16px',
    right: '5%',
    top: '-50%',
    fontFamily: 'Bogle',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px'
  },
  textRight: {
    textAlign: 'right'
  },
  textLeft: {
    textAlign: 'left'
  },
  textCenter: {
    textAlign: 'center'
  }
});

export default styles;
