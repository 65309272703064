const styles = (theme) => ({
  headerImgHolder: {
    width: 215,
    height: 30,
    marginTop: 48,
    [theme.breakpoints.up('lg')]: {
      width: 287,
      height: 40
    }
  },
  header: {
    fontSize: 16,
    fontWeight: '500',
    width: '85%',
    textAlign: 'center',
    margin: '32px 0 0',
    [theme.breakpoints.up('sm')]: {
      width: 344
    },
    [theme.breakpoints.up('md')]: {
      width: 300
    },
    [theme.breakpoints.up('lg')]: {
      width: 412,
      fontSize: 20
    }
  },
  headerContent: {
    fontSize: 14,
    fontWeight: '500',
    width: 312,
    textAlign: 'center',
    margin: '0 0 8px',
    color: '#414042',
    overflowWrap: 'anywhere',
    [theme.breakpoints.up('sm')]: {
      width: 344
    },
    [theme.breakpoints.up('md')]: {
      width: 300
    },
    [theme.breakpoints.up('lg')]: {
      width: 412,
      fontSize: 16
    }
  }
});

export default styles;
