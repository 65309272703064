/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles, Snackbar } from '@material-ui/core';
import styles from './styles';
import PropTypes from 'prop-types';

const SnackBanner = (props) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const {
    isSnackbarOpen,
    snackbarMessage,
    onSnackBarClose,
    autoHideDuration = 3000,
    snackbarPosition = { vertical: 'top', horizontal: 'center' },
    errorMessage = false
  } = props;

  return (
    <Snackbar
      anchorOrigin={snackbarPosition}
      open={isSnackbarOpen}
      message={snackbarMessage}
      autoHideDuration={autoHideDuration}
      onClose={onSnackBarClose}
      ContentProps={{
        classes: {
          root: errorMessage ? classes.errorSnack : classes.snackbarRoot
        }
      }}
    />
  );
};

SnackBanner.propTypes = {
  isSnackbarOpen: PropTypes.bool.isRequired,
  snackbarMessage: PropTypes.string,
  onSnackBarClose: PropTypes.func,
  autoHideDuration: PropTypes.number,
  snackbarPosition: PropTypes.object,
  errorMessage: PropTypes.bool
};

export default SnackBanner;
