import React, { useEffect, useLayoutEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import Routes from './routes';
import themeStyles from './constants/styles';
import './App.scss';
import apiCall from './utils/api';
import API, { API_STATUS_CODE } from './constants/common';
import config from './constants/dummyCCM';
import ConfigContext from './contexts/ConfigContext';
import analytics from './utils/analytics';
import { setSession } from './utilities/utils';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './components/FallbackComponent';
import { logger } from './utils/logger';

const theme = createTheme(themeStyles);

const App = () => {
  const [ccmConfig, setCcmConfig] = useState({
    isError: false,
    isLoading: false,
    data: null
  });

  useLayoutEffect(() => {
    // calling these functions here because these need to setup before the application first renders
    setSession();
  }, []);

  useEffect(() => {
    setCcmConfig((_ccmConfig) => {
      return { ..._ccmConfig, isLoading: true };
    });

    if (typeof window !== 'undefined' && window.document) {
      const { host } = window?.location;
      if (host.includes('localhost')) {
        setCcmConfig({ isLoading: false, isError: false, data: config });
      } else {
        apiCall('/api/ccm/basicConfig', API.METHOD.GET).then((res) => {
          if (res.status === API_STATUS_CODE.SUCCESS) {
            setCcmConfig({
              data: res?.data,
              isLoading: false,
              isError: false
            });
          } else {
            setCcmConfig({
              data: null,
              isLoading: false,
              isError: true
            });
          }
        });
      }
    }

    const defineAdobeAnalytics = () => {
      analytics.setBase();
      analytics.loadContent(document.getElementsByTagName('head')[0]);
      analytics.loadAdobeScript();
    };

    defineAdobeAnalytics();
  }, []);

  const onAppError = (error, info) => {
    logger.fatal('App: Error boundary caught an error', {
      message: error?.message ?? 'Unknown error',
      stack: JSON.stringify(error?.stack),
      info: JSON.stringify(info?.componentStack)
    });
  };

  return (
    <ConfigContext.Provider value={ccmConfig?.data}>
      <Router>
        <MuiThemeProvider theme={theme}>
          <div className="app">
            <meta charSet="utf-8" />
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onError={onAppError}
            >
              <Routes />
            </ErrorBoundary>
          </div>
        </MuiThemeProvider>
      </Router>
    </ConfigContext.Provider>
  );
};

export default App;
