/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  makeStyles
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classNames from 'classnames';
import styles from './styles';

const Accordian = (props) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const {
    unmountOnExit,
    defaultExpanded = false,
    accordianTitle,
    accordianDetails,
    onAccordianBtnClick
  } = props;

  return (
    <>
      <Accordion
        className={classes.accordion}
        TransitionProps={{ unmountOnExit: unmountOnExit }}
        defaultExpanded={defaultExpanded}
        classes={{ root: classes.MuiAccordionroot }}
        onChange={onAccordianBtnClick}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className={classes.profileHead}>
            <Typography className={classes.summary}>
              {accordianTitle}
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          {accordianDetails}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

Accordian.propTypes = {
  unmountOnExit: PropTypes.bool,
  defaultExpanded: PropTypes.bool,
  accordianTitle: PropTypes.string,
  accordianDetails: PropTypes.element,
  onAccordianBtnClick: PropTypes.func
};

export default Accordian;
