import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { Tabs, Tab, withStyles } from '@material-ui/core';
import { isValidArray } from '../../utils/common';
import { TAB_INDICATOR_STYLE } from '../../constants/styles';
import styles from './styles';
import { FormattedMessage } from 'react-intl';

const SuperTab = ({ classes, tabItems }) => {
  const location = useLocation();
  const { selected, tab, tabContainer, wrapper } = classes;

  const getTabFromPath = (path) => {
    if (path?.includes('history')) {
      return 2;
    } else if (path?.includes('activities')) {
      return 1;
    } else {
      return 0;
    }
  };
  return (
    <div className={wrapper}>
      <div className={tabContainer}>
        {isValidArray(tabItems) && (
          <Tabs
            value={getTabFromPath(location?.pathname)}
            indicatorColor="primary"
            TabIndicatorProps={TAB_INDICATOR_STYLE}
          >
            {tabItems.map((item, index) => (
              <Tab
                key={index}
                className={tab}
                classes={{ selected: selected }}
                label={
                  <FormattedMessage
                    id={`HOME_TAB_DISPLAY_${index}`}
                    defaultMessage={item.display}
                  />
                }
                data-testid={`${item.display}-tab`}
                component={Link}
                to={`/${
                  item.display.toLowerCase() !== 'rewards'
                    ? item.display.toLowerCase()
                    : 'home'
                }`}
              />
            ))}
          </Tabs>
        )}
      </div>
    </div>
  );
};

SuperTab.defaultProps = {
  classes: {}
};

SuperTab.propTypes = {
  classes: PropTypes.object,
  tabItems: PropTypes.array.isRequired
};

export default withStyles(styles)(SuperTab);
