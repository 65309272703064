import React from 'react';
import styles from '../styles';
import {
  Button,
  Grid,
  makeStyles,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { emailChangeConstants } from '../../constanst';

const OTPForm = (props) => {
  const {
    handleAuthCodeChange,
    enteredOtp,
    handleResendOtpClick,
    resendEnabledFlag,
    handleOtpContinueClick
  } = props;
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const isOtpValid = () =>
    !!enteredOtp && enteredOtp.length > 4 && !isNaN(enteredOtp);

  const otpHelperText = () => {
    return (
      !isOtpValid() && enteredOtp.length > 0 && 'Please enter a valid code.'
    );
  };

  return (
    <>
      <Grid item xs={12} className={classes.textFieldItem}>
        <TextField
          variant="outlined"
          label="Authorization Code"
          onChange={handleAuthCodeChange}
          className={classes.textField}
          error={enteredOtp.length > 0 && !isOtpValid()}
          helperText={otpHelperText()}
        ></TextField>
      </Grid>
      <Grid item xs={12}>
        <Grid container className={classes.resendOtpContainer}>
          <Grid item>
            <Typography
              className={classes.resendOtpTypography}
              classes={{ root: classes.resendOtpText }}
            >
              {emailChangeConstants.resentOtpText}
            </Typography>
          </Grid>
          <Tooltip
            title="You may resend the code after 1 minute has passed"
            placement="top"
          >
            <Grid className={classes.buttonItem}>
              <Button
                classes={{
                  root: classes.resendButton,
                  label: classes.resendButtonLabel
                }}
                onClick={handleResendOtpClick}
                disabled={!resendEnabledFlag}
              >
                Resend
              </Button>
            </Grid>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="center">
          <Grid item xs={12} className={classes.userNoteItem}>
            <Typography className={classes.userNoteText}>
              {emailChangeConstants.noteForUser}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.userNoteItemBold}>
            <Typography className={classes.userNoteTextBold}>
              {emailChangeConstants.noteForUserBold}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={classes.continueButtonContainer}>
        <Grid item>
          <Button
            variant="contained"
            disabled={!isOtpValid()}
            onClick={handleOtpContinueClick}
            className={classes.continueButton}
            classes={{ root: classes.continueButtonRoot }}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default OTPForm;
