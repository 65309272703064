import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import React from 'react';
import {
  RoundedFilledButton,
  UnfilledSquareButton
} from '../../../components/StyledButtons';
import CloseIconButton from '../../../components/CloseIconButton';
import styles from './styles';
import {
  MAYBE_LATER,
  PLEASE_REVIEW_YOUR_PROFILE,
  PROFILE_REVIEW_INFO
} from '../../../constants/keywords';

const ReviewInformationDialog = (props) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const { open, onClose, onClickReviewProfile, onClickMaybeLater, loading } =
    props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      disableScrollLock
      classes={{ paperWidthSm: classes.dialogPaperSm }}
    >
      <DialogTitle>
        <Grid container className={classes.dialogTitleContainer}>
          <CloseIconButton onClick={onClose} />
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container className={classes.dialogContentContainer}>
          <Typography className={classes.dialogContentText}>
            {PLEASE_REVIEW_YOUR_PROFILE.map((x) => x)}
          </Typography>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActionsRoot}>
        <Grid container className={classes.dialogFooterContainer}>
          <UnfilledSquareButton
            label={MAYBE_LATER}
            onClick={onClickMaybeLater}
          />
          <RoundedFilledButton
            label={PROFILE_REVIEW_INFO}
            onClick={onClickReviewProfile}
            disabled={loading}
          />
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ReviewInformationDialog;
