import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  Link,
  Typography,
  withStyles
} from '@material-ui/core';
import Logo from '../../../../Logo';
import styles from './styles';
import { SOURCE } from '../../../../../constants/common';
import { activity } from '../../../constants';
import analytics from '../../../../../utils/analytics';
import { PAGES } from '../../../../../constants/analytics';
import { useWindowSize } from '../../../../../hooks';

const ActivityCard = ({
  classes,
  imageType,
  name,
  points,
  surveyLink,
  shortId
}) => {
  /** Internal survey links will be having panelist hash already
   * So appending only the source & separated
   */

  const framedSurveyLink = `${surveyLink}${
    surveyLink?.includes('https') ? '' : SOURCE
  }`;

  const { width } = useWindowSize();

  const surveyClicked = (evt, surveyUrl) => {
    const surveyLinkEnd = surveyUrl?.split('/');
    const panelistHash = surveyUrl?.split('?panelist_hash');
    analytics.trackClick(PAGES.ACTIVITIES, 'survey', {
      survey: surveyLinkEnd?.length
        ? surveyLinkEnd?.[surveyLinkEnd?.length - 1]
        : '',
      panelistHash: panelistHash?.length > 1 ? panelistHash?.[1] : ''
    });
    // Default Behaviour of the div onClick event is to open the link
    // It was being prevented using the below event preventDefault()
    // evt.preventDefault();
  };

  const getActivityCard = () => {
    return (
      <Card className={classes.base} variant="outlined">
        <CardContent classes={{ root: classes.cardContentRoot }}>
          <Grid container xs={12} className={classes.cardTextContainer}>
            <Grid item className={classes.textWrapper}>
              <Typography className={classes.title}>{name}</Typography>
            </Grid>
            <Grid item className={classes.textWrapper}>
              <Typography className={classes.points}>
                {`${points || 0} ${activity.activityFreeTexts.points}`}
              </Typography>
            </Grid>
            <Grid item className={classes.textWrapper}>
              <Typography className={classes.shortID}>{`ID: ${
                shortId || null
              }`}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={5} className={classes.mobileView}>
            <Grid container className={classes.imgWrapper}>
              <Logo type={'activity'} flag={imageType} />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions classes={{ root: classes.actionRoot }} disableSpacing>
          <Grid container className={classes.actionContainer}>
            <Link
              href={framedSurveyLink}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.action}
              underline="none"
              onClick={(evt) => surveyClicked(evt, framedSurveyLink)}
            >
              {activity.activityFreeTexts.beginActivity}
            </Link>
          </Grid>
        </CardActions>
      </Card>
    );
  };

  return width < 600 ? (
    <Link
      href={framedSurveyLink}
      target="_blank"
      rel="noopener noreferrer"
      className={classes.action}
      underline="none"
      onClick={(evt) => surveyClicked(evt, framedSurveyLink)}
    >
      {getActivityCard()}
    </Link>
  ) : (
    getActivityCard()
  );
};

ActivityCard.defaultProps = {
  classes: {}
};

ActivityCard.propTypes = {
  classes: PropTypes.object,
  imageType: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  points: PropTypes.number.isRequired,
  surveyLink: PropTypes.string.isRequired
};

export default withStyles(styles)(ActivityCard);
