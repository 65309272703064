import React from 'react';
import { Grid, Typography, makeStyles, Button } from '@material-ui/core';
import styles from '../styles';
import { rewardsConstants } from '../constants';
import Logo from '../../Logo';
import clsx from 'clsx';

const RedeemCard = (props) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const { enabled, cardDollarValue, onClickRedeemReward } = props;

  const redeemRewardHandler = () => {
    onClickRedeemReward(cardDollarValue);
  };

  return (
    <Button
      disabled={!enabled}
      fullWidth
      className={clsx(
        classes.rewardCard,
        !enabled && classes.disabledRedeemRewardCard
      )}
      onClick={redeemRewardHandler}
      data-testid={`test-${cardDollarValue}dollar-card`}
      classes={{ label: classes.labelContainer }}
    >
      <Grid container className={classes.giftCardTextContainer}>
        <Typography
          className={classes.giftCardDollarValue}
        >{`${rewardsConstants.freeText.dollarSign} ${cardDollarValue}`}</Typography>
      </Grid>
      {enabled && (
        <Grid container className={classes.giftCardRedeemPointsContainer}>
          <Typography className={classes.giftCardRedeemPointsText}>
            {`${rewardsConstants.freeText.redeem} `}
          </Typography>
          <div>
            <Logo type="walmart-icon" />
          </div>
          <Typography className={classes.giftCardRedeemPointsText}>
            {` ${(cardDollarValue * 100).toLocaleString()} ${
              rewardsConstants.freeText.points
            }`}
          </Typography>
        </Grid>
      )}
    </Button>
  );
};

export default RedeemCard;
