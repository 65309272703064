const styles = (theme) => ({
  flexCenter: {
    display: 'flex',
    justifyContent: 'center'
  },
  image: {
    width: 80,
    [theme.breakpoints.down('md')]: {
      width: 72
    },
    [theme.breakpoints.down('sm')]: {
      width: 60
    },
    [theme.breakpoints.down('xs')]: {
      width: 48
    }
  },
  infoScreenWrap: {
    height: '100vh'
  },
  container: {
    height: '100%',
    alignItems: 'center',
    verticalAlign: 'middle',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  contentContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 40
  },
  headerContent: {
    fontFamily: theme?.font?.light,
    fontSize: 48,
    fontWeight: 300,
    letterSpacing: 0.5,
    textAlign: 'center',
    color: '#333333',
    [theme.breakpoints.down('md')]: {
      fontSize: 36
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 32
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 24
    }
  },
  subContent: {
    fontFamily: theme?.font?.regular,
    fontSize: 24,
    letterSpacing: 0.25,
    textAlign: 'center',
    color: '#333333',
    marginTop: 24,
    [theme.breakpoints.down('md')]: {
      fontSize: 18
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16
    }
  }
});

export default styles;
