const styles = (theme) => ({
  gridContainer: {
    maxWidth: '100vw',
    flexDirection: 'column'
  },
  innerGrid: {
    marginTop: 24,
    [theme.breakpoints.up('sm')]: {
      marginTop: 32.5
    }
  },
  innerTab: {
    fontSize: 14,
    minWidth: 164,
    paddingBottom: 0,
    fontWeight: 300,
    textTransform: 'none',
    [theme.breakpoints.up('sm')]: {
      minWidth: 275
    },
    [theme.breakpoints.up('md')]: {
      minWidth: 244
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: 221
    }
  },
  innerSelected: {
    color: '#0071ce',
    fontWeight: 'normal !important'
  },
  innerWrapper: {
    display: 'flex',
    justifyContent: 'center'
  },
  tabContainer: {
    display: 'flex'
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center'
  }
});

export default styles;
