import React from 'react';
import ActivityCard from './ActivityCard';
import EmptyCart from '../../../EmptyCart';
import styles from './styles';
import { Grid, makeStyles } from '@material-ui/core';
import { activity } from '../../constants';

const ActivitiesGrid = ({ activities }) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const gridContainerStyle = (noOfCards) => {
    if (noOfCards <= 4 && noOfCards !== 3) {
      return classes.activityCardsContainerMuliple2;
    }
    return classes.activityCardsDefault;
  };

  if (activities.length > 0) {
    return (
      <Grid
        container
        className={gridContainerStyle(activities.length)}
        data-testid="Activities-Container"
      >
        {activities.map((activity, index) => (
          <Grid item key={activity.shortId} className={classes.activityCard}>
            <ActivityCard {...activity} imageType={index} />
          </Grid>
        ))}
      </Grid>
    );
  } else {
    return (
      <EmptyCart
        id="EMPTY_CART"
        message={activity.activityFreeTexts.noActivitiesAvailable}
        logoType="emptyCart"
      />
    );
  }
};

export default ActivitiesGrid;
