const styles = (theme) => ({
  groceryBag: {
    width: 83,
    height: 120,
    [theme.breakpoints.down('870')]: {
      width: '100%',
      height: 80
    },
    [theme.breakpoints.down('600')]: {
      width: 45,
      height: 55
    }
  },
  trailer: {
    width: 105,
    height: 50,
    [theme.breakpoints.down('1380')]: {
      width: 90,
      height: 45
    },
    [theme.breakpoints.down('870')]: {
      width: '120%',
      height: 45
    },
    [theme.breakpoints.down('600')]: {
      width: 65,
      height: 30
    }
  },
  shopCart: {
    width: 100,
    height: 109,
    [theme.breakpoints.down('870')]: {
      width: '100%',
      height: 70
    },
    [theme.breakpoints.down('600')]: {
      width: 45,
      height: 50
    }
  },
  site: {
    width: 135,
    height: 73,
    [theme.breakpoints.down('870')]: {
      width: '100%',
      height: 45
    },
    [theme.breakpoints.down('600')]: {
      width: 58,
      height: 35
    }
  },
  boxes: {
    width: 135,
    height: 56,
    [theme.breakpoints.down('870')]: {
      width: '100%',
      height: 30
    },
    [theme.breakpoints.down('600')]: {
      width: 55,
      height: 30
    }
  },
  tech: {
    width: 135,
    height: 51,
    [theme.breakpoints.down('870')]: {
      width: '100%',
      height: 30
    },
    [theme.breakpoints.down('600')]: {
      width: 60,
      height: 33
    }
  },
  sports: {
    width: 135,
    height: 73,
    [theme.breakpoints.down('870')]: {
      width: '100%',
      height: 33
    },
    [theme.breakpoints.down('600')]: {
      width: 60,
      height: 40
    }
  },
  receipt: {
    width: 86,
    height: 88,
    [theme.breakpoints.down('870')]: {
      width: '100%',
      height: 60
    },
    [theme.breakpoints.down('600')]: {
      width: 55,
      height: 53
    }
  },
  mobileSite: {
    width: 86,
    height: 96,
    [theme.breakpoints.down('870')]: {
      width: '100%',
      height: 55
    },
    [theme.breakpoints.down('600')]: {
      width: 45,
      height: 47
    }
  },
  appLogo: {
    width: 143,
    height: 20,
    marginLeft: 16,
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer'
    },
    [theme.breakpoints.up('md')]: {
      width: 210,
      height: 29,
      marginLeft: 30
    }
  },
  emptyCart: {
    width: 80,
    height: 80,
    [theme.breakpoints.up('sm')]: {
      width: 97,
      height: 100
    }
  },
  headerImage: {
    width: '100%',
    height: '100%'
  },
  heroImage: {
    width: '100%',
    height: 170,
    marginTop: 60,
    objectFit: 'contain'
  },
  pageNotFound: {
    width: 300,
    height: 234,
    [theme.breakpoints.up('sm')]: {
      width: 360,
      height: 281
    },
    [theme.breakpoints.up('md')]: {
      width: 318,
      height: 248
    },
    [theme.breakpoints.up('lg')]: {
      width: 400,
      height: 312
    }
  },
  resultsNotFound: {
    width: 188,
    height: 123,
    [theme.breakpoints.up('sm')]: {
      width: 204,
      height: 134
    },
    [theme.breakpoints.up('md')]: {
      width: 194,
      height: 127
    },
    [theme.breakpoints.up('lg')]: {
      width: 210,
      height: 137
    }
  },
  avatar: {
    width: 100,
    height: 100,
    [theme.breakpoints.up('md')]: {
      width: 141,
      height: 141
    }
  },
  confAgree: {
    width: 284,
    height: 29
  },
  landing: {
    width: 196,
    height: 20,
    marginLeft: 16
  },
  carouselAvatar: {
    width: 40,
    height: 40,
    [theme.breakpoints.up('md')]: {
      width: 45,
      height: 45
    },
    [theme.breakpoints.up('lg')]: {
      width: 60,
      height: 60
    }
  },
  walmartIcon: {
    width: 7,
    height: 7.78,
    marginBottom: 5,
    marginLeft: 2,
    marginRight: 2
  },
  walmartLogo: {
    width: 127,
    height: 142
  },
  trophy: {
    height: 120
  },
  microSurveyCardImage: {
    height: 80,
    width: 80
  }
});

export default styles;
