const styles = (theme) => ({
  container: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItem: 'center'
  },
  errorContainer: {
    maxHeight: 477,
    maxWidth: 280,
    margin: '10%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItem: 'center'
  },
  imageItem: {
    width: '100%'
  },
  messageItem: {
    width: '100%'
  },
  headerText: {
    fontFamily: 'Bogle-bold',
    fontSize: 24,
    textAlign: 'center'
  },
  messageText: {
    fontFamily: 'Bogle-regular',
    fontSize: 16,
    textAlign: 'center'
  },
  buttonItem: {}
});

export default styles;
