export const HEADER_LOGO_TYPE = 'confAgree';
export const TITLE = 'Hi Spark Community Member!';
export const MAIN_MESSAGE =
  'We recently updated our terms to align with the new requirements under the California Consumer Privacy Act, as recently amended by the California Privacy Rights Act. By changing your state of residence to California, please select from one the following options below.';
export const MESSAGE_SIGN = 'The Walmart Customer Spark Team';
export const FORM_LABEL = 'Agreement';

export const LINK_HEADERS = {
  TERMS_CONDITIONS: 'Terms and Conditions',
  PRIVACY_NOTICE: 'Privacy Notice',
  NOTICE_FINANCIAL_INCENTIVE: 'Notice of Financial Incentive',
  CS_REWARD_PROGRAM: 'Walmart Customer Spark Community Rewards Program'
};
