const styles = (theme) => ({
  root: {
    padding: '10px',
    [theme.breakpoints.up('sm')]: {
      padding: '20px'
    }
  },
  innerSection: {
    padding: '15px'
  },
  unlockedSection: {
    marginBottom: '32px'
  },
  lockedSection: {
    marginTop: '32px',
    marginBottom: '20px'
  },
  avatarSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 15
  },
  lockIcon: {
    color: '#8B8A8E',
    marginTop: 10
  },
  avatar: {
    width: '80px',
    height: '80px',
    border: '1px solid transparent',
    [theme.breakpoints.up('sm')]: {
      width: '130px',
      height: '130px'
    }
  },
  selectedImage: {
    border: '1px solid #0171ce'
  },
  closeIcon: {
    display: 'flex',
    justifyContent: 'end'
  },
  iconButton: {
    padding: 0
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative'
  },
  mainHeading: {
    fontSize: '20px',
    fontWeight: 400,
    marginBottom: '8px',
    textAlign: 'center',
    marginTop: '44px',
    [theme.breakpoints.up('md')]: {
      marginTop: 0
    }
  },
  subHeading: {
    fontSize: '18px',
    fontWeight: 300,
    textAlign: 'center'
  },
  lockedAvatar: {
    filter: 'grayscale(1)'
  },
  buttonSection: {
    position: 'absolute',
    right: 0,
    marginRight: '15px'
  },
  buttons: {
    color: '#0071CE',
    fontSize: '14px',
    width: 100,
    textTransform: 'capitalize'
  },
  cancelButton: {
    marginRight: 15
  },
  saveButton: {
    border: '1px solid #0071CE'
  },
  container: {
    background: 'rgb(255 255 255 / 80%)',
    '& .MuiBackdrop-root': {
      background: 'none'
    }
  },
  avatarDisplay: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'flex-start'
  },
  stickyTop: {
    top: 0,
    position: 'sticky',
    zIndex: 1,
    backgroundColor: '#ffffff'
  }
});

export default styles;
