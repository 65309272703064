import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import styles from './styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

export const RoundedFilledButton = (props) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const { label = 'Button', onClick, disabled, component, to } = props;
  return (
    <Button
      variant="contained"
      color="primary"
      onClick={onClick}
      disabled={disabled}
      className={clsx(classes.buttonRoot, classes.roundedFilled)}
      data-testid={`${label}-button`}
      aria-labelledby={`${label}-button`}
      disableElevation
      component={component}
      to={to}
    >
      {label}
    </Button>
  );
};

export const RoundedUnfilledButton = (props) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const { label = 'Button', onClick, disabled } = props;

  return (
    <Button
      variant="contained"
      onClick={onClick}
      disabled={disabled}
      className={clsx(classes.buttonRoot, classes.roundedUnfilled)}
      data-testid={`${label}-button`}
      aria-labelledby={`${label}-button`}
      disableElevation
    >
      {label}
    </Button>
  );
};

export const UnfilledSquareButton = (props) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const { label = 'Button', onClick, disabled } = props;

  return (
    <Button
      variant="text"
      color="primary"
      onClick={onClick}
      disabled={disabled}
      classes={{ root: clsx(classes.buttonRoot, classes.unfilledSquare) }}
      data-testid={`${label}-button`}
      aria-labelledby={`${label}-button`}
      disableElevation
    >
      {label}
    </Button>
  );
};

RoundedFilledButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

UnfilledSquareButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};
