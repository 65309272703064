import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import styles from './styles';
import { Done, CloseOutlined } from '@material-ui/icons';
import { checkContainsSpecialChar } from '../utils';

const PasswordValidation = (props) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const { password = '' } = props;

  const PASSWORD_RULES = [
    {
      title: 'Include 8 to 30 characters',
      rule: () => new RegExp('^(?=.{8,30}$)').test(password)
    },

    {
      title: 'Include at least one number',
      rule: () => new RegExp('^(?=.*[0-9])').test(password)
    },
    {
      title: 'Include at least one upper case letter',
      rule: () => new RegExp('^(?=.*[A-Z])').test(password)
    },
    {
      title: 'Include at least one lower case letter',
      rule: () => new RegExp('^(?=.*[a-z])').test(password)
    },
    {
      title: 'Include at least one symbol (ie: #, &, $, %)',
      //rule: () => new RegExp('^(?=.*[!@#$%^&*])').test(password)
      rule: () => checkContainsSpecialChar(password)
    },
    {
      title: 'Cannot contain more than 3 consecutive identical characters',
      rule: () => !!password && !new RegExp('^(?=(.)+\\1\\1\\1)').test(password)
    }
  ];

  const renderPasswordInstructions = PASSWORD_RULES.map((x, i) => (
    <Grid key={`key-${i}`}>
      {x.rule(password) ? (
        <Done className={classes.tickIcon} />
      ) : (
        <CloseOutlined
          className={classes.tickIcon}
          classes={{ root: classes.crossIconRoot }}
        />
      )}
      <Typography className={classes.passwordInstructionsText}>
        {x.title}
      </Typography>
    </Grid>
  ));

  return <Grid>{renderPasswordInstructions}</Grid>;
};

export default PasswordValidation;
