const styles = (theme) => ({
  confirm: {
    width: 99,
    fontSize: 14,
    marginRight: 16,
    textTransform: 'none'
  },
  contentText: {
    fontSize: 14,
    fontWeight: 300,
    lineHeight: 1.43,
    color: '#414042'
  },
  dialog: {
    maxWidth: 328,
    borderRadius: 8,
    margin: 0,
    boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#ffffff',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 469
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 487
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 444
    }
  },
  dialogTitleImageContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%',
    marginTop: 16,
    marginBottom: 16
  },
  dialogTitleTextContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%',
    marginTop: 16
  },
  dialogTitleCloseContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    width: '100%'
  },
  dialogTitleText: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.1875,
    textAlign: 'center',
    color: '#0071CE'
  },
  dialogContentContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%',
    marginTop: 16,
    marginBottom: 40
  },
  dialogActions: {
    padding: '20px 0 40px 0',
    [theme.breakpoints.up('sm')]: {
      paddingRight: 24,
      paddingBottom: 32
    },
    [theme.breakpoints.up('md')]: {
      paddingRight: 29
    },
    [theme.breakpoints.up('lg')]: {
      padding: '20px 8px 32px 0'
    }
  },
  dialogContent: {
    padding: '0 16px',
    [theme.breakpoints.up('sm')]: {
      padding: '0px 38px'
    },
    [theme.breakpoints.up('md')]: {
      padding: '0 45px 0 54px'
    },
    [theme.breakpoints.up('lg')]: {
      padding: '8px 24px 0 32px'
    }
  },
  dialogOkActions: {
    padding: '20px 16px 40px 0',
    [theme.breakpoints.up('sm')]: {
      padding: '20px 39px 32px 0'
    },
    [theme.breakpoints.up('md')]: {
      paddingRight: 24
    }
  },
  dialogOkContent: {
    padding: '64px 16px 0 16px',
    [theme.breakpoints.up('sm')]: {
      padding: '72px 38px 0 38px'
    },
    [theme.breakpoints.up('md')]: {
      padding: '72px 60px 0 60px'
    },
    [theme.breakpoints.up('lg')]: {
      padding: '72px 24px 0 32px'
    }
  },
  dialogTitle: {
    padding: '48px 0px 8px 16px',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 38
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 54
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 32
    },
    '& h2': {
      height: 24,
      fontSize: 16,
      fontWeight: 500,
      letterSpacing: 0.15,
      color: '#414042'
    }
  },
  ok: {
    fontSize: 14,
    textTransform: 'none',
    width: 99
  }
});

export default styles;
