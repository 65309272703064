import { addressProperties, profileAPISelectionTypes } from './constanst';
import { Grid, makeStyles } from '@material-ui/core';
import styles from './styles';
import US_STATE from '../../utils/usStates.json';
import { REGEXP_CITY } from '../../constants/common';

export const extractProfileValue = (
  apiProfileDetailsData,
  constantProfileDetailsData
) => {
  let returnObj = {};

  apiProfileDetailsData.forEach((item) => {
    let value = '';

    if (item.selectionType === profileAPISelectionTypes.MULTIPLE) {
      value = item.selectedList;
    } else if (item.selectionType === profileAPISelectionTypes.BOOLEAN) {
      value = item.selectedList[0] === 'true' ? true : false;
    } else {
      value = item.selectedList[0];
    }

    const detailKeyObj = constantProfileDetailsData.find(
      (element) => element.apiKey === item.detailName
    );
    const detailKey = detailKeyObj?.key;

    detailKey && Object.defineProperty(returnObj, detailKey, { value: value });
  });

  return returnObj;
};

export const formatDataPutAPI = (data) => {
  if (!Array.isArray(data)) {
    if (typeof data === 'boolean') {
      return data === true ? ['true'] : ['false'];
    }

    return [data];
  }

  return data;
};

export const formatProfileStateData = (rawProfileDetail) => {
  const returnObj = {};

  if (rawProfileDetail) {
    rawProfileDetail.forEach((element) => {
      let value;

      if (element.selectionType === profileAPISelectionTypes.MULTIPLE) {
        value = element?.selectedList ? element?.selectedList : [];
      } else if (element.selectionType === profileAPISelectionTypes.BOOLEAN) {
        value = element?.selectedList[0] === 'true' ? true : false;
      } else if (element.selectionType === profileAPISelectionTypes.PASSWORD) {
        value = '••••••••••••••••';
      } else if (element.selectionType === profileAPISelectionTypes.JSON) {
        value = element?.selectedList ?? {};
      } else {
        value = element?.selectedList ? element.selectedList[0] : '';
      }

      returnObj[element.detailName] = value;
    });
  }
  return returnObj;
};

export const getDobYearsRange = () => {
  let yearValues = [];
  const date = new Date();
  const currYear = date.getFullYear();

  for (let i = currYear; i > currYear - 100; i--) {
    yearValues.push(i);
  }

  return yearValues;
};

export const generateUnlockIconMessage = (details) => {
  const dateToUnlock = new Date(details.unlockDate);
  const month = dateToUnlock.getMonth();
  const year = dateToUnlock.getFullYear();
  const date = dateToUnlock.getDate();
  return details.unlockDate
    ? `You will be able to edit ${details.displayName} again on: ${month + 1}/${
        date + 1
      }/${year}`
    : `This field cannot be edited`;
};

export const zipCodeValidityCheck = (zipCode) =>
  zipCode !== null && !isNaN(zipCode) && zipCode.toString().length === 5;

export const cityValidityCheck = (city = '') =>
  city !== null && REGEXP_CITY.test(city?.toString());

export const decodeOctalUTF8 = (str = '') =>
  // this is to match any unicode from string if there's any
  str.replace(/\\([0-3]?[0-7]{1,2})/g, (match = '', grp = 0) => {
    return String.fromCharCode(parseInt(grp, 8));
  });

export const confirmationDialogMessage = (emailId) => {
  const stringObj = new String(emailId);
  const slicedString = stringObj.slice(0, 5);
  const emailDomain = stringObj.split('@');

  const dialogMessage = `We sent you a one-time authorization code to (${slicedString}•••@${emailDomain?.[1]}).
  If you do not receive the code within a few minutes, please click “Resend” to send a code to your email.`;

  return dialogMessage;
};

export const combineStreetLines = (streetLine1, streetLine2) => {
  return streetLine1.concat(streetLine2 === '' ? '' : `, ${streetLine2}`);
};

export const PoweredByGoogle = (props) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return (
    <Grid container {...props}>
      powered by{' '}
      <img
        className={classes.googleIcon}
        alt="google-logo"
        src="/assets/images/google-logo.svg"
      />
    </Grid>
  );
};

export const convertToAddrObj = (addressDetails) => {
  let addressDetailObj;
  try {
    addressDetailObj = JSON.parse(
      addressDetails === '' ? '{}' : addressDetails,
      (key, value) => {
        if (
          (key === addressProperties.LATITUDE ||
            key === addressProperties.LONGITUDE) &&
          !isNaN(value)
        ) {
          return Number(value);
        }
        return value;
      }
    );
  } catch {
    addressDetailObj = {};
  }
  return addressDetailObj;
};

export const findState = (state) => {
  return US_STATE.find((s) => {
    return (
      s.abbreviation.toLowerCase() === state?.toLowerCase() ||
      s.name.toLowerCase() === state?.toLowerCase()
    );
  });
};

export const constructFullStateStr = (state) => {
  return `${state?.name ?? ''} (${state?.abbreviation ?? ''})`;
};

export const extractStateAbbreviation = (value) => {
  const regex = /.{1,20}\(|\).{0,20}/g; // extract anything inside the parenthesis
  return value?.length <= 2 ? value : value.replace(regex, '');
};

export const parseActivityValue = (value = '') => {
  if (typeof value === 'number') {
    return value === 1 ? value + ' activity' : value + ' activities';
  } else if (typeof value === 'string') {
    return value === 'Unlimited'
      ? value + ' activities'
      : value?.split?.(' ')?.[0];
  }
};
