const styles = (theme) => ({
  btnLabel: {
    '& .MuiButton-label': {
      display: 'none'
    }
  },
  btnRoot: {
    minWidth: 20,
    flexGrow: 0,
    background: '#8c8a8a',
    color: '#ffffff',
    borderRadius: '50%',
    transform: 'translateY(-150px)',
    margin: '0 20px',
    padding: 0,
    '&:hover': {
      background: '#333333'
    },
    [theme.breakpoints.up('md')]: {
      transform: 'translateY(-180px)'
    }
  },
  carouselWrapper: {
    width: '100%',
    flexFlow: 'row',
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-evenly'
    }
  },
  contentHead: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    marginLeft: 16,
    height: '30%',
    fontSize: 14,
    textAlign: 'center',
    color: '#333333',
    '& > span': {
      marginLeft: 8
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 20,
      '& > span': {
        marginLeft: 16
      }
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: 24
    }
  },
  contentText: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '70%',
    margin: '0px 8px',
    fontSize: 16,
    fontStyle: 'italic',
    textAlign: 'center',
    color: '#333333',
    [theme.breakpoints.up('md')]: {
      fontSize: 20,
      margin: '0 24px'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 24
    }
  },
  contentWrapper: {
    minWidth: '56%',
    height: 204,
    borderRadius: 8,
    margin: '0 20px',
    alignSelf: 'center',
    backgroundColor: '#ffffff',
    [theme.breakpoints.up('sm')]: {
      minWidth: '40%'
    },
    [theme.breakpoints.up('md')]: {
      margin: 0,
      maxWidth: '25%',
      minWidth: 'min-content',
      height: 250
    },
    [theme.breakpoints.up('lg')]: {
      height: 335
    }
  },
  gridContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100vw',
    minHeight: 360,
    backgroundColor: '#e6f1fc'
  },
  reviewHead: {
    margin: '24px 0 36px',
    fontSize: 20,
    fontWeight: 500,
    textAlign: 'center',
    color: '#333333'
  },
  stepperRoot: {
    minHeight: 68,
    flexGrow: 1,
    background: '#e6f1fc'
  },
  transLeftCard: {
    marginLeft: '-44%',
    opacity: 0.3,
    height: 188,
    [theme.breakpoints.up('sm')]: {
      marginLeft: '-15%'
    }
  },
  transRightCard: {
    opacity: 0.3,
    height: 188
  }
});

export default styles;
