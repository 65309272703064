const styles = (theme) => ({
  checkIcon: {
    width: 17,
    height: 16,
    '&.black': {
      color: '#000000'
    }
  },
  cushion: {
    marginLeft: 5
  },
  passwordRuleTitle: {
    fontSize: 16,
    paddingLeft: 4,
    color: '#414042'
  },
  passwordRulesItem: {
    display: 'flex',
    width: 238,
    fontSize: 12,
    color: '#414042',
    marginTop: 16,
    [theme.breakpoints.up('sm')]: {
      marginTop: 24
    }
  },
  redIcon: {
    color: '#CB2022'
  }
});

export default styles;
