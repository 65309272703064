import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Body, ErrorMessage } from '@walmart-web/livingdesign-components';
import { ReduxTypes, SurveySelectors } from '@walmart/survey-taker-api-js-sdk';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Lottie from 'lottie-react';
import confetti from '../../animations/confetti.json';
import { logger } from '../../utils/logger';

const { SurveyStatus } = ReduxTypes;

const getSurveyMessage = (surveyStatus) => {
  switch (surveyStatus) {
    case SurveyStatus.Completed:
      return {
        title: 'Thank you for your feedback!',
        description: "It looks like you've already taken this survey."
      };
    case SurveyStatus.Closed:
      return {
        title: 'Thanks for your interest!',
        description: 'This survey is no longer available.'
      };
    case SurveyStatus.NotFound:
      return {
        title: 'Thanks for your interest!',
        description: 'This survey is not found.'
      };
    case SurveyStatus.Unauthorized:
      return {
        title: 'Thanks for your interest!',
        description: 'You are not authorized to take this survey.'
      };
    case SurveyStatus.Error:
    default:
      return {
        title: 'Something went wrong!',
        description: 'There was a problem loading the survey. Please try again.'
      };
  }
};

function SurveyMessage(props) {
  const surveyStatus = useSelector(SurveySelectors.selectSurveyStatus);

  const [animationFinished, setAnimationFinished] = useState(false);

  const { title, description } = props.title
    ? props
    : getSurveyMessage(surveyStatus);

  useEffect(() => {
    if (!props.showConfetti) {
      logger.error('SurveyMessage: Failed to load survey', {
        surveyStatus
      });
    } else {
      logger.info('SurveyMessage: Survey submitted successfully', {
        surveyStatus
      });
    }
  }, [surveyStatus, props.showConfetti]);

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      width={'100%'}
      justifyContent={'flex-start'}
      alignItems={'center'}
    >
      {props.showConfetti && !animationFinished && (
        <Lottie
          loop={false}
          autoPlay
          animationData={confetti}
          onComplete={() => setAnimationFinished(true)}
          style={{ height: '300px', position: 'absolute' }}
        />
      )}
      <Box my={'130px'} width={'492px'}>
        <ErrorMessage
          title={title}
          media={
            <img
              alt="spark logo"
              height="100"
              src="/assets/images/spark.svg"
              width="100"
            />
          }
          actions={props.actions}
        >
          <Body as="p" UNSAFE_style={{ color: '#74767C' }}>
            {description}
          </Body>
        </ErrorMessage>
      </Box>
    </Box>
  );
}

SurveyMessage.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  actions: PropTypes.node,
  showConfetti: PropTypes.bool
};

export default SurveyMessage;
