import React, { useEffect } from 'react';
import Iframe from '../../components/Iframe';
import { PAGES } from '../../constants/analytics';
import { FOOTER_LINKS, FOOTER_LINK_NAMES } from '../../constants/common';
import { FOOTER_HEADER } from '../../constants/keywords';
import analytics from '../../utils/analytics';

const PrivacyPolicy = () => {
  useEffect(() => {
    analytics.trackPageView(PAGES.PRIVACY_POLICY);
  }, []);

  return (
    <Iframe
      url={
        FOOTER_LINKS.find(
        footerLinks => (
          footerLinks.name === FOOTER_LINK_NAMES.PRIVACY_NOTICE 
          && footerLinks.type === FOOTER_HEADER.CUSTOMER_SPARK
          )
        )?.url
        }
      title="Privacy Policy"
      type="privacyPolicy"
    />
  );
};

export default PrivacyPolicy;
