import { Paper, makeStyles } from '@material-ui/core';
import styles from '../styles';
import { useCallback, useEffect, useState } from 'react';
import { AddrLabels, addressProperties } from '../../constanst';
import apiCall from '../../../../utils/api';
import api from '../../../../constants/api';
import SnackBanner from '../../../../components/SnackBanner';
import API, { API_STATUS_CODE } from '../../../../constants/common';
import { PoweredByGoogle, findState } from '../../utils';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import MuiAutoComplete from '../../../../components/MuiAutoComplete';

const StreetAutoComplete = (props) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const { id, value, valueOnChange, formRegister, setIsLoadingDetail } = props;
  const [addressOptions, setAddressOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [snackBanner, setSnackBanner] = useState({
    displaySnackBanner: false,
    snackBannerMessage: ''
  });
  const [sessionToken, setSessionToken] = useState();
  const [callAutoComplete, setCallAutoComplete] = useState(false);
  const [isCustomChange, setIsCustomChange] = useState(true);
  const [addressValues, setAddressValues] = useState({
    boxValue: value,
    inputValue: value
  });

  useEffect(() => {
    setSessionToken(uuidv4());
  }, []);

  useEffect(() => {
    setCallAutoComplete(true);
  }, [sessionToken]);

  const fetchAutoCompletedOption = (input) => {
    if (callAutoComplete) {
      const payload = {
        session: sessionToken,
        query: input
      };
      apiCall(api.addressAutoComplete, API.METHOD.GET, payload)
        .then(async (res) => {
          if (res.status === API_STATUS_CODE.SUCCESS) {
            setAddressOptions(res?.data);
          } else if (res?.error !== null) {
            openSnackBanner(res?.error);
          }
        })
        .catch((error) => openSnackBanner(error?.message));
    }
    setLoading(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetch = useCallback(
    _.debounce((value) => fetchAutoCompletedOption(value), 3000),
    [sessionToken, callAutoComplete]
  );

  const handleInputChange = (input, confirmed = false) => {
    setLoading(false);
    setAddressValues({
      boxValue: input,
      inputValue: input ?? ''
    });
    // make google autoComplete API call
    if (input.length >= 3 && !confirmed) {
      setLoading(true);
      // eslint-disable-next-line react-hooks/rules-of-hooks
      debouncedFetch(input);
    }
  };

  const handleValueChange = (selection) => {
    if (typeof selection === 'string') {
      setIsCustomChange(true);
      valueOnChange(selection, isCustomChange);
    } else if (selection !== null) {
      setIsCustomChange(false);
      const { place_id } = selection;
      setCallAutoComplete(false);
      // make google detail API call once selected
      changeAddressDetail(place_id, selection);
    }
  };

  const changeAddressDetail = async (placeId, selection) => {
    const payload = {
      session: sessionToken,
      place_id: placeId
    };
    let addrDetail;
    apiCall(api.addressDetail, API.METHOD.GET, payload)
      .then(async (res) => {
        setIsLoadingDetail(true);

        if (res.status === API_STATUS_CODE.SUCCESS) {
          addrDetail = res?.data;
          addrDetail[addressProperties.STATE] = findState(
            addrDetail[addressProperties.STATE]
          ).abbreviation;
        } else if (res?.error) {
          openSnackBanner(res?.error);
        }
      })
      .then(() => {
        if (addrDetail !== null) {
          setAddressValues({
            boxValue: selection?.[addressProperties.MAIN_LINE] ?? '',
            inputValue: selection?.[addressProperties.MAIN_LINE] ?? ''
          });

          valueOnChange(
            {
              ...addrDetail,
              main_line: selection?.[addressProperties.MAIN_LINE]
            } ?? ''
          );
          setIsLoadingDetail(false);
        }
      })
      .catch((error) => openSnackBanner(error?.message))
      .finally(() => setSessionToken(uuidv4())); // concludes session token
  };

  const openSnackBanner = (message) => {
    setSnackBanner({
      displaySnackBanner: true,
      snackBannerMessage: message
    });
  };

  const handleSnackBannerClose = () => {
    setSnackBanner({
      displaySnackBanner: false,
      snackBannerMessage: ''
    });
  };

  const PaperComponentCustom = (params) => {
    return (
      <Paper {...params}>
        {params.children}
        {!loading &&
          addressOptions.length !== 0 &&
          PoweredByGoogle({ className: classes.googleTitle })}
      </Paper>
    );
  };

  return (
    <>
      <SnackBanner
        isSnackbarOpen={snackBanner.displaySnackBanner}
        snackbarMessage={snackBanner.snackBannerMessage}
        onSnackBarClose={handleSnackBannerClose}
      />
      <MuiAutoComplete
        {...formRegister(addressProperties.ADDRESS_LINE1)}
        id={id}
        options={addressOptions}
        textInputLabel={AddrLabels.LINE1}
        loading={loading}
        setLoading={setLoading}
        value={addressValues}
        onValueChange={(event, newValue) => {
          if (newValue !== '') {
            handleValueChange(newValue);
          }
        }}
        handleInputChange={(event, newInputValue) => {
          setIsCustomChange(true);
          handleInputChange(newInputValue);
          handleValueChange(newInputValue);
        }}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          return option.description;
        }}
        PaperComponent={PaperComponentCustom}
        renderOption={(option, { inputValue }) => {
          if (typeof option !== 'string' && option !== null) {
            option = option.description;
          }
          const matches = match(option, inputValue);
          const parts = parse(option, matches);

          return (
            <div data-testid={`${option}-test-id`}>
              {parts.map((part, index) => (
                <span style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}
            </div>
          );
        }}
      />
    </>
  );
};

export default StreetAutoComplete;
