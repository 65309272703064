import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Button,
  Divider,
  Grid,
  Fade,
  MobileStepper,
  Typography,
  withStyles
} from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Logo from '../Logo';
import { useWindowSize } from '../../hooks';
import { LANDING_REVIEW_CONTENT } from '../../constants/common';
import styles from './styles';
import { FormattedMessage } from 'react-intl';

const Carousel = ({ classes }) => {
  const {
    btnLabel,
    btnRoot,
    carouselWrapper,
    contentHead,
    contentText,
    contentWrapper,
    gridContainer,
    reviewHead,
    stepperRoot,
    transLeftCard,
    transRightCard
  } = classes;
  const { width } = useWindowSize();
  const [activeStep, setActiveStep] = useState(0);
  const [firstLoad, setFirstLoad] = useState(true);
  const maxSteps = LANDING_REVIEW_CONTENT.length;

  useEffect(() => {
    if (firstLoad) {
      const steps = width <= 960 ? maxSteps : maxSteps - 2;
      setFirstLoad(false);
      for (let index = 1; index <= steps; index++) {
        setTimeout(() => {
          if (index === steps) {
            setActiveStep(0);
          } else {
            setActiveStep((prevActiveStep) =>
              prevActiveStep < steps - 1 ? prevActiveStep + 1 : prevActiveStep
            );
          }
        }, index * 3000);
      }
    }
  }, [activeStep]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getCarouselItem = (step, style) => (
    <Grid item className={style}>
      <Typography className={contentText}>
        <FormattedMessage
          id={`LANDING_REVIEW_CONTENT_${step}_REVIEW`}
          defaultMessage={LANDING_REVIEW_CONTENT[step].review}
        />
      </Typography>
      <Divider />
      <Typography className={contentHead}>
        <Logo
          type={'carousel-avatar'}
          flag={LANDING_REVIEW_CONTENT[step].avatar}
        />
        <span>
          <FormattedMessage
            id={`LANDING_REVIEW_CONTENT_${step}_HEADING`}
            defaultMessage={LANDING_REVIEW_CONTENT[step].heading}
          />
        </span>
      </Typography>
    </Grid>
  );

  return (
    <Grid item container className={gridContainer}>
      <Grid item>
        <Typography className={reviewHead}>
          <FormattedMessage
            id="SAYING"
            defaultMessage="What our members are saying"
          />
        </Typography>
      </Grid>
      <Grid item container className={carouselWrapper}>
        <Fade in={true}>
          {width <= 960 ? (
            <>
              {activeStep > 0 &&
                getCarouselItem(
                  activeStep - 1,
                  classNames(contentWrapper, transLeftCard)
                )}
              {activeStep === 0 && (
                <Grid
                  item
                  className={classNames(contentWrapper, transLeftCard)}
                  style={{ opacity: 0 }}
                ></Grid>
              )}
              {getCarouselItem(activeStep, contentWrapper)}
              {activeStep < maxSteps - 1 &&
                getCarouselItem(
                  activeStep + 1,
                  classNames(contentWrapper, transRightCard)
                )}
              {activeStep === maxSteps - 1 && (
                <Grid
                  item
                  className={classNames(contentWrapper, transRightCard)}
                  style={{ opacity: 0 }}
                ></Grid>
              )}
            </>
          ) : (
            <>
              {activeStep >= 0 && getCarouselItem(activeStep, contentWrapper)}
              {getCarouselItem(activeStep + 1, contentWrapper)}
              {activeStep < maxSteps - 2 &&
                getCarouselItem(activeStep + 2, contentWrapper)}
            </>
          )}
        </Fade>
      </Grid>
      <Grid item>
        <MobileStepper
          variant="dots"
          steps={width <= 960 ? maxSteps : maxSteps - 2}
          position="static"
          activeStep={activeStep}
          className={stepperRoot}
          nextButton={
            <Button
              size="small"
              className={classNames(
                btnRoot,
                width <= 960 && activeStep === maxSteps - 1 && btnLabel,
                width > 960 && activeStep === maxSteps - 3 && btnLabel
              )}
              onClick={handleNext}
              disabled={
                width <= 960
                  ? activeStep === maxSteps - 1
                  : activeStep === maxSteps - 3
              }
              data-testid="next-button"
            >
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button
              size="small"
              className={classNames(btnRoot, activeStep === 0 && btnLabel)}
              onClick={handleBack}
              disabled={activeStep === 0}
              data-testid="back-button"
            >
              <KeyboardArrowLeft />
            </Button>
          }
        />
      </Grid>
    </Grid>
  );
};

Carousel.defaultProps = {
  classes: {}
};

Carousel.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(Carousel);
