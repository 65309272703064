import React from 'react';
import styles from '../styles';
import {
  Button,
  Grid,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import { emailChangeConstants } from '../../constanst';
import { isValidEmailId } from '../../../../utils/common';

const EmailForm = (props) => {
  const { handleEmailInputChange, enteredEmail, handleEmailContinueClick } =
    props;
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12} className={classes.textFieldItem}>
        <TextField
          variant="outlined"
          label="Email"
          onChange={handleEmailInputChange}
          className={classes.textField}
          error={!!enteredEmail && !isValidEmailId(enteredEmail.toLowerCase())}
        ></TextField>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="center">
          <Grid item xs={12} className={classes.userNoteItem}>
            <Typography className={classes.userNoteText}>
              {emailChangeConstants.noteForUser}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.userNoteItemBold}>
            <Typography className={classes.userNoteTextBold}>
              {emailChangeConstants.noteForUserBold}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={classes.continueButtonContainer}>
        <Grid item>
          <Button
            variant="contained"
            disabled={!isValidEmailId(enteredEmail?.toLowerCase())}
            onClick={handleEmailContinueClick}
            className={classes.continueButton}
            classes={{ root: classes.continueButtonRoot }}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default EmailForm;
