/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import { makeStyles, Grid, Box, CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import styles from './styles';
import FormSelect from '../../components/FormSelect';
import { SaveButton } from '../../components/Buttons/index.jsx';
import {
  activityFrequencyNumber,
  activityFrequencyPeriod,
  unlimitedActivityFreq
} from './constanst';
import { parseActivityValue } from './utils.js';

const ActivityFrequencyEditor = (props) => {
  const { onSave, frequency, period, isSaveLoading } = props;
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const [frequencyValueToSave, setFrequencyValueToSave] = useState(
    parseActivityValue(frequency)
  );
  const [periodValueToSave, setPeriodValueToSave] = useState(period);

  const disabledPeriod =
    periodValueToSave === 'None' ||
    frequencyValueToSave === unlimitedActivityFreq
      ? true
      : false;

  useEffect(() => {
    if (frequencyValueToSave === unlimitedActivityFreq) {
      setPeriodValueToSave(' ');
    }
  }, [frequencyValueToSave]);

  const isValidActivityFreq = () => {
    if (periodValueToSave === (' ' || 'None')) {
      return frequencyValueToSave === unlimitedActivityFreq;
    } else {
      return frequencyValueToSave;
    }
  };

  const onDetailSave = () => {
    onSave({ frequencyValueToSave, periodValueToSave });
  };

  const circularLoader = (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <CircularProgress size={20} thickness={2} />
    </Box>
  );

  return (
    <Grid container className={classes.activityEditWrapper}>
      <Grid container>
        <Grid item xs={5}>
          <FormSelect
            id="times"
            label="Number of Activities"
            value={frequencyValueToSave}
            selectedValue={setFrequencyValueToSave}
            menuItems={activityFrequencyNumber}
            maxHeight={200}
          />
        </Grid>
        <Grid
          container
          item
          xs={2}
          className={disabledPeriod && classes.disabledText}
          justifyContent="center"
        >
          per
        </Grid>
        <Grid item xs={5}>
          <FormSelect
            disabled={frequencyValueToSave === unlimitedActivityFreq}
            id="period"
            label="Period"
            value={disabledPeriod ? ' ' : periodValueToSave}
            selectedValue={setPeriodValueToSave}
            menuItems={activityFrequencyPeriod}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={1}>
          {isSaveLoading ? (
            circularLoader
          ) : (
            <SaveButton
              onButtonClick={onDetailSave}
              testId="save-activityfreq"
              disabled={!isValidActivityFreq()}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ActivityFrequencyEditor;
