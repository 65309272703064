import { Box, Container, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useStyles } from './styles';
import MobileDownloadBanner from '../SignIn/MobileDownloadBanner';
import { useLocation, useHistory } from 'react-router-dom';
import { getQueryStringValue } from '../../utils/common';
import { FormattedMessage } from 'react-intl';

function EmailUnsubscribe() {
  const location = useLocation();
  const history = useHistory();
  const styles = useStyles();

  useEffect(() => {
    const successQueryParam = getQueryStringValue(location, 'success');
    if (successQueryParam !== 'true') {
      history.replace('/');
    }
  }, [location, history]);

  return (
    <Container maxWidth="sm" className={styles.container}>
      <Box display="flex" justifyContent="center">
        <img
          src="/assets/images/walmart-customer-spark-dialog.jpg"
          alt="customer spark logo"
          className={styles.logo}
        />
      </Box>
      <Box className={styles.titleContainer}>
        <Typography className={styles.titleText}>
          <FormattedMessage
            id="EMAIL_UNSUB_TITLE"
            defaultMessage="You have been unsubscribed from Customer Spark Community marketing and
          promotional emails."
          />
        </Typography>
      </Box>
      <Box display="flex" my="8px" flexDirection="column" gridRowGap="40px">
        <Typography className={styles.contentText}>
          <FormattedMessage
            id="EMAIL_UNSUB_TEXT1"
            defaultMessage="This means that you will not receive email notifications when you have
          opportunities to earn points as part of the Community. If you did this
          in error or would like to still receive these emails, please follow up
          with your email provider as to how you can resubscribe to our emails."
          />
        </Typography>

        <Typography className={styles.contentText}>
          <FormattedMessage
            id="EMAIL_UNSUB_TEXT2"
            defaultMessage="You may still participate in activities by checking the Customer Spark
            Community apps. Please review your activity preferences to ensure you
            are opted into activities."
          />
        </Typography>
      </Box>

      <MobileDownloadBanner />
    </Container>
  );
}

export default EmailUnsubscribe;
