export const activityFrequencyNumber = [
  'Unlimited activities',
  '1 activity',
  '2 activities',
  '3 activities',
  '4 activities',
  '5 activities',
  '6 activities',
  '7 activities',
  '8 activities',
  '9 activities'
];

export const unlimitedActivityFreq = 'Unlimited activities';

export const activityFrequencyPeriod = ['Day', 'Week', 'Month'];

export const profileAPITypes = {
  ICON: 'icon',
  PERSONAL: 'personal',
  HOUSEHOLD: 'household',
  SHOPPING: 'shopping',
  ACTIVITY_PREFERENCE: 'activitypreference',
  PERSONAL_INFO: 'personInfo',
  HOUSEHOLD_INFO: 'householdInfo',
  ACTIVITY_PREF: 'activityPref'
};

export const profileAPISelectionTypes = {
  MULTIPLE: 'multiple',
  BOOLEAN: 'boolean',
  SINGLE: 'single',
  PASSWORD: 'password_text',
  ADDRESS: 'address_type',
  JSON: 'json'
};

export const profileHeaders = {
  personal: 'Personal Information',
  household: 'Household Information',
  shopping: 'Shopping',
  activitypreference: 'Activity Preferences',
  accountPreference: 'Account Preferences'
};

export const disableOtherOptions = [
  'None of the above',
  'No Pharmacy',
  'Prefer not to answer'
];

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const monthMap = {
  January: '01',
  February: '02',
  March: '03',
  April: '04',
  May: '05',
  June: '06',
  July: '07',
  August: '08',
  September: '09',
  October: '10',
  November: '11',
  December: '12'
};

export const addressProperties = {
  ADDRESS_LINE1: 'address_line1',
  ADDRESS_LINE2: 'address_line2',
  CITY: 'panelist_city',
  STATE: 'panelist_st',
  ZIP: 'panelist_zip',
  LATITUDE: 'latitude',
  LONGITUDE: 'longitude',
  MAIN_LINE: 'main_line'
};

export const requiredAddressProps = [
  addressProperties.ADDRESS_LINE1,
  addressProperties.CITY,
  addressProperties.STATE,
  addressProperties.ZIP
];

export const APIConstants = {
  detailNames: {
    DOB: 'per_dob',
    zipCode: 'per_zip_code',
    address: 'per_address'
  },
  selectionTypes: {
    DATE: 'date_type',
    TEXT: 'open_text',
    PASSWORD: 'password_text',
    SINGLE: 'single',
    MULTIPLE: 'multiple',
    JSON: 'json'
  }
};

export const userProfileFreeTexts = {
  zipCodeErrorMsg: 'Please enter a valid zip code.',
  cityCodeErrorMsg: 'Please enter a valid city name'
};

export const emailChangeConstants = {
  pageTitle: 'Change Email',
  pageSubTitle: 'Please provide the information below',
  noteForUser:
    'Please note: Once the new email is changed, this will be your main email for Customer Spark.',
  noteForUserBold:
    'Once you change your email you will need to log back in to successfully apply the changes.',
  resentOtpText: 'Didn’t get a code?',
  formState: {
    email: 'email',
    otp: 'otp'
  },
  alertTypes: {
    sentOtp: 'sentOtp',
    emailChanged: 'emailChanged'
  },
  submitOtpDialogMsg:
    'You successfully changed your email. You will need to login again to access the community!'
};

export const CHANGE_PASSWORD = {
  FORM_CONSTANTS: {
    HEADER: 'Change Password',
    SUB_HEADER: 'Please provide the information below',
    SUBMIT_BUTTON: {
      label: 'Change Password',
      id: 'CHANGE_PASSWORD',
      variant: 'contained'
    },
    TEXT_FIELDS: {
      CURRENT_PASSWORD: {
        id: 'CURRENT_PASSWORD',
        name: 'currentPassword',
        label: 'Current Password',
        variant: 'outlined'
      },
      NEW_PASSWORD: {
        id: 'NEW_PASSWORD',
        name: 'newPassword',
        label: 'New Password',
        variant: 'outlined'
      },
      CONFIRM_PASSWORD: {
        id: 'CONFIRM_PASSWORD',
        name: 'confirmPassword',
        label: 'Confirm Password',
        variant: 'outlined'
      }
    },
    ERROR_MSG_TEXTS: {
      INVALID_PASSWORD: 'Please enter a valid password.',
      DONT_MATCH: 'Passwords do not match',
      STRENGTH: 'Strong password required'
    },
    PASS_VALIDATION_HEADER: 'Strong password required:'
  }
};

export const CCPA = {
  UNSUB_CONFIRM_MSG:
    'You just selected that you do NOT agree to our updated terms. Please confirm that you understand that you will be unsubscribed and removed from the Customer Spark Community and that you will not receive additional opportunities for further points. This cannot be undone.'
};

export const AddrConfirmation = {
  title: 'Address Confirmation',
  description:
    'Please review the information below, and select the address you wish to use',
  addressEntered: 'Address Entered',
  suggestedAddress: 'Suggested Address'
};

export const AddrLabels = {
  LINE1: 'Street Address',
  LINE2: 'Apt, suite, etc. (Optional)',
  CITY: 'City',
  STATE: 'State',
  ZIP: 'Zip'
};

export const EmptyAddress = 'Please enter your address';

export const zipStateNotMatch =
  'Entered zip code and state do not correspond. Please provide a valid entry and try again.';

export const zipValidateFail = 'Fail to validate the zip code you entered';
