import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import styles from './styles';
import Logo from '../Logo';
import HomeFooter from '../../components/Footer/HomeFooter';
import { useHistory } from 'react-router-dom';

const CSLayout = (props) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const history = useHistory();

  const handleHomeClick = () => {
    history.push('/home', 'homeLogoEvent');
  };

  return (
    <Grid container className={classes.root}>
      <Grid container className={classes.homeLinkContainer}>
        <Grid item className={classes.homeLinkButton}>
          <Logo callBack={handleHomeClick} />
        </Grid>
      </Grid>
      {props.children}
      <Grid container className={classes.footerContainer}>
        <HomeFooter />
      </Grid>
    </Grid>
  );
};

export default CSLayout;
