import React from 'react';
import MicroSurveys from './container';
import { Provider } from 'react-redux';
import store from './store';

function MicroSurveysRoot() {
  return (
    <Provider store={store}>
      <MicroSurveys />
    </Provider>
  );
}

export default MicroSurveysRoot;
