const styles = () => ({
  snackbarRoot: {
    maxWidth: 444,
    fontSize: 16,
    fontWeight: 500,
    color: '#041e42',
    backgroundColor: '#cce3f5',
    textAlign: 'center'
  },
  errorSnack: {
    maxWidth: 444,
    fontSize: 16,
    fontWeight: 500,
    color: '#9B1419',
    backgroundColor: '#FCE8E9',
    textAlign: 'center',
    border: '1px solid #EF8E92',
    borderRadius: '4px'
  }
});

export default styles;
