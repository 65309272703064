const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: 64,
    [theme.breakpoints.up('sm')]: {
      marginTop: 96,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    [theme.breakpoints.up('md')]: {
      alignItems: 'unset'
    }
  },
  accordionContainer: {
    display: 'flex',
    margin: 20,
    padding: 20,
    flexWrap: 'wrap'
  },
  headerIcon: {
    marginLeft: '30px',
    marginRight: '16px',
    display: 'flex'
  },
  accordionWrapper: {
    padding: '0px 5px'
  },
  accordion: {
    margin: '0px !important',
    boxShadow: 'none'
  },
  accordionDetails: {
    padding: 0,
    margin: '0px !important'
  },
  activityEditWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between'
  },
  activitySelect: {
    width: '40%'
  },
  avatarWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '24px 0',
    [theme.breakpoints.up('sm')]: {
      margin: '24px 0 75px 0'
    }
  },
  backClick: {
    display: 'flex',
    alignItems: 'center',
    color: '#333333'
  },
  backIcon: {
    display: 'flex',
    justifyContent: 'center',
    margin: '0 16px',
    [theme.breakpoints.up('sm')]: {
      margin: '0 16px 0 30px',
      width: 24,
      height: 24
    }
  },
  detailWrapper: {
    display: 'block',
    paddingBottom: 32
  },
  detailsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 32,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 25
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: 63,
      paddingLeft: 25
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 93
    }
  },
  editSave: {
    width: 'inherit',
    textAlign: 'end',
    transform: 'translateY(25px)'
  },
  extraMarginTop: {
    marginTop: 20,
    marginBottom: 20
  },
  featureContainer: {
    padding: '0 10px'
  },
  formControl: {
    margin: '8px 8px 8px 0',
    width: '85%',
    [theme.breakpoints.up('sm')]: {
      width: '85%'
    }
  },
  icon: {
    width: 20,
    height: 20,
    margin: '3px 16px 0 0',
    [theme.breakpoints.up('sm')]: {
      width: 24,
      height: 24
    }
  },
  iconPadding: {
    marginBottom: 66,
    '&.addExtra': {
      marginBottom: 118
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: 32
    }
  },
  toolTipButton: {
    width: 0.1,
    height: 0.1,
    marginLeft: 5,
    [theme.breakpoints.up('sm')]: {
      width: 0.1,
      height: 0.1,
      marginLeft: 5
    }
  },
  toolTipIcon: {
    width: 13,
    height: 13,
    paddingBottom: 10,
    paddingLeft: 10,
    [theme.breakpoints.up('sm')]: {
      width: 10,
      height: 10
    }
  },
  nav: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    height: 60,
    background: '#fff',
    zIndex: 2,
    boxShadow: '0 0px 10px 0px rgb(0 0 0 / 10%)',
    color: '#333333',
    '& p': {
      fontSize: 18,
      [theme.breakpoints.up('sm')]: {
        fontSize: 24
      }
    }
  },
  profile: {
    alignSelf: 'center',
    [theme.breakpoints.up('md')]: {
      width: '45%'
    }
  },
  profileEdit: {
    width: 25,
    height: 20,
    fontSize: 14,
    color: '#0071ce',
    [theme.breakpoints.up('sm')]: {
      marginRight: 32
    },
    [theme.breakpoints.up('md')]: {
      marginRight: 0
    }
  },
  profileEditDate: {
    alignSelf: 'flex-end'
  },
  profileH3Name: {
    fontSize: 18,
    textAlign: 'left',
    flexGrow: 1
  },
  profileH3IconWrap: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      marginRight: 32
    }
  },
  profileH3Value: {
    fontSize: 18,
    fontWeight: 300
  },
  profileHead: {
    display: 'flex',
    alignItems: 'baseline',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'start'
    }
  },
  profileRowWrap: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      width: 456,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      '&.textFieldFlex': {
        flexDirection: 'column'
      }
    }
  },
  profileSave: {
    display: 'flex',
    alignItems: 'center',
    height: 40,
    marginTop: 16,
    [theme.breakpoints.up('md')]: {
      width: 194
    }
  },
  saveButton: {
    fontSize: 14,
    textTransform: 'none'
  },
  snackbarRoot: {
    maxWidth: 444,
    fontSize: 16,
    fontWeight: 500,
    color: '#041e42',
    backgroundColor: '#cce3f5',
    textAlign: 'center'
  },
  summary: {
    fontSize: 20,
    [theme.breakpoints.up('md')]: {
      marginRight: 100
    }
  },
  switch: {
    width: 'inherit',
    textAlign: 'end',
    transform: 'translateY(25px)'
  },
  switchLabel: {
    marginRight: 0,
    fontSize: 16
  },
  textField: {
    width: 288,
    height: 54,
    fontSize: 14,
    borderRadius: 4,
    backgroundColor: '#ffffff',
    marginTop: 16,
    [theme.breakpoints.up('sm')]: {
      height: 54,
      fontSize: 14
    },
    [theme.breakpoints.up('md')]: {
      height: 54
    }
  },
  unsubscribe: {
    fontSize: 14,
    textTransform: 'none'
  },
  unsubscribeWrapper: {
    marginTop: 64,
    marginBottom: 74,
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      marginBottom: 0
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: 48
    }
  },
  wrapperMdView: {
    [theme.breakpoints.up('md')]: {
      width: 248
    }
  },

  lowerBorderLine: {
    height: '1px',
    background: '#DADCE0',
    border: ' #DADCE0',
    margin: 'auto',
    marginTop: '80px',
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      marginTop: '30px',
      width: '100%'
    }
  },
  textFieldError: {
    marginBottom: 17
  },
  textFieldErrorMsg: {
    color: 'rgba(213, 34, 28, 1)',
    fontSize: 12,
    fontWeight: 400,
    textAlign: 'left'
  },
  errorIcon: {
    color: 'rgba(213, 34, 28, 1)',
    height: 20,
    width: 20,
    marginRight: 5
  },
  googleIcon: {
    margin: '5px 5px 0 5px'
  },
  disabledText: {
    color: `rgba(0, 0, 0, 0.38)`
  }
});

export default styles;
